import { Button, Grid, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { DocumentType, DocumentTypeDisplay } from "../../types/enums";
import { IClaimFileState, ISupportingDocTypeProps, IStringValue } from '../../types/types';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SecuritiesLoader from '../../components/Shared/SecuritiesLoader';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { setSupportingDocTypeProps } from './claimFile.actions';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import InfoIcon from '@mui/icons-material/Info';
import authService from '../../components/api-authorization/AuthorizeService';
import { Transform } from 'stream';

const createLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            flexGrow: 1,
            color: 'black',
            fontSize: "1rem",
            display: "inline-flex"
        },
        disabledButton: {
            backgroundColor: '#cfcfcf !important',
        },
        primaryBtn: {
            color: '#fff!important',
            backgroundColor: '#007bff!important'
        },
        secondaryBtn: {
            color: 'blue',
            backgroundColor: '#fff!important',
            border: '1px solid',
            borderColor: 'blue'
        },
        paragraph: {
            marginTop: '10px',
            marginBottom: '0px'
        },
        inputDiv: {
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
        },
        inputTextField: {
            width: "75%",
            marginRight: "5px"
        },
        uploadedBtn: {
            color: 'white!important',
            backgroundColor: '#28a745!important'
        },
        supportingDocumentIcon: {
            fontSize: "20px;",
            color: "#d7d5d5",
        },
        dashBoardStartedWidth: {
            width: "500px",
        }
    }),
);

interface ISupportingDocFileUploadInputProps {
    id: DocumentType,
    supportingDocFileProps: ISupportingDocTypeProps[],
    stringContentData: IStringValue[],
    hasAllFiles: Function
}

const SupportingDocFileUploadInput = ({ id, supportingDocFileProps, stringContentData, hasAllFiles }: ISupportingDocFileUploadInputProps) => {
    const dispatch = useDispatch();
    const [localFile, setLocalFile] = useState<File>();
    const classes = createLocalStyles();
    const claimFileState: IClaimFileState = useSelector((state: any) => state.claimFileState);
    let docFileProps: ISupportingDocTypeProps = supportingDocFileProps.find(r => r.id == id)!;
    const docTypeDescription: string = docFileProps.docTypeDescription;
    const existingFileName: string = docFileProps.existingFileName;
    const fileIsCorrectType: Function = docFileProps.fileIsCorrectType;
    const tooltipText: string = docFileProps.tooltipText || "";
    let progress: number = docFileProps.progress;
    let isUploading: boolean = docFileProps.isUploading;
    let isUploaded: boolean = docFileProps.isUploaded;
    let isNotProvided: boolean = docFileProps.isNotProvided;
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    let [showContent, setShowContent] = useState(false);
    const [doctypeText, setDoctypeText] = useState<string>("");

    useEffect(() => {

        if (localFile && !docFileProps.file) {
            docFileProps.file = localFile;

            let payload = {
                supportingDocTypeProps: supportingDocFileProps
            };

            dispatch(setSupportingDocTypeProps(payload));
        }
    }, [localFile, progress, isNotProvided, isUploading, isUploaded, claimFileState]);

    const selectFile = (event: any) => {
        setLocalFile(event.target.files[0]);
        hasAllFiles();
    };

    const hasFile = (): boolean => {
        return (localFile && localFile !== undefined)
            ? true
            : false;
    };

    const getContent = (event: any, docTypeDescription: string): void => {

        if (stringContentData !== null)
            stringContentData?.map((content) => {

                if (content.key.toLowerCase().replaceAll(" ", "") === docTypeDescription.toLowerCase().replaceAll(" ", "")) {
                    setDoctypeText(content.value);
                    setShowContent(!showContent);
                    setTarget(event.target);
                }

            });
    };

    return (
        <section id={id.toString()}>
            <Grid item xs={12}>

                {
                    docTypeDescription === DocumentTypeDisplay.Other ?
                    <Tooltip title={tooltipText} placement="bottom-start">
                        <Typography variant="h6" noWrap>
                            <p className={classes.paragraph}>{docTypeDescription}</p>

                        </Typography>
                    </Tooltip>
                    :
                    <Typography variant="h6" className={classes.title} noWrap>
                        <p className={classes.paragraph}>{docTypeDescription}</p>
                    </Typography>
                }
                <div style={{ position: "relative", display: "inline-flex" }} ref={ref}>
                    <Button style={{ background: "none", outline: "none", position: "absolute", left: "5px", padding: "0", top: "-18px", justifyContent: "left" }}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            getContent(event, docTypeDescription);
                        }}
                    >
                        <InfoIcon className={classes.supportingDocumentIcon} />
                    </Button>
                    <Overlay
                        show={showContent}
                        target={target}
                        placement="right"
                        container={ref}
                        containerPadding={20}
                        rootClose
                        onHide={() => setShowContent(false)}
                    >
                        <Popover className={classes.dashBoardStartedWidth} id="popover-contained">
                            <Popover.Header as="h3"><span style={{ color: "#000" }}>{docTypeDescription}</span></Popover.Header>
                            {doctypeText && (
                                <Popover.Body dangerouslySetInnerHTML={{ __html: doctypeText }}></Popover.Body>
                            )}
                        </Popover>
                    </Overlay>
                </div>
            </Grid>
            <Grid item xs={12}>
                {
                    progress >= 0 && progress <= 1 &&
                    <SecuritiesLoader variant="determinate" hidden={!isUploading} value={progress * 100} />
                }
            </Grid>
            <Grid item xs={12}>
                <div className={classes.inputDiv}>
                    <TextField
                        className={classes.inputTextField}
                        error={hasFile() && (localFile && !fileIsCorrectType(localFile))}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        multiline
                        variant="outlined"
                        helperText={(hasFile() && (localFile && !fileIsCorrectType(localFile))) && "Unsupported file type"}
                        label={hasFile() && (localFile && !fileIsCorrectType(localFile)) && "Error"}
                        value={localFile && localFile !== undefined
                            ? localFile.name
                            : existingFileName > ""
                                ? existingFileName
                                : 'No file selected...'} >
                    </TextField>

                    <Button
                        classes={isUploaded ? { root: classes.uploadedBtn, disabled: classes.uploadedBtn } :
                            { root: classes.primaryBtn, disabled: classes.primaryBtn }}
                        component="label"
                        color="primary"
                        variant="contained"
                        disabled={isUploaded}
                    >
                        {isUploaded ? "Uploaded" : "Select a File"}
                        <input
                            id={`fileInput${id}`}
                            name={`fileInput${id}`}
                            type="file"
                            accept=".xlsx, .docx, .pdf, .txt"
                            hidden
                            onChange={selectFile} />
                    </Button>

                </div>
            </Grid>
        </section>
    );
};

export default SupportingDocFileUploadInput;