import { IAdminCardCountsState, IAction } from '../../types/types';
import { GET_ADMIN_CARD_COUNT_SUCCESS, GET_ADMIN_CARD_COUNT_FAILURE, GET_ADMIN_CARD_COUNT } from './admin.actions';

const initialState: IAdminCardCountsState = {
    isLoading: false,
    adminCardCounts: { approved: 0, cases: 0, institutions: 0, unApproved: 0 },
    error: null
}

export default (state = initialState, { type, payload }: IAction) => {

    switch (type) {
        case GET_ADMIN_CARD_COUNT:
            return {
                ...state,
                isLoading: true
            }
        case GET_ADMIN_CARD_COUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                adminCardCounts: payload
            }
        case GET_ADMIN_CARD_COUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }

        default:
            return state;
    }

}