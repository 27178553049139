import authService from "../../components/api-authorization/AuthorizeService";

export const getAdminCardCounts = async () => {

    try {
        const token = await authService.getAccessToken();

        let response = await fetch(`api/Dashboard/CardCounts`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (!response.ok)
            throw new Error(response.statusText);

        return response.json();

    } catch (error) {
        console.error(error);
        throw error;
    }
};