import React, { useState } from 'react';
import {
	Checkbox,
	Table,
	TableHead,
	TableBody,
	TableCell,
	TableRow,
	Theme,
	Collapse,
	Typography,
	Box
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IColumnHeader, ISecuritiesTableField } from './SecuritiesTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import { format12hourDateTime } from "../../../utilities/utilities";

interface SecuritiesCollapsibleTableRowProps<T> {
	columns: IColumnHeader[],
	subTableColumns: IColumnHeader[],
	row: any,
	index: number,
	collapse?: boolean,
	subTableTitle?: string
}

const renderField = (field: ISecuritiesTableField): any => {


	switch (field.type) {

		case "datetime":
			if (field.value === null) {
				return '';
			}

			return format12hourDateTime(new Date(field.value));

		case "date":
			if (field.value === null) {
				return '';
			}

			let date = new Date(field.value);
			return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

		case "boolean":
			if (field.value === null) {
				return '';
			}

			return field.value ? <Checkbox checked={true} /> : <Checkbox checked={false} />;
	
		default:
			return field.value
	}
}

const createLocalStyles = makeStyles((theme: Theme) =>
	createStyles({
		tablecell: {
			whiteSpace: "pre-wrap",
			wordWrap: "break-word",
			overflowWrap: "break-word"
		},
		namecell: {
			maxWidth: '380px',
			overflowWrap: "break-word",
			whiteSpace: "pre-wrap",
			wordWrap: "break-word"
		},
		arrowcell: {
			'&:focus': {
				outline: 'none',
			}
		},
		spacer: {
			width: '34px',
			position: 'relative',
			float: 'left'
		}
	}),
);

export default function SecuritiesCollapsibleTableRow<T>({ columns, subTableColumns, row, index, collapse = false, subTableTitle }: SecuritiesCollapsibleTableRowProps<T>) {
	const classes = createLocalStyles();
	const [open, setOpen] = useState(false);

	return (
		<>
			<TableRow key={index}>
				{columns.map((column, idx) => {
					if (!collapse && idx > 0) {
						return <TableCell size="small" key={idx} className={column.field === "name" ? classes.namecell : classes.tablecell} >
							{renderField(row[column.field])}
							
						</TableCell>
					}

					if (!collapse && idx === 0) {
						return <TableCell size="small" key={idx} className={column.field === "name" ? classes.namecell : classes.tablecell}
							style={{
								paddingLeft: '50px'
							}}
						>
							<div className={ classes.spacer}>&nbsp;</div>{renderField(row[column.field])}
						</TableCell>
					}

					if (collapse && idx === 0) {
						return <TableCell size="small" key={idx} className={column.field === "name" ? classes.namecell : classes.tablecell} >
							<IconButton
								className={classes.arrowcell}
								aria-label="expand row"
								size="small"
								onClick={() => {
									setOpen(!open);
								}}

							>
								{open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
							</IconButton>
							{renderField(row[column.field])}
						</TableCell>
					}

					return <TableCell size="small" key={idx} className={column.field === "name" ? classes.namecell : classes.tablecell} >
						{renderField(row[column.field])}
					</TableCell>

				})}
			</TableRow>

			{collapse ?
				<TableRow>
					<TableCell key={0}
						sx={{
							backgroundColor: (theme) => theme.subTableColor,
							paddingBottom: 0,
							paddingTop: 0
						}}
						colSpan={columns.length}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Box sx={{ margin: 1 }}>
								{subTableTitle && <Typography variant="h6" gutterBottom component="div">
									{subTableTitle}
								</Typography>
								}
								<Table size="small" aria-label="related-submissions">
									<TableHead>
										<TableRow>
											{subTableColumns.map((column, idx) => {
												return <TableCell key={idx}>{column.label}</TableCell>
											})}
										</TableRow>
									</TableHead>
									<TableBody>
										{row.subTableRows.value.length && row.subTableRows.value.map((rs: any, index: number) => (
											<TableRow key={index}>
												{subTableColumns.map((column, idx) => {
													if (idx === 0) {
														return (
															<TableCell
																size="small"
																key={idx}
																style={{
																	paddingLeft: '50px'
																}}
																className={classes.tablecell} >
																{renderField(rs[column.field])}
															</TableCell>)
													}
													return (
														<TableCell
															size="small"
															key={idx}
															className={classes.tablecell} >
															{renderField(rs[column.field])}
														</TableCell>)
												})}
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</TableRow>
				: null
			}
		</>

	)
}