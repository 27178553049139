import { ICmxTrackingNumberList } from '../../types/types';

export const GET_CLAIMANT_STATUS_BY_ID_LIST = 'GET_CLAIMANT_STATUS_BY_ID_LIST';
export const GET_CLAIMANT_STATUS_BY_ID_LIST_SUCCESS = 'GET_CLAIMANT_STATUS_BY_ID_LIST_SUCCESS';
export const GET_CLAIMANT_STATUS_BY_ID_LIST_FAILED = 'GET_CLAIMANT_STATUS_BY_ID_LIST_FAILED';
export const CLEAR_CLAIMANT_STATUS_BY_ID_LIST = 'CLEAR_CLAIMANT_STATUS_BY_ID_LIST';

export const getClaimantStatusByIdList = (payload: { cmxTrackingNumberList: ICmxTrackingNumberList }) => ({
	type: GET_CLAIMANT_STATUS_BY_ID_LIST,
	payload
});

export const getClaimantStatusByIdListSuccess = (payload: any) => ({
	type: GET_CLAIMANT_STATUS_BY_ID_LIST_SUCCESS,
	payload
});

export const getClaimantStatusByIdListFailed = (payload: any) => ({
	type: GET_CLAIMANT_STATUS_BY_ID_LIST_FAILED,
	payload
});

export const clearClaimantStatusList = () => ({
	type: CLEAR_CLAIMANT_STATUS_BY_ID_LIST
});

