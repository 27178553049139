import { object, string } from 'yup';
import { IInstitution, IInstitutionType, IInstitutionListState } from "../../types/types";

import { Grid, Stack, Theme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import makeStyles from '@mui/styles/makeStyles';
import { updateInstitutionAction } from "./institutionlist.actions";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { useSignal } from '@preact/signals-react';
import { getCountriesStatesAsync } from './institutionlist.api';
import { formatUSZipCode } from '../../utilities/utilities';

const useStyles: any = makeStyles((theme: Theme) => ({
    commonbackgroundcard: {
        boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 30%)',
        padding: '1.5rem 1rem',
        margin: '2rem 1rem 0',
        background: '#fff',
        borderRadius: '5px',
    },
    required: {
        color: 'red',
    },
    institutionDetailsLabel: {
        position: 'relative',
        top: '10px',
        left: '20px',
        background: '#fff',
        fontSize: '14px',
        padding: '5px',
        zIndex: '9',
        color: '#9e9e9e'
    }
}));

interface IInstitutionDetailFormProps {
    institutionDetail: IInstitution;
    institutionTypeList: IInstitutionType[];
    countriesStatesInstitution: any
}

function formatZipCode(country: string, zipCode: string) {
    if (!zipCode) {
        return '';
    }

    if (country === 'UNITED STATES' || country === 'US') {
        zipCode = zipCode.replace('-', '');

        return formatUSZipCode(zipCode);
    }

    if ((country === "CANADA")) {

        zipCode = zipCode.replace(' ', '');
        var isNumeric = (Number.isInteger(Number(zipCode)))

        if (zipCode.length == 6 && !isNumeric) {
            var x: any = zipCode.match(/(\w{3})(\w{3})/);
            return (!x[2] ? x[1] : (x[1] ? x[1] + ' ' + x[2] : ''));
        }
        else {
            return !isNumeric ? zipCode : "";
        }

    }

    return zipCode.replace(' ', '').replace('-', '');
}

export default function InstitutionDetailForm({ institutionDetail, institutionTypeList, countriesStatesInstitution }: IInstitutionDetailFormProps) {
    const [selectedInstitution, setselectedInstitution] = useState<IInstitution>({ ...institutionDetail });
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const institutionListState: IInstitutionListState = useSelector((state: any) => state.institutionListState);
    const [selectedCountry, setSelectedCountry] = useState(institutionDetail.country);
    const [selectedState, setSelectedState] = useState(institutionDetail.stateProvince);
    const [needsRedirect, setNeedsRedirect] = useState(false);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(institutionDetail.phone);
    const [selectedInstitutionType, setSelectedInstitutionType] = useState(institutionDetail.institutionTypeId);
    const [selectedZipCode, setSelectedZipCode] = useState(institutionDetail.zipCode);

    const countriesStates = useSignal(countriesStatesInstitution);

    // Fetch the dictionary of countries with corresponding states
    useEffect(() => {
        const fetchData = async () => {
            try {
                let data = await getCountriesStatesAsync();
                countriesStates.value = data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (!countriesStates.value)
            fetchData();
    }, []);

    document.title = (selectedInstitution === null || selectedInstitution === undefined) ? 'Institution Detail' : "Epiq Filling - " + selectedInstitution?.name;

    useEffect(() => {
        setselectedInstitution({ ...institutionDetail });
        setSelectedCountry(institutionDetail.country);

        if (countriesStates.value) {
            if (institutionDetail.country) {
                if (countriesStates.value[institutionDetail.country]) {
                    setSelectedCountry(institutionDetail.country);
                }
            }
        }

        setSelectedState(institutionDetail.stateProvince);

        setSelectedPhoneNumber(institutionDetail.phone);

        setSelectedInstitutionType(institutionDetail.institutionTypeId);

        const formattedZipCode = formatZipCode(selectedCountry, institutionDetail.zipCode).trim();
        setSelectedZipCode(formattedZipCode);
    }, [institutionDetail, countriesStates.value]);

    useEffect(() => {
        if (needsRedirect && institutionListState && !institutionListState.isLoading) {
            if (institutionListState.updateInstitutionResponse && institutionListState.updateSuccess) {
                history.replace('/InstitutionList');
            }
        }
    }, [institutionListState]);

    const handleSubmitUpdateInstitution = (e: any) => {

        const finalInst = {
            ...selectedInstitution,
            country: selectedCountry,
            stateProvince: selectedState === 'null' ? null : selectedState,
            institutionTypeId: selectedInstitutionType,
            phone: selectedPhoneNumber,
            zipCode: selectedZipCode.replace(/[^0-9a-zA-Z]+/g, '')
        }
        setNeedsRedirect(true);
        dispatch(updateInstitutionAction(finalInst));
    };

    const handleOnChange = (e: any) => {
        const { name, value } = e.target;
        setselectedInstitution((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleNumberChange = (e: any) => {
        setSelectedPhoneNumber(e.target.value);
    };

    const handleZipChange = (e: any) => {
        let zipCode = e.target.value.replace(/[^0-9a-zA-Z- ]+/g, '');
        const formattedZipCode = formatZipCode(selectedCountry, zipCode).trim();
        setSelectedZipCode(formattedZipCode);
    };

    const handleTypeChange = (e: any) => {
        setSelectedInstitutionType(Number(e.target.value));
    }

    const handleCountrySelect = (e: any) => {
        setSelectedState('null')
        setSelectedCountry(e.target.value);

        if (selectedZipCode) {
            let zipCode = selectedZipCode.replace(/[^0-9a-zA-Z- ]+/g, '');
            const formattedZipCode = formatZipCode(e.target.value, zipCode).trim();
            setSelectedZipCode(formattedZipCode);
        }
    }

    const handleStateSelect = (e: any) => {
        setSelectedState(e.target.value);
    }

    const cancelEditInstitution = () => {
        history.push("/InstitutionList");
    }

    const phoneRegExp = /^[0-9\-+() ]*$/;
    const zipRegExp = /^(\d{5}-\d{1,4}|\d{5,9}|[0-9a-zA-Z]{0,9})$|^([a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d)$/;

    const validationSchema = object().shape({
        name: string()
            .required('Institution Name is required')
            .min(2, "Institution Name should be more than 2 characters."),
        address1: string()
            .required('Address Line 1 is required'),
        country: string(),
        zipCode: string()
            .when('country', (country, schema) => country === "UNITED STATES" || country === "CANADA" ?
                schema.required('Zip is required')
                    .matches(zipRegExp, "Invalid zip code format")
                    .min(5, "5 digit minimum") : schema),
        city: string()
            .required('City is required'),
        emailAddress: string()
            .email('Email is invalid')
            .required('Institution Group Contact Email  is required'),
        phone: string()
            .required("Institution Main Phone is required")
            .matches(phoneRegExp, 'Valid characters are 0-9, -, +, (, ), and space.')
            .min(10, "Minimum 10 characters.")
            .max(25, "Max 25 characters.")

    });

    const saveButtonIsDisabled = (): boolean => {
        return ((institutionListState.errorGetInstitutionType || institutionListState.errorGetCountriesStates || institutionListState.errorGetInstitutionById || institutionListState.errorUpdateInstitution))
            ? true : false
    };

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({ resolver: yupResolver(validationSchema) });

    return (
        <Fragment>
            {/*form*/}
            <form>
                <Grid container spacing={2}>
                    {/*name*/}
                    <Grid style={{ paddingTop: '0' }} item xs={12} sm={6} lg={6}>
                        <div className="form-group mb-0">
                            <label className={classes.institutionDetailsLabel}> Institution Name <span className={classes.required}> * </span></label>
                            <input
                                type="text"
                                {...register('name')}
                                className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                                style={{
                                    height: '3rem',
                                    border: '1px solid #707070',
                                }}
                                value={selectedInstitution?.name || ''}
                                onChange={handleOnChange}
                                autoFocus
                                maxLength={64}
                            />
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        </div>
                    </Grid>

                    {/*institution type*/}
                    <Grid style={{ paddingTop: '0' }} item xs={12} sm={6} lg={6}>
                        <div className="form-group mb-0">
                            <label className={classes.institutionDetailsLabel}>Institution Type <span className={classes.required}> * </span></label>
                            <select
                                {...register('institutionTypeId')}
                                className={`form-control ${errors.institutionTypeId ? 'is-invalid' : ''}`}
                                style={{
                                    height: '3rem',
                                    border: '1px solid #707070',
                                }}
                                onChange={handleTypeChange} value={selectedInstitutionType || 0}>
                                {institutionTypeList && institutionTypeList.map((item: any, index: any) =>
                                    <option key={index} value={item.value}>{item.key}</option>)}
                            </select>
                            <div className="invalid-feedback">{errors.institutionTypeId?.message}</div>
                        </div>
                    </Grid>

                    {/*phone*/}
                    <Grid style={{ paddingTop: '0' }} item xs={12} sm={6} lg={6}>
                        <div className="form-group mb-0">
                            <label className={classes.institutionDetailsLabel}>Institution Main Phone <span className={classes.required}> * </span></label>
                            <input
                                type="text"
                                {...register('phone')}
                                className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                                style={{
                                    height: '3rem',
                                    border: '1px solid #707070',
                                }}
                                value={selectedPhoneNumber || ''}
                                placeholder="(999) 999-9999"
                                onChange={e => handleNumberChange(e)}
                            />
                            <div className="invalid-feedback">{errors.phone?.message}</div>
                        </div>

                    </Grid>

                    {/*email*/}
                    <Grid style={{ paddingTop: '0' }} item xs={12} sm={6} lg={6}>
                        <div className="form-group mb-0">
                            <label className={classes.institutionDetailsLabel}>Institution Group Contact Email  <span className={classes.required}> * </span></label>
                            <input
                                type="email"
                                {...register('emailAddress')}
                                className={`form-control ${errors.emailAddress ? 'is-invalid' : ''}`}
                                style={{
                                    height: '3rem',
                                    border: '1px solid #707070',
                                }}
                                value={selectedInstitution?.emailAddress || ''}
                                onChange={handleOnChange}

                            />
                            <div className="invalid-feedback">{errors.emailAddress?.message}</div>
                        </div>
                    </Grid>

                    {/*address1*/}
                    <Grid style={{ paddingTop: '0' }} item xs={12} sm={6} lg={6}>
                        <div className="form-group mb-0">
                            <label className={classes.institutionDetailsLabel}>Address Line 1 <span className={classes.required}> * </span></label>
                            <input
                                type="text"
                                {...register('address1')}
                                className={`form-control ${errors.address1 ? 'is-invalid' : ''}`}
                                style={{
                                    height: '3rem',
                                    border: '1px solid #707070',
                                }}
                                value={selectedInstitution?.address1 || ''}
                                onChange={handleOnChange}
                                maxLength={70}
                            />
                            <div className="invalid-feedback">{errors.address1?.message}</div>
                        </div>
                    </Grid>

                    {/*address2*/}
                    <Grid style={{ paddingTop: '0' }} item xs={12} sm={6} lg={6}>
                        <div className="form-group mb-0">
                            <label className={classes.institutionDetailsLabel}>Address Line 2</label>
                            <input
                                type="text"
                                {...register('address2')}
                                className={'form-control'}
                                style={{
                                    height: '3rem',
                                    border: '1px solid #707070',
                                }}
                                value={selectedInstitution?.address2 || ''}
                                onChange={handleOnChange}
                                maxLength={70}
                            />
                        </div>
                    </Grid>

                    {/*address3*/}
                    <Grid style={{ paddingTop: '0' }} item xs={12} sm={6} lg={6}>
                        <div className="form-group mb-0">
                            <label className={classes.institutionDetailsLabel}>Address Line 3</label>
                            <input
                                type="text"
                                {...register('address3')}
                                className={'form-control'}
                                style={{
                                    height: '3rem',
                                    border: '1px solid #707070',
                                }}
                                value={selectedInstitution?.address3 || ''}
                                onChange={handleOnChange}
                                maxLength={70}
                            />
                        </div>
                    </Grid>

                    {countriesStates.value &&
                        <>
                            {/*country*/}
                            <Grid style={{ paddingTop: '0' }} item xs={12} sm={6} lg={6}>
                                <div className="form-group mb-0">
                                    <label className={classes.institutionDetailsLabel}>Country <span className={classes.required}> * </span></label>
                                    <select
                                        className={`form-control ${errors.country ? 'is-invalid' : ''}`}
                                        style={{
                                            height: '3rem',
                                            border: '1px solid #707070',
                                        }}
                                        {...register('country')}
                                        onChange={e => handleCountrySelect(e)}
                                        value={selectedCountry ? selectedCountry.toUpperCase() : ''}
                                    >
                                        {countriesStates.value && Object.keys(countriesStates.value).map((name: string, index: number) => (
                                            <option key={index} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="invalid-feedback">{errors.country?.message}</div>
                                </div>
                            </Grid>

                            {/*city*/}
                            <Grid style={{ paddingTop: '0' }} item xs={12} sm={4} lg={4}>
                                <div className="form-group mb-0">
                                    <label className={classes.institutionDetailsLabel}>City <span className={classes.required}> * </span></label>
                                    <input
                                        type="text"
                                        {...register('city')}
                                        className={`form-control ${errors.city ? 'is-invalid' : ''}`}
                                        style={{
                                            height: '3rem',
                                            border: '1px solid #707070',
                                        }}
                                        value={selectedInstitution?.city || ''}
                                        onChange={handleOnChange}
                                        maxLength={35}
                                    />
                                    <div className="invalid-feedback">{errors.city?.message}</div>
                                </div>
                            </Grid>

                            {selectedCountry
                                && countriesStates.value[selectedCountry] &&
                                <>
                                    {/*state*/}
                                    <Grid style={{ paddingTop: '0' }} item xs={12} sm={4} lg={4}>
                                        <div className="form-group mb-0">
                                            <label className={classes.institutionDetailsLabel}>State</label>
                                            <select
                                                className={'form-control'}
                                                style={{
                                                    height: '3rem',
                                                    border: '1px solid #707070',
                                                }}
                                                name='stateProvince'
                                                onChange={e => handleStateSelect(e)}
                                                value={selectedState || ''}
                                            >
                                                <option value='null'> -- Select -- </option>
                                                {countriesStates.value[selectedCountry].map((state: any, key: any) => (
                                                    <option key={key} value={state}>
                                                        {state}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </Grid>
                                </>
                            }
                        </>}

                    {/*zip code*/}
                    <Grid style={{ paddingTop: '0' }} item xs={12} sm={4} lg={4}>
                        <div className="form-group mb-0">
                            <label className={classes.institutionDetailsLabel}>Zip/Postal <span className={classes.required}> * </span></label>
                            <input
                                type="text"
                                {...register('zipCode')}
                                className={`form-control ${errors.zipCode ? 'is-invalid' : ''}`}
                                style={{
                                    height: '3rem',
                                    border: '1px solid #707070',
                                }}
                                value={selectedZipCode || ''}
                                onChange={e => handleZipChange(e)}

                            />
                            <div className="invalid-feedback">{errors.zipCode?.message}</div>
                        </div>
                    </Grid>
                </Grid>
            </form>

            {/*buttons*/}
            <Grid style={{ paddingTop: '0' }} container justifyContent="flex-end">
                <Stack direction="row" spacing={1}>
                    <button
                        type="button"
                        onClick={cancelEditInstitution}
                        className="btn float-right ml-5 mt-5"
                        style={{
                            width: '100px',
                            fontSize: '16px',
                            color: '#2C3E50',
                            background: 'none',
                            fontWeight: '600',
                            height: '50px'
                        }}
                    >
                        Cancel
                    </button>

                    <button
                        type="button"
                        onClick={handleSubmit(handleSubmitUpdateInstitution)}
                        disabled={saveButtonIsDisabled()}
                        className={!saveButtonIsDisabled() ? "btn btn-primary mt-5" : "btn btn-primary mt-5 disabled"}
                        style={{
                            width: '100px',
                            color: '#2C3E50',
                            fontSize: '16px',
                            fontWeight: '600',
                            height: '50px'
                        }}
                    >
                        Save
                    </button>
                </Stack>
            </Grid>
        </Fragment >
    )
}
