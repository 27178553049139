import { IClaimFileModalProps, IClaimFileUploadResponse, IClaimFileState, IStringValue } from '../../types/types';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Icon,
    Modal,
    TextField,
    Theme,
    InputLabel,
    Checkbox
} from '@mui/material';
import React, { useContext, useCallback, useEffect, useState, useRef } from 'react';
import { clearClaimFileAction, uploadClaimFileAction, clearClaimAcknowledgementTextAction, getClaimAcknowledgementTextAction } from './claimFile.actions';
import { useDispatch, useSelector } from 'react-redux';
import SecuritiesLoader from '../../components/Shared/SecuritiesLoader';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Stack, Backdrop, CircularProgress, Typography } from '@mui/material';
import { Configuration, SubmissionTypeDisplay, SubmissionType, Strings } from "../../types/enums";
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { EpiqUser, Nominee } from '../../constants/roles';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const createLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
        },
        submissionWizardGuide: {
            padding: '16px',
            paddingBottom: '0',
            paddingLeft: '0',
            fontWeight:'500'
        },
        dashboardIconBackground: {
            fontSize: "20px"
        },
        claimmodal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            justifyContent: 'center',
            width: '700px;'
        },
        modalHeader: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 500,
            color: theme.palette.common.white,
            textEmphasisColor: theme.palette.common.white,
            font: '16',
        },
        modalHeaderTitle: {
            textDecoration: 'underline',
            paddingBottom: '5px;'
        },
        disabledButton: {
            backgroundColor: '#cfcfcf !important',
        },
        title: {
            flexGrow: 1,
            color: 'black',
            display: 'inline-flex',
            marginRight: "5px"
        },
        caseTitleNumber: {
            flexGrow: 1,
            color: 'black',
            display: 'flex',
            marginRight: "5px",
            whiteSpace: "pre-line"
        },
        label: {
            color: 'black',
            float: 'left',
            fontSize: '20px',
            marginRight: '5px',
            marginTop: '1px'
        },
        cardAction: {
            justifyContent: 'flex-end'
        },
        primaryBtn: {
            color: '#fff!important',
            backgroundColor: '#007bff!important'
        },
        secondaryBtn: {
            color: 'blue',
            backgroundColor: '#fff!important',
            border: '1px solid blue',
        },
        paragraph: {
            marginBottom: '0px'
        },
        textField: {
            color: 'black'
        },
        required: {
            color: 'red',
        },
        inputLabel: {
            position: 'relative',
            top: '10px',
            left: '10px',
            background: '#fff',
            fontSize: '16px',
            paddingLeft: '0px',
            padding: '0px',
            zIndex: '9',
            color: 'black'
        },
        floatLeft: {
            float: 'left'
        },
        floatRight: {
            float: 'right',
        },
    }),
);

interface IClaimFileUploadModalProps {
    modalOpen: boolean
    claimFileModalProps: IClaimFileModalProps
}

const ClaimFileUploadModal = ({ modalOpen, claimFileModalProps }: IClaimFileUploadModalProps) => {
    const dispatch = useDispatch();
    const { user } = useContext(UserContext) as UserContextType;
    const handleClose: Function = claimFileModalProps.handleClose;
    const handleSupportingDocModalOpen: Function = claimFileModalProps.handleSupportingDocModalOpen;
    const handleSupportingDocModalClose: Function = claimFileModalProps.handleSupportingDocModalClose;
    const setSupportingDocModalProps: Function = claimFileModalProps.setSupportingDocModalProps;
    const submissionTypeDisplay: SubmissionTypeDisplay = claimFileModalProps.submissionTypeDisplay;
    const submissionType: SubmissionType = claimFileModalProps.submissionType;
    const parentSubmissionId: number = claimFileModalProps.parentSubmissionId;
    const caseId: number = claimFileModalProps.caseId;
    const caseName: string = claimFileModalProps.caseName;
    const caseNumber: string = claimFileModalProps.caseNumber;
    const claimFileUploadResponse: IClaimFileUploadResponse = useSelector((state: any) => state.claimFileState.uploadResponse);
    const progress: number = useSelector((state: any) => state.claimFileState.progress);
    const [claimFile, setClaimFile] = useState<File>();
    const [hideLoadingBar, setHideLoadingBar] = useState<boolean>(false);
    const [filesAreSubmitted, setFilesAreSubmitted] = useState<boolean>(false);
    const [displayAcknowledgementMessage, setDisplayAcknowledgementMessage] = useState<boolean>(false);
    const [acknowledgementChecked, setAcknowledgementChecked] = useState<boolean>(false);
    const [acknowledgementMessage, setAcknowledgementMessage] = useState<string>("");
    const claimFileState: IClaimFileState = useSelector((state: any) => state.claimFileState);
    const classes = createLocalStyles();
    const [statedClaimantCount, setStatedClaimantCount] = useState<number>();
    const [statedTransactionCount, setStatedTransactionCount] = useState<number>();
    let [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [stringContentData, setStringContentData] = useState<IStringValue | null>();
    const [submissionWizardGuideStringContentData, setSubmissionWizardGuideStringContentData] = useState<IStringValue | null>();
    const [submissionModalKeyTextStringContentData, setSubmissionModalKeyTextStringContentData] = useState<IStringValue | null>();
    const [submissionModalKeyTextClaimFileStringContentData, setSubmissionModalKeyTextClaimFileStringContentData] = useState<IStringValue | null>();
    const [submissionModalKeyTextReminderStringContentData, setSubmissionModalKeyTextReminderStringContentData] = useState<IStringValue | null>();
    
    const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;

    const handleModalClosed = useCallback(() => {
        setFilesAreSubmitted(false);
        setClaimFile(undefined);
        setHideLoadingBar(true);
        clearModal();
        dispatch(clearClaimFileAction());
        clearClaimAcknowledgementMessage();
        handleClose();
    }, [handleClose, setClaimFile, setHideLoadingBar]);

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: path, title: "Claim File Upload Modal" });
    }, [location]);

    useEffect(() => {
        if (modalOpen && !claimFileState.loadClaimAcknowledgementTextDispatched
            && (submissionType === SubmissionType.Replacement || submissionType === SubmissionType.Update)) {
            let acknowledgementTextPayload = {
                configurationKey: submissionType === SubmissionType.Replacement ? Strings.ReplacementSubmissionAcknowledgementText : Strings.UpdateSubmissionAcknowledgementText
            };

            dispatch(getClaimAcknowledgementTextAction(acknowledgementTextPayload));
        }

        if (claimFileState.claimAcknowledgementText && acknowledgementMessage.trim() === "") {
            setDisplayAcknowledgementMessage(true);
            setAcknowledgementMessage(claimFileState.claimAcknowledgementText.value);
        }

        if (filesAreUploaded() && claimFileUploadResponse && claimFileUploadResponse.submissionId) {
            setSupportingDocModalProps(
                ({
                    handleClose: handleSupportingDocModalClose,
                    submissionTypeDisplay: submissionTypeDisplay,
                    submissionType: submissionType,
                    submissionId: claimFileUploadResponse.submissionId,
                    caseId: caseId,
                    caseName: caseName,
                    caseNumber: caseNumber,
                    submissionDescription: claimFileUploadResponse.submissionDescription
                }));

            handleSupportingDocModalOpen();
            handleModalClosed();
        }

    }, [modalOpen, claimFileUploadResponse, claimFileState, isAuthenticated, isInitialized, isInRole]);

    const processInfoResponse = (data: any) => {
        data.map((item: IStringValue) => {
            switch (item.key) {
                case Strings.SubmissionWizardActionGuide:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentData(item);
                    break;
                case Strings.SubmissionModalKeyText:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setSubmissionModalKeyTextStringContentData(item);
                    break;

                case Strings.SubmissionModalKeyTextClaimFile:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setSubmissionModalKeyTextClaimFileStringContentData(item);
                    break;

                case Strings.SubmissionModalKeyTextReminder:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setSubmissionModalKeyTextReminderStringContentData(item);
                    break;
            }
        });
    };

    useEffect(() => {
        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([Strings.Reports,
                Strings.SubmissionWizardActionGuide,
                Strings.SubmissionModalKeyText,
                Strings.SubmissionModalKeyTextClaimFile,
                Strings.SubmissionModalKeyTextReminder
            ]);

            processInfoResponse(data);
        }

        fetchInfoStrings();
    }, [isAuthenticated, isInitialized, isInRole]);

    const filesAreUploaded = (): boolean => {
        return (filesAreSubmitted && !claimFileState.isUploading && !claimFileState.isUploadFailed)
            ? true
            : false;
    };

    const filesAreUploading = (): boolean => {
        return (filesAreSubmitted && claimFileState.isUploading)
            ? true
            : false;
    };

    const selectClaimFile = (event: any) => {
        setClaimFile(event.target.files[0]);
    };

    const hasClaimFile = (): boolean => {
        return (claimFile && claimFile !== undefined)
            ? true
            : false;
    };

    const clearModal = (): void => {
        let input = document.getElementById('claimFileInput') as HTMLInputElement;

        if (input)
            input.value = '';
    };

    const submitClaimFile = (): void => {
        if (claimFile && claimFile !== undefined && claimFileIsXlsx()) {
            setHideLoadingBar(false);
            setFilesAreSubmitted(true);

            let data = new FormData();
            data.append('claimFile', claimFile);
            data.append('caseId', caseId.toString());
            data.append('userId', user.id.toString());
            data.append('submissionType', submissionType.toString());
            data.append('parentSubmissionId', parentSubmissionId.toString());
            data.append('institutionId', user.institutionId.toString());
            data.append('statedClaimantCount', (statedClaimantCount) ? statedClaimantCount.toString() : "0");
            data.append('statedTransactionCount', (statedTransactionCount) ? statedTransactionCount.toString() : "0");

            let payload = { claimFilePayload: data };
            dispatch(uploadClaimFileAction(payload));
        }
    };

    const claimFileIsXlsx = (): boolean => {

        if (claimFile && claimFile !== undefined) {
            let extension = claimFile.name.substring(claimFile.name.lastIndexOf(".") + 1);

            return extension.toLowerCase() === 'xlsx';
        }

        return false;
    };

    const cancelClaimFileUpload = (): void => {
        handleModalClosed();
    };

    const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAcknowledgementChecked(event.target.checked);
    };

    const selectButtonIsDisabled = (): boolean => {
        return ((displayAcknowledgementMessage && !acknowledgementChecked))
            ? true
            : false;
    };

    const continueButtonIsDisabled = (): boolean => {
        return ((displayAcknowledgementMessage && !acknowledgementChecked) || (claimFileState && claimFileState.isUploadFailed))
            ? true
            :
            !(hasClaimFile() && claimFileIsXlsx() && (statedClaimantCount && statedClaimantCount > 0))
                ? true
                : false;
    };

    const clearClaimAcknowledgementMessage = (): void => {
        dispatch(clearClaimAcknowledgementTextAction());
        setDisplayAcknowledgementMessage(false);
        setAcknowledgementChecked(false);
        setAcknowledgementMessage("");
    };

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };

    

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClosed}>
            <Card className={classes.claimmodal}>
                <div style={{padding: "16px", paddingBottom:"0"}} ref={ref}>
                    <Typography variant="h6" className={classes.title} noWrap>{submissionTypeDisplay}</Typography>
                    <Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
                    <Overlay
                        show={show}
                        target={target}
                        placement="right-start"
                        container={ref}
                        containerPadding={20}
                        rootClose
                        onHide={() => setShow(false)}
                    >
                        <Popover id="popover-contained">
                            <Popover.Header as="h3"><span style={{ color: "#000" }}>Action Guide</span></Popover.Header>
                            {stringContentData && (
                                <Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
                            )}
                        </Popover>
                    </Overlay>
                </div>
                <CardHeader
                    subheader={
                        <div>
                            <InputLabel className={classes.label}>Case:</InputLabel>
                            <Typography variant="h6" className={classes.caseTitleNumber} noWrap>
                                {caseName} {caseNumber}
                            </Typography>
                        </div>
                    }
                    classes={{ title: classes.modalHeaderTitle }}
                    action={<Icon style={{position:"absolute", top:"20px", right:"20px", cursor:"pointer"}} onClick={() => { handleModalClosed() }}>close</Icon>}>
                </CardHeader>

                {
                    progress > 0 && progress < 1 &&
                    <SecuritiesLoader variant="determinate" hidden={hideLoadingBar} value={progress * 100} />
                }

                <CardContent>
                    <p className={classes.paragraph}>
                        {submissionModalKeyTextStringContentData && (
                            <Popover.Body dangerouslySetInnerHTML={{ __html: submissionModalKeyTextStringContentData.value }}></Popover.Body>
                        )}
                    </p>
                    <Grid style={{ alignItems: 'end' }} container spacing={1}>
                        <Grid style={{ paddingTop: '0' }} item xs={12} sm={2} lg={2}>
                        </Grid>

                        <Grid style={{ paddingTop: '0' }} item xs={12} sm={4} lg={4}>
                            <div>
                                <label className={classes.inputLabel}>Number of Claims</label>
                                <input
                                    id="statedClaimants"
                                    type="number"
                                    placeholder="0"
                                    style={{
                                        height: '3rem',
                                        border: '1px solid #707070',
                                        borderRadius: '4px',
                                        paddingLeft: '15px'
                                    }}
                                    onKeyDown={(e: React.KeyboardEvent) => {
                                        if (e.keyCode === 189 || e.keyCode === 190)  // keyCode 189 = "-" Key Code 190 = "."
                                            e.preventDefault()
                                    }}
                                    onChange={(e: any) => {
                                        setStatedClaimantCount(e.target.value);
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid style={{ paddingTop: '0' }} item xs={12} sm={4} lg={4}>
                            <div>
                                <label className={classes.inputLabel}>Number of Transactions</label>
                                <input
                                    id="statedTransactions"
                                    type="number"
                                    placeholder="0"
                                    onKeyDown={(e: React.KeyboardEvent) => {
                                        if (e.keyCode === 189 || e.keyCode === 190)    // keyCode 189 = "-" Key Code 190 = "."
                                            e.preventDefault()
                                    }}
                                    onChange={(e: any) => {
                                        setStatedTransactionCount(e.target.value);
                                    }}
                                    style={{
                                        height: '3rem',
                                        border: '1px solid #707070',
                                        borderRadius: '4px',
                                        paddingLeft: '15px'
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid style={{ paddingTop: '0' }} item xs={12} sm={2} lg={2}>
                        </Grid>
                        <Grid style={{ paddingTop: '0' }} item xs={12} sm={2} lg={2}>
                        </Grid>
                        <Grid style={{ paddingTop: '0', minHeight: '25px' }} item xs={12} sm={4} lg={4}>
                            {
                                (statedClaimantCount && statedClaimantCount <= 0) &&
                                <div className="invalid-feedback d-block">Claimants must be greater than 0</div>
                            }
                        </Grid>
                        <Grid style={{ paddingTop: '0', minHeight: '25px' }} item xs={12} sm={4} lg={4}>
                            {
                                (statedTransactionCount && statedTransactionCount < 0) &&
                                <div className="invalid-feedback d-block">Transactions must be greater or equal to 0</div>
                            }
                        </Grid>
                        <Grid style={{ paddingTop: '0' }} item xs={12} sm={2} lg={2}>
                        </Grid>
                    </Grid>
                    <p className={classes.paragraph}>
                        {submissionModalKeyTextClaimFileStringContentData && (
                            <Popover.Body style={{paddingLeft:'0'}} dangerouslySetInnerHTML={{ __html: submissionModalKeyTextClaimFileStringContentData.value }}></Popover.Body>
                        )}
                    </p>
                    <Grid container spacing={1}>
                        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={filesAreUploading()}>
                            <Stack alignItems="center">
                                <CircularProgress color="inherit" />
                                <br />
                                <Typography sx={{ color: "#fff" }} variant="h4">
                                    Uploading documents...
                                </Typography>
                            </Stack>
                        </Backdrop>
                        <Grid item xs={12}>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={hasClaimFile() && !claimFileIsXlsx()}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                multiline
                                variant="outlined"
                                helperText={(hasClaimFile() && !claimFileIsXlsx()) && "Incorrect file type"}
                                label={hasClaimFile() && !claimFileIsXlsx() && "Error"}
                                value={claimFile && claimFile !== undefined
                                    ? claimFile.name
                                    : 'No file selected...'} >
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                classes={
                                    { root: classes.primaryBtn }}
                                component="label"
                                color="primary"
                                variant="contained"
                            >
                                Select a file
                                <input
                                    id="claimFileInput"
                                    name="claimFileInput"
                                    type="file"
                                    accept=".xlsx"
                                    hidden
                                    onChange={selectClaimFile} />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                displayAcknowledgementMessage && acknowledgementMessage &&
                                <Typography variant="h6" color="InfoText">
                                    <Checkbox sx={{ color: 'black', paddingTop: '5px', marginBottom: '-3px' }}
                                        checked={acknowledgementChecked}
                                        color="primary"
                                        inputProps={{ "aria-label": "checkbox" }}
                                        onChange={handleChecked}
                                    />
                                    {acknowledgementMessage}
                                </Typography>
                            }
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions className={classes.cardAction}>
                    <Button
                        disabled={continueButtonIsDisabled()}
                        classes={continueButtonIsDisabled() ? { root: classes.disabledButton, disabled: classes.disabledButton } :
                            { root: classes.primaryBtn, disabled: classes.primaryBtn }}
                        onClick={() => { submitClaimFile() }}
                        color="primary" >
                        Continue
                    </Button>
                    <Button
                        onClick={() => { cancelClaimFileUpload() }}
                        classes={{ root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
                        color="secondary">
                        Cancel
                    </Button>
                </CardActions>
                <p style={{paddingBottom:"16px"}} className={classes.paragraph}>
                    {submissionModalKeyTextReminderStringContentData && (
                        <Popover.Body dangerouslySetInnerHTML={{ __html: submissionModalKeyTextReminderStringContentData.value }}></Popover.Body>
                    )}
                </p>
            </Card >
        </Modal >
    );
};

export default ClaimFileUploadModal;