import { useEffect } from "react";
import { useSignal } from '@preact/signals-react';
import { Typography, Theme, Skeleton } from  "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ISubmissionRejectionSlideoutProps, ISubmissionRejection } from "../../types/types";
import { getSubmissionRejectionsBySubmissionId } from "./submissions.api";


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        maxHeight: "70vh",
    },
    rejectionsDrawer: {
        margin: "0px",
        padding: "60px 20px",
        alignItems: "center",
        justifyContent: "space-evenly",
        verticalAlign: "top",
    },
    drawerHeader: {
        fontSize: "22px",
        fontWeight: "600",
        marginBottom: "30px",
    },
    drawerSubHeader: {
        marginTop: "20px",
        fontSize: "16px",
        fontWeight: "700",
        marginBottom: "20px",
    },
    accordionList: {
        border: "5px solid #d2d2d3",
        boxShadow: "3px 3px 9px 2px #33393f",
        overflow: "auto",
        maxHeight: "60vh",
    },
    accordionSummary: {
        borderBottom: "3px solid #d2d2d3",
    },
    rejectionDetail: {
        fontSize: "15px",
        fontWeight: "500",
        marginTop: "20px",
        marginBottom: "20px",
    }
}));

interface IReasonsSlideoutDrawerProps {
    isOpen: boolean,
    submissionRejectionSlideoutProps: ISubmissionRejectionSlideoutProps
}

const ReasonsSlideoutDrawer = ({ isOpen,
    submissionRejectionSlideoutProps }
    : IReasonsSlideoutDrawerProps) => {

    const classes = useStyles();
    const submissionId = submissionRejectionSlideoutProps.submissionId;
    const submissionFileName = submissionRejectionSlideoutProps.submissionFileName;
    const handleClose: any = submissionRejectionSlideoutProps.handleClose;    
    const submissionRejections = useSignal<ISubmissionRejection[]>([]);
    const currentSourceLocation = useSignal("");
    const submissionRejectionsLoaded = useSignal(false);
    const sourceLocations = submissionRejections.value.sort((a, b) => Number(a.sourceLocationSortOrder) - Number(b.sourceLocationSortOrder)).reduce((acc:string[], item) => {
        if (!acc.includes(item.sourceLocation)) {
            acc.push(item.sourceLocation);
        }
        return acc;
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let data = await getSubmissionRejectionsBySubmissionId(submissionId);
                submissionRejections.value = data;
                submissionRejectionsLoaded.value = true;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (isOpen && submissionRejections.value.length === 0)
            fetchData();
    }, [isOpen]);

    function handleDrawerClose() {
        submissionRejections.value = [];
        currentSourceLocation.value = "";
        submissionRejectionsLoaded.value = false;
        handleClose();
    }


    function handleAccordionClick(event: any, location: string ) {
        event.stopPropagation();

        if (currentSourceLocation.value === location) {
            currentSourceLocation.value = "";
        }
        else {
            currentSourceLocation.value = location;
        }

    }
    
    const buildRejectionList = (location: string) => {

        if (sourceLocations.length > 0 && currentSourceLocation.value === "")
        {
            currentSourceLocation.value = sourceLocations[0];
        }

        let rejections = submissionRejections.value.filter(r => r.sourceLocation === location).sort((a,b) => Number(b.count) - Number(a.count));
        let rejectionCount = rejections.reduce((acc, item) => {
            return acc + item.count;
        }, 0);



        return (
            <div key={location} className={classes.accordionList}>
                <Accordion disableGutters
                    key={location}

                    expanded={location === currentSourceLocation.value}
                >
                        <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1-content"
                        id={location}
                        onClick={(event) => handleAccordionClick(event, location)}
                    >
                        <Typography>
                            {location} {(rejectionCount > 0 ? "(" + rejectionCount.toString() + ")" : "" )}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {
                        rejections.map((rejection) => 
                            <li key={rejection.id } className = { classes.rejectionDetail } >
                                {rejection.message} {(rejection.count > 0 ? "(" + rejection.count.toString() + ")" : "")}
                            </li>
                            )
                    }
                    </AccordionDetails>
                 </Accordion>
            </div>
        )
    }
    
    return (
        <div onClick={handleDrawerClose}>
            <Drawer anchor={"right"} open={isOpen} onClose={() => handleDrawerClose()} sx={{ zIndex: "9999" }} PaperProps={{ sx: {width: "33%"}} }>
                <Box role="presentation" sx={{ overflowY: "hidden" }} onClick={() => handleDrawerClose()} onKeyDown={() => handleDrawerClose()}>
                    <div className={classes.rejectionsDrawer}>
                        <h1 className={classes.drawerHeader}>Reject Summary</h1>
                        <h2 className={classes.drawerSubHeader}>File Name</h2>
                        {submissionFileName &&
                            <h2 className={classes.rejectionDetail}>{submissionFileName}</h2>
                        }
                        <h2 className={classes.drawerSubHeader}>Reject reasons from submission</h2>
                        <div id="accordionContainer" className={classes.container}>
                            { 
                                 submissionRejectionsLoaded.value && submissionRejections.value ?
                                    sourceLocations.map((location) => (buildRejectionList(location)))
                                :
                                <div>  <Skeleton animation="wave" /></div>

                            }
                        </div>
                    </div>
                </Box>
            </Drawer>
        </div>
    );
};

export default ReasonsSlideoutDrawer;