import React, { useContext, useEffect, useState } from "react";
import { ICase, ICasebookState, IFilterDTO } from "../../types/types";
import {
	alpha,
	Box,
	Button,
	CircularProgress,
	Container,
	Grow,
	Icon,
	InputBase,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Toolbar,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { caseSearch } from "./casebook.actions";
import { importCase } from "../Cases/cases.actions";
import { useHistory } from "react-router-dom";
import { UserContext, UserContextType } from "../User/UserContext";
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import { getCases } from "../Cases/cases.api";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		grow: {
			flexGrow: 1,
		},
		paper: {
			position: "absolute",
		},
		modal: {
			maxHeight: "90%",
		},
		modalHeader: {
			backgroundColor: theme.palette.primary.main,
			minWidth: 700,
			minHeight: 50,
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(3),
		},
		search: {
			position: "relative",
			borderRadius: theme.shape.borderRadius,
			backgroundColor: alpha(theme.palette.common.white, 0.15),
			"&:hover": {
				backgroundColor: alpha(theme.palette.common.white, 0.25),
			},
			marginRight: theme.spacing(1),
			marginLeft: 0,
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.spacing(1),
				width: "auto",
			},
			color: alpha(theme.palette.common.white, 1),
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: "100%",
			position: "absolute",
			pointerEvents: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		text: {
			color: alpha(theme.palette.common.white, 1),
		},
		title: {
			flexGrow: 1,
		},
		inputRoot: {
			color: "inherit",
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)})`,
			transition: theme.transitions.create("width"),
			width: "100%",
			[theme.breakpoints.up("md")]: {
				width: "50ch",
			},
		},
		container: {
			maxHeight: 500,
		},
		disabledButton: {
			backgroundColor: "#cfcfcf !important",
		},
	})
);

function getModalStyle() {
	const top = 50;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
	};
}

interface CasebookImportModalProps {
	modalOpen: boolean;
	handleClose: any;
}

const CasebookImportModal = ({ modalOpen, handleClose }: CasebookImportModalProps) => {
	const classes = useStyles();
	const history = useHistory();
	const [modalStyle] = React.useState(getModalStyle);
	const dispatch = useDispatch();
	const { user } = useContext(UserContext) as UserContextType;
	const caseBookState: ICasebookState = useSelector((state: any) => state.caseBookState);
	const [existingCases, setExistingCases] = useState([] as ICase[]);

	const location = useLocation();

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: "Casebook Import Modal" });
	}, [location]);

	useEffect(() => {
		async function fetchData() {
			setExistingCases(await getCases([] as IFilterDTO[], true));
		}

		fetchData();
	}, []); 

	const handleSearchClick = () => {
		const searchCriteriaInput = document.getElementById("SearchCriteriaInput") as HTMLInputElement;
		const criteria = searchCriteriaInput.value;
		dispatch(caseSearch(criteria));
	};

	// Event is type 'any' because typing the event breaks the onKeyUp event handler on InputBase control. Thanks, Micro$oft!
	const handleSearchKeyUp = (e: any) => {
		if (e.key === "Enter") {
			handleSearchClick();
		}
	};

	// Event is type 'any' because typing the event breaks the onClick event handler on Button control.
	const handleImport = (caseDetailId: number): any => {
		let userId = user.id;
		dispatch(importCase({ caseDetailId, userId, history }));
	};

	const caseHasBeenImported = (caseDetailId: number): boolean => {
		return existingCases.some((c) => c.casebookCaseDetailId === caseDetailId);
	};

	return (
		<Modal open={modalOpen} onClose={handleClose}>
			<Grow in={modalOpen}>
				<Paper style={modalStyle} className={classes.paper}>
					<div className={classes.modalHeader}>
						<Toolbar disableGutters={true}>
							<div className={classes.search}>
								<div className={classes.searchIcon}>
									<Icon>search</Icon>
								</div>
								<InputBase
									id="SearchCriteriaInput"
									name="SearchCriteriaInput"
									placeholder="Search by billing code, case name, or contract code."
									fullWidth
									classes={{
										root: classes.inputRoot,
										input: classes.inputInput,
									}}
									inputProps={{ "aria-label": "search" }}
									onKeyUp={(e) => {
										handleSearchKeyUp(e);
									}}
								/>
							</div>
							<Button onClick={handleSearchClick} variant="contained" color="success">Search</Button>
						</Toolbar>
					</div>
					<Container maxWidth="lg" className={classes.content}>
						{caseBookState.isLoading ? (
							<Box display="flex" justifyContent="center" alignItems="center">
								<CircularProgress size={40} variant="indeterminate" />
							</Box>
						) : (
							caseBookState.searchResults.length > 0 && (
								<TableContainer className={classes.container}>
									<Table stickyHeader>
										<TableHead>
											<TableRow>
												<TableCell>Billing Code</TableCell>
												<TableCell>Case Name</TableCell>
												<TableCell>SAP Contract Code</TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{caseBookState.searchResults.map((item, index) => (
												<TableRow key={index}>
													<TableCell>{item.billingCode}</TableCell>
													<TableCell>{item.caseName}</TableCell>
													<TableCell>{item.sapContractCode}</TableCell>
													<TableCell>
														<Button
															onClick={() => {
																handleImport(item.caseDetailId);
															}}
															variant="contained"
															color="primary"
															disabled={caseHasBeenImported(item.caseDetailId)}
															classes={
																caseHasBeenImported(item.caseDetailId)
																	? {
																		root: classes.disabledButton,
																		disabled: classes.disabledButton,
																	}
																	: {}
															}
														>
															{caseHasBeenImported(item.caseDetailId) ? "Imported" : "Import"}
														</Button>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							)
						)}
					</Container>
					{caseBookState.error && <p style={{ color: 'red', paddingLeft: '8px' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
				</Paper>
			</Grow>
		</Modal>
	);
};

export default CasebookImportModal;
