import authService from "../../components/api-authorization/AuthorizeService";

export const getNomineeCardCounts = async (userId: number, isUserSubmissions: boolean) => {

	try {
		const token = await authService.getAccessToken();

		let response = await fetch(`api/NomineeDashboard/CardCounts?userId=${userId}&isUserSubmissions=${isUserSubmissions}`, {
			method: 'GET',
			headers: {
				"Authorization": `Bearer ${token}`
			}
		});

		if (!response.ok)
			throw new Error("Failed to get nominee card counts.");

		return response.json();

	} catch (error) {
		console.error(error);
		throw error;
	}
};