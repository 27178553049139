import { AlertType, Strings } from "../../types/enums";
import { IUserListState, IRegistrationDetail, IInstitutionListState, IStringValue } from "../../types/types";

import { Divider, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from "react";
import {registerUserInstitutions, reviewUserAction} from "./userlist.actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { EpiqUser } from '../../constants/roles';
import ReviewDetailForm from "./ReviewDetailForm";
import ReviewInstitutionListGrid from './ReviewInstitutionListGrid';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { showAlert } from "../Alerts/alerts.actions";
import { TECHNICAL_ERROR_MESSAGE, TOASTER_MESSAGE_REGISTRATION } from "../../constants/CommonConstants";
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import Popover from 'react-bootstrap/Popover';

const useStyles = makeStyles((theme: Theme) => ({
  commonbackgroundcard: {
    boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 30%)',
    padding: '1.5rem 1rem 0.25rem',
    margin: '2rem 1rem 0',
    background: theme.palette.background.paper,
    borderRadius: '5px',
  },
  title: {
    flexGrow: 1,
    color: theme.palette.grey[500]
  },
  divider: {
    marginTop: '2rem',
  },
  header: {
    color: theme.palette.text.primary,
  }
}));

export default function ReviewDetailPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { registrationId } = useParams<{ registrationId: string }>();
  let userListState: IUserListState = useSelector((state: any) => state.userListState);
  let registrationDetail: IRegistrationDetail = useSelector((state: any) => state.userListState.registrationDetail);
  const institutionListState: IInstitutionListState = useSelector((state: any) => state.institutionListState);
  const { isInRole } = useContext(UserContext) as UserContextType;
  const [stringContentData, setStringContentData] = useState<IStringValue | null>();
  const classes = useStyles();
    const registrationIdNumber = Number(registrationId);

    const processInfoResponse = (data: any) => {
        data.map((item: IStringValue) => {
            switch (item.key) {
                case Strings.ToasterMessageRegistration:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentData(item);
                    break;
            }
        });
    };

    useEffect(() => {
        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([Strings.ToasterMessageRegistration]);

            processInfoResponse(data);
        }

        fetchInfoStrings();

    }, [dispatch, registrationId, history]);
        
    useEffect(() => {
    
        if (isNaN(registrationIdNumber) && stringContentData !== null && stringContentData !== undefined) {
      dispatch(showAlert({
          hidden: false,
          message: stringContentData?.value,
          severity: AlertType.error,
      }))

      history.push('/RegistrationRequests')
      return;
    }

    dispatch(reviewUserAction({ registrationId: registrationIdNumber, history: history }));
    dispatch(registerUserInstitutions(registrationId));

    }, [dispatch, registrationId, history, stringContentData]);

  useEffect(() => {
    if (userListState && !userListState.isLoading) {
      if (userListState.approveUserResponse || userListState.denyUserResponse) {
        history.replace('/RegistrationRequests');
      }  
    }
  }, [userListState]);

  return (
    <div className={classes.commonbackgroundcard}>
      <Typography className={classes.header} variant="h6" gutterBottom={true} noWrap>
        Registration Request
      </Typography>
      <Typography variant="body1" className={classes.title} noWrap>
        From the User's Registration Request -
      </Typography>
      <ReviewDetailForm 
      	registrationDetail={registrationDetail}
      />
      <Divider className={classes.divider}/>
      <Typography margin={4} align="center">
        <b>OR</b> approve and assign the user to an existing
        institution
      </Typography>
  
      <ReviewInstitutionListGrid 
        registrationUser={registrationDetail?.user}
          />

          {(userListState.error || institutionListState.error) && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
    </div>
  );
}