import { IDrawerMenu } from '../types/types';

// Notes about the icon names used below...
// https://mui.com/material-ui/icons/#usage:~:text=.-,Note%3A%20The%20Material%20Design%20guidelines%20name%20the%20icons%20using%20%22snake_case%22%20naming,.,-Filled
// Note: The Material Design guidelines name the icons using "snake_case" naming (for example delete_forever, add_a_photo), while @mui/icons-material exports the respective icons using "PascalCase" naming (for example DeleteForever, AddAPhoto). There are three exceptions to this naming rule: 3d_rotation exported as ThreeDRotation, 4k exported as FourK, and 360 exported as ThreeSixty.

export const DrawerMenu: IDrawerMenu =
{
	DrawerMenuItems: [
		
		{
			DisplayText: "Dashboard",
			Route: "/Dashboard",
			IconName: "dashboard",
			Tooltip: "Dashboard"
		},
		
		{
			DisplayText: "Cases",
			Route: "/CaseList",
			IconName: "view_list",
			Tooltip: "Cases"
		},
		{
			DisplayText: "Submissions",
			Route: "/SubmissionList",
			IconName: "list_alt_outlined",
			Tooltip: "Submissions"
		},
		{
			DisplayText: "Templates",
			Route: "/TemplateList",
			IconName: "table_chart",
			Tooltip: "Templates"
		},
		{
			DisplayText: "Admin",
			Route: "/Admin",
			IconName: "verified_user",
			Tooltip: "Admin"
		},
		{
			DisplayText: "Reports",
			Route: "/Reports",
			IconName: "assignment';",
			Tooltip: "Reports"
		}
	],

	SecondaryMenuItems: [
		{
			DisplayText: "Disclaimer",
			Route: "http://www.epiqglobal.com/disclaimer",
			IconName: "report_problem", //Secondary Menu Items have no icon.,
			Tooltip: "Disclaimer"
		},
		{
			DisplayText: "Terms of Use",
			Route: "/TermsOfUse",
			IconName: "assignment", //Secondary Menu Items have no icon.
			Tooltip: "Terms of Use"
		},
		{
			DisplayText: "Privacy Statement",
			Route: "https://www.epiqglobal.com/en-us/general/eca-privacy-policy",
			IconName: "privacy_tip", //Secondary Menu Items have no icon.
			Tooltip: "Privacy Statement"
		},
	]
}
