import { IInstitution, IInstitutionType, IInstitutionUpdate } from "../../types/types";

import { useContext, useEffect } from "react";
import { getCountriesStatesAction, getInstitutionByIdAction, getInstitutionTypeAction } from "./institutionlist.actions";
import { useDispatch, useSelector } from "react-redux";

import InstitutionDetailForm from "./InstitutionDetailForm";
import { Theme } from '@mui/material';
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useHistory, useParams } from "react-router-dom";
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import { useSignal } from "@preact/signals-react";
import { UserContext, UserContextType } from '../../modules/User/UserContext'
import { EpiqAdmin, EpiqTechSupport } from "../../constants/roles";

const useStyles: any = makeStyles((theme: Theme) => ({
    commonbackgroundcard: {
        boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 30%)',
        padding: '1.5rem 1rem',
        margin: '2rem 1rem 0',
        background: '#fff',
        borderRadius: '5px',
    },
    title: {
        flexGrow: 1,
        marginBottom: '15px',
    }
}));


interface UpdateInstitutionDetailProps {
    institutionDetail?: IInstitution;
    selectedInstitution?: IInstitutionUpdate;
}

export default function InstitutionDetailPage({ institutionDetail }: UpdateInstitutionDetailProps) {
    const dispatch = useDispatch();
    const institutionTypeList: IInstitutionType[] = useSelector((state: any) => state.institutionListState.institutionTypeList);
    const countriesstatesInstitution: any = useSelector((state: any) => state.institutionListState.countriesstatesInstitution);
    let selectedInstitutionDetails: any = useSelector((state: any) => state.institutionListState.selectedInstitutionDetails);
    let institutionDetails: any = useSelector((state: any) => state.institutionListState);
    const classes = useStyles();
    let { id } = useParams<{ id: string }>();
    const location = useLocation();
    const institution = useSignal<IInstitution | undefined>(undefined);
    const { user, isInRole } = useContext(UserContext) as UserContextType;
    const history = useHistory();

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: path, title: "Institution Details Page" });
    }, [location]);

    useEffect(() => {
        dispatch(getInstitutionTypeAction());
        dispatch(getCountriesStatesAction());

        if (id === '0') {
            if (!isInRole([EpiqAdmin, EpiqTechSupport])) {
                history.push('/institutionList');
            }

            institution.value = {} as IInstitution;
            institution.value.insertedByUserId = user.id;
            institution.value.institutionTypeId = 1;    // Broker
            institution.value.zipCode = '';
            institution.value.country = 'UNITED STATES';
        }
        else {
            dispatch(getInstitutionByIdAction(id));
        }
    }, []);

    useEffect(() => {
        if (id !== '0') {
            institution.value = selectedInstitutionDetails;
        }
    }, [selectedInstitutionDetails]);

    const displayTechinicalErrorMessage = (): boolean => {
        return ((institutionDetails.errorGetInstitutionType || institutionDetails.errorGetCountriesStates || institutionDetails.errorGetInstitutionById || institutionDetails.errorUpdateInstitution))
            ? true : false
    };

    return (
        <div className={classes.commonbackgroundcard}>
            <Typography variant="h6" className={classes.title} noWrap>
                Institution Details
            </Typography>
            {
                institution.value !== undefined &&
                (
                    <>
                        <InstitutionDetailForm
                            institutionDetail={institution.value}
                            institutionTypeList={institutionTypeList}
                            countriesStatesInstitution={countriesstatesInstitution}
                        />
                    </>
                )
            }
            {displayTechinicalErrorMessage() && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
        </div>
    );
}



