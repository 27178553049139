import download from 'downloadjs';
import authService from '../../components/api-authorization/AuthorizeService';
import { IFilterDTO, ITemplate, ITemplateCriteria } from '../../types/types';
import { createFilterCriteria } from '../../utilities/filterCriteria';

export const downloadMailingFileTemplate = async () => {
	const token = await authService.getAccessToken();

	let response = await fetch(`api/document/downloadMailingFileTemplate/`, {
		method: 'GET',
		headers: {
			"Content-Type": "application/octet-stream",
			'Authorization': `Bearer ${token}`
		}
	});

	if (!response.ok)
		throw new Error(response.statusText);

	let blob = await response.blob();
	let file = new Blob([blob], { type: 'application/octet-stream' });
	let fileName = response.headers.get('Content-Disposition')?.split(';')[1].trim().split('=')[1].replaceAll('"', '');
	download(file, fileName, "application/octet-stream");
};

export const getTemplatesAsync = async (filter: IFilterDTO[]): Promise<ITemplate[]> => {
	let criteria = createFilterCriteria(filter) as ITemplateCriteria;
	const token = await authService.getAccessToken();
	let templates: ITemplate[] = [] as ITemplate[];

	try {
		let response = await fetch(`api/Template/List`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(criteria)
		});

		if (!response.ok) {
			throw new Error("Failed to get templates.");
		}

		templates = await response.json();
	}

	catch (exception) {
		console.error(exception)
		throw exception;
	}

	return templates;
};

export const getTemplateDetailAsync = async (templateId: number): Promise<ITemplate> => {
	const token = await authService.getAccessToken();
	let template: ITemplate = {} as ITemplate;

	try {
		let response = await fetch(`api/Template/${templateId}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			}
		});

		if (!response.ok) {

			throw new Error("Failed to get template detail.");

		}

		template = await response.json();
	}

	catch (exception) {

		console.error(exception);
		throw exception;

	}

	return template;
};

export const saveTemplate = async (templateDetails: ITemplate) => {
	const token = await authService.getAccessToken();
	let result = false;

	try {

		let response = await fetch(`api/Template/UpdateTemplate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(templateDetails)
		});

		if (!response.ok) {

			throw new Error("Failed to save template.");

		}

		result = await response.json();

	}

	catch (exception) {

		console.error(exception)
		throw exception;

	}

	return result;

}