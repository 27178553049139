import * as React from 'react';
import {
    Backdrop,
    Box,
    Checkbox, CircularProgress, createTheme, FormControl, FormControlLabel, Grid, InputLabel, Paper,
    Radio, RadioGroup, RadioProps, styled, TextField, Theme, Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import { IBatchApprovalOptionsResponse, IBatchApprovalOptionsCriteria, IStringValue } from '../../types/types';
import { batchApprovalOptionsById } from './submissions.actions';
import { Strings } from '../../types/enums';
import { useEffect, useRef } from 'react';
import { getInfoJsonWithArray, transformHelpUrl } from '../../utilities/utilities';
import { EpiqUser } from '../../constants/roles';
import { useState } from 'react';
import { useContext } from 'react';
import { UserContext, UserContextType } from '../User/UserContext';
import { Overlay, Stack } from 'react-bootstrap';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from 'react-bootstrap/Popover';
import { blue, lightGreen } from '@mui/material/colors';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        minWidth: "500px",
        maxwidth: "700px"
    },
    title: {
        flexGrow: 1,
        fontSize: '14px',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: '400',
        lineHeight: '1.5',
        flexWrap: 'wrap',
        color: '#000000',
        display: 'inline-flex',
        marginRight: "5px",
        wordBreak: 'break-all',
        marginBottom: '0',
        paddingBottom: '0'

    },
    label: {
        color: '#555A5C',
        fontWeight: 'bold',
        fontSize: '14px',
        fontFamily: 'Montserrat, sans-serif',
        float: 'left',
        marginRight: '5px',
        lineHeight: '1.5',
        margin: '0',
        width: '100%',
        wordBreak: 'break-all',
    },
    modalIconBackground: {
        fontSize: "20px"
    },
    rejectReasonHelper: {
        color: 'red !important'
    },
    disabledButton: {
        cursor: 'not-allowed',
        backgroundColor:'rgb(229, 229, 229) !important',
    }
}));

interface IModalDialogueProps {
    title: string,
    body: any,
    modalOpen: boolean,
    handleClose: any,
    documentId: number,
    submissionId: number,
    caseName: string,
    submissionName: string,
    institutionName: string,
    caseId: number,
    mailingBatchId: number
}

const SubmissionEpiqDocumentApproveModal = ({
    title,
    body,
    modalOpen,
    handleClose,
    documentId,
    submissionId,
    caseName,
    submissionName,
    institutionName,
    caseId,
    mailingBatchId
}: IModalDialogueProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const approveSubmissionDocumentResponse: IBatchApprovalOptionsResponse = useSelector((state: any) => state.submissionListState.approveSubmissionDocumentResponse);
    const isBatchApprovalProcessComplete: boolean = useSelector((state: any) => state.submissionListState.isBatchApprovalProcessLoading);
    const [stringContentData, setStringContentData] = useState<IStringValue[]>();
    const [batchApprovalOptionsStringContentData, setBatchApprovalOptionsStringContentData] = useState<IStringValue | null>();
    const { isInRole, user } = useContext(UserContext) as UserContextType;
    const ref = useRef(null);
    let [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [checkboxDisabled, setCheckboxDisabled] = useState(true); // used to enable or disable send email automatically checkbox
    const [checked, setChecked] = React.useState(false); // used to set the send email automatically checkbox
    const [value, setValue] = React.useState(''); //set RadioGroup Value in Batch options modal
    const [rejectReason, setRejectReason] = useState('');
    const [helperTextRejectReason, setHelperTextRejectReason] = useState('');
    const [showRejectReasonError, setshowRejectReasonError] = useState(false);
    const [rejectReasonDisabled, setRejectReasonDisabled] = useState(true);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    useEffect(() => {
        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([
                Strings.BatchApprovalOptionsActionGuide,
            ]);

            if (data) {
                let idx = -1;

                data.map((item: IStringValue, index: number) => {
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]))

                    if (item.key === Strings.BatchApprovalOptionsActionGuide) {
                        idx = index;
                        setBatchApprovalOptionsStringContentData(item);
                    }

                });

                if (idx > -1)
                    data.splice(idx, 1);

                setStringContentData(data);
            }
        }

        fetchInfoStrings().catch(console.error);
    }, [isInRole]);

    useEffect(() => {
        switch (value) {
            case '':
                setSubmitDisabled(true);
                setRejectReason('');
                break;

            case 'sendNotice':
                setCheckboxDisabled(false);
                setChecked(false);
                setRejectReasonDisabled(true);
                setSubmitDisabled(false);
                setRejectReason('');
                break;

            case 'rejectNotice':
                setCheckboxDisabled(true);
                setChecked(false);
                setRejectReasonDisabled(false);
                setSubmitDisabled(false);
                break;

        }
        
    }, [value]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);

        switch (value) {
            case 'sendNotice':
                setCheckboxDisabled(false);
                setChecked(false);
                setRejectReasonDisabled(true);
                setRejectReason('');
                break;

            case 'rejectNotice':
                setCheckboxDisabled(true);
                setChecked(false);
                setRejectReasonDisabled(false);
                break;
        }


    };

    const handleSubmitApproveDocument = () => {

        var breakOperation = false;

        switch (value) {
            case '':
                break;

            case 'rejectNotice':
                if (rejectReason === '') {
                    setHelperTextRejectReason("please enter the reason for rejecting this.")
                    setshowRejectReasonError(true);
                    breakOperation = true;
                }
                break;

            case 'sendNotice':
                setHelperTextRejectReason('');
                setshowRejectReasonError(false);
                break;
        }

        if (breakOperation)
            return false;

        let criteria: IBatchApprovalOptionsCriteria = {
            sendNotices: (value === 'sendNotice') ? true : false,
            sendNoticesAutomatically: (value === 'sendNotice' && checked) ? true : false,
            caseId: caseId,
            userId: user.id,
            mailingBatchId: mailingBatchId,
            notes: rejectReason
        };

        if (value === 'sendNotice' && checked)
            criteria.sendNotices = false;

        dispatch(batchApprovalOptionsById({ submissionId, criteria }));
    };

    const BpIcon = styled('span')(({ theme }) => ({
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow:
            theme.palette.mode === 'dark'
                ? '0 0 0 1px rgb(16 22 26 / 40%)'
                : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
        backgroundImage:
            theme.palette.mode === 'dark'
                ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
                : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background:
                theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
        },
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    });
    function BpRadio(props: RadioProps) {
        return (
            <Radio
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                {...props}
            />
        );
    }

    const [sendNotices, setSendNotices] = React.useState("");
    const [sendNoticeAndEmailAutomatically, setSendNoticeAndEmailAutomatically] = React.useState("");
    const [rejectNotices, setRejectNotices] = React.useState("");

    React.useEffect(() => {

        if (body) {
            body.map((item: any) => {

                if (item.key === Strings.DeficiencyMailingBatchSendOne)
                    setSendNotices(item.value);

                if (item.key === Strings.DeficiencyMailingBatchAutoSendAll)
                    setSendNoticeAndEmailAutomatically(item.value);

                if (item.key === Strings.DeficiencyMailingBatchReject)
                    setRejectNotices(item.value);


            });
        }

        if (!approveSubmissionDocumentResponse)
            return;

        handleClose(false);

    }, [approveSubmissionDocumentResponse, body, dispatch, handleClose]);

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return (
        <div>
            
            <Dialog
                open={modalOpen}
                onClose={handleClose}
                fullWidth
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle id="scroll-dialog-title"
                    className="cmn-dialogtitle"
                >
                    <>

                        {title}
                        <Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "0", right: "-6px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}>
                            <InfoOutlinedIcon className={classes.modalIconBackground} />
                        </Button>

                        <div ref={ref}>
                            <Overlay
                                show={show}
                                target={target}
                                placement="bottom-start"
                                container={ref}
                                containerPadding={20}
                                rootClose
                                onHide={() => setShow(false)}
                            >
                                <Popover id="popover-contained">
                                    <Popover.Header as="h3"><span style={{ color: "#000" }}>Action Guide</span></Popover.Header>
                                    {batchApprovalOptionsStringContentData && (
                                        <Popover.Body dangerouslySetInnerHTML={{ __html: batchApprovalOptionsStringContentData.value }}></Popover.Body>
                                    )}
                                </Popover>
                            </Overlay>
                        </div>
                    </>

                </DialogTitle>
                <DialogContent dividers={true}>
                    <Paper elevation={6}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            component="span"
                            tabIndex={-1}
                            className="cmn-dialogtext" >
                            <Typography
                                component="span"
                                color="black"
                                className="cmn-dialogtext"
                            >
                                <InputLabel sx={{ padding: '10px' }} className={classes.title}>Mailing Batch ID:
                                    {
                                        <Typography component="span" color="black" sx={{ fontWeight: '600', paddingLeft: '5px', fontSize: '14px', lineHeight: '21px' }}>{mailingBatchId}</Typography>
                                    }
                                </InputLabel>
                                <FormControl sx={{ padding: '20px' }}>                                    
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={value}
                                        onChange={handleChange}
                                        defaultValue="sendNotice"
                                    >
                                        <Grid container spacing={2} sx={{ textAlign: 'left' }}>
                                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isBatchApprovalProcessComplete}>
                                                    <Stack>
                                                        <CircularProgress color="inherit" />
                                                        <br />
                                                        <Typography sx={{ color: "#fff" }} variant="h4">
                                                            Processing your request...
                                                        </Typography>
                                                    </Stack>
                                                </Backdrop>
                                            </Box>
                                            <Grid item xs={12} sm={12} lg={12}>
                                                <FormControlLabel value="sendNotice" control={<BpRadio />} label={sendNotices} />
                                            </Grid>
                                            <Grid style={{paddingLeft:'35px', paddingTop:'5px'}} item xs={12} sm={12} lg={12}>
                                                <FormControlLabel control={<Checkbox sx={{
                                                    color: blue[800],
                                                    '&.Mui-checked': {
                                                        color: blue[600],
                                                    },
                                                }} checked={checked} disabled={checkboxDisabled} onChange={handleCheckChange} />} label={sendNoticeAndEmailAutomatically} />
                                            </Grid>
                                            <Grid style={{ paddingTop:'5px'}} item xs={1} sm={1} lg={1}>

                                            </Grid>
                                            <Grid style={{ paddingTop: '0px' }} item xs={12} sm={12} lg={12}>
                                                <hr style={{
                                                    border: 'none',
                                                    height: '5px',
                                                    backgroundColor: '#006B86',
                                                    margin:'0px'
                                                }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={12}>
                                                <FormControlLabel value="rejectNotice" control={<BpRadio />} label={rejectNotices} />
                                                <div id="divRejectReason">
                                                    <Box
                                                        component="form"
                                                        sx={{
                                                            '& > :not(style)': { m: 1},
                                                        }}
                                                        noValidate
                                                        autoComplete="off"
                                                        className="rejectReasonHelper" 
                                                    >
                                                        <TextField  
                                                            variant="outlined" InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        rows={4} multiline id="rejectReason"
                                                        label="reject reason" value={rejectReason}
                                                        helperText={helperTextRejectReason}
                                                        error={showRejectReasonError}
                                                        fullWidth                                                       
                                                        disabled={rejectReasonDisabled}
                                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                        if (event.target.value !== '') {
                                                            setHelperTextRejectReason('');
                                                            setshowRejectReasonError(false);
                                                        }
                                                        setRejectReason(event.target.value);
                                                        }} />
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </Typography>

                        </DialogContentText>
                    </Paper>
                </DialogContent>
                <DialogActions sx={{paddingRight:'22px'} }>
                    <Button color="inherit"
                        className="cmn-secbtn"
                        size="small"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button size="small" className="btn btn-primary" variant="contained" onClick={handleSubmitApproveDocument} disabled={submitDisabled} classes={{ disabled: classes.disabledButton}}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default SubmissionEpiqDocumentApproveModal;