import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Theme,
	Typography,
	Button
} from "@mui/material";

import {
	IColumnHeader,
	ISecuritiesTableFieldMap,
	ISorter,
	Order
} from "./SecuritiesTable";

import { DEFAULT_ROWS_PER_PAGE } from "../../../constants/CommonConstants";
import React, { forwardRef, useState, useEffect, useRef} from "react";
import SecuritiesTableFilter from "./SecuritiesTableFilter";
import SecuritiesTableHeader from "./SecuritiesTableHeader";
import SecuritiesTableRow from "./SecuritiesTableRow";
import SubmissionDetailCollapsibleTableRow from "./SubmissionDetailCollapsibleTableRow";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

const createLocalStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			marginBottom: '0rem',
		},
		paper: {
			width: "100%",
			marginBottom: theme.spacing(2),
		},
		title: {
			paddingTop: theme.spacing(1),
			paddingLeft: theme.spacing(2),
			fontWeight: "bold",
		},
		table: {
			minWidth: 800,
		},
		exportBtn: {
			float: "right",
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
			marginRight: theme.spacing(2)
		}
	})
);

export interface ISubmissionDetailTableProps {
	columns: IColumnHeader[];
	rows: ISecuritiesTableFieldMap[] | null;
	sorter: ISorter;
	title?: string;
	maxTableHeight?: number;
	subTableTitle?: string;
	idToExpandOnLoad?: number;
	scrollFunc: Function;
}

const DEFAULT_MAX_TABLE_HEIGHT = 750;


const SubmissionDetailTable = forwardRef(function SubmissionDetailTable(props: ISubmissionDetailTableProps, ref: any) {
	const { columns, rows, sorter, title, maxTableHeight, subTableTitle, idToExpandOnLoad, scrollFunc } = props;

	const classes = createLocalStyles();
	const [order, setOrder] = useState<Order>(sorter.order);
	const [orderBy, setOrderBy] = useState<keyof ISecuritiesTableFieldMap>(sorter.property);
	
	const activeSorter: ISorter = {
		property: orderBy,
		order: order,
	};

	const handleScroll = (index: number) => {
		scrollFunc(index);
    }

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ISecuritiesTableFieldMap) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);

		sorter.property = property;
		sorter.order = isAsc ? "desc" : "asc";
	};

	function genericSort(a: ISecuritiesTableFieldMap, b: ISecuritiesTableFieldMap, sorter: ISorter) {
		if (!sorter?.property) return 0;

		let objectA =
			a[sorter.property].sortValue === null || a[sorter.property].sortValue === undefined
				? a[sorter.property].value
				: a[sorter.property].sortValue;

		let objectB =
			b[sorter.property].sortValue === null || b[sorter.property].sortValue === undefined
				? b[sorter.property].value
				: b[sorter.property].sortValue;

		if (typeof objectA === "string" || objectA instanceof String) {
			objectA = objectA.toLowerCase();
		}

		if (typeof objectB === "string" || objectB instanceof String) {
			objectB = objectB.toLowerCase();
		}

		const result = () => {
			if (objectA === null) {
				return -1;
			}

			if (objectB === null) {
				return 1;
			}

			if (objectA > objectB) {
				return 1;
			}

			if (objectA < objectB) {
				return -1;
			}

			return 0;
		};

		return sorter.order !== "asc" ? result() * -1 : result();
	}

	const processRows = (rows: ISecuritiesTableFieldMap[]) => {
		let rowShouldExpand = false;
		let rowIndex = -1;

		var processedRows = rows
			.sort((a, b) => genericSort(a, b, activeSorter))
			.map((row: any, index) => {

				let shouldExpand = idToExpandOnLoad === row.id.value && row.isNonClaimDeficiencyResponse.value === false;

				if (shouldExpand) {
					rowShouldExpand = true;
					rowIndex = index;
				}

				return <SubmissionDetailCollapsibleTableRow
					columns={columns}
					row={row}
					index={index}
					key={index}
					subTableTitle={subTableTitle}
					openOnLoad={shouldExpand}
				/>
			});

		if (rowShouldExpand && rowIndex > 0) {
			handleScroll(rowIndex);
        }
		return processedRows;
	};

	

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				{title ? <Typography className={classes.title}>{title}</Typography> : null}

				<TableContainer ref={ref} sx={{ maxHeight: maxTableHeight || DEFAULT_MAX_TABLE_HEIGHT }} id={ 'my-container' }>
					<Table
						id="securitiesTable"
						className={classes.table}
						aria-labelledby="tableTitle"
						aria-label="enhanced table"
					>
						<SecuritiesTableHeader
							columns={columns}
							orderBy={orderBy}
							order={order}
							onRequestSort={handleRequestSort}
						/>
						
						<TableBody>
							{!rows || rows.length === 0 ? (
								<TableRow>
									<TableCell colSpan={columns.length} style={{ "textAlign": "center" }}>
										{rows && <Typography id="noResults" variant='h6'>The search returned no results.</Typography>}
									</TableCell>
								</TableRow>
							) : (
								processRows(rows)
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
});

export default SubmissionDetailTable;