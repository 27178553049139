import { all, call, put, takeLeading } from 'redux-saga/effects';
import { IAction } from '../../types/types';
import {
	caseSearchFailed, caseSearchSuccess, CASE_SEARCH, clearCaseSearchFailed, clearCaseSearchSuccess,
	CLEAR_CASE_SEARCH, getCaseDetailByIdFailed, getCaseDetailByIdSuccess, GET_CASEDETAIL_BY_ID
} from './casebook.actions';
import { getCasebookCaseById, searchCases } from './casebook.api';

function* caseSearchSaga(action: IAction): any {
	try {
		const results = yield call(searchCases, action.payload);
		yield put(caseSearchSuccess(results));
	} catch (error: any) {
		yield put(caseSearchFailed(error.message));
	}
};

function* caseSearchWatcher() {
	yield takeLeading(CASE_SEARCH, caseSearchSaga);
};

function* clearCaseSearchSaga(): any {
	try {
		yield put(clearCaseSearchSuccess());
	} catch (error: any) {
		yield put(clearCaseSearchFailed(error));
	}
};

function* clearCaseSearchWatcher() {
	yield takeLeading(CLEAR_CASE_SEARCH, clearCaseSearchSaga);
};

function* getCaseDetailByIdSaga(action: IAction): any {
	try {
		const caseDetail = yield call(getCasebookCaseById, action.payload);
		yield put(getCaseDetailByIdSuccess(caseDetail));
	} catch (error) {
		yield put(getCaseDetailByIdFailed(error));
	}
}

function* getCaseDetailByIdWatcher() {
	yield takeLeading(GET_CASEDETAIL_BY_ID, getCaseDetailByIdSaga)
}

export default function* casebookSaga() {
	yield all([
		caseSearchWatcher(),
		clearCaseSearchWatcher(),
		getCaseDetailByIdWatcher()
	])
};