import { AlertType } from '../../types/enums';
import { IAction, IUpdateInstitutionResponse } from '../../types/types';

import { GET_INSTITUTIONLIST, GET_INSTITUTION_BY_ID, GET_INSTITUTION_COUNTRIESSTATES, GET_INSTITUTION_TYPE, UPDATE_INSTITUTION, UPDATE_INSTITUTION_FAILED, UPDATE_INSTITUTION_SUCCESS, getCountriesStatesFailure, getCountriesStatesSuccess, getInstitutionByIdFailed, getInstitutionByIdSuccess, getInstitutionListFailure, getInstitutionListSuccess, getInstitutionTypeFailure, getInstitutionTypeSuccess, updateInstitutionFailed, updateInstitutionSuccess } from './institutionlist.actions';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import { getCountriesStatesAsync, getInstitutionByIdAsync, getInstitutionList, getInstitutionTypeAsync, updateInstitutionAsync } from './institutionlist.api';
import { showAlert } from '../Alerts/alerts.actions';

function* getInstitutionListSaga({ payload: { filter } }: any): any {
    try {
        const institution = yield call(getInstitutionList, filter);
        yield put(getInstitutionListSuccess(institution));
    } catch (error: any) {
        yield put(getInstitutionListFailure(error.message));
    }
}

function* getInstitutionListWatcher() {
    yield takeLeading(GET_INSTITUTIONLIST, getInstitutionListSaga);
}

function* getInstitutionByIdSaga(action: IAction): any {

    try {

        const institutiondetailid = yield call(getInstitutionByIdAsync, action.payload);
		yield put(getInstitutionByIdSuccess(institutiondetailid));
    }

    catch (error: any) {

        yield put(getInstitutionByIdFailed(error.message));

    }

}

function* getInstitutionByIdWatcher() {
	yield takeLeading(GET_INSTITUTION_BY_ID, getInstitutionByIdSaga);
}

function* updateInstitutionSaga(action: IAction): any {

    try {

        yield call(updateInstitutionAsync, action.payload);
        const updateInstitutionResponse: IUpdateInstitutionResponse = { success: true, message: 'Institution updated successfully.' }
        yield put(updateInstitutionSuccess(updateInstitutionResponse));
        yield put(showAlert({
            hidden: false,
			message: updateInstitutionResponse.message,
			severity: updateInstitutionResponse.success
			? AlertType.success
			: AlertType.error
        }));

    }

    catch (error: any) {

        yield put(updateInstitutionFailed(error.message));

    }
}


function* updateInstitutionWatcher() {
	yield takeLeading(UPDATE_INSTITUTION, updateInstitutionSaga)
}


function* getInstitutionTypeSaga(): any {

    try {

        const institutionType = yield call(getInstitutionTypeAsync);
        yield put(getInstitutionTypeSuccess(institutionType));

    }

    catch (error: any) {

        yield put(getInstitutionTypeFailure(error.message));

    }

}

function* getInstitutionTypeWatcher() {
    yield takeLeading(GET_INSTITUTION_TYPE, getInstitutionTypeSaga);
}

function* getCountriesStatesSaga(): any {

    try {

        const countriesstatesInstitution = yield call(getCountriesStatesAsync);
        yield put(
            getCountriesStatesSuccess(countriesstatesInstitution)
        );

    }

    catch (error: any) {

        yield put(getCountriesStatesFailure(error.message));

    }

}

function* getCountriesStatesWatcher() {
    yield takeLeading(GET_INSTITUTION_COUNTRIESSTATES, getCountriesStatesSaga);
}

export default function* insitutionlistSaga() {
    yield all([getInstitutionListWatcher(),
        getInstitutionByIdWatcher(),
        updateInstitutionWatcher(),
        getInstitutionTypeWatcher(),
        getCountriesStatesWatcher()
    ]) //only one of these right now. add more as needed
}