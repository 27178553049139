import authService from '../../components/api-authorization/AuthorizeService';
import { IUser } from './UserContext';

export const getLoggedInUser = async () => {

    let user: IUser = { name: "" } as IUser;

    try {      

        const isUserAuthenticated = await authService.isAuthenticated();

        if (!isUserAuthenticated)
            return user;

        const token = await authService.getAccessToken();

        let response = await fetch(`api/User/LoggedInUser`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        if (!response.ok)
            throw new Error(response.statusText);

        return response.json();

    } catch (error) {
        console.error(`failed to get logged in user, error: ${error}`);
        return user;
    }

};