import {
	CASE_SEARCH, CASE_SEARCH_FAILED, CASE_SEARCH_SUCCESS, CLEAR_CASE_SEARCH, CLEAR_CASE_SEARCH_FAILED, CLEAR_CASE_SEARCH_SUCCESS, GET_CASEDETAIL_BY_ID,
	GET_CASEDETAIL_BY_ID_FAILED, GET_CASEDETAIL_BY_ID_SUCCESS
} from './casebook.actions';
import { ICasebookState, IAction } from '../../types/types';

const initialState: ICasebookState = {
	isLoading: false,
	searchResults: []
};

const handleAction = (state = initialState, { type, payload }: IAction) => {
	switch (type) {
		case CASE_SEARCH:
			return {
				...state,
				isLoading: true,
				error: null
			}
		case CASE_SEARCH_SUCCESS:
			return {
				...state,
				isLoading: false,
				searchResults: payload
			}
		case CASE_SEARCH_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload
			}
		case CLEAR_CASE_SEARCH:
			return {
				...state,
				isLoading: true
			}
		case CLEAR_CASE_SEARCH_SUCCESS:
			return {
				...state,
				isLoading: false,
				searchResults: []
			}
		case CLEAR_CASE_SEARCH_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload
			}
		case GET_CASEDETAIL_BY_ID:
			return {
				...state,
				isLoading: true,
				selectedCasebookCaseDetail: null
			}
		case GET_CASEDETAIL_BY_ID_SUCCESS:
			return {
				...state,
				isLoading: false,
				selectedCasebookCaseDetail: payload
			}
		case GET_CASEDETAIL_BY_ID_FAILED:
			return {
				...state,
				error: payload,
				isLoading: false,
				selectedCasebookCaseDetail: null
			}
		default:
			return state;
	}

};

export default handleAction;
