import React from 'react';
import { Checkbox, TableCell, TableRow, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IColumnHeader, ISecuritiesTableField } from './SecuritiesTable'
import { format12hourDateTime } from "../../../utilities/utilities";

interface SecuritiesTableRowProps<T> {
	columns: IColumnHeader[],
	row: any,
	index: number
}

const renderField = (field: ISecuritiesTableField): any => {

	switch (field.type) {

		case "datetime":
			if (field.value === null) {
				return '';
			}

			return format12hourDateTime(new Date(field.value));

		case "date":
			if (field.value === null) {
				return '';
			}

			let date = new Date(field.value);
			return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

		case "boolean":
			if (field.value === null) {
				return '';
			}

			return field.value ? <Checkbox checked={true} style={{color: 'rgba(0, 0, 0, 0.6)'}}/> : <Checkbox checked={false} style={{color: 'rgba(0, 0, 0, 0.6)'}}/>;

		default:
			return field.value
	}
}

const createLocalStyles = makeStyles((theme: Theme) =>
	createStyles({
		tablecell: {
			whiteSpace: "pre-wrap",
			wordWrap: "break-word",
			overflowWrap: "break-word"
		},
		namecell: {
			overflowWrap: "break-word",
			whiteSpace: "pre-wrap",
			wordWrap: "break-word"
		},
	}),
);

export default function SecuritiesTableRow<T>({ columns, row, index }: SecuritiesTableRowProps<T>) {
	const classes = createLocalStyles();
	return (
		<TableRow key={index}>
			{columns.map((column, idx) => (
				<TableCell size="small" key={idx} className={column.field === "name" ? classes.namecell : classes.tablecell} >
					{renderField(row[column.field])}
				</TableCell>
			))}
		</TableRow>
	)
}