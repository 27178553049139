import { IRegisterUser } from '../../types/types';
import React from "react";
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import { convertUTCToLocalTime, formatPhoneNumber } from '../../utilities/utilities';

const useStyles = makeStyles((theme: Theme) => ({
    userDetails: {
        margin: '0px',
        padding: '80px 20px',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        verticalAlign: 'top',
    },
    userDetailHead: {
        fontSize: '22px',
        fontWeight: '600',
        textTransform: 'capitalize',
        marginBottom: '40px',
    },
    userDetailsContactOuter: {
        marginBottom: '40px',
    },
    userDetailTitle: {
        fontSize: '15px',
        fontWeight: '500',
        textTransform: 'capitalize',
    },
    userDetailTitleEmail: {
        fontSize: '15px',
        fontWeight: '500',
        textTransform: 'none',
    },
    userDetailContact: {
        fontSize: '16px',
        fontWeight: '700',
        textTransform: 'capitalize',
    },
    userDetailSpan: {
        width: '150px',
        display: 'inline-block',
    }
}));

interface ISlideoutDrawerProps {
    selectedDrawerUser?: IRegisterUser,
    isOpen: boolean,
    handleClose: any
}
const UserSlideoutDrawer = ({ selectedDrawerUser, isOpen, handleClose }: ISlideoutDrawerProps) => {
    const classes = useStyles();

    return (
        <div>
            <Drawer
                anchor={'right'}
                open={isOpen}
                onClose={handleClose}
                sx={{ zIndex: '9999' }}
            >
                <Box
                    sx={{ width: 380 }}
                    role="presentation"
                    onClick={handleClose}
                    onKeyDown={handleClose}
                >
                    <div className={classes.userDetails}>
                        <h1 className={classes.userDetailHead}>user details</h1>
                        <div className={classes.userDetailsContactOuter}>
                            <h2 className={classes.userDetailContact}>contact info</h2>
                            <h2 className={classes.userDetailTitle}>{selectedDrawerUser?.firstName} {selectedDrawerUser?.lastName}</h2>
                            <h2 className={classes.userDetailTitleEmail}>{selectedDrawerUser?.email}</h2>
                            <h2 className={classes.userDetailTitle}>{formatPhoneNumber(selectedDrawerUser?.phone)}
                                <span> </span>
                                <span className={classes.userDetailTitle}>{selectedDrawerUser?.extension}</span>
                            </h2>
                        </div>
                        <div className={classes.userDetailsContactOuter}>
                            <h2 className={classes.userDetailContact}>institution</h2>
                            <h2 className={classes.userDetailTitle}>{selectedDrawerUser?.institution?.name}</h2>
                        </div>
                        <div className={classes.userDetailsContactOuter}>
                            <h2 className={classes.userDetailTitle}><span><strong>Created On:</strong></span> {convertUTCToLocalTime(selectedDrawerUser?.insertedOn === undefined ? "" : (selectedDrawerUser?.insertedOn))}</h2>
                            <h2 className={classes.userDetailTitle}><span><strong>Confirmed On:</strong></span> {convertUTCToLocalTime(selectedDrawerUser?.confirmedOn === undefined ? "" : (selectedDrawerUser?.confirmedOn))}</h2>
                            <h2 className={classes.userDetailTitle}><span><strong>Approved On:</strong></span> {convertUTCToLocalTime(selectedDrawerUser?.approvedOn === undefined ? "" : (selectedDrawerUser?.approvedOn))}</h2>
                            <h2 className={classes.userDetailTitle}><span><strong>Approved By:</strong></span> {selectedDrawerUser?.approvedByUserName}</h2>
                            <h2 className={classes.userDetailTitle}><span><strong>Last Login:</strong></span> {convertUTCToLocalTime(selectedDrawerUser?.lastLogin === undefined ? "" : (selectedDrawerUser?.lastLogin))}</h2>
                        </div>
                    </div>
                </Box>
            </Drawer>
        </div>
    );
};

export default UserSlideoutDrawer;