import { SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE, DEFAULT_ROWS_PER_PAGE, STANDARD_KABOB_HEIGHT } from '../../constants/CommonConstants';
import { IFilterDTO, ISubmissionListState, ISubmissionListItem, ISubmissionListResponse } from "../../types/types";
import React, { Fragment, useContext, useEffect, useState } from "react";
import SecuritiesTable from '../../components/Shared/SecuritiesTable/SecuritiesTable';
import {
    IColumnHeader,
    ISecuritiesTableFieldMap,
    ISorter
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import { createFilterDTOsFromColumnHeaders } from "../../utilities/filterCriteria";
import { getCaseSubmissionsAction, getSubmissionDocumentAction, exportCaseSubmissionsAction } from "../Submissions/submissions.actions";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from '@mui/material/MenuItem';
import { useHistory, Link, useParams } from "react-router-dom";
import { Card, CardContent, Grid, IconButton, Menu, Skeleton, Theme, Tooltip, Typography } from "@mui/material";
import { getSubmissionStatusList, getSubmissionStatusText, transformNomineeStatusList } from '../../utilities/submissionStatus';
import { SubmissionState, SubmissionType, SubmissionTypeDisplay } from "../../types/enums";
import { EpiqProjectManager, EpiqTechSupport, EpiqUser, Nominee } from '../../constants/roles';
import { filterSubmissions } from './common';
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import makeStyles from "@mui/styles/makeStyles";
import { setShowUserSubmissionsAction } from './submissions.actions';
import { getCaseIdsAcceptingSubmissions } from '../Cases/cases.api';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSignal } from '@preact/signals-react';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        flexGrow: 1,
        marginBottom: theme.spacing(2),
        display: "inline-flex",
        marginRight: "5px"
    },
    switch: {
        position: "relative",
        zIndex: '1'
    },
    grid: {
        position: "relative",
        top: "-60px"
    },
    links: {
        color: "#006B86"
    },
    kabobIconEnable: {
        color: "#087C9B",
        filter: "dropShadow(1px 1px 2px rgba(0, 0, 0, 0.44))",
        "&:disabled": {
            color: "#BCC0C5",
        },
    }

}));
interface ISubmissionListGridProps {
    selectSubmission: Function,
    resumeSubmission: Function,
    slideoutDrawer: Function,
    isDirty: boolean,
    selectDeficiencySubmission: Function,
    slideoutDrawerRejections: Function
}

const submissionTypes: string[] = ["Initial Submission", "Correction", "Update", "Replacement", "Defect Response"];

const submissionColumns: IColumnHeader[] = [
    {
        field: "dateOfSubmission",
        label: "Date of Submission",
        parameterName: "dateOfSubmissionEnd",
        type: "date",
        value: "",
        allowFilter: true,
    },
    { field: "number", label: "Submission #", parameterName: "number", type: "string", value: "", allowFilter: true },
    { field: "caseName", label: "Case Name", parameterName: "caseName", type: "string", value: "", allowFilter: true },
    { field: "fileName", label: "File Name", parameterName: "fileName", type: "string", value: "", allowFilter: true },
    {
        field: "institutionName",
        label: "Institution",
        parameterName: "institutionName",
        type: "string",
        value: "",
        allowFilter: true,
    },
    {
        field: "status",
        label: "Status",
        parameterName: "statusList",
        type: "selectMany",
        enumValues: [],
        value: "",
        allowFilter: true,
        hideSortIcon: true
    },
    { field: 'actions', label: 'Actions' }
];

const relatedSubmissionColumns: IColumnHeader[] = [
    { field: "dateOfSubmission", label: "Date of Submission" },
    { field: "number", label: "Submission #" },
    { field: "submissionType", label: "Submission Type" },
    { field: "fileName", label: "File Name" },
    { field: "status", label: "Status", hideSortIcon: true }
];

const defaultSort: ISorter = {
    property: "dateOfSubmission",
    order: "desc",
};

const defaultFilter: IFilterDTO[] = [];
let sortInfo: ISorter = { ...defaultSort };
let filterBackup: IFilterDTO[];

const SubmissionListGrid = ({ selectSubmission, resumeSubmission, slideoutDrawer, isDirty, selectDeficiencySubmission, slideoutDrawerRejections}: ISubmissionListGridProps) => {
    const { isAuthenticated, isInitialized, user, isInRole } = useContext(UserContext) as UserContextType;
    const history = useHistory();
    const dispatch = useDispatch();
    let { type } = useParams<{ type: string }>();
    let [institutionId, setInstitutionId] = useState(0);
    let [userId, setuserId] = useState(0);
    let initialFilter: IFilterDTO[];
    let [shouldPrefilter, setShouldPrefilter] = useState(true);
    const submissionState: ISubmissionListState = useSelector((state: any) => state.submissionListState);
    const showUserSubmissions = useSignal<boolean>(submissionState.showUserSubmissions);
    const classes = useStyles();
    const [caseIdsAcceptingSubmissions, setCaseIdsAcceptingSubmissions] = useState<number[]>([]);

    useEffect(() => {
        getCaseIdsAcceptingSubmissions()
            .then((data) => {
                if (data) {
                    setCaseIdsAcceptingSubmissions(data);
                }
            });
    }, []);

    useEffect(() => {

        if (user.isOffshoreUser)
            defaultFilter.push({
                'parameter': 'isOffshoreUser',
                'type': 'boolean',
                'value': 'true'
            });

    }, [user]);

    let submissionResponse: ISubmissionListResponse = useSelector((state: any) => {
        return {
            submissions: state.submissionListState.submissions,
            totalRowCount: state.submissionListState.totalRowCount
        };
    });

    if (shouldPrefilter) {
        if (type !== null && type !== undefined && type !== ':type')
            (type === '1') ? submissionColumns[5].value = '1,2,3,4,5,6,10' : submissionColumns[5].value = type;

        setShouldPrefilter(false);
    }

    const initializeGrid = () => {
        if (type === '0') {
            submissionColumns[5].value = '';
            history.replace('/SubmissionList');
        }

        populateStatusListFilter();
        initialFilter = createFilterDTOsFromColumnHeaders(submissionColumns).concat(defaultFilter);
        transformStatusListFilter(initialFilter);
        filterBackup = [...initialFilter];
        setInstitutionId(user.institutionId);
        setuserId(user.id);

        dispatch(getCaseSubmissionsAction({
            filter: initialFilter,
            caseId: 0,
            institutionId: user.institutionId,
            userId: user.id,
            isUserSubmission: showUserSubmissions.value,
            sorter: sortInfo,
            pageNumber: 1,
            rowsPerPage: DEFAULT_ROWS_PER_PAGE
        }));
    };

    useEffect(() => {
        if (!user.id) {
            return;
        }

        if (isInRole([EpiqUser])) {
            showUserSubmissions.value = false;
        } else {
            showUserSubmissions.value = submissionState.showUserSubmissions;
        }
    }, [user]);

    useEffect(() => {
        if (!user.id) {
            return;
        }

        initializeGrid();
    }, [user, showUserSubmissions.value]);

    const handleResetFilter = () => {
        setShouldPrefilter(false);
        sortInfo = { ...defaultSort }
    };

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        showUserSubmissions.value = !event.target.checked;
        dispatch(setShowUserSubmissionsAction(showUserSubmissions.value));
    };

    const transformStatusListFilter = (filters: IFilterDTO[]) => {
        let statusListObj = filters.find(item => item.parameter === 'statusList');

        if (statusListObj)
            statusListObj.value = transformNomineeStatusList(statusListObj.value);

    }

    const populateStatusListFilter = () => {

        if (submissionColumns[5].enumValues?.length === 0) {
            submissionColumns[5].enumValues = getSubmissionStatusList(isInRole([Nominee]));
        }

    }

    const fetchFilteredCaseSubmissionsList = (filters: IFilterDTO[], pageNumber: number, rowsPerPage: number) => {

        populateStatusListFilter();
        transformStatusListFilter(filters);

        let submissionListFilters: IFilterDTO[] = filters.concat(defaultFilter);

        filterBackup = [...submissionListFilters];
        dispatch(getCaseSubmissionsAction({
            filter: submissionListFilters,
            caseId: 0,
            institutionId: institutionId,
            userId: userId,
            isUserSubmission: showUserSubmissions.value,
            sorter: sortInfo,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        }));
    };

    const exportFilteredCaseSubmissionList = () => {
        let isNominee: boolean = false;

        if (isInRole([Nominee]))
            isNominee = true;

        dispatch(exportCaseSubmissionsAction({
            filter: filterBackup,
            caseId: 0,
            isNominee: isNominee,
            institutionId: institutionId,
            userId: userId,
            isUserSubmission: showUserSubmissions.value,
            sorter: sortInfo
        }));
    };

    const getSubmissionDocument = (submissionId: number) => {
        dispatch(getSubmissionDocumentAction(submissionId));
    }

    const userCanPerformSubmissionActions: boolean = (isInitialized && isAuthenticated && isInRole([EpiqProjectManager, EpiqTechSupport, Nominee]));

    const getSubmissionActions = (caseId: number,
        submissionState: SubmissionState,
        submissionId: number,
        parentSubmissionId: number,
        caseName: string,
        caseNumber: string,
        submissionType: SubmissionType,
        submissionDescription: string,
        relatedSubmissions: ISubmissionListItem[],
        canAcceptDeficiencyResponse: boolean
    ): any => {
        if (submissionState === SubmissionState.Started) {
            return (
                (caseIdsAcceptingSubmissions.includes(caseId) &&
                    <div>
                        <MenuItem
                            onClick={() => {

                                let idToResume = 0;
                                let submissionTypeToResume: SubmissionType = 0

                                if (relatedSubmissions.length > 0) {
                                    idToResume = relatedSubmissions[0].id;
                                    submissionTypeToResume = relatedSubmissions[0].submissionTypeId;
                                }
                                else {
                                    idToResume = submissionId;
                                    submissionTypeToResume = submissionType;
                                }

                                resumeSubmission(
                                    caseId,
                                    submissionTypeToResume,
                                    idToResume,
                                    caseName,
                                    caseNumber,
                                    submissionState,
                                    submissionDescription
                                );
                            }}
                        >
                            <Tooltip title={
                                <Typography style={{ fontSize: 14 }}>Resume Submission</Typography>
                            }
                                placement="left-end"
                            >
                                <Typography>Resume</Typography>
                            </Tooltip>
                        </MenuItem>
                    </div>
                )
            );
        }

        if (submissionState === SubmissionState.Rejected) {
            return (
                (caseIdsAcceptingSubmissions.includes(caseId) &&
                    <MenuItem
                        onClick={() => {
                            selectSubmission(caseId,
                                SubmissionTypeDisplay.Replacement,
                                (parentSubmissionId) ? parentSubmissionId : submissionId,
                                caseName,
                                caseNumber
                            )
                        }}
                    >
                        <Tooltip title={
                            <Typography style={{ fontSize: 14 }}>Replace Submission</Typography>
                        }
                            placement="left-end"
                        >
                            <Typography>Replace</Typography>
                        </Tooltip>
                    </MenuItem>
                )
            );
        }
        else {
            return (
                (
                    <div>
                        {(caseIdsAcceptingSubmissions.includes(caseId) &&
                            <>
                                <MenuItem
                                    onClick={() => {
                                        selectSubmission(caseId,
                                            SubmissionTypeDisplay.Update,
                                            (parentSubmissionId) ? parentSubmissionId : submissionId,
                                            caseName,
                                            caseNumber
                                        )
                                    }}
                                >
                                    <Tooltip title={
                                        <Typography style={{ fontSize: 14 }}>Update Submission</Typography>
                                    }
                                        placement="left-end"
                                    >
                                        <Typography>Update</Typography>
                                    </Tooltip>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        selectSubmission(caseId,
                                            SubmissionTypeDisplay.Replacement,
                                            (parentSubmissionId) ? parentSubmissionId : submissionId,
                                            caseName,
                                            caseNumber
                                        )
                                    }}
                                >
                                    <Tooltip title={
                                        <Typography style={{ fontSize: 14 }}>Replace Submission</Typography>
                                    }
                                        placement="left-end"
                                    >
                                        <Typography>Replace</Typography>
                                    </Tooltip>
                                </MenuItem>
                            </>
                        )}
                        {(canAcceptDeficiencyResponse &&
                            <MenuItem
                                onClick={() => {
                                    selectDeficiencySubmission(caseId,
                                        SubmissionTypeDisplay.Deficiency,
                                        (parentSubmissionId) ? parentSubmissionId : submissionId,
                                        caseName,
                                        caseNumber
                                    )
                                }}
                            >
                                <Tooltip title={
                                    <Typography style={{ fontSize: 14 }}>Deficiency Respons</Typography>
                                }
                                    placement="left-end"
                                >
                                    <Typography>Defect Response</Typography>
                                </Tooltip>
                            </MenuItem>
                        )}
                    </div>
                )
            );
        }
    };

    const getSubmissionType = (item: ISubmissionListItem, row: ISubmissionListItem): string => {

        if (item.submissionTypeId === 0)
            return submissionTypes[0];

        const preposition = item.submissionTypeId === 3 ? ' of ' : ' to ';
        let parentId = item.parentSubmissionId;

        if (row.id === item.parentSubmissionId)
            return submissionTypes[item.submissionTypeId] + preposition + row.submissionDescription;


        if (row.relatedSubmissions) {
            const found = row.relatedSubmissions.find(element => element.id === parentId);

            if (found)
                return submissionTypes[item.submissionTypeId] + preposition + found.submissionDescription;

        }

        return submissionTypes[item.submissionTypeId];
    }

    const buildRelatedSubmissionRows = (parentIndex: number, row: ISubmissionListItem): ISecuritiesTableFieldMap[] => {
        var relatedSubmissionRows: ISecuritiesTableFieldMap[] = [];

        row.relatedSubmissions &&
            row.relatedSubmissions.forEach((item, index) => {
                if (isInRole([Nominee]) && item.status.toLowerCase() === "error")
                    item.status = SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE;

                let newItem: ISecuritiesTableFieldMap = {
                    dateOfSubmission: { type: "date", value: item.dateOfSubmission },
                    number: {
                        type: "link",
                        value: (
                            <Link color="success" to={`/SubmissionDetail/${item.id}`}>{item.submissionDescription}</Link>
                        )
                    },
                    caseName: {
                        type: "link",
                        value: (
                            <Link color="success" to={`/CaseDetail/${item.caseId}`}>{item.caseName}</Link>
                        )
                    },
                    submissionType: {
                        type: "string",
                        value: getSubmissionType(item, row)
                    },
                    fileName: {
                        type: "link",
                        value: (
                            <span style={{ cursor: "pointer", color: "#006B86" }} onClick={() => { getSubmissionDocument(item.id) }}>{item.fileName}</span>
                        )
                    },
                    institutionName: {
                        type: "link",
                        value: (
                            <Link color="success" to="#" onClick={() => { slideoutDrawer(item.institutionId, true) }}>{item.institutionName}</Link>
                        )
                    },
                    status: {
                        type: "string",
                        value: <span>{getSubmissionStatusText(isInRole([Nominee]), item.status) || ''}</span>
                    }
                };

                relatedSubmissionRows.push(newItem);
            });

        return relatedSubmissionRows;
    }

    const [kabobsMenu, setKabobsMenu] = React.useState<null | HTMLElement>(null);
    let [selectedRowIndex, setSelectedRowIndex] = useState(-1);

    const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
        setKabobsMenu(event.currentTarget);
        setSelectedRowIndex(index);
    };

    const handleClose = () => {
        setKabobsMenu(null);
        setSelectedRowIndex(-1);
    };

    const buildSubmissionRows = (): ISecuritiesTableFieldMap[] => {
        var submissionRows: ISecuritiesTableFieldMap[] = [];

        let submissionsToDisplay: ISubmissionListItem[] = [];

        submissionsToDisplay = filterSubmissions(submissionResponse.submissions, isInRole([Nominee]))

        submissionsToDisplay &&
            submissionsToDisplay.forEach((item, index) => {
                if (isInRole([Nominee]) && item.status.toLowerCase() === "error")
                    item.status = SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE;

                let newItem: ISecuritiesTableFieldMap = {
                    collapsible: { type: 'boolean', value: item.hasRelatedSubmissions ? true : false },
                    subTableRows: { type: 'array', value: buildRelatedSubmissionRows(index, item) },
                    dateOfSubmission: { type: "date", value: item.dateOfSubmission },
                    number: {
                        type: "link",
                        value: (
                            <>
                                <Link color="success" to={`/SubmissionDetail/${item.id}`}>
                                    {item.submissionDescription}
                                </Link>
                            </>
                        ),
                        sortValue: item.submissionDescription,
                    },
                    caseName: {
                        type: "link",
                        value: (
                            <Link color="success" to={`/CaseDetail/${item.caseId}`}>
                                {item.caseName}
                            </Link>
                        ),
                        sortValue: item.caseName,
                    },
                    fileName: {
                        type: "link",
                        value: (
                            <span style={{ cursor: "pointer", color: "#006B86" }} onClick={() => { getSubmissionDocument(item.latestSubmissionId) }}>
                                {item.fileName}
                            </span>
                        ),
                        sortValue: item.fileName,
                    },
                    institutionName: {
                        type: "link",
                        value: (
                            <Link color="success" to="#" onClick={() => { slideoutDrawer(item.institutionId, true) }}>
                                {item.institutionName}
                            </Link>
                        ),
                    },
                    status: {
                        type: "link",
                        value: (
                            [SubmissionState.Rejected].includes(item.submissionStateId)
                            ?
                                <Link color="success" to="#" onClick={() => { slideoutDrawerRejections(item.latestSubmissionId, true) }}>
                                    <span>{getSubmissionStatusText(isInRole([Nominee]), item.status) || ''}</span>
                                </Link>
                            :
                                <span>{getSubmissionStatusText(isInRole([Nominee]), item.status) || ''}</span>
                        ),
                        sortValue: item.status,
                    },
                    actions: {
                        type: "link",
                        value:
                            <Fragment>
                                <div>

                                    <IconButton
                                        aria-label="more"
                                        className={classes.kabobIconEnable}
                                        id={"long-button-" + index}
                                        aria-haspopup="true"
                                        sx={{ padding: "0" }}
                                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                                            handleClick(event, index);
                                        }}
                                        disabled={[
                                            SubmissionState.Submitted,
                                            SubmissionState.Extracting,
                                            SubmissionState.Extracted,
                                            SubmissionState.Verifying,
                                            SubmissionState.Verified,
                                            SubmissionState.Loading,
                                            SubmissionState.Error,
                                            SubmissionState.PartiallyExtracted,
                                            SubmissionState.Received].includes(item.submissionStateId)
                                            || !userCanPerformSubmissionActions
                                            || (!caseIdsAcceptingSubmissions.includes(item.caseId) && !item.canAcceptDeficiencyResponse)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                    <Menu
                                        id={"long-menu-" + index}
                                        MenuListProps={{
                                            "aria-labelledby": "long-button" + index,
                                        }}
                                        anchorEl={kabobsMenu}
                                        open={selectedRowIndex === index}
                                        onClose={handleClose}
                                        onClick={handleClose}
                                        PaperProps={{
                                            style: {
                                                maxHeight: STANDARD_KABOB_HEIGHT * 4.5,
                                                width: "20ch",
                                            },
                                        }}
                                        
                                    >
                                        {userCanPerformSubmissionActions
                                            && (caseIdsAcceptingSubmissions.includes(item.caseId) || item.canAcceptDeficiencyResponse)
                                            && getSubmissionActions(item.caseId,
                                                item.submissionStateId,
                                                item.id,
                                                item.parentSubmissionId,
                                                item.caseName,
                                                item.caseNumber,
                                                item.submissionTypeId,
                                                item.submissionDescription,
                                                item.relatedSubmissions || [],
                                                item.canAcceptDeficiencyResponse
                                            )
                                        }
                                    </Menu>


                                </div>
                            </Fragment>
                    }
                };

                submissionRows.push(newItem);
            });

        return submissionRows;
    };

    let rows = null as ISecuritiesTableFieldMap[] | null;

    if (!submissionState.error)
        rows = buildSubmissionRows();

    return (
        <>
            {
                submissionState.isLoaded ?
                    <>
                        {/*Include all submissions switch*/}
                        <div className={classes.switch}>
                            <Tooltip title={
                                <Typography style={{ fontSize: 14 }}>Include all submissions from Institution</Typography>
                            }
                                placement="top"
                            >
                                <FormControlLabel
                                    className={classes.title}
                                    control={
                                        <Switch
                                            id="InstitutionAccountToggleSwitch"
                                            checked={!(showUserSubmissions.value)}
                                            onChange={handleSwitchChange}
                                            inputProps={{ "aria-label": "controlled" }}
                                        />
                                    }
                                    label={"Include all submissions from Institution"}
                                    labelPlacement="start"
                                />
                            </Tooltip>
                        </div>

                        {/*Submission list*/}
                        <div className={classes.grid}>
                            <SecuritiesTable
                                columns={submissionColumns}
                                subTableColumns={relatedSubmissionColumns}
                                rows={rows}
                                totalRowCount={submissionResponse.totalRowCount}
                                sorter={sortInfo}
                                paging={'server'}
                                pageNumber={submissionState.pageNumber - 1}  // page numbers need to be 0-based to keep mui happy
                                rowsPerServerPage={submissionState.rowsPerPage}
                                showFilter={true}
                                dataFetchFunc={fetchFilteredCaseSubmissionsList}
                                resetFilterFunc={handleResetFilter}
                                subTableTitle={'Related Files'}
                                collapsible={true}
                                dataExportFunc={exportFilteredCaseSubmissionList}
                                enableExport={true}
                            />
                        </div>
                    </>
                    :
                    //skeleton
                    <Card>
                        <CardContent>
                            {[...Array(DEFAULT_ROWS_PER_PAGE)].map((elementInArray, index) => (
                                <Grid container spacing={2} key={index}>
                                    <Grid item xs={2}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                </Grid>
                            ))}
                        </CardContent>
                    </Card>
            }
        </>
    );
};

export default SubmissionListGrid;