import React, { useState, useEffect, useContext, useRef } from 'react';
import { IConfiguration, IStringValue } from '../types/types';
import { Theme, Button} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { ReportsTabTitle, Strings } from "../types/enums";
import { transformHelpUrl, getInfoJsonWithArray } from "../utilities/utilities";
import { UserContext, UserContextType } from '../modules/User/UserContext'
import { EpiqUser } from '../constants/roles';
import { getPowerBiURL } from '../modules/Settings/settings.api';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme: Theme) => ({
	commonbackgroundcard: {
		boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 30%)',
		padding: '1.5rem 1rem 0.25rem',
		margin: '1rem 1rem 0 0',
		background: '#fff',
		borderRadius: "5px",
	},
	heading: {
		fontSize: 100
	},
	subheading: {
		fontSize: 30
	},
	errortxtgray: {
		color: '#888896'
	},
	title: {
		flexGrow: 1,
		marginBottom: "15px",
		display: 'inline-flex',
		marginRight: "5px"
	},
	floatLeft: {
		float: 'left'
	},
	floatRight: {
		float: 'right',
	},
	dashboardIconBackground: {
		fontSize: "20px"
	},
	reportBackground: {
		background: "#fff",
		boxShadow: "0px 10px 15px -3px rgb(0 0 0 / 30%)",
		borderRadius: "5px",
		margin: '20px 0px 38px 0',
		padding: '15px',
		"& a": {
			fontSize: "18px",
			color: '#1d819b',
			fontWeight: '500'
		},
	}
}));

const Reports = () => {
	const classes = useStyles();
	const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;
	let [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	const [stringContentData, setStringContentData] = useState<IStringValue | null>();
	const location = useLocation();
	document.title = ReportsTabTitle.Title;

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: ReportsTabTitle.Title });
	}, [location]);

	const handleClick = (event: any) => {
		setShow(!show);
		setTarget(event.target);
	};

	const processInfoResponse = (data: any) => {
		data.map((item: IStringValue) => {
			switch (item.key) {
				case Strings.Reports:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setStringContentData(item);
					break;
			}
		});
	};

	useEffect(() => {
		const fetchInfoStrings = async () => {
			const data: any = await getInfoJsonWithArray([Strings.Reports,
			Strings.ClaimsByClaimStatus,
			Strings.ClaimsBySubmissionStatus]);

			processInfoResponse(data);
		}

		fetchInfoStrings();
	}, [isAuthenticated, isInitialized, isInRole]);

	const [powerBiURl, setPowerBiURL] = useState('');


	useEffect(() => {
		const astf = async () => {
			const data: any = await getPowerBiURL();
			setPowerBiURL(data);
		}
		astf();
	}, []);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }} >
			<div className={classes.commonbackgroundcard}>
				<div ref={ref}>
					<Typography variant="h6" className={classes.title} noWrap>Reports</Typography>
					<Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} /></Button>
					<Overlay
						show={show}
						target={target}
						placement="right-start"
						container={ref}
						containerPadding={20}
						rootClose
						onHide={() => setShow(false)}
					>
						<Popover id="popover-contained">
							<Popover.Header as="h3"><span style={{ color: "#000" }}>Reports</span></Popover.Header>
							{stringContentData && (
								<Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
							)}
						</Popover>
					</Overlay>
				</div>
			</div>

			<div className={classes.commonbackgroundcard} style={{ flexGrow: 1 }}>
				{powerBiURl ?
					<iframe title="Epiq Filing Reports"
						src={powerBiURl}
						style={{ height: `692px`, width: `100%`, borderWidth: 0 }}>
					</iframe>
					: null
				}
			</div>
		</div>
	);
};

export default Reports;