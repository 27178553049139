import { IAdminOverview } from "../../types/types";

export const GET_ADMIN_CARD_COUNT = "GET_ADMIN_CARD_COUNT";
export const GET_ADMIN_CARD_COUNT_SUCCESS = "GET_ADMIN_CARD_COUNT_SUCCESS";
export const GET_ADMIN_CARD_COUNT_FAILURE = "GET_ADMIN_CARD_COUNT_FAILURE";

export const getAdminCardCounts = () => ({
    type: GET_ADMIN_CARD_COUNT
});

export const getAdminCardCountsSuccess = (payload: IAdminOverview) => {
    return {
        type: GET_ADMIN_CARD_COUNT_SUCCESS,
        payload
    };
};

export const getAdminCardCountsFailure = (payload: any) => {
    return {
        type: GET_ADMIN_CARD_COUNT_FAILURE,
        payload
    };
};