import { GET_SETTINGS, GET_SETTINGS_FAILED, GET_SETTINGS_SUCCESS } from './settings.actions';
import { IAction } from '../../types/types';

export interface ISettingsState {
    settings: any,
    error: any
};

const initialState: ISettingsState = {
    settings: {},
    error: null
};

export default (state = initialState, { type, payload }: IAction) => {
    switch (type) {
        case GET_SETTINGS:
            return state;
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                settings: payload
            }
        case GET_SETTINGS_FAILED:
            return {
                ...state,
                error: payload
            }
        default:
            return state;
    }
};