export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';

export const getSettings = () => ({
    type: GET_SETTINGS
});

export const getSettingsSuccess = (payload: any) => ({
    type: GET_SETTINGS_SUCCESS,
    payload
})

export const getSettingsFailed = (payload: any) => ({
    type: GET_SETTINGS_FAILED,
    payload
})