import React, { SyntheticEvent, useState } from 'react';
import {
	ListItemText,
	InputAdornment,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Theme,
	Button,
	TextField,
	Typography,
	Checkbox
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ISecuritiesTableFieldMap, ISorter, Order } from './SecuritiesTable'
import { IEnumDTO, IFilterDTO } from '../../../types/types';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { IColumnHeader } from './SecuritiesTable';
import { createFilterDTOsFromColumnHeaders } from '../../../utilities/filterCriteria';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
interface ISecuritiesTableFilterProps {
	orderBy: keyof ISecuritiesTableFieldMap | undefined;
	order: Order;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ISecuritiesTableFieldMap, filter?: IFilterDTO[]) => void;
	filterColumns: IColumnHeader[];
	fetchDataFunc: Function;
	resetFilterFunc: Function;
	updateParentFilterCopy?: Function;
	defaultFilter?: IFilterDTO[];
}

const createLocalStyles = makeStyles((theme: Theme) =>
	createStyles({
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		filterBox: {
			backgroundColor: theme.filterBox,
			borderRadius: '3px',
			position: 'sticky',
			top: 0,
			zIndex: 2
		},
		tableRow: {
			verticalAlign: 'bottom',
		},
		filterInput: {
			background: theme.palette.background.paper,
			borderRadius: '5px',
		},
		searchButton: {
			background: '#006B86',
		},
		resetButton: {
			color: theme.palette.text.primary,
		},
		select: {
			height: '2.5rem',
			backgroundColor: theme.palette.background.paper,
			textOverflow: 'ellipsis',
			maxWidth: '10rem'
		}
	}),
);
export default function SecuritiesTableFilter<T>({
	filterColumns,
	orderBy,
	order,
	onRequestSort,
	fetchDataFunc,
	resetFilterFunc,
	updateParentFilterCopy,
	defaultFilter }: ISecuritiesTableFilterProps) {
	const classes = createLocalStyles();

	const createSortHandler = (property: keyof ISecuritiesTableFieldMap) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property, filterValues);
	};

	const handleSearchClick = (e: SyntheticEvent) => {
		e.preventDefault();
		let errorFound = validateInputs();

		if (!errorFound) {
			fetchDataFunc(filterValues);
		}

		return false;
	}

	const handleResetClick = () => {
		for (var i = 0; i < filterColumns.length; i++) {
			filterColumns[i].value = filterColumns[i].default ? filterColumns[i].default : '';

			if (filterColumns[i].type && filterColumns[i].type === 'selectMany') {
				filterColumns[i].enumValues = [];
			}
		}

		let fv;

		if (defaultFilter) {
			fv = defaultFilter;
		} else {
			fv = filterValues.map((item: any, idx: number) => {
				let fc = filterColumns.find(fc => fc.parameterName === item.parameter);
				let newItem = { ...item, value: fc?.default ? fc?.default : '' };
				return newItem;
			});
		}

		setFilterValues(fv);

		if (updateParentFilterCopy) {
			updateParentFilterCopy(fv);
		}

		resetFilterFunc();
	}

	const handleFilterValueChange = (index: number, val: string, performSearch: boolean = false) => {
		filterColumns[index].value = val;

		let fv = filterValues.map((item: IFilterDTO, idx: number) =>
			idx === index
				? { ...item, value: val }
				: { ...item }
		);

		setFilterValues(fv);

		if (updateParentFilterCopy) {
			updateParentFilterCopy(fv);
		}

		if (performSearch) {
			if (!validateInputs()) {
				fetchDataFunc(fv);
			}
		}
	}

	const handleMultiSelectChange = (index: number, values: string[], performSearch: boolean = false) => {
		// if the values array contains 0, the default 'select' message remove it
		let zeroIndex = values.indexOf('0');

		if (zeroIndex > -1) {
			values.splice(zeroIndex, 1);
		}

		let selectedValues: string = values.join(',');
		filterColumns[index].value = selectedValues;

		let fv = filterValues.map((item: IFilterDTO, idx) =>
			idx === index
				? { ...item, value: selectedValues }
				: { ...item }
		);

		setFilterValues(fv);

		if (updateParentFilterCopy) {
			updateParentFilterCopy(fv);
		}

		if (performSearch) {
			if (!validateInputs()) {
				fetchDataFunc(fv);
			}
		}
	}

	const getLabelForId = (id: string, enumValues: IEnumDTO[]): string => {
		let label: string;

		for (var i = 0; i < enumValues.length; i++) {
			if (enumValues[i].value == id) {
				label = enumValues[i].label;
				return label;
			}
		}

		return '';
	}

	const handleFieldType = (item: IColumnHeader, index: number) => {
		let fieldType: string | undefined = item.type;

		if (!fieldType) {
			return null;
		}

		switch (fieldType) {
			case 'selectOne':
				return <Select
					className={classes.select}
					id={item.label}
					value={item.value ? item.value : '0'}
					onChange={(e: any) => {
						handleFilterValueChange(index, e.target.value);
					}}
				>
					<MenuItem key={-1} value={'0'}>{' -- select -- '}</MenuItem>
					{item.enumValues ?
						item.enumValues.map((itm: any, idx: number) => {
							return <MenuItem key={idx} value={itm.value}>{itm.label}</MenuItem>
						})
						: null
					}
				</Select>
			case 'selectMany':
			case 'selectManyString':
				let defaultLabel = ' -- select -- ';
				return <Select
					className={classes.select}
					id={item.label}
					multiple={true}
					value={item.value && item.value?.length > 0 ? item.value.split(',') : ['0']}
					renderValue={(selected) => {
						let labels: string[] = [];

						if (item.enumValues) {
							for (var i = 0; i < selected.length; i++) {
								let label = getLabelForId(selected[i], item.enumValues);

								if (label !== '') {
									labels.push(getLabelForId(selected[i], item.enumValues))
								}
							}
						}

						if (labels.length > 0) {
							return labels.join(', ');
						} else {
							return defaultLabel;
						}

					}}
					onChange={(e: any) => {
						handleMultiSelectChange(index, e.target.value);
					}}
				>
					{item.enumValues ?
						item.enumValues.map((itm: any, idx: number) => {
							return <MenuItem key={idx} value={itm.value}>
								<Checkbox checked={item.value?.split(',').includes(itm.value)} />
								<ListItemText primary={itm.label} />
							</MenuItem>
						})
						: null
					}
				</Select>
			case 'boolean':
				return <Select
					className={classes.select}
					value={item.value ? item.value : '0'}
					id={item.label}
					onChange={(e: any) => {
						handleFilterValueChange(index, e.target.value, true);
					}}
				>
					<MenuItem key={-1} value={'0'}>{' -- select -- '}</MenuItem>
					<MenuItem key={0} value={'true'}>{'Yes'}</MenuItem>
					<MenuItem key={1} value={'false'}>{'No'}</MenuItem>
				</Select>
			default:
				return <TextField
					style={item.minWidth ? { 'minWidth': item.minWidth } : {}}
					variant={'standard'}
					error={errors ? errors[index] !== '' : false}
					helperText={errors && errors[index] !== '' ? errors[index] : ''}
					className={classes.filterInput}
					type={item.type === 'date' ? 'date' : 'text'}
					onChange={(e: any) => { handleFilterValueChange(index, e.target.value); }}
					onKeyUp={(event) => {
						if (event.key === 'Enter') {
							return handleSearchClick(event);
						}
					}}
					id={item.label}
					value={filterValues ? filterValues[index].value : ''}
					InputProps={
						item.type !== 'date' ?
							{
								startAdornment: (
									<InputAdornment position="start">
										<SearchOutlinedIcon style={{cursor: "pointer"}} onMouseDown={(e) => handleSearchClick(e)} />
									</InputAdornment>
								),
							}
							: {}
					}
				/>
		}
	}

	const validateInputs = () => {
		let updatedErrors: string[] = initialErrorState()
		let errorFound = false;
		const datePattern: RegExp = /\d{4}-\d{1,2}-\d{1,2}/

		for (var i = 0; i < filterValues.length; i++) {
			var item: IFilterDTO = filterValues[i];

			if (item.value !== '') {
				if (item.type == 'date') {

					if (!datePattern.test(item.value) || isNaN(new Date(item.value).getTime())) {
						updatedErrors[i] = 'Invalid date';
						errorFound = true;
					}

				} else if (item.type == 'number') {
					if (isNaN(parseFloat(item.value))) {
						updatedErrors[i] = 'Invalid number';
						errorFound = true;
					}
				}
			}
		}

		setErrors(updatedErrors);

		return errorFound
	}

	const initialFilterState: IFilterDTO[] = createFilterDTOsFromColumnHeaders(filterColumns);

	if (updateParentFilterCopy) {
		updateParentFilterCopy(initialFilterState);
	}

	const initialErrorState = () => {
		let arr: string[] = [];

		for (var i = 0; i < filterColumns.length; i++) {
			arr[i] = '';
		}

		return arr;
	}

	const [filterValues, setFilterValues] = useState(initialFilterState);

	const [errors, setErrors] = useState(initialErrorState());

	return (
		<TableHead className={classes.filterBox}>
			<TableRow className={classes.tableRow}>
				{filterColumns.map((item: IColumnHeader, index: number) => {
					if (item.allowFilter) {
						return (
							<TableCell size='small' key={index}	>
								<TableSortLabel
									active={orderBy === item.field && !item.hideSortIcon}
									direction={orderBy === item.field ? order : 'asc'}
									onClick={createSortHandler(item.field)}
									hideSortIcon={item.hideSortIcon || undefined}
								>
									{item.label}
									{orderBy === item.field ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</span>
									) : null}
								</TableSortLabel><br />
								{
									handleFieldType(item, index)
								}
							</TableCell>
						)
					} else {
						if (!item.hideHeader) {
							return (
								<TableCell key={index} >
									<TableSortLabel
										active={orderBy === item.field}
										direction={orderBy === item.field ? order : 'asc'}
										onClick={createSortHandler(item.field)}>
										{item.label}
										{orderBy === item.field ? (
											<span className={classes.visuallyHidden}>
												{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
											</span>
										) : null}
									</TableSortLabel>
									<Typography>&nbsp;</Typography>
								</TableCell>

							)

						}

						return null;
					}

				})}
				<TableCell key={999} >
					<div>
						<Button id="btnSearch" variant="contained" className={classes.searchButton} color={'primary'} onMouseDown={(e) => handleSearchClick(e)}>Search</Button>
						<Button id="btnReset" variant="text" className={classes.resetButton} onClick={handleResetClick}>Reset</Button>
					</div>
				</TableCell>
			</TableRow>
		</TableHead>
	)
}