import {
	GET_CLAIMANTS_FOR_SUBMISSION,
	EXPORT_SUBMISSION_CLAIMANTS,
	getClaimantsForSubmissionSuccess,
	getClaimantsForSubmissionFailed,
	exportSubmissionClaimantsSuccess,
	exportSubmissionClaimantsFailed
} from './claimants.actions';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import { getClaimantsForSubmission, exportSubmissionClaimants } from './claimants.api';
import { IClaimantListResponse } from '../../types/types';
import {clearClaimantStatusList } from '../Cmx/cmx.actions';

function* getClaimantsForSubmissionSaga({ payload: { submissionId, cmxCaseNumber, filter, sorter, pageNumber, rowsPerPage } }: any): any {
	try {
		yield put(clearClaimantStatusList());
		const response = yield call(getClaimantsForSubmission, submissionId, cmxCaseNumber, filter, sorter, pageNumber, rowsPerPage);
		yield put(getClaimantsForSubmissionSuccess(response as IClaimantListResponse));
	} catch (error: any) {
		yield put(getClaimantsForSubmissionFailed(error.message));
	}
}

function* getClaimantsForSubmissionWatcher() {
	yield takeLeading(GET_CLAIMANTS_FOR_SUBMISSION, getClaimantsForSubmissionSaga);
}

function* exportSubmissionClaimantsSaga({ payload: { submissionId, filter, cmxCaseNumber } }: any): any {
	try {
		const response = yield call(exportSubmissionClaimants, submissionId, filter, cmxCaseNumber);
		yield put(exportSubmissionClaimantsSuccess(response as IClaimantListResponse));
	} catch (error: any) {
		yield put(exportSubmissionClaimantsFailed(error.message));
	}
}

function* exportSubmissionClaimantsWatcher() {
	yield takeLeading(EXPORT_SUBMISSION_CLAIMANTS, exportSubmissionClaimantsSaga);
}

export default function* claimantsSaga() {
	yield all([
		getClaimantsForSubmissionWatcher(),
		exportSubmissionClaimantsWatcher()
	]);
}