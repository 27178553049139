import { IClaimFileModalProps, IClaimFileUploadResponse, IClaimFileState, IStringValue } from '../../types/types';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Icon,
    Modal,
    TextField,
    Theme,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel
} from '@mui/material';
import React, { useContext, useCallback, useEffect, useState } from 'react';
import { clearClaimFileAction, uploadClaimFileAction, clearClaimAcknowledgementTextAction, getClaimAcknowledgementTextAction } from './claimFile.actions';
import { useDispatch, useSelector } from 'react-redux';
import SecuritiesLoader from '../../components/Shared/SecuritiesLoader';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Stack, Backdrop, CircularProgress, Typography } from '@mui/material';
import { SubmissionTypeDisplay, SubmissionType, Strings } from "../../types/enums";
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import { getInfoJsonWithArray, transformHelpUrl } from '../../utilities/utilities';
import { EpiqUser } from '../../constants/roles';
import Popover from 'react-bootstrap/Popover';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import { blueGrey } from '@mui/material/colors';

const createLocalStyles = makeStyles((theme: Theme) =>
	createStyles({
		paper: {
			position: 'absolute',
		},
		claimmodal: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			justifyContent: 'center',
			width: '700px;'
		},
		modalHeader: {
			backgroundColor: theme.palette.primary.main,
			minWidth: 500,
			color: theme.palette.common.white,
			textEmphasisColor: theme.palette.common.white,
			font: '16',
		},
		modalHeaderTitle: {
			textDecoration: 'underline',
			paddingBottom: '5px;'
		},
		disabledButton: {
			backgroundColor: '#cfcfcf !important',
		},
		title: {
			flexGrow: 1,
			color: 'black'
		},
		label: {
			color: 'black',
			float: 'left',
			fontSize: '20px',
			marginRight: '5px',
			marginTop: '1px'
		},
		cardAction: {
			justifyContent: 'flex-end'
		},
		primaryBtn: {
			color: '#fff!important',
			backgroundColor: '#007bff!important'
		},
		secondaryBtn: {
			color: 'blue',
			backgroundColor: '#fff!important',
			border: '1px solid blue',
		},
		paragraph: {
			marginTop: '1rem',
			marginBottom: '0px'
		},
		textField: {
			color: 'black'
		},
		required: {
			color: 'red',
		},
		inputLabel: {
			position: 'relative',
			top: '10px',
			left: '10px',
			background: '#fff',
			fontSize: '16px',
			paddingLeft: '0px',
			padding: '0px',
			zIndex: '9',
			color: 'black'
		},
		disabledDiv: {
			cursor: 'not-allowed',
			opacity: '0.2'
		}
	}),
);

interface IClaimFileUploadModalProps {
	modalOpen: boolean
	claimFileModalProps: IClaimFileModalProps
}

const DeficiencyClaimFileUploadModal = ({ modalOpen, claimFileModalProps}: IClaimFileUploadModalProps) => {
	const dispatch = useDispatch();
	const { user } = useContext(UserContext) as UserContextType;
	const handleClose: Function = claimFileModalProps.handleClose;
	const handleSupportingDocModalOpen: Function = claimFileModalProps.handleSupportingDocModalOpen;
	const handleSupportingDocModalClose: Function = claimFileModalProps.handleSupportingDocModalClose;
	const setSupportingDocModalProps: Function = claimFileModalProps.setSupportingDocModalProps;
	const submissionTypeDisplay: SubmissionTypeDisplay = claimFileModalProps.submissionTypeDisplay;
	const submissionType: SubmissionType = claimFileModalProps.submissionType;
	const parentSubmissionId: number = claimFileModalProps.parentSubmissionId;
	const caseId: number = claimFileModalProps.caseId;
	const caseName: string = claimFileModalProps.caseName;
	const caseNumber: string = claimFileModalProps.caseNumber;
	const isNonClaimDeficiencyResponseFromProp: boolean = claimFileModalProps.isNonClaimDeficiencyResponse;
	const claimFileUploadResponse: IClaimFileUploadResponse = useSelector((state: any) => state.claimFileState.uploadResponse);
	const progress: number = useSelector((state: any) => state.claimFileState.progress);
	const [claimFile, setClaimFile] = useState<File>();
	const [hideLoadingBar, setHideLoadingBar] = useState<boolean>(false);
	const [filesAreSubmitted, setFilesAreSubmitted] = useState<boolean>(false);
	const [displayAcknowledgementMessage, setDisplayAcknowledgementMessage] = useState<boolean>(false);
	const [acknowledgementChecked, setAcknowledgementChecked] = useState<boolean>(false);
	const [acknowledgementMessage, setAcknowledgementMessage] = useState<string>("");
	const claimFileState: IClaimFileState = useSelector((state: any) => state.claimFileState);
	const classes = createLocalStyles();
	const [newClaimCount, setNewClaimCount] = useState<number>(0);
	const [updateClaimCount, setUpdateClaimCount] = useState<number>(0);
	const [totalClaimCount, setTotalClaimCount] = useState<number>(0);
	const [newTxnCount, setNewTxnCount] = useState<number>(0);
	const [updateTxnCount, setUpdateTxnCount] = useState<number>(0);
	const [totalTxnCount, setTotalTxnCount] = useState<number>(0);

	const [claimCountValid, setClaimCountValid] = useState<boolean>(true);
	const [txnCountValid, setTxnCountValid] = useState<boolean>(true);
	const { isInRole } = useContext(UserContext) as UserContextType;
	const [deficiencyModalKeyTextStringContentData, setDeficiencyModalKeyTextStringContentData] = useState<IStringValue | null>();
	const [deficiencyModalKeyTextClaimFileStringContentData, setDeficiencyModalKeyTextClaimFileStringContentData] = useState<IStringValue | null>();
	const [deficiencyModalKeyTextReminderStringContentData, setDeficiencyModalKeyTextReminderStringContentData] = useState<IStringValue | null>();
	const [deficiencyModalKeyTextClaimCountErrorStringContentData, setDeficiencyModalKeyTextClaimCountErrorStringContentData] = useState<IStringValue | null>();
	const [deficiencyModalKeyTextTransactionCountErrorStringContentData, setDeficiencyModalKeyTextTransactionCountErrorStringContentData] = useState<IStringValue | null>();

	const [deficiencyResponseModalClaimSubmissionText, setDeficiencyResponseModalClaimSubmissionText] = useState<string | null>();

	const [isNonClaimDeficiencyResponse, setIsNonClaimDeficiencyResponse] = React.useState(false);
 
	const handleIsNonClaimDeficiencyResponse = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearModal();
        setClaimFile(undefined);
		setIsNonClaimDeficiencyResponse(event.target.checked);
    };
    
	const handleModalClosed = useCallback(() => {
		setFilesAreSubmitted(false);
		setClaimFile(undefined);
		setHideLoadingBar(true);
		clearModal();
		dispatch(clearClaimFileAction());
		clearClaimAcknowledgementMessage();
		handleClose();
	}, [handleClose, setClaimFile, setHideLoadingBar]);

	const location = useLocation();

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: "Deficiency Claim File Upload Modal" });
	}, [location]);

	useEffect(() => {
		setClaimCountValid((newClaimCount || 0) + (updateClaimCount || 0) === (totalClaimCount || 0));
		setTxnCountValid((newTxnCount || 0) + (updateTxnCount || 0) === (totalTxnCount || 0));

		if ((totalClaimCount || 0) < 1 && (totalTxnCount || 0) < 1) {
			setClaimCountValid(false);
			setTxnCountValid(false);
		}
	}, [newClaimCount, updateClaimCount, totalClaimCount,
		newTxnCount, updateTxnCount, totalTxnCount
	]);

	useEffect(() => {

		if (modalOpen && !claimFileState.loadClaimAcknowledgementTextDispatched
			&& (submissionType === SubmissionType.Replacement || submissionType === SubmissionType.Update)) {

			let acknowledgementTextPayload = {
				configurationKey: submissionType === SubmissionType.Replacement
					? Strings.ReplacementSubmissionAcknowledgementText
					: Strings.UpdateSubmissionAcknowledgementText
			};

			dispatch(getClaimAcknowledgementTextAction(acknowledgementTextPayload));
		}

		if (claimFileState.claimAcknowledgementText && acknowledgementMessage.trim() === "") {
			setDisplayAcknowledgementMessage(true);
			setAcknowledgementMessage(claimFileState.claimAcknowledgementText.value);
		}

		if (filesAreUploaded() && claimFileUploadResponse && claimFileUploadResponse.submissionId) {
			setSupportingDocModalProps(
				({
					handleClose: handleSupportingDocModalClose,
					submissionTypeDisplay: submissionTypeDisplay,
					submissionType: submissionType,
					submissionId: claimFileUploadResponse.submissionId,
					caseId: caseId,
					caseName: caseName,
					caseNumber: caseNumber,
					submissionDescription: claimFileUploadResponse.submissionDescription,
					isNonClaimDeficiencyResponse: isNonClaimDeficiencyResponseFromProp
				}));

			handleSupportingDocModalOpen();
			handleModalClosed();
		}
	}, [modalOpen, claimFileUploadResponse, claimFileState]);

	const processInfoResponse = (data: any) => {
		data.map((item: IStringValue) => {
			switch (item.key) {
				case Strings.DeficiencyModalKeyText:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDeficiencyModalKeyTextStringContentData(item);
					break;

				case Strings.DeficiencyModalKeyTextClaimFile:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDeficiencyModalKeyTextClaimFileStringContentData(item);
					break;

				case Strings.DeficiencyModalKeyTextReminder:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDeficiencyModalKeyTextReminderStringContentData(item);
					break;

				case Strings.DeficiencyModalKeyTextClaimCountError:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDeficiencyModalKeyTextClaimCountErrorStringContentData(item);
					break;

				case Strings.DeficiencyModalKeyTextTransactionCountError:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDeficiencyModalKeyTextTransactionCountErrorStringContentData(item);
					break;

				case Strings.DeficiencyResponseModalClaimSubmissionText:					
					setDeficiencyResponseModalClaimSubmissionText(item.value);
					break;

			}
		});
	};

	useEffect(() => {
		const fetchInfoStrings = async () => {
			const data: any = await getInfoJsonWithArray([
				Strings.DeficiencyModalKeyText,
				Strings.DeficiencyModalKeyTextClaimFile,
				Strings.DeficiencyModalKeyTextReminder,
				Strings.DeficiencyModalKeyTextClaimCountError,
				Strings.DeficiencyModalKeyTextTransactionCountError,
				Strings.DeficiencyResponseModalClaimSubmissionText
			]);

			processInfoResponse(data);
		}

		fetchInfoStrings();
	}, []);

	const filesAreUploaded = (): boolean => {
		return (filesAreSubmitted && !claimFileState.isUploading && !claimFileState.isUploadFailed)
			? true
			: false;
	};

	const filesAreUploading = (): boolean => {
		return (filesAreSubmitted && claimFileState.isUploading)
			? true
			: false;
	};

	const selectClaimFile = (event: any) => {
		setClaimFile(event.target.files[0]);
		continueButtonIsDisabled();
	};

	const hasClaimFile = (): boolean => {
		return (claimFile && claimFile !== undefined)
			? true
			: false;
	};

	const clearModal = (): void => {
		let input = document.getElementById('claimFileInput') as HTMLInputElement;

		if (input)
			input.value = '';
	};

	const submitClaimFile = (): void => {
		if (claimFile && claimFile !== undefined) {
			setHideLoadingBar(false);
			setFilesAreSubmitted(true);

			let data = new FormData();
			data.append('claimFile', claimFile);
			data.append('caseId', caseId.toString());
			data.append('userId', user.id.toString());
			data.append('submissionType', submissionType.toString());
			data.append('parentSubmissionId', parentSubmissionId.toString());
			data.append('institutionId', user.institutionId.toString());
			data.append('statedClaimantCount', newClaimCount ? newClaimCount.toString() : '0');
			data.append('statedTransactionCount', newTxnCount ? newTxnCount.toString() : '0');
			data.append('isNonClaimDeficiencyResponse', isNonClaimDeficiencyResponse.toString());
			let payload = { claimFilePayload: data };
			dispatch(uploadClaimFileAction(payload));
		}
		else {
			setSupportingDocModalProps(
				({
					handleClose: handleSupportingDocModalClose,
					submissionTypeDisplay: submissionTypeDisplay,
					submissionType: submissionType,
					submissionId: claimFileUploadResponse.submissionId,
					caseId: caseId,
					caseName: caseName,
					caseNumber: caseNumber,
					submissionDescription: claimFileUploadResponse.submissionDescription,
					isNonClaimDeficiencyResponse: isNonClaimDeficiencyResponse
				}));

			handleSupportingDocModalOpen();
			handleModalClosed();
		}
	};

	const claimFileIsSupported = (): boolean => {

		if (claimFile && claimFile !== undefined) {
			let extension = claimFile.name.substring(claimFile.name.lastIndexOf(".") + 1).toLowerCase();

			return extension === 'xlsx' || extension === 'docx' || extension === 'pdf' || extension === 'txt' || extension === 'csv';
		}

		return false;
	};
	
	const claimFileExtension = (): string => {

        if (claimFile && claimFile !== undefined) {
            let extension = claimFile.name.substring(claimFile.name.lastIndexOf(".") + 1).toLowerCase();

            return extension;
        }

        return "";
    };

	const cancelClaimFileUpload = (): void => {
		handleModalClosed();
	};

	const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAcknowledgementChecked(event.target.checked);
	};

	const selectButtonIsDisabled = (): boolean => {
		return ((displayAcknowledgementMessage && !acknowledgementChecked))
			? true
			: false;
	};

	const continueButtonIsDisabled = (): boolean => {

        if (hasClaimFile()) {
            var extension = claimFileExtension();

            if (extension !== "")
                return false;

            return !hasClaimFile() || !claimCountValid || !txnCountValid ? true : false
        }

        return !hasClaimFile() || !claimCountValid || !txnCountValid ? true : false
    };

	const clearClaimAcknowledgementMessage = (): void => {
		dispatch(clearClaimAcknowledgementTextAction());
		setDisplayAcknowledgementMessage(false);
		setAcknowledgementChecked(false);
		setAcknowledgementMessage("");
	};


	return (
		<Modal
			open={modalOpen}
			onClose={handleModalClosed}>
			<Card className={classes.claimmodal}>

				<CardHeader
					title={submissionTypeDisplay}
					subheader={
						<div>
							<InputLabel className={classes.label}>Case:</InputLabel>
							<Typography variant="h6" className={classes.title} noWrap>
								{caseName}
							</Typography>
							<Typography variant="h6" className={classes.title} noWrap>
								{caseNumber}
							</Typography>
						</div>
					}
					classes={{ title: classes.modalHeaderTitle }}
					action={<Icon onClick={() => { handleModalClosed() }}>close</Icon>}>
				</CardHeader>

				{
					progress > 0 && progress < 1 &&
					<SecuritiesLoader variant="determinate" hidden={hideLoadingBar} value={progress * 100} />
				}

				<CardContent>
					
					<p className={classes.paragraph}>

                        <FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={isNonClaimDeficiencyResponse}
										onChange={handleIsNonClaimDeficiencyResponse}
										name="isDeficiencyResponse"
										inputProps={{ 'aria-label': 'controlled' }}
										sx={{
											color: blueGrey[800],
											'&.Mui-checked': {
												color: blueGrey[600],
											},
										}}
									/>
								}
								label={deficiencyResponseModalClaimSubmissionText ? deficiencyResponseModalClaimSubmissionText :  ""}
                            />
                        </FormGroup>

                    </p>
                    <fieldset disabled={!isNonClaimDeficiencyResponse ? false : true} className={!isNonClaimDeficiencyResponse ? "" :classes.disabledDiv  } >
					<p className={classes.paragraph}>
						{deficiencyModalKeyTextStringContentData && (
							<Popover.Body dangerouslySetInnerHTML={{ __html: deficiencyModalKeyTextStringContentData.value }}></Popover.Body>
						)}
					</p>
					<Grid style={{ alignItems: 'end' }} container spacing={1}>

						<Grid style={{ paddingTop: '0' }} item xs={12} sm={3.66} lg={3.66}>
							<div>
								<label className={classes.inputLabel}>New Claims</label>
								<input
									id="newClaims"
									type="number"
									placeholder=""
									style={{
										height: '3rem',
										border: '1px solid #707070',
										borderRadius: '4px',
										width: '100%'
									}}
									onKeyDown={(e: React.KeyboardEvent) => {
										if (e.keyCode === 189 || e.keyCode === 190)  // keyCode 189 = "-" Key Code 190 = "."
											e.preventDefault()
									}}
									onChange={(e: any) => {
										setNewClaimCount(parseInt(e.target.value, 10));
									}}
								/>
							</div>
						</Grid>
						<Grid style={{ paddingTop: '0', textAlign: 'center', height: '2.4rem' }} item xs={12} sm={0.5} lg={0.5}>+</Grid>

						<Grid style={{ paddingTop: '0' }} item xs={12} sm={3.66} lg={3.66}>
							<div>
								<label className={classes.inputLabel}>Claims to Update</label>
								<input
									id="claimsToUpdate"
									type="number"
									placeholder=""
									onKeyDown={(e: React.KeyboardEvent) => {
										if (e.keyCode === 189 || e.keyCode === 190)    // keyCode 189 = "-" Key Code 190 = "."
											e.preventDefault()
									}}
									onChange={(e: any) => {
										setUpdateClaimCount(parseInt(e.target.value, 10));
									}}
									style={{
										height: '3rem',
										border: '1px solid #707070',
										borderRadius: '4px',
										width: '100%'
									}}
								/>
							</div>
						</Grid>
						<Grid style={{ paddingTop: '0', textAlign: 'center', height: '2.4rem' }} item xs={12} sm={0.5} lg={0.5}>=</Grid>

						<Grid style={{ paddingTop: '0' }} item xs={12} sm={3.66} lg={3.66}>
							<div>
								<label className={classes.inputLabel}>Total Claims</label>
								<input
									id="totalClaims"
									type="number"
									placeholder=""
									onKeyDown={(e: React.KeyboardEvent) => {
										if (e.keyCode === 189 || e.keyCode === 190)    // keyCode 189 = "-" Key Code 190 = "."
											e.preventDefault()
									}}
									onChange={(e: any) => {
										setTotalClaimCount(parseInt(e.target.value, 10));
									}}
									style={{
										height: '3rem',
										border: '1px solid #707070',
										borderRadius: '4px',
										width: '100%'
									}}
								/>
							</div>
						</Grid>

						<Grid style={{ paddingTop: '0' }} item xs={12} sm={3.66} lg={3.66}>
							<div>
								<label className={classes.inputLabel}>New Transactions</label>
								<input
									id="newTxns"
									type="number"
									placeholder=""
									style={{
										height: '3rem',
										border: '1px solid #707070',
										borderRadius: '4px',
										width: '100%'
									}}
									onKeyDown={(e: React.KeyboardEvent) => {
										if (e.keyCode === 189 || e.keyCode === 190)  // keyCode 189 = "-" Key Code 190 = "."
											e.preventDefault()
									}}
									onChange={(e: any) => {
										setNewTxnCount(parseInt(e.target.value, 10));
									}}
								/>
							</div>
						</Grid>
						<Grid style={{ paddingTop: '0', textAlign: 'center', height: '2.4rem' }} item xs={12} sm={0.5} lg={0.5}>+</Grid>

						<Grid style={{ paddingTop: '0' }} item xs={12} sm={3.66} lg={3.66}>
							<div>
								<label className={classes.inputLabel}>Transactions to Update</label>
								<input
									id="txnsToUpdate"
									type="number"
									placeholder=""
									onKeyDown={(e: React.KeyboardEvent) => {
										if (e.keyCode === 189 || e.keyCode === 190)    // keyCode 189 = "-" Key Code 190 = "."
											e.preventDefault()
									}}
									onChange={(e: any) => {
										setUpdateTxnCount(parseInt(e.target.value, 10));
									}}
									style={{
										height: '3rem',
										border: '1px solid #707070',
										borderRadius: '4px',
										width: '100%'
									}}
								/>
							</div>
						</Grid>
						<Grid style={{ paddingTop: '0', textAlign: 'center', height: '2.4rem' }} item xs={12} sm={0.5} lg={0.5}>=</Grid>

						<Grid style={{ paddingTop: '0' }} item xs={12} sm={3.66} lg={3.66}>
							<div>
								<label className={classes.inputLabel}>Total Transactions</label>
								<input
									id="totalTxns"
									type="number"
									placeholder=""
									onKeyDown={(e: React.KeyboardEvent) => {
										if (e.keyCode === 189 || e.keyCode === 190)    // keyCode 189 = "-" Key Code 190 = "."
											e.preventDefault()
									}}
									onChange={(e: any) => {
										setTotalTxnCount(parseInt(e.target.value, 10));
									}}
									style={{
										height: '3rem',
										border: '1px solid #707070',
										borderRadius: '4px',
										width: '100%'
									}}
								/>
							</div>
						</Grid>
						{
							deficiencyModalKeyTextClaimCountErrorStringContentData && totalClaimCount && !claimCountValid ?
								<Grid style={{ paddingTop: '0', minHeight: '25px' }} item xs={12} sm={12} lg={12}>
									<div className="invalid-feedback d-block">{deficiencyModalKeyTextClaimCountErrorStringContentData.value}</div>
								</Grid>
								: null
						}
						{
							deficiencyModalKeyTextTransactionCountErrorStringContentData && totalTxnCount && !txnCountValid ?
								<Grid style={{ paddingTop: '0', minHeight: '25px' }} item xs={12} sm={12} lg={12}>
									<div className="invalid-feedback d-block">{deficiencyModalKeyTextTransactionCountErrorStringContentData.value}</div>
								</Grid>
								: null
						}

					</Grid>

					<p className={classes.paragraph}>
						{deficiencyModalKeyTextClaimFileStringContentData && (
							<Popover.Body style={{ paddingLeft: '0' }} dangerouslySetInnerHTML={{ __html: deficiencyModalKeyTextClaimFileStringContentData.value }}></Popover.Body>
						)}
					</p>
					 </fieldset>

					<Grid container spacing={1}>
						<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={filesAreUploading()}>
							<Stack alignItems="center">
								<CircularProgress color="inherit" />
								<br />
								<Typography sx={{ color: "#fff" }} variant="h4">
									Uploading documents...
								</Typography>
							</Stack>
						</Backdrop>
						<Grid item xs={12}>
						</Grid>
						<Grid item xs={12}>
							<TextField
								error={hasClaimFile() && !claimFileIsSupported()}
								InputProps={{
									readOnly: true,
								}}
								fullWidth
								multiline
								variant="outlined"
								helperText={(hasClaimFile() && !claimFileIsSupported()) && "UnSupported file type"}
								label={hasClaimFile() && !claimFileIsSupported() && "Error"}
								value={claimFile && claimFile !== undefined
									? claimFile.name
									: 'No file selected...'} >
							</TextField>
						</Grid>
						<Grid item xs={12}>
							<Button
								classes={
									{ root: classes.primaryBtn }}
								component="label"
								color="primary"
								variant="contained"
							>
								Select a file
								<input
									id="claimFileInput"
									name="claimFileInput"
									type="file"
									accept={isNonClaimDeficiencyResponse ? ".xlsx, .docx, .pdf, .txt, .csv" : ".xlsx"}
									hidden
									onChange={selectClaimFile} />
							</Button>
						</Grid>
						<Grid item xs={12}>
							{displayAcknowledgementMessage && acknowledgementMessage &&
								<Typography variant="h6" color="InfoText">
									<Checkbox sx={{ color: 'black', paddingTop: '5px', marginBottom: '-3px' }}
										checked={acknowledgementChecked}
										color="primary"
										inputProps={{ "aria-label": "checkbox" }}
										onChange={handleChecked}
									/>
									{acknowledgementMessage}
								</Typography>
							}
						</Grid>
					</Grid>
				</CardContent>
				<CardActions className={classes.cardAction}>
					<Button
						disabled={continueButtonIsDisabled()}
						classes={continueButtonIsDisabled() ? { root: classes.disabledButton, disabled: classes.disabledButton } :
							{ root: classes.primaryBtn, disabled: classes.primaryBtn }}
						onClick={() => { submitClaimFile() }}
						color="primary" >
						Continue
					</Button>
					<Button
						onClick={() => { cancelClaimFileUpload() }}
						classes={{ root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
						color="secondary">
						Cancel
					</Button>
				</CardActions>
				<p className={`classes.paragraph ${!isNonClaimDeficiencyResponse ? "" : classes.disabledDiv}`}>
					{deficiencyModalKeyTextReminderStringContentData && (
						<Popover.Body dangerouslySetInnerHTML={{ __html: deficiencyModalKeyTextReminderStringContentData.value }}></Popover.Body>
					)}
				</p>
			</Card >
		</Modal >
	);
};

export default DeficiencyClaimFileUploadModal;