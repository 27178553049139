import { Backdrop, Button, Card, CardContent, CircularProgress, FormControlLabel, FormGroup, Grid, Stack, Theme, Typography, Fab, Checkbox, Tooltip, Link, TextField, Tab, Tabs, Box } from "@mui/material";
import { EpiqProjectManager, EpiqTechSupport, EpiqTemplateAdmin, EpiqUser, EpiqAdmin, Nominee, BusinessAnalyst } from "../../constants/roles";
import { ICase, IClaimFileModalProps, ISupportingDocModalProps, ITemplate, ITemplateState, IStringValue, ISubmissionListState, ICasebookState } from "../../types/types";
import React, { useContext, useEffect, useState, useRef, SyntheticEvent } from "react";
import { SubmissionState, SubmissionType, SubmissionTypeDisplay, Strings } from "../../types/enums";
import { UserContext, UserContextType } from "../User/UserContext";
import { convertToDate, getEnumKeyByEnumValue, getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import CaseSubmissionGrid from "./CaseSubmissionGrid";
import CaseTemplateListGrid from "./CaseTemplateListGrid";
import ClaimFileUploadModal from '../ClaimFileUpload/ClaimFileUploadModal';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import SecuritiesCard from "../../components/Shared/SecuritiesCard";
import SecuritiesTextField from "../../components/Shared/SecuritiesTextField";
import { Skeleton } from "@mui/material";
import SupportingDocFilesUploadModal from '../ClaimFileUpload/SupportingDocFilesUploadModal';
import DeficiencyClaimFileUploadModal from '../ClaimFileUpload/DeficiencyClaimFileUploadModal';
import DeficiencyOptionalFilesUploadModal from '../ClaimFileUpload/DeficiencyOptionalFilesUploadModal';
import makeStyles from '@mui/styles/makeStyles';
import { saveCase } from "./cases.actions";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { downloadCaseTemplateAction } from "../Cases/cases.actions";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfirmationMessageModal from "../../components/Shared/ConfirmationMessageModal";
import { getInstitutionDetailsByIdAction } from "../Submissions/submissions.actions";
import InstitutionSlideoutDrawer from "../Submissions/InstitutionSlideoutDrawer";
import { PARTIAL_INFO_MESSAGE } from "../../constants/CommonConstants";
import { downloadMailingFileTemplate } from "../Templates/template.api";
import DialogAddMailingAsNominee from "../Mailings/DialogAddMailingAsNominee";
import DialogAddMailingAsEpiqUser from "../Mailings/DialogAddMailingAsEpiqUser";
import { mailingSubmissionsFeatureEnabled } from "../../App";

const useStyles = makeStyles((theme: Theme) => ({
    fab: {
        background: 'none',
        color: '#006B86',
        boxShadow: 'none',
        width: 'auto',
        height: 'auto',
        "&:hover": {
            background: 'none',
        },
        "&:focus": {
            outline: 'none',
            background: 'none',
            boxShadow: 'none',
        },
        "&:active": {
            outline: 'none',
            background: 'none',
            boxShadow: 'none',
        },
    },
    template: {
        position: "relative",
    },
    templateIcon: {
        height: "100%",
        position: "absolute",
        top: "50%",
        right: "0%",
        cursor: "pointer",
        paddingLeft: "15px"
    },
    cstmdialogtitle: {
        position: "relative",
    },
    cancelaction: {
        right: "5%",
        position: "absolute",
    },
    title: {
        flexGrow: 1,
        marginBottom: "0px",
        display: 'inline-flex',
        marginRight: "5px",
        fontWeight: "600",
        fontSize: "21px",
        color: "#000D13",
        lineHeight: "24px",
        letterSpacing: "0.15px"
    },

    floatLeft: {
        textAlign: 'left',
        marginTop: '20px',
        marginLeft: '10px',
        float: 'left',
    },

    floatRight: {
        textAlign: 'right',
        marginTop: '20px',
        marginRight: '10px',
        float: 'right',
    },

    dashboardIconBackground: {
        fontSize: "20px"
    },
    urlLabel: {
        opacity: "1",
        color: "#00000061",
        paddingTop: "7px"
    },
    urlContainer: {
        borderBottom: '1px dotted #00000061',
    },
    disabledButton: {
        backgroundColor: "#cfcfcf !important",
        pointerEvents: "none",
        cursor: "not-allowed"
    },
    label: {
        color: 'rgba(0, 0, 0, 0.38)',
        transform: 'translate(0, -1.5px) scale(0.75)',
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#006B86',
    },
    tabCommonStyle: {
        padding: "0px",
        position: 'relative',
        top: '1px',
        zIndex: '9',
        "&& .MuiTab-root": {
            backgroundColor: "#F2F2F3",
            border: 'none',
            outline: 'none !important',
            borderBottom: '1px solid #087C9B !important',
        },
        "&& .Mui-selected": {
            borderBottom: 'none !important',
            outline: 'none !important',
            backgroundColor: '#fff !important',
            border: '1px solid #087C9B !important',
            borderRadius: '6px 6px 0 0'
        },

        "&& .MuiTabs-indicator": {
            display: 'none'
        },
    }
}));

interface CaseDetailFormProps {
    caseDetail?: ICase;
    caseBookDetails?: ICase;
    template?: ITemplate;
    isLoading: boolean;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function CaseDetailForm({ caseDetail, caseBookDetails, template, isLoading }: CaseDetailFormProps) {
    const { user, isInitialized, isAuthenticated, isInRole } = useContext(UserContext) as UserContextType;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [associateTemplateOpen, setAssociateTemplateOpen] = useState(false);
    const templateState: ITemplateState = useSelector((state: any) => state.templateState);
    const [newTemplateName, setNewTemplateName] = useState("");
    let [caseDetailWorkingCopy, setCaseDetailWorkingCopy] = useState<ICase>();
    let [caseDetailCleanCopy, setCaseDetailCleanCopy] = useState<ICase>();
    let [isDirty, setIsDirty] = useState<boolean>();
    let [claimFileModalIsOpen, setClaimFileModalIsOpen] = useState(false);
    let [supportingDocFilesModalIsOpen, setSupportingDocFilesModalIsOpen] = useState(false);
    let [claimFileModalProps, setClaimFileUploadProps] = useState<IClaimFileModalProps>();
    let [supportingDocModalProps, setSupportingDocModalProps] = useState<ISupportingDocModalProps>()
    let [submissionListIsDirty, setSubmissionListIsDirty] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    let [show, setShow] = useState(false);
    let [caseDetailSubmissionList, setShowCaseDetailSubmissionList] = useState(false);
    let [showMailingListHelp, setShowMailingListHelp] = useState(false);
    const [stringContentData, setStringContentData] = useState<IStringValue | null>();
    const [submissionListStringContentData, setSubmissionListStringContentData] = useState<IStringValue | null>();
    const [mailingListHelpStringContent, setMailingListHelpStringContent] = useState<IStringValue | null>();
    let [validCaseBookDetailUrl, setValidCaseBookDetailUrl] = useState<string | null>();
    let submissionListState: ISubmissionListState = useSelector((state: any) => state.submissionListState);
    let casebookState: ICasebookState = useSelector((state: any) => state.caseBookState);
    let [deficiencyClaimFileModalIsOpen, setDeficiencyClaimFileModalIsOpen] = useState(false);
    let [deficiencyOptionalFilesModalIsOpen, setDeficiencyOptionalFilesModalIsOpen] = useState(false);

    document.title = (caseDetailWorkingCopy === null || caseDetailWorkingCopy === undefined) ? 'Case Detail' : "Epiq Filling - " + caseDetailWorkingCopy.name;

    useEffect(() => {
        setIsDirty(false);

        if (caseDetail) {
            setCaseDetailWorkingCopy({ ...caseDetail, submissionAcceptanceUserId: user.id, updatedByUserId: user.id });
            setCaseDetailCleanCopy({ ...caseDetail, submissionAcceptanceUserId: user.id });
        }
    }, [caseDetail, user.id]);

    useEffect(() => {

        const validateCaseBookDetailUrl = () => {

            if (!caseBookDetails || (caseBookDetails && !caseBookDetails.url))
                return null;

            let workingUrl = !caseBookDetails.url.startsWith("http://") && !caseBookDetails.url.startsWith("https://") ?
                `http://${caseBookDetails.url}` : caseBookDetails.url;

            if (/^(http(s)?:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/gi.test(workingUrl))
                return workingUrl;

        }

        if (caseBookDetails && caseBookDetails.url)
            setValidCaseBookDetailUrl(validateCaseBookDetailUrl());

    }, [caseBookDetails]);

    const isDownloading: boolean = useSelector((state: any) => {
        return state.submissionListState.isDownloading;
    });

    const selectCase = (caseId: number, submissionTypeDisplay: SubmissionTypeDisplay, caseName: string, caseNumber: string) => {
        setSupportingDocFilesModalIsOpen(false);

        setClaimFileUploadProps(({
            handleClose: handleUploadClaimFileModalClosed,
            submissionTypeDisplay: submissionTypeDisplay,
            submissionType: (SubmissionType[getEnumKeyByEnumValue(SubmissionTypeDisplay, submissionTypeDisplay)]),
            parentSubmissionId: 0,
            caseId: caseId,
            caseName: caseName,
            caseNumber: caseNumber,
            isNonClaimDeficiencyResponse: false,
            handleSupportingDocModalOpen: handleSupportingDocModalOpen,
            handleSupportingDocModalClose: handleSupportingDocModalClose,
            setSupportingDocModalProps: setSupportingDocModalProps
        }));

        setClaimFileModalIsOpen(true);
    }

    const selectSubmission = (caseId: number,
        submissionTypeDisplay: SubmissionTypeDisplay,
        submissionId: number,
        caseName: string,
        caseNumber: string
    ) => {
        setSupportingDocFilesModalIsOpen(false);
        setSubmissionListIsDirty(false);
        setClaimFileUploadProps((
            {
                handleClose: handleUploadClaimFileModalClosed,
                submissionTypeDisplay: submissionTypeDisplay,
                submissionType: (SubmissionType[getEnumKeyByEnumValue(SubmissionTypeDisplay, submissionTypeDisplay)]),
                parentSubmissionId: submissionId,
                caseId: caseId,
                caseName: caseName,
                caseNumber: caseNumber,
                isNonClaimDeficiencyResponse: false,
                handleSupportingDocModalOpen: handleSupportingDocModalOpen,
                handleSupportingDocModalClose: handleSupportingDocModalClose,
                setSupportingDocModalProps: setSupportingDocModalProps
            }
        ));

        setClaimFileModalIsOpen(true);
    };

    const resumeSubmission = (caseId: number,
        submissionType: SubmissionType,
        submissionId: number,
        caseName: string,
        caseNumber: string,
        submissionState: SubmissionState,
        submissionDescription: string
    ) => {
        setSubmissionListIsDirty(false);
        var submissionTypeDisplay: SubmissionTypeDisplay = SubmissionTypeDisplay[SubmissionType[submissionType] as unknown as keyof typeof SubmissionTypeDisplay];

        setSupportingDocModalProps(
            ({
                handleClose: submissionType === SubmissionType.Deficiency
                    ? handleDeficiencyOptionalFilesModalClose
                    : handleSupportingDocModalClose,
                submissionTypeDisplay: submissionTypeDisplay,
                submissionType: submissionType,
                submissionId: submissionId,
                caseId: caseId,
                caseName: caseName,
                caseNumber: caseNumber,
                submissionState: submissionState,
                submissionDescription: submissionDescription
            }));

        if (submissionType === SubmissionType.Deficiency) {
            setDeficiencyOptionalFilesModalIsOpen(false);
            handleDeficiencyOptionalFilesModalOpen();
        }
        else {
            setSupportingDocFilesModalIsOpen(false);
            handleSupportingDocModalOpen();
        }

    };

    const [slideoutDrawerOpen, setslideoutDrawerOpen] = React.useState(false);

    const handleDrawerClose = () => {
        setslideoutDrawerOpen(false);
    };

    const slideoutDrawerInstitution = (id: number) => {
        dispatch(getInstitutionDetailsByIdAction(id));
        setslideoutDrawerOpen(true);
    };

    const handleUploadClaimFileModalClosed = () => {
        setClaimFileModalIsOpen(false);
    };

    const handleSupportingDocModalOpen = () => {
        setSupportingDocFilesModalIsOpen(true);
    };

    const handleSupportingDocModalClose = (submitted: boolean = false) => {
        if (submitted) {
            //show confirmation modal
            setConfirmationModalOpen(true);
        }
        setSupportingDocFilesModalIsOpen(false);
        setSubmissionListIsDirty(true);
    };

    const associateTemplateModal = () => {
        setAssociateTemplateOpen(true);
    };

    const handleTemplateModalClose = () => {
        setAssociateTemplateOpen(false);
    };

    const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);

    const handleSelectedCaseTemplate = (id: number) => {
        const caseTemplateAssociation = templateState.templates.find((x) => x.id === id);

        if (caseTemplateAssociation)
            setNewTemplateName(caseTemplateAssociation.name)

        if (caseDetailWorkingCopy) {
            let newState = {
                ...caseDetailWorkingCopy,
                currentTemplateId: id
            };

            setCaseDetailWorkingCopy(newState);
            setIsDirty(true);
        }
        setAssociateTemplateOpen(false);
    };

    const caseTemplateHasBeenSelected = (Id: number): boolean => {
        return caseDetailCleanCopy?.currentTemplateId === Id
    };

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };

    const caseDetailSubmissionListHandleClick = (event: any) => {
        setShowCaseDetailSubmissionList(!show);
        setTarget(event.target);
    };

    const showMailingListHelpHandleClick = (event: any) => {
        setShowMailingListHelp(!showMailingListHelp);
        setTarget(event.target);
    }

    const processInfoResponse = (data: any) => {
        data.map((item: IStringValue) => {
            switch (item.key) {
                case Strings.CaseDetail:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentData(item);
                    break;

                case Strings.SubmissionList:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setSubmissionListStringContentData(item);
                    break;

                case Strings.MailingListHelp:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setMailingListHelpStringContent(item);
                    break;

                default:
                    break;
            }
        });
    };

    useEffect(() => {
        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([
                Strings.CaseDetail,
                Strings.SubmissionList,
                Strings.MailingListHelp,
            ]);

            processInfoResponse(data);
        };


        if (isInitialized && isAuthenticated) {
            fetchInfoStrings();
        }
    }, [isAuthenticated, isInitialized]);

    const downloadTemplate = () => {
        dispatch(downloadCaseTemplateAction(caseDetailWorkingCopy!.id));
    }

    const downloadMailingTemplate = async () => {
        await downloadMailingFileTemplate();
    }

    const handleClose = (
        event: {},
        reason: "backdropClick" | "escapeKeyDown"
    ) => {
        if (reason === "backdropClick") {
            console.log(reason);
        } else {
            setConfirmationModalOpen(false);
        }
    };

    const formatCaseBookDetailsUrl = () => {
        if (caseBookDetails) {
            return (

                <div className={classes.urlContainer}>
                    {caseBookDetails.url && validCaseBookDetailUrl &&
                        <Tooltip title={caseBookDetails.url || ""} placement="top">
                            <Link href={validCaseBookDetailUrl} target="_blank">
                                <SecuritiesTextField
                                    id="url"
                                    value={caseBookDetails.url || ""}
                                    label="URL"
                                    type="lockedUrl"
                                    fullWidth
                                    multiline
                                />
                            </Link>
                        </Tooltip>
                    }
                </div>
            )
        }
    }

    const selectDeficiencySubmission = (caseId: number,
        submissionTypeDisplay: SubmissionTypeDisplay,
        submissionId: number,
        caseName: string,
        caseNumber: string,
        isNonClaimDeficiencyResponse: boolean
    ) => {
        setSupportingDocFilesModalIsOpen(false);

        setClaimFileUploadProps(({
            handleClose: handleDeficiencyUploadClaimFileModalClosed,
            submissionTypeDisplay: submissionTypeDisplay,
            submissionType: (SubmissionType[getEnumKeyByEnumValue(SubmissionTypeDisplay, submissionTypeDisplay)]),
            parentSubmissionId: submissionId,
            caseId: caseId,
            caseName: caseName,
            caseNumber: caseNumber,
            isNonClaimDeficiencyResponse: isNonClaimDeficiencyResponse,
            handleSupportingDocModalOpen: handleDeficiencyOptionalFilesModalOpen,
            handleSupportingDocModalClose: handleDeficiencyOptionalFilesModalClose,
            setSupportingDocModalProps: setSupportingDocModalProps
        }));

        setDeficiencyClaimFileModalIsOpen(true);
    };

    const handleDeficiencyUploadClaimFileModalClosed = () => {
        setDeficiencyClaimFileModalIsOpen(false);
    };

    const handleDeficiencyOptionalFilesModalOpen = () => {
        setDeficiencyOptionalFilesModalIsOpen(true);
    };

    const handleDeficiencyOptionalFilesModalClose = () => {
        setDeficiencyOptionalFilesModalIsOpen(false);
    };

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            {/* Case Details Section */}
            <div id="commonOuterWraper">
                <ConfirmationMessageModal open={confirmationModalOpen} handleClose={handleClose} />

                {/*case details title*/}
                <Typography sx={{ 'color': "#000D13 !important", 'marginBottom': "0" }} variant="h6" className={classes.title} noWrap>
                    Case Details
                </Typography>

                <Button
                    style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }}
                    onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} />
                </Button>

                <Overlay
                    show={show}
                    target={target}
                    placement="right-start"
                    container={ref}
                    containerPadding={20}
                    rootClose
                    onHide={() => setShow(false)}
                >
                    <Popover id="popover-contained-caseDetail">
                        <Popover.Header as="h3"><span style={{ color: "#000" }}>Case Detail</span></Popover.Header>
                        {stringContentData && (
                            <Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
                        )}
                    </Popover>
                </Overlay>

                {/*actions and details*/}
                <Grid container spacing={2}>
                    <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDownloading}>
                        <Stack alignItems="center">
                            <CircularProgress color="inherit" />
                            <br />
                            <Typography variant="h4" color="InfoText">
                                Downloading document...
                            </Typography>
                        </Stack>
                    </Backdrop>

                    {/*save/cancel buttons*/}
                    <Grid container direction="row" justifyContent="flex-end">
                        <Stack direction="row" spacing={1} hidden={!isDirty}>
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                onClick={(e: any) => {
                                    dispatch(saveCase({ caseInfo: caseDetailWorkingCopy }));

                                    if (caseDetailWorkingCopy)
                                        setCaseDetailCleanCopy({ ...caseDetailWorkingCopy });

                                    setIsDirty(false);
                                }}
                            >
                                Save
                            </Button>

                            <Button
                                variant="outlined"
                                size="small"
                                onClick={(e: any) => {
                                    if (caseDetailCleanCopy)
                                        setCaseDetailWorkingCopy({ ...caseDetailCleanCopy });

                                    setNewTemplateName(template ? template.name : "")
                                    setIsDirty(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Grid>

                    {/*case detail fields*/}
                    <Grid item xs={12}>
                        <SecuritiesCard>
                            {caseDetailWorkingCopy && caseBookDetails && !isLoading ? (
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4} lg={4}>
                                            <SecuritiesTextField
                                                id="caseNumber"
                                                value={caseDetailWorkingCopy.number || ""}
                                                label="Case Number"
                                                type="locked"
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={8} lg={4}>
                                            <SecuritiesTextField
                                                id="casename"
                                                value={caseDetailWorkingCopy.name || ""}
                                                label="Case Name"
                                                type="locked"
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4} lg={4}>
                                            <div className={classes.template} >
                                                <SecuritiesTextField

                                                    id="template"
                                                    value={newTemplateName || (template?.name || "No associated template")}
                                                    label="Template"
                                                    type="locked"
                                                    fullWidth
                                                    multiline
                                                />
                                                <Button disabled={!caseDetailWorkingCopy.currentTemplateId} style={{ background: "none", outline: "none", padding: "0", position: "absolute", top: "5px", right: "5px", justifyContent: "left", minWidth: "15px", width: "15px", height: "15px" }}><FileDownloadOutlinedIcon onClick={() => {
                                                    downloadTemplate();
                                                }} className={classes.dashboardIconBackground} /></Button>
                                                {
                                                    isInRole([EpiqProjectManager, EpiqTechSupport]) &&
                                                    <>
                                                        <span color="secondary" onClick={associateTemplateModal} className={classes.templateIcon} aria-label="add">
                                                            <MoreHorizOutlinedIcon /> <span style={{ color: '#000', fontSize: '15px', fontWeight: '500' }}></span>
                                                        </span>
                                                    </>
                                                }
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <Grid style={{ marginBottom: '0' }} container spacing={2}>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <SecuritiesTextField
                                                id="classPeriodFrom"
                                                value={
                                                    caseBookDetails?.classPeriodFrom
                                                        ? convertToDate(caseBookDetails?.classPeriodFrom)
                                                        : ""
                                                }
                                                label="Class Period From"
                                                type="locked"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <SecuritiesTextField
                                                id="classPeriodTo"
                                                value={
                                                    caseBookDetails?.classPeriodTo ? convertToDate(caseBookDetails?.classPeriodTo) : ""
                                                }
                                                label="Class Period To"
                                                type="locked"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} lg={3}>
                                            <SecuritiesTextField
                                                id="claimsDeadline"
                                                value={
                                                    caseDetail?.claimsDeadline ? convertToDate(caseDetail?.claimsDeadline) : ""
                                                }
                                                label="Filing Deadline"
                                                type="locked"
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid sx={{ position: 'relative' }} item xs={12} sm={6} lg={3} xl={3}>
                                            <Typography
                                                style={{ color: 'black', fontFamily: "'Roboto',sans-serif !important", fontSize: '12px' }}>
                                                Total Fund Amount
                                            </Typography>

                                            <Stack spacing={1}>
                                                <Stack direction="row" spacing={1}>
                                                    <TextField
                                                        id="currencyType"
                                                        variant="outlined"
                                                        disabled={true}
                                                        value={caseBookDetails.currencyType || ""}
                                                        sx={{ width: '64px', top: '5px' }}
                                                    />

                                                    <TextField
                                                        id="totalBenefitsAmount"
                                                        value={(caseBookDetails.totalBenefitsAmount || 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                        disabled={true}
                                                        sx={{ width: '100%', top: '5px' }}
                                                    />
                                                    <FormGroup style={{ alignItems: 'end', position: 'absolute', right: '0', bottom: '-30px' }}>
                                                        <FormControlLabel
                                                            labelPlacement="end"
                                                            control={
                                                                <Checkbox
                                                                    id="notApplicableUnknown"
                                                                    disabled={true}
                                                                    checked={caseBookDetails.notApplicableUnknown || false}
                                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 15 } }}
                                                                />
                                                            }

                                                            label={<span style={{ fontSize: '12px' }}>Not Applicable/Unknown</span>}
                                                        />
                                                    </FormGroup>
                                                </Stack>


                                            </Stack>
                                        </Grid>
                                    </Grid>

                                    {/*case flags*/}
                                    <Grid style={{ marginBottom: '0' }} container spacing={2}>

                                        {/*Accepting Mailings*/}
                                        {
                                            mailingSubmissionsFeatureEnabled.value &&
                                            isInRole([EpiqProjectManager, EpiqTechSupport, EpiqUser, EpiqAdmin, EpiqTemplateAdmin, Nominee]) &&
                                            <Grid style={{ paddingTop: '10' }} item xs={12} sm={3} lg={2}>
                                                <FormGroup sx={{ float: 'left' }}>
                                                    <FormControlLabel
                                                        value="end"
                                                        sx={{ alignItems: 'flex-start' }} // Added alignItems style
                                                        label="Accepting Mailings"
                                                        labelPlacement="end"
                                                        control={
                                                            <Checkbox
                                                                sx={{ color: 'rgba(0, 0, 0, 0.87)', alignSelf: 'flex-start' }} // Added alignSelf style

                                                                id="isAcceptingMailings"
                                                                disabled={!isInRole([EpiqProjectManager, EpiqTechSupport])}
                                                                checked={(caseDetailWorkingCopy.isAcceptingMailings) || false}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

                                                                    if (caseDetailWorkingCopy) {
                                                                        let newState = {
                                                                            ...caseDetailWorkingCopy,
                                                                            isAcceptingMailings: checked,
                                                                        };

                                                                        setCaseDetailWorkingCopy(newState);
                                                                        setIsDirty(true);
                                                                    }

                                                                }} />
                                                        }

                                                    />
                                                </FormGroup>
                                            </Grid>
                                        }

                                        {/*Accepting Filings*/}
                                        {
                                            isInRole([EpiqProjectManager, EpiqTechSupport, EpiqUser, EpiqAdmin, EpiqTemplateAdmin, Nominee]) &&
                                            <Grid style={{ paddingTop: '10' }} item xs={12} sm={3} lg={2}>
                                                <FormGroup sx={{ float: 'left' }}>
                                                    <FormControlLabel
                                                        value="end"
                                                        sx={{ alignItems: 'flex-start' }} // Added alignItems style
                                                        label="Accepting Filings"
                                                        labelPlacement="end"
                                                        control={
                                                            <Checkbox
                                                                sx={{ color: 'rgba(0, 0, 0, 0.87)', alignSelf: 'flex-start' }} // Added alignSelf style

                                                                id="isAcceptingSubmissions"
                                                                disabled={!caseDetailWorkingCopy.currentTemplateId || !isInRole([EpiqProjectManager, EpiqTechSupport])}
                                                                checked={(caseDetailWorkingCopy.isAcceptingSubmissions) || false}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

                                                                    if (caseDetailWorkingCopy) {
                                                                        let newState = {
                                                                            ...caseDetailWorkingCopy,
                                                                            isAcceptingSubmissions: checked,
                                                                        };

                                                                        setCaseDetailWorkingCopy(newState);
                                                                        setIsDirty(true);
                                                                    }

                                                                }} />
                                                        }

                                                    />
                                                </FormGroup>
                                            </Grid>
                                        }

                                        {/*Accepting Defect Responses*/}
                                        {
                                            isInRole([EpiqProjectManager, EpiqTechSupport, EpiqUser, EpiqAdmin, EpiqTemplateAdmin, Nominee]) &&
                                            <Grid style={{ paddingTop: '10' }} item xs={12} sm={3} lg={2}>
                                                <FormGroup sx={{ float: 'left' }}>
                                                    <FormControlLabel
                                                        value="end"
                                                        sx={{ alignItems: 'flex-start' }} // Added alignItems style
                                                        label="Accepting Defect Responses"
                                                        labelPlacement="end"
                                                        control={
                                                            <Checkbox
                                                                sx={{ color: 'rgba(0, 0, 0, 0.87)', alignSelf: 'flex-start' }} // Added alignSelf style

                                                                id="isAcceptingDeficiencyResponse"
                                                                disabled={!caseDetailWorkingCopy.currentTemplateId || !isInRole([EpiqProjectManager, EpiqTechSupport])}
                                                                checked={(caseDetailWorkingCopy.currentTemplateId && caseDetailWorkingCopy.isAcceptingDeficiencyResponse) || false}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

                                                                    if (caseDetailWorkingCopy) {
                                                                        let newState = {
                                                                            ...caseDetailWorkingCopy,
                                                                            isAcceptingDeficiencyResponse: checked,
                                                                        };

                                                                        setCaseDetailWorkingCopy(newState);
                                                                        setIsDirty(true);
                                                                    }

                                                                }} />
                                                        }

                                                    />
                                                </FormGroup>
                                            </Grid>
                                        }

                                        {/*Show CMx Claim Results*/}
                                        {
                                            isInRole([EpiqProjectManager, EpiqTechSupport]) &&
                                            <Grid style={{ paddingTop: '10' }} item xs={12} sm={3} lg={2}>
                                                <FormGroup sx={{ float: 'left' }}>
                                                    <FormControlLabel
                                                        value="end"
                                                        sx={{ alignItems: 'flex-start' }} // Added alignItems style
                                                        label="Show CMx Claim Results"
                                                        labelPlacement="end"
                                                        control={
                                                            <Checkbox
                                                                sx={{ color: 'rgba(0, 0, 0, 0.87)', alignSelf: 'flex-start' }} // Added alignSelf style

                                                                id="showCmxClaimResults"
                                                                disabled={!isInRole([EpiqProjectManager, EpiqTechSupport])}
                                                                checked={caseDetailWorkingCopy.showCmxClaimResults || false}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

                                                                    if (caseDetailWorkingCopy) {
                                                                        let newState = {
                                                                            ...caseDetailWorkingCopy,
                                                                            showCmxClaimResults: checked,
                                                                        };

                                                                        setCaseDetailWorkingCopy(newState);
                                                                        setIsDirty(true);
                                                                    }

                                                                }} />
                                                        }
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        }

                                        {/*Send Defect Notice Automatically*/}
                                        {
                                            isInRole([EpiqProjectManager, EpiqTechSupport, EpiqUser, EpiqAdmin, EpiqTemplateAdmin]) &&
                                            <Grid style={{ paddingTop: '10' }} item xs={12} sm={3} lg={2}>
                                                <FormGroup sx={{ float: 'left' }}>
                                                    <FormControlLabel
                                                        value="end"
                                                        sx={{ alignItems: 'flex-start' }} // Added alignItems style
                                                        label="Send Defect Notice Automatically"
                                                        labelPlacement="end"
                                                        control={
                                                            <Checkbox
                                                                sx={{ color: 'rgba(0, 0, 0, 0.87)', alignSelf: 'flex-start' }} // Added alignSelf style
                                                                id="AutomaticallySendDeficiencyEmail"
                                                                disabled={!caseDetailWorkingCopy.currentTemplateId || !isInRole([EpiqProjectManager, EpiqTechSupport])}
                                                                checked={(caseDetailWorkingCopy.automaticallySendDeficiencyEmail) || false}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                                    if (caseDetailWorkingCopy) {
                                                                        let newState = {
                                                                            ...caseDetailWorkingCopy,
                                                                            automaticallySendDeficiencyEmail: checked,
                                                                        };
                                                                        setCaseDetailWorkingCopy(newState);
                                                                        setIsDirty(true);
                                                                    }
                                                                }} />
                                                        }

                                                    />
                                                </FormGroup>
                                            </Grid>
                                        }
                                    </Grid>
                                </CardContent>
                            ) : (
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={4} lg={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={8} lg={4}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={4} lg={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={4} lg={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={4} lg={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={3}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}
                        </SecuritiesCard>
                    </Grid>

                    {/*view more details*/}
                    <Grid item xs={12}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >

                            </AccordionSummary>

                            {caseDetailWorkingCopy && caseBookDetails && !isLoading && !casebookState.isLoading ? (

                                <SecuritiesCard>
                                    <CardContent>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                                    <SecuritiesTextField
                                                        id="courtName"
                                                        value={caseBookDetails.courtName || ""}
                                                        label="Court Name"
                                                        type="locked"
                                                        fullWidth
                                                        multiline
                                                        zoom
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                                    <SecuritiesTextField
                                                        id="courtCaption"
                                                        value={caseBookDetails.courtCaption || ""}
                                                        label="Court Caption"
                                                        type="locked"
                                                        fullWidth
                                                        multiline
                                                        zoom
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                                    <SecuritiesTextField
                                                        id="detailedBenefitsDescription"
                                                        value={caseBookDetails.detailedBenefitsDescription || ""}
                                                        label="Detailed Benefits Description"
                                                        type="locked"
                                                        fullWidth
                                                        multiline
                                                        zoom
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                                    <SecuritiesTextField
                                                        id="phoneNumber"
                                                        value={caseBookDetails.phoneNumber || ""}
                                                        label="Phone Number"
                                                        type="locked"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                                    <SecuritiesTextField
                                                        id="caseEmail"
                                                        value={caseBookDetails.caseEmail || ""}
                                                        tooltip={caseBookDetails.caseEmail || ""}
                                                        label="Case Email"
                                                        type="locked"
                                                        fullWidth
                                                        multiline
                                                    />
                                                </Grid>


                                                <Grid item xs={12} sm={6} lg={4} xl={4} >
                                                    {validCaseBookDetailUrl ?
                                                        formatCaseBookDetailsUrl() :
                                                        <SecuritiesTextField
                                                            id="url"
                                                            value={caseBookDetails.url || ""}
                                                            label="URL"
                                                            type="locked"
                                                            fullWidth
                                                            multiline
                                                        />
                                                    }
                                                </Grid>
                                                {
                                                    isInRole([EpiqUser]) &&

                                                    (
                                                        <>
                                                            <Grid item xs={12} sm={6} lg={4} xl={4}>
                                                                <SecuritiesTextField
                                                                    id="projectManagerName"
                                                                    value={caseBookDetails.projectManagerName || ""}
                                                                    tooltip={caseBookDetails.projectManagerName || ""}
                                                                    label="Project Manager Name"
                                                                    type="locked"
                                                                    fullWidth
                                                                    multiline
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} lg={4} xl={4}>
                                                                <SecuritiesTextField
                                                                    id="ProjectManagerEmail"
                                                                    value={caseBookDetails.projectManagerEmail || ""}
                                                                    tooltip={caseBookDetails.projectManagerEmail || ""}
                                                                    label="Project Manager Email"
                                                                    type="locked"
                                                                    fullWidth
                                                                    multiline
                                                                />
                                                            </Grid>

                                                            {isInRole([EpiqProjectManager, EpiqTechSupport]) &&
                                                                <Grid item xs={12} sm={6} lg={4} xl={4}>
                                                                    <SecuritiesTextField
                                                                        id="OffshoreWorkLevel"
                                                                        value={caseDetailWorkingCopy.offshoreWorkLevel || 0}
                                                                        label="Offshore Work Level"
                                                                        type="locked"
                                                                        fullWidth
                                                                        multiline
                                                                    />
                                                                </Grid>
                                                            }
                                                        </>
                                                    )
                                                }
                                            </Grid>
                                        </AccordionDetails>
                                    </CardContent>

                                </SecuritiesCard>

                            ) : (
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} lg={3} xl={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={3} xl={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={3} xl={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={3} xl={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={7} xl={4}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={12} lg={5} xl={3}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={6} xl={4}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={6} xl={5}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )}
                        </Accordion>
                    </Grid>
                </Grid>
            </div>

            {/* Tab View Section: Submissions, Mailings */}
            <Box sx={{ width: '100%', background: '#fff' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs sx={{ display: 'inline-flex' }}
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        {/*Submissions*/}
                        <Tab label="Submissions" {...a11yProps(0)} sx={{ color: "#484D54", fontWeight: 'bold' }} />

                        {/*Mailings*/}
                        {mailingSubmissionsFeatureEnabled.value &&
                            <Tab label="Mailings" {...a11yProps(1)} sx={{ color: "#484D54", fontWeight: 'bold' }} />
                        }
                    </Tabs>
                </Box>

                {/*Submissions*/}
                <CustomTabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                        {/*Header Section*/}
                        <Grid item xs={12}>
                            {/* submissions list title section */}
                            <div className={classes.floatLeft} ref={ref}>
                                <Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }}
                                    onClick={caseDetailSubmissionListHandleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} />
                                </Button>

                                <Overlay
                                    show={caseDetailSubmissionList}
                                    target={target}
                                    placement="right-start"
                                    container={ref}
                                    containerPadding={20}
                                    rootClose
                                    onHide={() => setShowCaseDetailSubmissionList(false)}
                                >
                                    <Popover id="popover-contained-submissions">
                                        <Popover.Header as="h3"><span style={{ color: "#000" }}>Submissions</span></Popover.Header>
                                        {submissionListStringContentData && (
                                            <Popover.Body dangerouslySetInnerHTML={{ __html: submissionListStringContentData.value }}></Popover.Body>
                                        )}
                                    </Popover>
                                </Overlay>
                            </div>

                            {/* Add Submission Action */}
                            <div className={classes.floatRight}>
                                {isInRole([EpiqProjectManager, EpiqTechSupport, Nominee]) && caseDetail?.isAcceptingSubmissions &&
                                    <Fab color="secondary" className={classes.fab} aria-label="add" onClick={() => { selectCase(caseDetail?.id ? caseDetail?.id : 0, SubmissionTypeDisplay.Original, caseDetail?.name ? caseDetail?.name : '', caseDetail?.number ? caseDetail?.number : '') }}>
                                        <AddIcon /> <span style={{ color: '#000', fontSize: '15px', fontWeight: '500' }}>add submission</span>
                                    </Fab>
                                }
                            </div>
                        </Grid>

                        {/*case submissions and skeletons*/}
                        <Grid item xs={12}>
                            {caseDetailWorkingCopy && !isLoading ? (
                                <CaseSubmissionGrid
                                    caseId={caseDetail?.id}
                                    selectSubmission={selectSubmission}
                                    resumeSubmission={resumeSubmission}
                                    isDirty={submissionListIsDirty}
                                    slideoutDrawer={slideoutDrawerInstitution}
                                    selectDeficiencySubmission={selectDeficiencySubmission}
                                />
                            ) : (
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6} lg={3} xl={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>

                                            <Grid item xs={12} sm={6} lg={3} xl={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>

                                            <Grid item xs={12} sm={6} lg={3} xl={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>

                                            <Grid item xs={12} sm={6} lg={3} xl={2}>
                                                <Skeleton animation="wave" />
                                            </Grid>

                                            <Grid item xs={12} sm={12} lg={7} xl={4}>
                                                <Skeleton animation="wave" />
                                            </Grid>

                                            <Grid item xs={12} sm={12} lg={5} xl={3}>
                                                <Skeleton animation="wave" />
                                            </Grid>

                                            <Grid item xs={12} sm={6} lg={6} xl={4}>
                                                <Skeleton animation="wave" />
                                            </Grid>

                                            <Grid item xs={12} sm={6} lg={6} xl={5}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            )
                            }

                            {/*institution slide out*/}
                            {
                                submissionListState?.isLoading === false && submissionListState?.institutionDetail &&
                                (
                                    <>
                                        {
                                            <InstitutionSlideoutDrawer
                                                slideoutDrawerInstitution={submissionListState}
                                                isOpen={slideoutDrawerOpen}
                                                handleClose={handleDrawerClose}
                                            />
                                        }
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                </CustomTabPanel>   {/*submissions*/}

                {/*Mailing Submissions*/}
                <CustomTabPanel value={value} index={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>

                            {/* mailings help icon */}
                            <div className={classes.floatLeft} ref={ref}>
                                <Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }}
                                    onClick={showMailingListHelpHandleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} />
                                </Button>

                                <Overlay
                                    show={showMailingListHelp}
                                    target={target}
                                    placement="right-start"
                                    container={ref}
                                    containerPadding={20}
                                    rootClose
                                    onHide={() => setShowMailingListHelp(false)}
                                >
                                    <Popover id="popover-mailingListHelp">
                                        <Popover.Header as="h3"><span style={{ color: "#000" }}>Mailings</span></Popover.Header>
                                        {mailingListHelpStringContent && (
                                            <Popover.Body dangerouslySetInnerHTML={{ __html: mailingListHelpStringContent.value }}></Popover.Body>
                                        )}
                                    </Popover>
                                </Overlay>
                            </div>

                            {/* Actions: Add, Download */}
                            <div className={classes.floatRight}>
                                <Stack direction="row" spacing={2}>
                                    {/*Add Mailing Action*/}
                                    {isInRole([EpiqTechSupport, Nominee]) && caseDetail?.isAcceptingSubmissions &&
                                        <DialogAddMailingAsNominee CaseId={caseDetail?.id} CaseNumber={caseDetail?.number} CaseName={caseDetail?.name} IsAcceptingMailings={caseDetail?.isAcceptingMailings} />
                                    }
                                    {isInRole([EpiqProjectManager, BusinessAnalyst]) && caseDetail?.isAcceptingSubmissions &&
                                        <DialogAddMailingAsEpiqUser
                                        CaseId={caseDetail?.id}
                                        CaseNumber={caseDetail?.number}
                                        CaseName={caseDetail?.name}
                                        IsAcceptingMailings={caseDetail?.isAcceptingMailings}
                                    />
                                    }
                                    {/*Download Mailing Template Action*/}
                                    <Tooltip title="Download Mail File Template">
                                        <Button disabled={false}>
                                            <FileDownloadOutlinedIcon onClick={() => { downloadMailingTemplate(); }} className={classes.dashboardIconBackground} />
                                        </Button>
                                    </Tooltip>
                                </Stack>
                            </div>
                        </Grid>

                        {/*mailing list and skeletons*/}
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} lg={3} xl={2}>
                                            <Skeleton animation="wave" />
                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={3} xl={2}>
                                            <Skeleton animation="wave" />
                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={3} xl={2}>
                                            <Skeleton animation="wave" />
                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={3} xl={2}>
                                            <Skeleton animation="wave" />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={7} xl={4}>
                                            <Skeleton animation="wave" />
                                        </Grid>

                                        <Grid item xs={12} sm={12} lg={5} xl={3}>
                                            <Skeleton animation="wave" />
                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={6} xl={4}>
                                            <Skeleton animation="wave" />
                                        </Grid>

                                        <Grid item xs={12} sm={6} lg={6} xl={5}>
                                            <Skeleton animation="wave" />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </CustomTabPanel>
            </Box>

            {/* Modals */}

            {
                claimFileModalIsOpen && claimFileModalProps &&
                <ClaimFileUploadModal
                    modalOpen={claimFileModalIsOpen}
                    claimFileModalProps={claimFileModalProps}
                />
            }

            {
                supportingDocFilesModalIsOpen && supportingDocModalProps &&
                <SupportingDocFilesUploadModal
                    modalOpen={supportingDocFilesModalIsOpen}
                    supportingDocModalProps={supportingDocModalProps}
                />
            }

            {
                deficiencyClaimFileModalIsOpen && claimFileModalProps &&
                <DeficiencyClaimFileUploadModal
                    modalOpen={deficiencyClaimFileModalIsOpen}
                    claimFileModalProps={claimFileModalProps}
                />
            }

            {
                deficiencyOptionalFilesModalIsOpen && supportingDocModalProps &&
                <DeficiencyOptionalFilesUploadModal
                    modalOpen={deficiencyOptionalFilesModalIsOpen}
                    supportingDocModalProps={supportingDocModalProps}
                />
            }

            {!isLoading && !casebookState.isLoading && !casebookState?.selectedCasebookCaseDetail ?
                <div>
                    <p style={{ color: 'red' }}>{PARTIAL_INFO_MESSAGE}</p>
                </div>
                : null

            }

            {/* Associate Template Dialog */}
            <Dialog
                open={associateTemplateOpen}
                onClose={handleTemplateModalClose}
                fullWidth
                maxWidth="sm"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        maxWidth: '70%',
                    },
                }}
            >
                <DialogTitle className={classes.cstmdialogtitle} id="scroll-dialog-title">
                    Associate Case Template
                    <ClearOutlinedIcon className={classes.cancelaction} onClick={handleTemplateModalClose} />
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                        className="cmn-dialogtext"
                    />
                    <CaseTemplateListGrid
                        handleSelectedCaseTemplate={handleSelectedCaseTemplate}
                        caseTemplateHasBeenSelected={caseTemplateHasBeenSelected}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};