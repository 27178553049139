import {
	GET_TEMPLATES, GET_TEMPLATES_SUCCESS, GET_TEMPLATES_FAILED,
	GET_TEMPLATE_DETAIL, GET_TEMPLATE_DETAIL_SUCCESS, GET_TEMPLATE_DETAIL_FAILED,
	SAVE_TEMPLATE, SAVE_TEMPLATE_SUCCESS, SAVE_TEMPLATE_FAILED,
} from './template.actions';
import { IAction, ITemplate, ITemplateState } from '../../types/types';

const initialState: ITemplateState = {
	isLoading: false,
	templates: [] as ITemplate[],
	selectedTemplate: {} as ITemplate,
	error: null,
	templateStatusId: 0
}

export default (state = initialState, { type, payload }: IAction) => {
	switch (type) {
		case GET_TEMPLATES:
			return {
				...state,
				isLoading: true
			}
		case GET_TEMPLATES_SUCCESS:
			return {
				...state,
				isLoading: false,
				templates: payload
			}
		case GET_TEMPLATES_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload
			}


		case GET_TEMPLATE_DETAIL:
			return {
				...state,
				isLoading: true
			}
		case GET_TEMPLATE_DETAIL_SUCCESS:
			return {
				...state,
				isLoading: false,
				selectedTemplate: payload
			}
		case GET_TEMPLATE_DETAIL_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload
			}

		case SAVE_TEMPLATE:
			return {
				...state,
				isLoading: true
			}
		case SAVE_TEMPLATE_SUCCESS:
			return {
				...state,
				isLoading: false,
				templateDetail: payload,
				saveSuccess: true
			}
		case SAVE_TEMPLATE_FAILED:
			return {
				...state,
				isLoading: false,
				error: payload,
				saveSuccess: false
			}

		default:
			return state;
	}
}