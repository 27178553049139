import authService from '../../components/api-authorization/AuthorizeService';
import { ICaseBookDetails, ICasebookSearchResults, IImportCase } from '../../types/types';

const casebookBaseURL = localStorage.getItem("casebookApiBaseURL");

export const searchCases = async (criteria: string): Promise<ICasebookSearchResults> => {
	const token = await authService.getAccessToken();

	return fetch(`api/case/searchCasebook/${criteria}`, {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		}
	})
		.then((response) => response.json())
		.catch((error) => Promise.reject(error));
};

export const getCasebookCaseForImportById = async (casebookCaseDetailId: number): Promise<IImportCase> => {
	const token = await authService.getAccessToken();

	return fetch(`api/case/importCasebookCase/${casebookCaseDetailId}`, {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		}
	})
		.then((response) => response.json())
		.catch((error) => Promise.reject(error));
};

export const getCasebookCaseById = async (casebookCaseDetailId: number): Promise<ICaseBookDetails> => {
	const token = await authService.getAccessToken();

	return fetch(`api/case/getCasebookCaseDetails/${casebookCaseDetailId}`, {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		}
	})
		.then((response) => response.json())
		.catch((error) => Promise.reject(error));
};

