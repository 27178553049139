import {
	UPLOAD_CLAIMFILE, UPLOAD_CLAIMFILE_FAILED, UPLOAD_CLAIMFILE_SUCCESS, CLEAR_CLAIMFILE, UPLOAD_PROGRESS,
	PUBLISH_SUBMISSION_MESSAGE, PUBLISH_SUBMISSION_MESSAGE_SUCCESS, PUBLISH_SUBMISSION_MESSAGE_FAILED, CLEAR_PUBLISH_SUBMISSION_MESSAGE, 
	DELETE_SUBMISSION, DELETE_SUBMISSION_SUCCESS, DELETE_SUBMISSION_FAILED, CLEAR_DELETE_SUBMISSION,
	GET_CASE_DOCUMENT_TYPES, GET_CASE_DOCUMENT_TYPES_SUCCESS, GET_CASE_DOCUMENT_TYPES_FAILED, CLEAR_CASE_DOCUMENT_TYPES,
	GET_SUPPORT_EMAIL_ADDRESS, GET_SUPPORT_EMAIL_ADDRESS_SUCCESS, GET_SUPPORT_EMAIL_ADDRESS_FAILED, CLEAR_SUPPORT_EMAIL_ADDRESS,
	GET_CLAIM_ACKNOWLEDGEMENT_TEXT, GET_CLAIM_ACKNOWLEDGEMENT_TEXT_SUCCESS, GET_CLAIM_ACKNOWLEDGEMENT_TEXT_FAILED, CLEAR_CLAIM_ACKNOWLEDGEMENT_TEXT,
	DELETE_DOCUMENT, DELETE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_FAILED, CLEAR_DELETE_DOCUMENT,
	SET_SUPPORTING_DOC_TYPE_PROPS,CLEAR_SUPPORTING_DOC_FILE, UPLOAD_SUPPORTING_DOC_FILE , UPLOAD_SUPPORTING_DOC_FILE_PROGRESS , UPLOAD_SUPPORTING_DOC_FILE_SUCCESS , UPLOAD_SUPPORTING_DOC_FILE_FAILED
	} from './claimFile.actions';

import { IAction, IClaimFileState }  from '../../types/types';

const initialState: IClaimFileState = {
	isUploading: false,
	isUploadFailed: false,
	uploadResponse: undefined,
	publishMessage: false,
	publishMessageSuccess: false,
	publishMessageDispatched: false,
	deleteSubmissionResponse: undefined,
	loadingDocumentTypes: false,
	loadDocumentTypesSuccess: false,
	documentTypes: [],
	supportEmailAddress: undefined,
	deleteDocumentResponse: undefined,
	loadClaimAcknowledgementTextDispatched: false,
	claimAcknowledgementText: undefined,
	supportingDocTypeProps: [],
	isDeleted: false
}

const handleAction = (state = initialState, { type, payload }: IAction) => {
	switch (type) {
		case UPLOAD_CLAIMFILE:
			return {
				...state,
				isUploading: true,
				isUploadFailed: false
			}
		case UPLOAD_PROGRESS:
			return {
				...state,
				progress: payload
			}
		case UPLOAD_CLAIMFILE_SUCCESS:
			return {
				...state,
				isUploading: false,
				isUploadFailed: false,
				uploadResponse: payload
			}
		case UPLOAD_CLAIMFILE_FAILED:
			return {
				...state,
				isUploading: false,
				isUploadFailed: true,
				uploadResponse: payload,
			}
		case CLEAR_CLAIMFILE:
			return {
				...state,
				isUploading: false,
				isUploadFailed: false,
				uploadResponse: null
			}
		case PUBLISH_SUBMISSION_MESSAGE:
			return {
				...state,
				publishMessage: true,
				publishMessageSuccess: false,
				publishMessageDispatched: true,
			}
		case PUBLISH_SUBMISSION_MESSAGE_SUCCESS:
			return {
				...state,
				publishMessage: false,
				publishMessageSuccess: true
			}
		case PUBLISH_SUBMISSION_MESSAGE_FAILED:
			return {
				...state,
				publishMessage: false,
				publishMessageSuccess: false
			}
		case CLEAR_PUBLISH_SUBMISSION_MESSAGE:
			return {
				...state,
				publishMessage: false,
				publishMessageSuccess: false,
				publishMessageDispatched: false
			}
		case DELETE_SUBMISSION:
			return {
				...state,
				isDeleted: false
			}
		case DELETE_SUBMISSION_SUCCESS:
			return {
				...state,
				deleteSubmissionResponse: payload,
				isDeleted: true
			}
		case DELETE_SUBMISSION_FAILED:
			return {
				...state,
				deleteSubmissionResponse: payload,
				isDeleted: false
			}
		case CLEAR_DELETE_SUBMISSION:
			return {
				...state,
				deleteSubmissionResponse: null,
				isDeleted: false
			}
		case GET_CASE_DOCUMENT_TYPES:
			return {
				...state,
				loadingDocumentTypes: true,
				loadDocumentTypesSuccess: false
			}
		case GET_CASE_DOCUMENT_TYPES_SUCCESS:
			return {
				...state,
				loadingDocumentTypes: false,
				loadDocumentTypesSuccess: true,
				documentTypes: payload
			}
		case GET_CASE_DOCUMENT_TYPES_FAILED:
			return {
				...state,
				loadingDocumentTypes: false,
				loadDocumentTypesSuccess: false,
				documentTypes: payload
			}
		case CLEAR_CASE_DOCUMENT_TYPES:
			return {
				...state,
				loadingDocumentTypes: false,
				loadDocumentTypesSuccess: false,
				documentTypes: []
			}
		case CLEAR_SUPPORT_EMAIL_ADDRESS:
			return {
				...state,
				supportEmailAddress: null
			}
		case GET_SUPPORT_EMAIL_ADDRESS:
			return {
				...state,
			}
		case GET_SUPPORT_EMAIL_ADDRESS_SUCCESS:
			return {
				...state,
				supportEmailAddress: payload
			}
		case GET_SUPPORT_EMAIL_ADDRESS_FAILED:
			return {
				...state,
				supportEmailAddress: payload
			}
		case GET_CLAIM_ACKNOWLEDGEMENT_TEXT:
			return {
				...state,
				loadClaimAcknowledgementTextDispatched: true,
			}
		case GET_CLAIM_ACKNOWLEDGEMENT_TEXT_SUCCESS:
			return {
				...state,
				claimAcknowledgementText: payload
			}
		case GET_CLAIM_ACKNOWLEDGEMENT_TEXT_FAILED:
			return {
				...state,
				claimAcknowledgementText: payload
			}
		case CLEAR_CLAIM_ACKNOWLEDGEMENT_TEXT:
			return {
				...state,
				loadClaimAcknowledgementTextDispatched: false,
				claimAcknowledgementText: null
			}
		case DELETE_DOCUMENT:
			return {
				...state,
			}
		case DELETE_DOCUMENT_SUCCESS:
			return {
				...state,
				deleteDocumentResponse: payload
			}
		case DELETE_DOCUMENT_FAILED:
			return {
				...state,
				deleteDocumentResponse: payload
			}
		case CLEAR_DELETE_DOCUMENT:
			return {
				...state,
				deleteDocument: payload
			}
		case SET_SUPPORTING_DOC_TYPE_PROPS:
			return {
				...state,
				supportingDocTypeProps: payload
			}
		case UPLOAD_SUPPORTING_DOC_FILE:
			return {
				...state
			}
		case UPLOAD_SUPPORTING_DOC_FILE_PROGRESS:
			return {
				...state,
				progress: payload
			}
		case UPLOAD_SUPPORTING_DOC_FILE_SUCCESS:
			return {
				...state,
				uploadResponse: payload
			}
		case UPLOAD_SUPPORTING_DOC_FILE_FAILED:
			return {
				...state,
				uploadResponse: payload
			}
		case CLEAR_SUPPORTING_DOC_FILE:
			return {
				...state,
				supportingDocTypeProps: []
			}
		default:
			return state;
	}
}

export default handleAction;