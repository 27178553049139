import { GET_ADMIN_CARD_COUNT, getAdminCardCountsFailure, getAdminCardCountsSuccess } from './admin.actions';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import { AlertType } from '../../types/enums';
import { getAdminCardCounts } from './admin.api';
import { showAlert } from '../Alerts/alerts.actions';

function* getAdminCardCountsSaga(): any {

    try {
        const counts = yield call(getAdminCardCounts);
        yield put(
            getAdminCardCountsSuccess(counts)
        );
    }
    catch (error: any) {
        yield put(getAdminCardCountsFailure(error.message));
        yield put(showAlert({
            hidden: false,
            message: `There was a problem retrieving data. Please contact support if this problem persists.`,
            severity: AlertType.error,
        }));
    }

}

function* getAdminCardCountsWatcher() {
    yield takeLeading(GET_ADMIN_CARD_COUNT, getAdminCardCountsSaga);
}

export default function* adminCardCountsSaga() {
    yield all([getAdminCardCountsWatcher()]) //only one of these right now. add more as needed
}