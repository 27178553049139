import { Backdrop, CardContent, CircularProgress, Grid, Skeleton, Stack, Theme, Typography, Checkbox } from '@mui/material';
import { Configuration, DocumentTypeDisplay, Strings } from "../../types/enums";
import { IConfiguration, ISupportingDocTypeProps, ICaseDocumentType } from '../../types/types';
import React, { Fragment, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OptionalFileUploader from "./OptionalFileUpload"
import SupportingDocFileUploadInput from "./SupportingDocFileUploadInput";
import createStyles from '@mui/styles/createStyles';
import { getSupportEmailAddressAction } from './claimFile.actions';
import makeStyles from '@mui/styles/makeStyles';
import { UserContext, UserContextType } from "../../modules/User/UserContext";

const createLocalStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: {
			flexGrow: 1,
			color: 'black',
			fontSize: "1rem",
			paddingTop: "5px",
			paddingLeft:"15px"
		},
		uploadcard: {
			padding: "10px", 
			height: "auto",
			overflow: "auto"
		},
		submissionDocumentTitleHead: {
			fontSize: "1rem"
		},
		dashboardIconBackground: {
			fontSize: "20px"
		},
	}),
);

interface ISupportingDocFilesUploadCardProps {
	docTypeRows?: ISupportingDocTypeProps[],
	filesAreUploading: boolean,
	handleOptionalDocFileUpload: (i: any, event: any) => void,
	handleRemoveClick: Function,
	additionalDocuments: ISupportingDocTypeProps[],
	stringContentData: any,
	hasAllFiles: Function,
	setParentConsolidatedDocsChecked: Function
}

const SupportingDocFilesUploadCard = ({ docTypeRows, filesAreUploading, handleOptionalDocFileUpload, additionalDocuments, handleRemoveClick, stringContentData, hasAllFiles, setParentConsolidatedDocsChecked}: ISupportingDocFilesUploadCardProps) => {
	const dispatch = useDispatch();
	const classes = createLocalStyles();
	const [emailAddress, setEmailAddress] = useState<string>("");
	const supportEmailAddress: IConfiguration = useSelector((state: any) => state.claimFileState.supportEmailAddress);
	const { isInRole } = useContext(UserContext) as UserContextType;
	const [consolidatedDocsCheckBoxIsVisible, setConsolidatedDocsCheckBoxIsVisible] = useState<boolean>(false);
	const [consolidatedDocsChecked, setConsolidatedDocsChecked] = useState<boolean>(false);
	const documentTypes: ICaseDocumentType[] = useSelector((state: any) => state.claimFileState.documentTypes);
	const [consolidatedDocumentsText, setConsolidatedDocumentsText] = useState<string>();

	useEffect(() => {

		if (docTypeRows && docTypeRows.length === 0 && !supportEmailAddress) {
			let supportEmailAddressPayload = {
				configurationKey: Configuration.ProductionSupportEmailAddress
			};

			dispatch(getSupportEmailAddressAction(supportEmailAddressPayload));
		}

		if (supportEmailAddress && emailAddress === "")
			setEmailAddress(supportEmailAddress.value);

		if (stringContentData && documentTypes && !consolidatedDocumentsText) {

			if ((documentTypes.filter(items => items.isConsolidated === true).length > 0)) {
				setConsolidatedDocsCheckBoxIsVisible(true);
				setParentConsolidatedDocsChecked(true);
				setConsolidatedDocsChecked(true);
				getFormattedRequiredDocsText();
			}
			else {
				setParentConsolidatedDocsChecked(false);
				setConsolidatedDocsChecked(false);
			}

		}

	}, [docTypeRows, supportEmailAddress, isInRole, stringContentData, consolidatedDocumentsText]);


	const getFormattedRequiredDocsText = () => {

		let supportingDocumentsStringContentData = stringContentData.find((item: any) => item.key === Strings.ConsolidatedSupportingDocumentsListText);
		var docTypes = documentTypes.filter(item => item.isConsolidated === false).sort((a, b) => a.label.localeCompare(b.label)).map(item => item.label).join(', ');

		if (docTypes) {
			setConsolidatedDocumentsText(supportingDocumentsStringContentData?.value.replace("{docTypes}", docTypes));
		}

	}

	const processRows = (rows: ISupportingDocTypeProps[]) => {

		return (
			<Fragment>
				<Grid item xs={12}>
					{
						rows.filter(row => row.isConsolidatedDocumentsType === consolidatedDocsChecked).map((row) =>
						(

							<Fragment key={row.id}>
								{
									row.docTypeDescription === DocumentTypeDisplay.Other ?? additionalDocuments.push(row)
								}

								<SupportingDocFileUploadInput
									id={row.id}
									supportingDocFileProps={rows}
									stringContentData={stringContentData}
									hasAllFiles={hasAllFiles}
								/>
							</Fragment>

						))
					}
				</Grid>

				<Grid item xs={12}>
					<OptionalFileUploader
						handleOptionalDocFileUpload={handleOptionalDocFileUpload}
						additionalDocuments={additionalDocuments}
						handleRemoveOptionalDocFileUpload={handleRemoveClick}
						stringContentData={stringContentData}
					/>
				</Grid>
			</Fragment>
		);
	};

	const displaySupportMessage = () => {
		return (
			<Grid item xs={12}>
				<p>No available documentation types for this submission type. Please contact: {emailAddress}</p>
			</Grid>
		);
	};

	const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
		var checked = event.target.checked;
		setConsolidatedDocsChecked(checked);
		setParentConsolidatedDocsChecked(checked);
	};

	return (
		<CardContent className={classes.uploadcard}>
			<Typography style={{ float: "left" }} variant="h6" className={classes.title} noWrap>
				Add Required Supporting Documents
			</Typography>

			{consolidatedDocsCheckBoxIsVisible &&
				<Typography style={{ float: "left", paddingLeft: '3px' }} variant="h6" className={classes.title}>
					<Checkbox sx={{ color: 'black', paddingTop: '5px', marginBottom: '-3px' }}
						checked={consolidatedDocsChecked}
						color="primary"
						inputProps={{ "aria-label": "checkbox" }}
						onChange={handleChecked}
					/>
					Upload a combined PDF of all Supporting Documents
				</Typography>
			}

			{consolidatedDocumentsText && consolidatedDocsChecked &&
				<Typography style={{ float: "left" }} variant="h6" className={classes.title}>
						{consolidatedDocumentsText}
				</Typography>
			}

			<Grid container spacing={1}>
				<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={filesAreUploading}>
					<Stack alignItems="center">
						<CircularProgress color="inherit" />
						<br />
						<Typography sx={{ color: "#fff" }} variant="h4">
							Uploading documents...
						</Typography>
					</Stack>
				</Backdrop>

				{docTypeRows && docTypeRows.length === 0 && emailAddress ?
					(displaySupportMessage()) :
					(
						docTypeRows ? (processRows(docTypeRows)) :
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={12}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={12}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={12}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={12}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={12}>
									<Skeleton animation="wave" />
								</Grid>
							</Grid>
					)
				}

			</Grid>
		</CardContent>
	);
};

export default SupportingDocFilesUploadCard;