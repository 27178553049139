import React, { useEffect, useState } from 'react';
import {
	TextField, 
	Theme,
	Tooltip,
	Typography
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import IconButton from "@mui/material/IconButton";
import SearchOutlined from '@mui/icons-material/SearchOutlined';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		label: {
			color: theme.palette.background.paper,
			marginRight: "auto",
			position: "relative",
			fontSize: "18px",
			fontWeight:"600",
		},
		headerBackground: {
			background: '#052c66',
			padding: '6px 20px',
			borderRadius: '4px 4px 0px 0px',
			display: "flex",
			flexWrap: "wrap",
			minHeight: "50px",
			alignItems: "center",
			alignContent: "center",
		},
		searchBackground: {
				background: theme.palette.background.paper,
				width: '450px',
				borderRadius: '10px',
				justifyContent: 'center',
				marginRight: "auto",
				marginLeft: "-6vh"
			},
	})
);


interface SecuritiesOmniSearchProps {
	id: string,
	headerLabel: string,
	tooltip?: string,
	placeHolderText?: string,
	handleParentSearchClick: Function,
	handleParentResetSearchClick: Function,
}

const SecuritiesOmniSearch = ({ id, headerLabel, tooltip, placeHolderText, handleParentSearchClick, handleParentResetSearchClick}: SecuritiesOmniSearchProps) => {
	const classes = useStyles();

	const [warning, setWarning] = useState(false);
	const [omniSearchString, setOmniSearchString] = useState("");
	const [warningMessage, setWarningMessage] = useState("");
	const [showResetIcon, setShowResetIcon] = useState(false);

	const handleOnChange = (value: string) => {

		if (showResetIcon && value.length === 0) {
			handleSearchResetClick();
			return;
		}

		if (!showResetIcon && value.length !== 0) {
			setShowResetIcon(true);
		}

		setOmniSearchString(value);
	}
	
	const handleSearchResetClick = () => {
		setWarning(false);
		setWarningMessage(""); 
		setOmniSearchString("");
		setShowResetIcon(false);
		handleParentResetSearchClick();
	};

	const handleSearchClick = () => {

		if (omniSearchString.length < 2) {
			setWarning(true);
			setWarningMessage("Please enter at least 2 characters before searching...")
			return;
		}

		setWarning(false);
		setWarningMessage(""); 
		handleParentSearchClick(omniSearchString);
	};

		
	return (
			<div className={classes.headerBackground}>
					<Tooltip
						title={
							<Typography style={{ fontSize: 14 }}>{tooltip}</Typography>
						}
						placement="top"
					>
						<label className={classes.label}> {headerLabel} </label>
					</Tooltip>

					<TextField
						id={id}
						className={classes.searchBackground}
						variant={'standard'}
						error={ warning ? true : false }
						helperText={warning  ? warningMessage : ''}
						type='text'
						onChange={(e: any) =>	{
								handleOnChange(e.target.value)
							}
						}
						onKeyUp={(event) => {

								if (event.key === 'Enter') {
									handleSearchClick();
								}

							}
						}
						value={omniSearchString || "" }
						placeholder={placeHolderText ? placeHolderText : "Search..."}
						fullWidth
						InputProps={{
							startAdornment: (
								<IconButton 
									onClick={() => handleSearchClick()}
								>
									<SearchOutlined />
								</IconButton>
							),
							endAdornment:
								(showResetIcon &&
									<IconButton style={{ position: 'absolute', right: "10px"}}
										onClick={() => handleSearchResetClick()}
										>
										<ClearOutlinedIcon />
									</IconButton>
								),
							}
						}
					/>
			</div>
	)
};

export default SecuritiesOmniSearch;

