import { STANDARD_KABOB_HEIGHT } from '../../constants/CommonConstants';
import { EpiqAdmin, EpiqTechSupport } from '../../constants/roles';
import { IUserListState, IRegisterUser } from '../../types/types';
import { Card, CardContent, Grid, Theme } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import SecuritiesTable, { IColumnHeader, ISecuritiesTableFieldMap, ISorter } from '../../components/Shared/SecuritiesTable/SecuritiesTable';
import { UserContext, UserContextType } from '../User/UserContext';
import { useDispatch, useSelector } from 'react-redux';

import { IFilterDTO } from '../../types/types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link as RouterLink } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { UserListType } from '../../types/enums';
import { getUserList } from './userlist.actions';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
	button: {
		background: "#006B86",
		size: 'small',
		margin: theme.spacing(1),
		color: 'white'
	},
	textLink: {
		color: theme.palette.text.primary,
		padding: '0.5em'
	},
	actionColumn: {
		width: '275px'
	},
	kabobIconEnable: {
		color: "#087C9B",
		filter: "dropShadow(1px 1px 2px rgba(0, 0, 0, 0.44))",
		"&:disabled": {
			color: "#BCC0C5",
		},
	}
}));

const registrationRequestsFilterColumns: IColumnHeader[] = [
	{ field: 'email', label: 'Email/UserName', parameterName: 'email', type: 'string', value: '', allowFilter: true },
	{ field: 'firstName', label: 'First Name', parameterName: 'firstName', type: 'string', value: '', allowFilter: true },
	{ field: 'lastName', label: 'Last Name', parameterName: 'lastName', type: 'string', value: '', allowFilter: true },
	{ field: 'institutionName', label: 'Institution Name', parameterName: 'institutionName', type: 'string', value: '', allowFilter: true },
	{ field: 'confirmedOn', label: 'Confirmed on', parameterName: 'confirmedOnEndDate', type: 'date', value: '', allowFilter: true },
	{ field: 'actions', label: 'Actions' }
];

const defaultSort: ISorter = {
	property: 'confirmedOn',
	order: 'desc'
}

let sortInfo: ISorter = { ...defaultSort };

interface RegistrationRequestGridProps {
	approveUser: Function,
	denyUser: Function,
	manageUserRoles: Function,
	needsRefresh: boolean
}

const RegistrationRequestsGrid = ({ approveUser, denyUser, needsRefresh, manageUserRoles }: RegistrationRequestGridProps) => {
	const dispatch = useDispatch();
	const userListState: IUserListState = useSelector((state: any) => state.userListState);
	const classes = useStyles();
	const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;
	const [kabobsMenu, setKabobsMenu] = React.useState<null | HTMLElement>(null);
	let [selectedRowIndex, setSelectedRowIndex] = useState(-1);

	const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		setKabobsMenu(event.currentTarget);
		setSelectedRowIndex(index);
	};

	const handleClose = () => {
		setKabobsMenu(null);
		setSelectedRowIndex(-1);
	};

	useEffect(() => {
		dispatch(getUserList({ filter: [], userListType: UserListType.unapproved }));
	}, [dispatch, isAuthenticated, isInitialized, isInRole]);

	useEffect(() => {

		if (needsRefresh)
			dispatch(getUserList({ filter: [], userListType: UserListType.unapproved }));

	}, [dispatch, needsRefresh]);

	const handleResetFilter = () => {
		sortInfo = { ...defaultSort };
	};

	const fetchFilteredRegistrationRequests = (filters: IFilterDTO[]) => {
		dispatch(getUserList({ filter: filters, userListType: UserListType.unapproved }));
	}

	const buildRegistrationRequestsRows = (): ISecuritiesTableFieldMap[] => {
		var registrationRequestsRows: ISecuritiesTableFieldMap[] = [];

		userListState && userListState.userList && userListState.userList.forEach((item: IRegisterUser, index) => {
			let newItem: ISecuritiesTableFieldMap = {
				email: { type: "string", value: item.email || "" },
				firstName: { type: "string", value: <span> {item.firstName || ""}</span>, sortValue: item.firstName },
				lastName: { type: "string", value: <span>{item.lastName || ""}</span>, sortValue: item.lastName },
				institutionName: { type: "string", value: <span>{item.institution ? item.institution.name : item.registrationInstitutionName}</span>, sortValue: item.institution ? item.institution.name : item.registrationInstitutionName },
				confirmedEmail: { type: "string", value: item.email || "" },
				confirmedOn: { type: "date", value: item.confirmedOn },
				actions: {
					type: "link",
					value:
						<Fragment>
							<div className={classes.actionColumn}>
								<IconButton
									aria-label="more"
									className={classes.kabobIconEnable}
									id={"long-button-" + index}
									aria-haspopup="true"
									onClick={(event: React.MouseEvent<HTMLElement>) => {
										handleClick(event, index);
									}}
								>

									< MoreVertIcon />

								</IconButton>
								<Menu
									id={"long-menu-" + index}
									MenuListProps={{
										'aria-labelledby': 'long-button' + index,
									}}
									anchorEl={kabobsMenu}
									open={selectedRowIndex === index}
									onClose={handleClose}
									onClick={handleClose}
									PaperProps={{
										style: {
											maxHeight: STANDARD_KABOB_HEIGHT * 4.5,
											width: '20ch',
										},
									}}
								>

									<div>
										<div>
											{
												isInRole([EpiqAdmin, EpiqTechSupport]) &&
												<>
													<MenuItem onClick={() => { approveUser(item.id) }}>Approve</MenuItem>
													<MenuItem onClick={() => { denyUser(item.id) }}> Deny</MenuItem>
												</>
											}
											<MenuItem component={RouterLink} to={`/ReviewDetail/${item.registrationId}`}>Review </MenuItem>
										</div>
									</div>

								</Menu>
							</div>
						</Fragment>
				}
			}

			registrationRequestsRows.push(newItem);
		});

		return registrationRequestsRows;
	};

	let rows = null as ISecuritiesTableFieldMap[] | null;

	if (!userListState.error)
		rows = buildRegistrationRequestsRows();

	return (
		<>
			{(userListState.isLoading === true) ?
				<Card>
					<CardContent>
						{[...Array(5)].map((elementInArray, index) => (
							<Grid container spacing={2} key={index}>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={4}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
							</Grid>
						))}
					</CardContent>
				</Card>
				: <SecuritiesTable
					columns={registrationRequestsFilterColumns}
					rows={rows}
					sorter={sortInfo}
					paging={'client'}
					showFilter={true}
					dataFetchFunc={fetchFilteredRegistrationRequests}
					resetFilterFunc={handleResetFilter}
				/>}

		</>
	)
};

export default RegistrationRequestsGrid;