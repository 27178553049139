import { all, call, put, takeLeading } from 'redux-saga/effects';
import { getTemplateDetailFailed, getTemplateDetailSuccess, getTemplatesFailed, getTemplatesSuccess, GET_TEMPLATES, GET_TEMPLATE_DETAIL, SAVE_TEMPLATE, saveTemplateSuccess, saveTemplateFailed } from './template.actions';
import { getTemplateDetailAsync, getTemplatesAsync, saveTemplate } from './template.api';
import { IAction, ISaveTemplateResponse } from '../../types/types';
import { AlertType } from '../../types/enums';
import { showAlert } from '../Alerts/alerts.actions';


function* getTemplatesSaga({ payload: { filter } }: any): any {
	try {
		let data = yield call(getTemplatesAsync, filter);
		yield put(getTemplatesSuccess(data));
	} catch (error: any) {
		yield put(getTemplatesFailed(error.message));
	}
}

function* getTemplatesWatcher() {
	yield takeLeading(GET_TEMPLATES, getTemplatesSaga)
}

function* getTemplateDetailSaga({ payload: { templateId } }: any): any {
	try {
		
		let data = yield call(getTemplateDetailAsync, templateId);
		yield put(getTemplateDetailSuccess(data));
	} catch (error: any) {
		yield put(getTemplateDetailFailed(error.message));
	}
}

function* getTemplateDetailWatcher() {
	yield takeLeading(GET_TEMPLATE_DETAIL, getTemplateDetailSaga)
}

function* saveTemplateSaga(action: IAction): any {
	try {

		let data = yield call(saveTemplate, action.payload);
		const saveTemplateResponse: ISaveTemplateResponse = { success: true, message: 'template updated successfully.' }
		yield put(saveTemplateSuccess(saveTemplateResponse));
		yield put(showAlert({
			hidden: false,
			message: saveTemplateResponse.message,
			severity: saveTemplateResponse.success
				? AlertType.success
				: AlertType.error
		}));
	} catch (error: any) {
		yield put(saveTemplateFailed(error.message));
	}
}

function* saveTemplateWatcher() {
	yield takeLeading(SAVE_TEMPLATE, saveTemplateSaga)
}

export default function* templateSaga() {
	yield all([getTemplatesWatcher(),
		getTemplateDetailWatcher(),
		saveTemplateWatcher()
	]);
}