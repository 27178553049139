import { ICasebookSearchResults } from '../../types/types';

export const CASE_SEARCH = 'CASE_SEARCH';
export const CASE_SEARCH_SUCCESS = 'CASE_SEARCH_SUCCESS';
export const CASE_SEARCH_FAILED = 'CASE_SEARCH_FAILED';

export const CLEAR_CASE_SEARCH = 'CLEAR_CASE_SEARCH';
export const CLEAR_CASE_SEARCH_SUCCESS = 'CLEAR_CASE_SEARCH_SUCCESS';
export const CLEAR_CASE_SEARCH_FAILED = 'CLEAR_CASE_SEARCH_FAILED';

export const GET_CASEDETAIL_BY_ID = 'GET_CASEDETAIL_BY_ID';
export const GET_CASEDETAIL_BY_ID_SUCCESS = 'GET_CASEDETAIL_BY_ID_SUCCESS';
export const GET_CASEDETAIL_BY_ID_FAILED = 'GET_CASEDETAIL_BY_ID_FAILED';

export const caseSearch = (payload: string) => ({
	type: CASE_SEARCH,
	payload
});

export const caseSearchSuccess = (payload: ICasebookSearchResults) => ({
	type: CASE_SEARCH_SUCCESS,
	payload
});

export const caseSearchFailed = (payload: string) => ({
	type: CASE_SEARCH_FAILED,
	payload
});

export const clearCaseSearch = () => ({
	type: CLEAR_CASE_SEARCH
});

export const clearCaseSearchSuccess = () => ({
	type: CLEAR_CASE_SEARCH_SUCCESS
});

export const clearCaseSearchFailed = (payload: string) => ({
	type: CLEAR_CASE_SEARCH_FAILED,
	payload
});

export const getCaseDetailById = (payload: number) => ({
	type: GET_CASEDETAIL_BY_ID,
	payload
});

export const getCaseDetailByIdSuccess = (payload: any) => ({
	type: GET_CASEDETAIL_BY_ID_SUCCESS,
	payload
});

export const getCaseDetailByIdFailed = (payload: any) => ({
	type: GET_CASEDETAIL_BY_ID_FAILED,
	payload
});
