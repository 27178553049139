import React from 'react';
import { colors, LinearProgress, Theme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const createLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
                backgroundColor: colors.green[100]
            },
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: colors.green[500]
            },
        },
    }));

interface ISecuritiesLoaderProps {
    variant: 'buffer'
    | 'determinate'
    | 'indeterminate'
    | 'query',
    hidden?: boolean,
    value: number
}

const SecuritiesLoader = ({ variant, hidden = false, value }: ISecuritiesLoaderProps) => {
    const classes = createLocalStyles();

    return (
        <LinearProgress
            classes={{ root: classes.root }}
            variant={variant}
            hidden={hidden}
            value={value} />
    )
}

export default SecuritiesLoader;