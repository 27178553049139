import { Theme, Button } from '@mui/material';
import { IRoles, IRegisterUser, IUserListState, IStringValue } from "../../types/types";
import { Strings } from "../../types/enums";
import { getInfoJsonWithArray, transformHelpUrl } from "../../utilities/utilities";
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import UserListGrid from './UserListGrid';
import makeStyles from '@mui/styles/makeStyles';
import { UserContext, UserContextType } from '../../modules/User/UserContext';
import SlideoutDrawer from "./SlideoutDrawer";
import { getRolesList } from './userlist.actions';
import { EpiqAdmin } from '../../constants/roles';
import ManageUserRolesModal from './ManageUserRolesModal';
import RemoveUserAccessDialog from './RemoveUserAccessDialog';
import { UserListPageTitle } from '../../types/enums';
import { TECHNICAL_ERROR_MESSAGE } from '../../constants/CommonConstants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import authService from '../../components/api-authorization/AuthorizeService';
import { EpiqUser, Nominee } from '../../constants/roles';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme: Theme) => ({
    fab: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
    },
    commonbackgroundcard: {
        boxShadow: '0px 10px 15px -3px rgb(0 0 0 / 30%)',
        padding: '1.5rem 1rem 0.25rem',
        margin: '2rem 1rem 0',
        background: '#fff',
        borderRadius: '5px',
    },
    title: {
        flexGrow: 1,
        marginBottom: '15px',
        display: 'inline-flex',
        marginRight: "5px"
    },
    floatLeft: {
        float: 'left'
    },
    floatRight: {
        float: 'right',
    },
    dashboardIconBackground: {
        fontSize: "20px"
    },
    headOuter: {
        display: 'inline-block',
        width: '100%',
    }
}));

const UserListPage = () => {
    const { user, isInitialized, isAuthenticated, isInRole } = useContext(UserContext) as UserContextType;
    const classes = useStyles();
    let [selectedUser, setSelectedUser] = useState<IRegisterUser>();
    const userListState: IUserListState = useSelector(
        (state: any) => state.userListState
    );
    const [removeAccessDialogOpen, setRemoveAccessDialogOpen] = React.useState(false);
    const [mangeRolesDialogOpen, setMangeRolesDialogOpen] = React.useState(false);
    const [slideoutDrawerOpen, setslideoutDrawerOpen] = React.useState(false);
    document.title = UserListPageTitle.Title;

    let [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const [stringContentData, setStringContentData] = useState<IStringValue | null>();

    const slideoutDrawer = (id: number) => {
        const selectedUser = userListState.userList.find((x) => x.id === id);

        if (selectedUser) {
            setSelectedUser(selectedUser);
            setslideoutDrawerOpen(true);
        }
    };

    const handleRemoveUserAccess = (id: number) => {
        const selectedUser = userListState.userList.find((x) => x.id === id);

        if (selectedUser) {
            setSelectedUser(selectedUser);
            setRemoveAccessDialogOpen(true);
        }
    };

    const handleDrawerClose = () => {
        setslideoutDrawerOpen(false);
    };

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: path, title: "Users List Page" });
    }, [location]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRolesList({ filter: true }));

    }, [dispatch]);

    const manageUserRoles = (id: number) => {
        const selectedUser = userListState.userList.find((x) => x.id === id);

        if (selectedUser === undefined)
            return;

        setSelectedUser(selectedUser);

        userListState.rolesList.forEach(function (role: IRoles) {
            role.isChecked = role.isEpiqDefault ? true : false;
            role.disabled = role.isEpiqDefault ? true : false;

            selectedUser?.roles?.forEach(function (userrole) {
                if (userrole.toLowerCase() !== role.id.toLowerCase())
                    return;

                role.isChecked = true;


                if (userrole.toLowerCase() === EpiqAdmin.toLowerCase().replace(' ', '')
                    && selectedUser.email === user.email)
                    role.disabled = true;

                return;
            });
        });

        setMangeRolesDialogOpen(true);
    }

    const handleMangeRolesDialogClose = () => {
        setMangeRolesDialogOpen(false);
    };

    const handleRemoveAccessDialogClose = () => {
        setRemoveAccessDialogOpen(false);
    };

    const handleClick = (event: any) => {
        setShow(!show);
        setTarget(event.target);
    };

    const processInfoResponse = (data: any) => {
        data.map((item: IStringValue) => {
            switch (item.key) {
                case Strings.AdminApprovedUsers:
                    item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
                    setStringContentData(item);
                    break;


                default:
                    break;
            }
        });
    };

    useEffect(() => {
        const fetchInfoStrings = async () => {
            const data: any = await getInfoJsonWithArray([
                Strings.AdminApprovedUsers,
            ]);

            processInfoResponse(data);
        };


        if (isInitialized && isAuthenticated) {
            fetchInfoStrings();
        }
    }, [isAuthenticated, isInitialized]);

    return (
        <div className={classes.commonbackgroundcard}>
            <div className={classes.headOuter}>
                <div className={classes.floatLeft} ref={ref}>
                    <Typography variant="h6" className={classes.title} noWrap>
                        Approved Users
                    </Typography>

                    <Button style={{ background: "none", outline: "none", padding: "0", position: "relative", bottom: "3px", justifyContent: "left", minWidth: "25px" }} onClick={handleClick}><InfoOutlinedIcon className={classes.dashboardIconBackground} />
                    </Button>

                    <Overlay
                        show={show}
                        target={target}
                        placement="right-start"
                        container={ref}
                        containerPadding={20}
                        rootClose
                        onHide={() => setShow(false)}
                    >
                        <Popover id="popover-contained">
                            <Popover.Header as="h3"><span style={{ color: "#000" }}>Approved Users</span></Popover.Header>
                            {stringContentData && (
                                <Popover.Body dangerouslySetInnerHTML={{ __html: stringContentData.value }}></Popover.Body>
                            )}
                        </Popover>
                    </Overlay>
                </div>
            </div>

            <UserListGrid slideoutDrawer={slideoutDrawer} removeUserAccess={handleRemoveUserAccess} manageUserRoles={manageUserRoles} />

            <SlideoutDrawer
                selectedDrawerUser={selectedUser}
                isOpen={slideoutDrawerOpen}
                handleClose={handleDrawerClose}
            />

            <RemoveUserAccessDialog
                userToRemove={selectedUser}
                dialogOpen={removeAccessDialogOpen}
                handleDialogClose={handleRemoveAccessDialogClose}
            />

            {
                userListState?.isLoading === false &&
                (
                    <>
                        {
                            userListState?.rolesList?.length > 0 &&
                            <ManageUserRolesModal
                                selectedUser={selectedUser}
                                modalOpen={mangeRolesDialogOpen}
                                handleClose={handleMangeRolesDialogClose}
                                roles={userListState.rolesList}
                            />
                        }
                    </>
                )
            }

            {userListState.error && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
        </div>
    )
};

export default UserListPage;
