import { SubmissionTypeDisplay, SubmissionType, DashboardTabTitle, Strings } from "../../types/enums";
import {
	ICaseState,
	ISubmissionListState,
	IClaimFileModalProps,
	INomineeCardCountsState,
	ISupportingDocModalProps,
	IStringValue,
} from "../../types/types";
import { EpiqUser, Nominee } from "../../constants/roles";
import {
	Backdrop,
	Button,
	CardActionArea,
	CircularProgress,
	Paper,
	Stack,
	Tooltip,
	Box,
	Popper,
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import { useDispatch, useSelector } from "react-redux";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DashboardCaseListGrid from "./DashboardCaseListGrid";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ErrorIcon from "@mui/icons-material/Error";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import PendingIcon from "@mui/icons-material/Pending";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import SendIcon from "@mui/icons-material/Send";
import Switch from "@mui/material/Switch";
import { Theme } from "@mui/material";
import { Typography } from "@mui/material";
import { downloadCaseTemplateAction } from "../Cases/cases.actions";
import { getNomineeCardCountsAction } from "./dashboard.actions";
import makeStyles from "@mui/styles/makeStyles";
import ClaimFileUploadModal from "../ClaimFileUpload/ClaimFileUploadModal";
import SupportingDocFilesUploadModal from "../ClaimFileUpload/SupportingDocFilesUploadModal";
import { getEnumKeyByEnumValue, transformHelpUrl, getInfoJsonWithArray } from "../../utilities/utilities";
import { PARTIAL_INFO_MESSAGE, SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE } from "../../constants/CommonConstants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { setShowUserSubmissionsAction } from "../Submissions/submissions.actions";
import ConfirmationMessageModal from "../../components/Shared/ConfirmationMessageModal";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";

import { batch, useSignal } from "@preact/signals-react";
import { Placement } from "react-bootstrap/esm/types";

const useStyles = makeStyles((theme: Theme) => ({
	commonBackgroundCardDashboard: {
		height: "93%",
		padding: ".2rem 0",
		margin: "0",
		"& thead tr th": {
			paddingTop: "10px",
			paddingBottom: "10px",
		},
		"& thead tr th:first-child": {
			width: "10%",
		},
		"& thead tr th:nth-child(2)": {
			width: "10%",
		},
		"& thead tr th:nth-child(3)": {
			width: "12%",
		},
		"& thead tr th:nth-child(4)": {
			width: "40%",
		},
		"& thead tr th:last-child": {
			width: "11%",
		},
		"& tbody tr td:first-child": {
			width: "10%",
			paddingTop: "0px",
			paddingBottom: "0px",
		},
		"& tbody tr td:nth-child(2)": {
			width: "10%",
			paddingTop: "0px",
			paddingBottom: "0px",
		},
		"& tbody tr td:nth-child(3)": {
			width: "12%",
			paddingTop: "0px",
			paddingBottom: "0px",
		},
		"& tbody tr td:nth-child(4)": {
			width: "40%",
			paddingTop: "0px",
			paddingBottom: "0px",
		},
		"& tbody tr td:last-child": {
			width: "11%",
			paddingTop: "0px",
			paddingBottom: "0px",
		},
	},
	dashboardButtonHover: {
		background: "none",
		outline: "none",
		zIndex: "999",
		"&:hover": {
			background: "none",
			outline: "none",
		},
	},
	dashboardTopHead: {
		boxShadow: "0px 10px 15px -3px rgb(0 0 0 / 30%)",
		background: "#fff",
		borderRadius: "5px",
		padding: "1.5rem 1rem",
		margin: "2rem 1rem 0 0",
		paddingBottom: "0",
	},
	casesBackground: {
		background: "#052c66",
		padding: "8px 20px",
		borderRadius: "4px 4px 0px 0px",
		"& input": {
			paddingLeft: "45px",
		},
	},
	title: {
		flexGrow: 1,
		marginBottom: "0px",
		display: "inline-flex",
		marginRight: "5px",
	},
	searchBackground: {
		background: "#fff",
		width: "400px",
		height: "40px",
		borderRadius: "10px",
		justifyContent: "center",
	},
	card: {
		margin: "5px 5px 5px 1px",
		padding: "20px",
		display: "inline-block",
		width: "100px",
		height: "130px",
		alignItems: "center",
		justifyContent: "space-evenly",
		verticalAlign: "top",
		boxSizing: "border-box",
		boxShadow: "0px 8.66667px 8.66667px 0px rgba(0, 0, 0, 0.25)",
		borderRadius: "10px",
		border: "2px solid #Fff",
		"@media (max-width: 1920px)": {
			width: "100px",
		},
		"@media (max-width: 1368px)": {
			width: "100px",
			padding: "12px",
		},
	},
	processingCard: {
		background: "#2eaee3",
		border: "2px solid #2eaee3",
		color: "#fff",
	},
	errorCard: {
		background: "#fff",
		borderColor: "#900d0d !important",
		color: "#333d42",
	},
	pendingCard: {
		background: "#183088",
		border: "2px solid #183088",
		color: "#333d42",
	},
	dashBoardStartedWidth: {
		width: "500px",
	},
	dashBoardDeficientWidth: {
		width: "500px",
	},
	startedCard: {
		background: "#183088",
		border: "2px solid #183088",
		color: "#fff",
	},
	deficientCard: {
		background: "#D36A00",
		border: "2px solid #D36A00",
		color: "#fff",
	},
	userCard: {
		width: "400px",
		margin: 10,
		alignItems: "center",
		justifyContent: "space-evenly",
		verticalAlign: "top",
		boxSizing: "border-box",
	},
	iconBackground: {
		border: "2px solid #87bcca",
		color: "#ffff",
		width: "35px",
		height: "35px",
	},
	rejectedIcon: {
		border: "2px solid #fff",
		color: "#900d0d",
		width: "35px",
		height: "35px",
	},
	errorIcon: {
		border: "2px solid #900d0d",
		color: "#900d0d",
		width: "35px",
		height: "35px",
	},
	pendingIcon: {
		border: "2px solid #183088",
		color: "#183088",
		width: "35px",
		height: "35px",
	},
	dashboardIconBackground: {
		fontSize: "20px",
	},
	dashboardStartedIcon: {
		fontSize: "20px;",
		color: "#fff",
	},
	dashboardErrorIcon: {
		fontSize: "20px;",
		color: "#900d0d",
	},
	dashboardLoadedIcon: {
		fontSize: "20px;",
		color: "#003087",
	},
	dashboardDeficientIcon: {
		fontSize: "20px;",
		color: "#fff",
	},
	startedIcon: {
		border: "1px solid #fff",
		color: "#fff",
		width: "35px",
		height: "35px",
		justifyContent: "flex-start",
	},
	deficientIcon: {
		border: "1px solid #fff",
		color: "#fff",
		width: "35px",
		height: "35px",
	},
	startedIconBackground: {
		background: "#183088",
		color: "#fff",
		borderRadius: "100%",
		fontSize: "1.2rem",
	},
	deficientIconBackground: {
		background: "#D36A00",
		color: "#fff",
		borderRadius: "100%",
		fontSize: '1.7rem',
		position: 'relative',
		left:'1px'
	},
	rejectedIconBackground: {
		background: "#fff",
		color: "#900d0d",
		borderRadius: "100%",
		fontSize: "1.2rem",
	},
	loadedIcon: {
		border: "1px solid #003087",
		color: "#ffff",
	},
	loadedIconBackground: {
		background: "#003087",
		color: "#fff",
		borderRadius: "100%",
		fontSize: "1.2rem",
	},
	cardContentTitle: {
		color: "#ffff",
		fontWeight: "600",
		lineHeight: "17px",
		height: "33px",
	},
	startedCardValue: {
		color: "#fff",
	},
	deficientCardValue: {
		color: "#fff",
	},
	loadedCardContentTitle: {
		color: "#333d42",
		fontWeight: "600",
		height: "33px",
	},
	loadedCardContentErrorTitle: {
		color: "#900d0d",
		fontWeight: "600",
		height: "33px",
	},
	loadedCardContentLoadedTitle: {
		color: "#003087",
		fontWeight: "600",
		height: "33px",
	},
	loadedCardValue: {
		color: "#33599f",
	},
	hoverCard: {
		background: "none",
		padding: "0",
		"&:hover": {
			background: "none",
			color: "#fff"
		},
		"&:hover span": {
			opacity: "0 !important",
		},
	},
	hoverCardError: {
		background: "none",
		padding: "0",
		"&:hover": {
			background: "none",
			color: "#900d0d"
		},
		"&:hover span": {
			opacity: "0 !important",
		}
	},
	errorCardTextColor: {
		color: "#900d0d"
	},
	viewAll: {
		display: "grid",
		background: "none",
		border: "none",
		boxShadow: "none",
		textAlign: "right",
		paddingTop: "0px",
		"@media (max-width: 1368px)": {
			marginTop: "-12px",
		},
	},
	viewAllLeft: {
		background: "none",
		border: "none",
		boxShadow: "none",
		textAlign: "right",
		marginTop: "0px",
		"@media (max-width: 1368px)": {
			marginTop: "49px",
		},
	},
	partiallyCard: {
		background: "#1d819b",
		border: "1px solid #1d819b",
		color: "#fff",
		boxShadow: "0px 6.70514px 6.70514px 0px rgba(0, 0, 0, 0.25)",
	},
	rejectedCard: {
		background: "#900d0d",
		border: "2px solid #900d0d",
		color: "#fff",
	},
	loadedCard: {
		background: "#fff",
		borderColor: "#003087 !important",
		color: "#fff",
		boxShadow: "0px 6.70514px 6.70514px 0px rgba(0, 0, 0, 0.25)",
	},

	viewAllSubmission: {
		color: "#1d819b",
		lineHeight: "1.5",
		"&:hover": {
			background: "none",
			border: "none",
			boxShadow: "none",
		},
		"&:active": {
			boxShadow: "none",
			background: "none",
		},
		"&:focus": {
			outline: "none",
		},
	},
	hide: {
		display: "none",
	},
	left: {
		float: "left",
		width: "100%",
	},
	nCountText: {
		fontSize: "18px",
	},
	gridConstraints: {
		maxHeight: "500px",
		minHeight: "200px",
	},
	floatLeft: {
		float: "left",
	},
	floatRight: {
		float: "right",
	},
	dashboardCardWrapper: {
		background: "#fff",
		borderRadius: "4px",
		boxShadow: "0px 0px 12px 2px rgba(0, 0, 0, 0.25)",
		width: "69%",
		margin: "1rem 1rem 0 0",
		padding: "1.5rem 1rem",
		display: "inline-flex",
		paddingBottom: "0.5rem",
	},
	dashboardCardSubmission: {
		background: "#fff",
		borderRadius: "4px",
		boxShadow: "0px 0px 12px 2px rgba(0, 0, 0, 0.25)",
		width: "29%",
		margin: "1rem 1rem 0 0",
		padding: "1.5rem 1rem",
		display: "inline-flex",
		paddingBottom: "0.5rem",
		height: "240px",
	},
	submissionRightCard: {
		borderBottom: "1px solid #D9D9D9",
		fontWeight: "500",
		paddingLeft: "0px !important",
		paddingTop: "0px !important",
	},
	submissionRightCardLast: {
		borderBottom: "none",
		fontWeight: "500",
		paddingLeft: "0px !important",
		paddingTop: "0px !important",
		position: "relative",
		textAlign: "right",
		paddingRight: "35px",
	},
	dashboardBackground: {
		padding: "0",
		background: "none",
		boxShadow: "none",
		borderRadius: "5px",
		height: "100%",
	},
	submissionRightCardIcon: {
		float: "right",
		color: "rgba(33, 147, 176, 1)",
		fontSize: "30px",
		position: "relative",
		padding: "0",
	},
	submissionRightCardBottomIcon: {
		color: "rgba(33, 147, 176, 1)",
		fontSize: "45px",
		borderBottom: "none",
		cursor: "pointer",
	},
	submissionRightCardBottomOuter: {
		display: "inline-flex",
		height: "50px",
		marginRight: "20px",
		textAlign: "center",
		"& submissionRightCardBottomOuter:last-child": {
			marginRight: "0px",
		},
		"& span": {
			position: "absolute",
			top: "40px",
			color: "#006B86",
			fontWeight: "400",
			fontSize: "13px",
		},
	},
	[`& .MuiTooltip-tooltip`]: {
		fontFamily: "'Grape Nuts', Helvetica",
		color: "deepskyblue",
		backgroundColor: "rgba(255,255,0,0.4)",
	},
	popperBox: {
		background: "#087C9B",
		padding: "8px",
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		borderRadius: "4px",
		overflow: "hidden",
	},
	popperBody: {
		background: "#087C9B",
		color: "white",
		fontSize: "14px",
		fontFamily: "Roboto",
		fontWeight: "400",
		wordWrap: "break-word",
	},
	popperCloseButton: {
		background: "#087C9B",
		border: "none",
		color: "white",
		fontSize: "12px",
		fontFamily: "Roboto",
		fontWeight: "100",
		wordWrap: "break-word",
		cursor: "pointer",
	},
	commonCardOuter: {
		marginBottom: "15px",
	},
	arrow: {
		top: "43% !important",
		position: "absolute",
		border: "1px solid #087C9B",
		backgroundColor: "#087C9B",
		marginLeft: "-0.9em",
		transform: "rotate(45deg) !important",

		fontSize: 7,
		width: "3em",
		height: "3em",
		content: '""',
		margin: "auto",
		display: "block",
	},
}));

export function Dashboard() {
	const { user, isInitialized, isAuthenticated, isInRole } = useContext(UserContext) as UserContextType;
	const dispatch = useDispatch();
	const history = useHistory();
	const cardCountsState: INomineeCardCountsState = useSelector((state: any) => state.cardCountsState);
	const classes = useStyles();
	const processingCount =
		(cardCountsState.cardCounts?.submitted || 0) +
		(cardCountsState.cardCounts?.extracting || 0) +
		(cardCountsState.cardCounts?.extracted || 0) +
		(cardCountsState.cardCounts?.verifying || 0) +
		(cardCountsState.cardCounts?.verified || 0) +
		(cardCountsState.cardCounts?.loading || 0) +
		(cardCountsState.cardCounts?.partiallyExtracted || 0);
	const caseState: ICaseState = useSelector((state: any) => state.caseState);

	let [claimFileModalIsOpen, setClaimFileModalIsOpen] = useState(false);
	let [supportingDocFilesModalIsOpen, setSupportingDocFilesModalIsOpen] = useState(false);
	let [show, setShow] = useState(false);
	let [dashboardStarted, setShowDashboardStarted] = useState(false);
	let [dashboardDeficient, setShowDashboardDeficient] = useState(false);
	let [dashboardPending, setShowDashboardPending] = useState(false);
	let [dashboardError, setShowDashboardError] = useState(false);
	let [dashboardNomineePending, setShowDashboardNomineePending] = useState(false);
	let [dashboardInProcess, setShowDashboardInProcess] = useState(false);
	let [dashboardSubmissionStatus, setShowDashboardSubmissionStatus] = useState(false);
	let [dashboardPartiallyLoaded, setShowDashboardPartiallyLoaded] = useState(false);
	let [dashboardClose, setShowDashboardClose] = useState(false);
	let [dashboardLoaded, setShowDashboardLoaded] = useState(false);
	const [target, setTarget] = useState(null);
	const ref = useRef(null);
	const refDashboard = useRef(null);
	let [claimFileModalProps, setClaimFileModalProps] = useState<IClaimFileModalProps>();
	let [supportingDocModalProps, setSupportingDocModalProps] = useState<ISupportingDocModalProps>();
	let hasCaseBookData = caseState.cases && caseState.cases.length > 0 ? caseState.cases.find((c) => c.claimsDeadline) : true;
	const [stringContentData, setStringContentData] = useState<IStringValue | null>();
	const [dashboardStartedStringContentData, setDashboardStartedStringContentData] = useState<IStringValue | null>();
	const [dashboardDeficientStringContentData, setDashboardDeficientStringContentData] = useState<IStringValue | null>();
	const [dashboardPendingStringContentData, setDashboardPendingStringContentData] = useState<IStringValue | null>();
	const [dashboardErrorStringContentData, setDashboardErrorStringContentData] = useState<IStringValue | null>();
	const [dashboardInProcessStringContentData, setDashboardInProcessStringContentData] = useState<IStringValue | null>();
	const [dashboardSubmissionStatusText, setDashboardSubmissionStatusText] = useState<IStringValue | null>();
	const [dashboardPartiallyLoadedStringContentData, setDashboardPartiallyLoadedStringContentData] =
		useState<IStringValue | null>();
	const [dashboardCloseStringContentData, setDashboardCloseStringContentData] = useState<IStringValue | null>();
	const [dashboardLoadedStringContentData, setDashboardLoadedStringContentData] = useState<IStringValue | null>();

	const [labelDownloadSubmissionTemplate, setLabelDownloadSubmissionTemplate] = useState<IStringValue | null>();
	const [dialogDownloadSubmissionTemplate, setDialogDownloadSubmissionTemplate] = useState<IStringValue | null>();
	const [labelAddSubmission, setLabelAddSubmission] = useState<IStringValue | null>();
	const [dialogAddSubmission, setDialogAddSubmission] = useState<IStringValue | null>();
	const [labelAmendSubmission, setLabelAmendSubmission] = useState<IStringValue | null>();
	const [dialogAmendSubmission, setDialogAmendSubmission] = useState<IStringValue | null>();
	const [labelCheckSubmissionStatus, setLabelCheckSubmissionStatus] = useState<IStringValue | null>();
	const [dialogCheckSubmissionStatus, setDialogCheckSubmissionStatus] = useState<IStringValue | null>();

	const [urlTutorial, setUrlTutorial] = useState<IStringValue | null>();
	const [urlQuickStart, setUrlQuickStart] = useState<IStringValue | null>();

	const submissionState: ISubmissionListState = useSelector((state: any) => state.submissionListState);
	const location = useLocation();
	document.title = DashboardTabTitle.Title;

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: DashboardTabTitle.Title });
	}, [location]);

	const handleClick = (event: any) => {
		setShow(!show);
		setTarget(event.target);
	};

	const dashboardStartedHandleClick = (event: any) => {
		setShowDashboardStarted(!dashboardStarted);
		setTarget(event.target);
	};

	const dashboardDeficientHandleClick = (event: any) => {
		setShowDashboardDeficient(!dashboardDeficient);
		setTarget(event.target);
	};

	const dashboardInProcessHandleClick = (event: any) => {
		setShowDashboardInProcess(!dashboardInProcess);
		setTarget(event.target);
	};

	const dashboardSubmissionStatusClick = (event: any) => {
		setShowDashboardSubmissionStatus(!dashboardSubmissionStatus);
		setTarget(event.target);
	}

	const dashboardErrorHandleClick = (event: any) => {
		setShowDashboardError(!dashboardError);
		setTarget(event.target);
	};

	const dashboardNomineePendingHandleClick = (event: any) => {
		setShowDashboardNomineePending(!dashboardNomineePending);
		setTarget(event.target);
	};

	const dashboardPartiallyLoadedHandleClick = (event: any) => {
		setShowDashboardPartiallyLoaded(!dashboardPartiallyLoaded);
		setTarget(event.target);
	};

	const dashboardCloseHandleClick = (event: any) => {
		setShowDashboardClose(!dashboardClose);
		setTarget(event.target);
	};

	const dashboardLoadedHandleClick = (event: any) => {
		setShowDashboardLoaded(!dashboardLoaded);
		setTarget(event.target);
	};

	const processInfoResponse = (data: any) => {
		data.map((item: IStringValue) => {
			switch (item.key) {
				case Strings.Dashboard:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setStringContentData(item);
					break;

				case Strings.DashboardStartedText:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDashboardStartedStringContentData(item);
					break;

				case Strings.DashboardDeficientText:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDashboardDeficientStringContentData(item);
					break;

				case Strings.DashboardInProcessText:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDashboardInProcessStringContentData(item);
					break;

				case Strings.DashboardSubmissionStatus:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDashboardSubmissionStatusText(item);
					break;

				case Strings.DashboardPendingText:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDashboardPendingStringContentData(item);
					break;

				case Strings.DashboardErrorText:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDashboardErrorStringContentData(item);
					break;

				case Strings.DashboardPartiallyLoadedText:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDashboardPartiallyLoadedStringContentData(item);
					break;

				case Strings.DashboardCloseText:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDashboardCloseStringContentData(item);
					break;

				case Strings.DashboardLoadedText:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setDashboardLoadedStringContentData(item);
					break;

				case Strings.LabelDownloadSubmissionTemplate:
					item.value = item.value ? item.value : "";
					setLabelDownloadSubmissionTemplate(item);
					break;

				case Strings.DialogDownloadSubmissionTemplate:
					item.value = item.value ? item.value : "stuff";
					setDialogDownloadSubmissionTemplate(item);
					break;

				case Strings.LabelAddSubmission:
					setLabelAddSubmission(item);
					break;

				case Strings.DialogAddSubmission:
					setDialogAddSubmission(item);
					break;

				case Strings.LabelAmendSubmission:
					setLabelAmendSubmission(item);
					break;

				case Strings.DialogAmendSubmission:
					setDialogAmendSubmission(item);
					break;

				case Strings.LabelCheckSubmissionStatus:
					setLabelCheckSubmissionStatus(item);
					break;

				case Strings.DialogCheckSubmissionStatus:
					setDialogCheckSubmissionStatus(item);
					break;

				case Strings.UrlQuickStart:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setUrlQuickStart(item);
					break;

				case Strings.UrlTutorial:
					item.value = transformHelpUrl(item.value, isInRole([EpiqUser]));
					setUrlTutorial(item);
					break;

				default:
					break;
			}
		});
	};

	useEffect(() => {
		const fetchInfoStrings = async () => {
			const data: any = await getInfoJsonWithArray([
				Strings.Dashboard,
				Strings.DashboardStartedText,
				Strings.DashboardDeficientText,
				Strings.DashboardSubmissionStatus,
				Strings.DashboardInProcessText,
				Strings.DashboardPendingText,
				Strings.DashboardErrorText,
				Strings.DashboardPartiallyLoadedText,
				Strings.DashboardCloseText,
				Strings.DashboardLoadedText,
				Strings.LabelDownloadSubmissionTemplate,
				Strings.DialogDownloadSubmissionTemplate,
				Strings.LabelAddSubmission,
				Strings.DialogAddSubmission,
				Strings.LabelAmendSubmission,
				Strings.DialogAmendSubmission,
				Strings.LabelCheckSubmissionStatus,
				Strings.DialogCheckSubmissionStatus,
				Strings.UrlQuickStart,
				Strings.UrlTutorial,
			]);

			processInfoResponse(data);
		};

		//when epiq users login they should see the insitution records for non epiq users they will see user records.

		if (window.performance && performance.navigation.type !== 1)
			if (isInRole([EpiqUser])) {
				submissionState.showUserSubmissions = false;
				setShowUserSubmissionsAction(submissionState.showUserSubmissions);
			}

		if (isInitialized && isAuthenticated) {
			fetchInfoStrings();
			dispatch(getNomineeCardCountsAction({ userId: user.id, isUserSubmissions: submissionState.showUserSubmissions }));
		}
	}, [isAuthenticated, isInitialized, dispatch, isInRole]);

	const viewAllSubmissions = () => {
		history.push("/SubmissionList/0");
	};

	const viewAllCases = () => {
		history.push("/CaseList");
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let showInstitutionSubmissions: boolean = event.target.checked;
		dispatch(setShowUserSubmissionsAction(!showInstitutionSubmissions));

		if (isInitialized && isAuthenticated)
			dispatch(getNomineeCardCountsAction({ userId: user.id, isUserSubmissions: !showInstitutionSubmissions }));
	};

	const downloadTemplate = (caseId: number) => {
		let caseSelected = caseState.cases.find((x) => x.id === caseId);
		dispatch(downloadCaseTemplateAction(caseSelected!.id));
	};

	const isDownloading: boolean = useSelector((state: any) => {
		return state.caseState.isDownloading;
	});

	const selectCase = (caseId: number, submissionTypeDisplay: SubmissionTypeDisplay, caseName: string, caseNumber: string) => {
		setSupportingDocFilesModalIsOpen(false);

		setClaimFileModalProps((prevState) => ({
			handleClose: handleUploadClaimFileModalClosed,
			submissionTypeDisplay: submissionTypeDisplay,
			submissionType: SubmissionType[getEnumKeyByEnumValue(SubmissionTypeDisplay, submissionTypeDisplay)],
			parentSubmissionId: 0,
			caseId: caseId,
			caseName: caseName,
			caseNumber: caseNumber,
			isNonClaimDeficiencyResponse: false,
			handleSupportingDocModalOpen: handleSupportingDocModalOpen,
			handleSupportingDocModalClose: handleSupportingDocModalClose,
			setSupportingDocModalProps: setSupportingDocModalProps,
		}));

		setClaimFileModalIsOpen(true);
	};

	const handleUploadClaimFileModalClosed = () => {
		setClaimFileModalIsOpen(false);
	};

	const handleSupportingDocModalOpen = () => {
		setSupportingDocFilesModalIsOpen(true);
	};

	const handleSupportingDocModalClose = (submitted: boolean = false) => {
		if (submitted) {
			//show confirmation modal
			setConfirmationModalOpen(true);
		}

		setSupportingDocFilesModalIsOpen(false);
	};

	const [confirmationModalOpen, setConfirmationModalOpen] = React.useState(false);

	const handleClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
		if (reason === "backdropClick") {
			console.log(reason);
		} else {
			setConfirmationModalOpen(false);
		}
	};

	// "I Want To" card variables --------------------------------------------------

	const millisecondsToWaitForElement = 2000;
	const dashboardCaseListGridElementId = "dashboardCaseListGrid";
	const drawerItemSubmissionsElementId = "drawerItem_submissions";

	// "I Want To", Download Submission Template Popper --------------------------------------------------

	const menuItemDownloadTemplateElementId = "menuItemDownloadTemplate";
	const downloadTemplatePlacement = useSignal<Placement | undefined>("bottom");
	const anchorDownloadSubmissionTemplatePopper = useSignal<null | HTMLElement>(
		document.getElementById(dashboardCaseListGridElementId)
	);
	const downloadSubmissionTemplatePopperOpen = useSignal<boolean>(false);

	useEffect(() => {
		let desiredElement = getFirstElementWithIdContaining(menuItemDownloadTemplateElementId) as HTMLElement;
		let fallbackElement = document.getElementById(dashboardCaseListGridElementId);
		anchorDownloadSubmissionTemplatePopper.value = desiredElement ? desiredElement : fallbackElement;
	}, [anchorDownloadSubmissionTemplatePopper.value]);

	const showDownloadSubmissionTemplatePopper = () => {
		setMenuItemDownloadTemplateAnchor();
		downloadSubmissionTemplatePopperOpen.value = true;
	};

	const hideDownloadSubmissionTemplatePopper = () => {
		downloadSubmissionTemplatePopperOpen.value = false;
	};

	function getFirstElementWithIdContaining(idContent: string): Element | null {
		const elements = document.querySelectorAll(`[id*="${idContent}"]`);
		let element: Element | null = elements.length > 0 ? elements[0] : null;
		return element;
	}

	function setMenuItemDownloadTemplateAnchor() {
		let menuItemDownloadTemplateElement = getFirstElementWithIdContaining(menuItemDownloadTemplateElementId);

		if (menuItemDownloadTemplateElement) {
			// Combine both writes into one update
			batch(() => {
				anchorDownloadSubmissionTemplatePopper.value = menuItemDownloadTemplateElement as HTMLElement;
				downloadTemplatePlacement.value = "right";
			});

			return;
		}

		// go into "finder" mode
		let attempt = 1;
		let downloadSubmissionIntervalId = setInterval(() => {
			menuItemDownloadTemplateElement = getFirstElementWithIdContaining(menuItemDownloadTemplateElementId);

			if (menuItemDownloadTemplateElement) {
				clearInterval(downloadSubmissionIntervalId);

				// Combine both writes into one update
				batch(() => {
					anchorDownloadSubmissionTemplatePopper.value = menuItemDownloadTemplateElement as HTMLElement;
					downloadTemplatePlacement.value = "right";
				});

				return;
			}

			if (++attempt > 3) {
				clearInterval(downloadSubmissionIntervalId);
				return;
			}
		}, millisecondsToWaitForElement);
	}

	// I Want To, Add a Submission Popper --------------------------------------------------

	const menuItemAddSubmissionElementId = "menuItemAddSubmission";
	const addSubmissionPlacement = useSignal<Placement | undefined>("bottom");
	const anchorAddSubmissionPopper = useSignal<null | HTMLElement>(null);
	const addSubmissionPopperOpen = useSignal<boolean>(false);

	useEffect(() => {
		let desiredElement = getFirstElementWithIdContaining(menuItemAddSubmissionElementId) as HTMLElement;
		let fallbackElement = document.getElementById(dashboardCaseListGridElementId);
		anchorAddSubmissionPopper.value = desiredElement ? desiredElement : fallbackElement;
	}, [anchorAddSubmissionPopper.value]);

	const showAddSubmissionPopper = () => {
		setAddSubmissionPopperAnchor();
		addSubmissionPopperOpen.value = true;
	};

	const hideAddSubmissionPopper = () => {
		addSubmissionPopperOpen.value = false;
	};

	function setAddSubmissionPopperAnchor() {
		let menuItemAddSubmissionElement = getFirstElementWithIdContaining(menuItemAddSubmissionElementId);

		if (menuItemAddSubmissionElement) {
			// Combine both writes into one update
			batch(() => {
				anchorAddSubmissionPopper.value = menuItemAddSubmissionElement as HTMLElement;
				addSubmissionPlacement.value = "right";
			});

			return;
		}

		// go into "finder" mode
		let attempt = 1;
		let intervalId = setInterval(() => {
			menuItemAddSubmissionElement = getFirstElementWithIdContaining(menuItemAddSubmissionElementId);

			if (menuItemAddSubmissionElement) {
				clearInterval(intervalId);

				// Combine both writes into one update
				batch(() => {
					anchorAddSubmissionPopper.value = menuItemAddSubmissionElement as HTMLElement;
					addSubmissionPlacement.value = "right";
				});

				return;
			}

			if (++attempt > 3) {
				clearInterval(intervalId);
				return;
			}
		}, millisecondsToWaitForElement);
	}

	// I Want To, Amend a Submission Popper --------------------------------------------------

	const anchorAmendSubmissionPopper = useSignal<null | HTMLElement>(null);
	const amendSubmissionPopperOpen = useSignal<boolean>(false);

	useEffect(() => {
		anchorAmendSubmissionPopper.value = document.getElementById(drawerItemSubmissionsElementId);
	});

	const showAmendSubmissionPopper = () => {
		amendSubmissionPopperOpen.value = true;
	};

	const hideAmendSubmissionPopper = () => {
		amendSubmissionPopperOpen.value = false;
	};

	// I Want To, Check a Submission Status Popper --------------------------------------------------

	const anchorCheckSubmissionStatusPopper = useSignal<null | HTMLElement>(null);
	const checkSubmissionStatusPopperOpen = useSignal<boolean>(false);

	useEffect(() => {
		anchorCheckSubmissionStatusPopper.value = document.getElementById(drawerItemSubmissionsElementId);
	});

	const showCheckSubmissionStatusPopper = () => {
		checkSubmissionStatusPopperOpen.value = true;
	};

	const hideCheckSubmissionStatusPopper = () => {
		checkSubmissionStatusPopperOpen.value = false;
	};

	// Component Render --------------------------------------------------------------------------------

	var downloadSubmissionPopperArrowRef = document.getElementById("downloadSubmissionPopperArrow");
	var addSubmissionPopperArrowRef = document.getElementById("addSubmissionPopperArrow");
	var amendSubmissionPopperArrowRef = document.getElementById("amendSubmissionPopperArrow");
	var checkSubmissionPopperArrowRef = document.getElementById("checkSubmissionPopperArrow");

	return (
		<div className={classes.commonBackgroundCardDashboard}>
			{/*I Want To, Download Submission Template Popper*/}
			<Popper
				style={{ zIndex: "10000" }}
				open={downloadSubmissionTemplatePopperOpen.value}
				anchorEl={anchorDownloadSubmissionTemplatePopper.value}
				placement={downloadTemplatePlacement.value}
				modifiers={[
					{
						name: "preventOverflow",
						enabled: true,
						options: {
							altAxis: true,
							altBoundary: true,
							tether: false,
							rootBoundary: "viewport",
							padding: 8,
						},
					},
					{
						name: "arrow",
						enabled: true,
						options: {
							element: downloadSubmissionPopperArrowRef,
						},
					},
				]}
			>
				<span id="downloadSubmissionPopperArrow" data-popper-arrow className={classes.arrow}></span>
				{dialogDownloadSubmissionTemplate && (
					<Box className={classes.popperBox}>
						<Stack>
							<Box
								className={classes.popperBody}
								dangerouslySetInnerHTML={{ __html: dialogDownloadSubmissionTemplate.value }}
							></Box>

							<button
								className={classes.popperCloseButton}
								type="button"
								onClick={hideDownloadSubmissionTemplatePopper}
							>
								Close
							</button>
						</Stack>
					</Box>
				)}
			</Popper>

			{/*I Want To, Add Submission Popper*/}
			<Popper
				style={{ zIndex: "10000" }}
				open={addSubmissionPopperOpen.value}
				anchorEl={anchorAddSubmissionPopper.value}
				placement={addSubmissionPlacement.value}
				modifiers={[
					{
						name: "preventOverflow",
						enabled: true,
						options: {
							altAxis: true,
							altBoundary: true,
							tether: false,
							rootBoundary: "viewport",
							padding: 8,
						},
					},
					{
						name: "arrow",
						enabled: true,
						options: {
							element: addSubmissionPopperArrowRef,
						},
					},
				]}
			>
				<span id="addSubmissionPopperArrow" data-popper-arrow className={classes.arrow}></span>
				{dialogAddSubmission && (
					<Box className={classes.popperBox}>
						<Stack>
							<Box
								className={classes.popperBody}
								dangerouslySetInnerHTML={{ __html: dialogAddSubmission.value }}
							></Box>

							<button className={classes.popperCloseButton} type="button" onClick={hideAddSubmissionPopper}>
								Close
							</button>
						</Stack>
					</Box>
				)}
			</Popper>

			{/*I Want To, Amend Submission Popper*/}
			<Popper
				style={{ zIndex: "10000" }}
				open={amendSubmissionPopperOpen.value}
				anchorEl={anchorAmendSubmissionPopper.value}
				placement="right"
				modifiers={[
					{
						name: "arrow",
						enabled: true,
						options: {
							element: amendSubmissionPopperArrowRef,
						},
					},
				]}
			>
				<span id="amendSubmissionPopperArrow" data-popper-arrow className={classes.arrow}></span>
				{dialogAmendSubmission && (
					<Box className={classes.popperBox}>
						<Stack>
							<Box
								className={classes.popperBody}
								dangerouslySetInnerHTML={{ __html: dialogAmendSubmission.value }}
							></Box>

							<button className={classes.popperCloseButton} type="button" onClick={hideAmendSubmissionPopper}>
								Close
							</button>
						</Stack>
					</Box>
				)}
			</Popper>

			{/*I Want To, Check Submission Popper*/}
			<Popper
				style={{ zIndex: "10000" }}
				open={checkSubmissionStatusPopperOpen.value}
				anchorEl={anchorCheckSubmissionStatusPopper.value}
				placement="right"
				modifiers={[
					{
						name: "arrow",
						enabled: true,
						options: {
							element: checkSubmissionPopperArrowRef,
						},
					},
				]}
			>
				<span id="checkSubmissionPopperArrow" data-popper-arrow className={classes.arrow}></span>
				{dialogCheckSubmissionStatus && (
					<Box className={classes.popperBox}>
						<Stack>
							<Box
								className={classes.popperBody}
								dangerouslySetInnerHTML={{ __html: dialogCheckSubmissionStatus.value }}
							></Box>

							<button
								className={classes.popperCloseButton}
								type="button"
								onClick={hideCheckSubmissionStatusPopper}
							>
								Close
							</button>
						</Stack>
					</Box>
				)}
			</Popper>

			<ConfirmationMessageModal open={confirmationModalOpen} handleClose={handleClose} />

			<Box sx={{ flexGrow: 1, marginRight: "16px" }}>
				<Grid container spacing={1}>
					{/*Dashboard/Cases*/}
					<Grid item xs={12}>
						<Card raised={true}>
							<CardContent style={{ paddingTop: "3px", paddingBottom: "3px" }}>
								{/*Dashboard Header*/}
								<div className={classes.floatLeft} ref={refDashboard}>
									<Typography variant="h6" className={classes.title} noWrap>
										Dashboard
									</Typography>
									<Button
										style={{
											background: "none",
											outline: "none",
											padding: "0",
											position: "relative",
											bottom: "8px",
											justifyContent: "left",
											minWidth: "25px",
										}}
										onClick={handleClick}
									>
										<InfoOutlinedIcon className={classes.dashboardIconBackground} />
									</Button>
									<Overlay
										show={show}
										target={target}
										placement="right-start"
										container={refDashboard}
										containerPadding={20}
										rootClose
										onHide={() => setShow(false)}
									>
										<Popover id="popover-contained" className={classes.dashBoardStartedWidth}>
											<Popover.Header as="h3">
												<span style={{ color: "#000" }}>Dashboard</span>
											</Popover.Header>
											{stringContentData && (
												<Popover.Body
													dangerouslySetInnerHTML={{ __html: stringContentData.value }}
												></Popover.Body>
											)}
										</Popover>
									</Overlay>
								</div>

								{/*Case List*/}
								<Grid style={{ position: "relative" }} container spacing={2}>
									<Grid item xs={12} style={{ position: "relative", paddingTop: "2px" }}>
										<Paper className={classes.dashboardBackground}>
											<div className={classes.gridConstraints} id="dashboardCaseListGrid">
												<DashboardCaseListGrid
													downloadTemplate={downloadTemplate}
													selectCase={selectCase}
												/>
											</div>

											<Card className={classes.viewAll}>
												{hasCaseBookData ? (
													<Button
														className={classes.viewAllSubmission}
														onClick={viewAllCases}
														id="buttonViewAllCases"
													>
														view all cases
													</Button>
												) : (
													<p style={{ color: "red", textAlign: "left" }}>{PARTIAL_INFO_MESSAGE}</p>
												)}
											</Card>
										</Paper>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>

					{/*Submission Status*/}
					<Grid item xs={8}>
						<Card raised={true} style={{ height: "322px" }}>
							<CardContent>
								<Paper className={classes.dashboardBackground}>
									<Stack spacing={1}>

										{/* submission status header w/info icon */}
										<div className={classes.floatLeft} ref={ref}>
											<Typography variant="h6" className={classes.title} noWrap>
												Submission Status
											</Typography>

											<Button
												style={{
													background: "none",
													outline: "none",
													padding: "0",
													position: "relative",
													bottom: "8px",
													justifyContent: "left",
													minWidth: "25px",
												}}
												onClick={dashboardSubmissionStatusClick}
											>
												<InfoOutlinedIcon className={classes.dashboardIconBackground} />
											</Button>

											<Overlay
												show={dashboardSubmissionStatus}
												target={target}
												placement="right-start"
												container={ref}
												containerPadding={20}
												rootClose
												onHide={() => setShowDashboardSubmissionStatus(false)}
											>
												<Popover className={classes.dashBoardStartedWidth} id="popover-contained">
													<Popover.Header as="h3">
														<span style={{ color: "#000" }}>Submission Status</span>
													</Popover.Header>

													{dashboardSubmissionStatusText && (
														<Popover.Body
															dangerouslySetInnerHTML={{
																__html: dashboardSubmissionStatusText.value,
															}}
														></Popover.Body>
													)}
												</Popover>
											</Overlay>
										</div>

										{/*include all submissions...*/}
										<div className={classes.left}>
											<Tooltip
												title={
													<Typography style={{ fontSize: 14 }}>
														Include all submissions from Institution
													</Typography>
												}
												placement="top"
											>
												<FormControlLabel
													className={classes.title}
													style={{ margin: "0" }}
													control={
														<Switch
															id="InstitutionAccountToggleSwitch"
															checked={!submissionState.showUserSubmissions}
															onChange={handleChange}
															inputProps={{ "aria-label": "controlled" }}
														/>
													}
													label={"Include all submissions from Institution"}
													labelPlacement="start"
												/>
											</Tooltip>
										</div>

										{/* submission status cards */}
										<div>
											{cardCountsState.cardCounts?.started > 0 && (
												<div style={{ float: "left", position: "relative", marginRight: "20px" }}>
													<div style={{ position: "absolute", top: "5px", right: "-10px" }} ref={ref}>
														<Button className={classes.dashboardButtonHover}
															
															onClick={dashboardStartedHandleClick}
														>
															<InfoOutlinedIcon className={classes.dashboardStartedIcon} />
														</Button>
														<Overlay
															show={dashboardStarted}
															target={target}
															placement="right-start"
															container={ref}
															containerPadding={20}
															rootClose
															onHide={() => setShowDashboardStarted(false)}
														>
															<Popover
																className={classes.dashBoardStartedWidth}
																id="popover-contained"
															>
																<Popover.Header as="h3">
																	<span style={{ color: "#000" }}>Started</span>
																</Popover.Header>
																{dashboardStartedStringContentData && (
																	<Popover.Body
																		dangerouslySetInnerHTML={{
																			__html: dashboardStartedStringContentData.value,
																		}}
																	></Popover.Body>
																)}
															</Popover>
														</Overlay>
													</div>
													<Card className={[classes.startedCard, classes.card].join(" ")}>
														<CardActionArea
															id="Started_Card_Action"
															className={classes.hoverCard}
															component={RouterLink}
															to={`/SubmissionList/12`}
														>
															<CardActions
																className={classes.commonCardOuter}
																disableSpacing
																sx={{ justifyContent: "center", padding: "0" }}
															>
																<IconButton className={classes.startedIcon} component="div">
																	<SendIcon className={classes.startedIconBackground} />
																</IconButton>
															</CardActions>
															<CardContent sx={{ justifyContent: "center", padding: "0" }}>
																<Typography
																	className={classes.cardContentTitle}
																	component="p"
																	variant="body2"
																	align="center"
																	color="text.primary"
																	id="Started_Card_Text"
																>
																	Started
																</Typography>
																<Typography
																	variant="body2"
																	component="p"
																	className={classes.startedCardValue}
																	align="center"
																	id="Started_Card_Count"
																>
																	{cardCountsState.cardCounts?.started || 0}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>
												</div>
											)}

											{processingCount > 0 && (
												<div style={{ float: "left", position: "relative", marginRight: "20px" }}>
													<div style={{ position: "absolute", top: "5px", right: "-10px" }} ref={ref}>
														<Button className={classes.dashboardButtonHover}
															
															onClick={dashboardInProcessHandleClick}
														>
															<InfoOutlinedIcon className={classes.dashboardStartedIcon} />
														</Button>
														<Overlay
															show={dashboardInProcess}
															target={target}
															placement="right-start"
															container={ref}
															containerPadding={20}
															rootClose
															onHide={() => setShowDashboardInProcess(false)}
														>
															<Popover
																className={classes.dashBoardStartedWidth}
																id="popover-contained"
															>
																<Popover.Header as="h3">
																	<span style={{ color: "#000" }}>In Process</span>
																</Popover.Header>
																{dashboardInProcessStringContentData && (
																	<Popover.Body
																		dangerouslySetInnerHTML={{
																			__html: dashboardInProcessStringContentData.value,
																		}}
																	></Popover.Body>
																)}
															</Popover>
														</Overlay>
													</div>
													<Card className={[classes.processingCard, classes.card].join(" ")}>
														<CardActionArea
															id="InProcess_Card_Action"
															className={classes.hoverCard}
															component={RouterLink}
															to={`/SubmissionList/1`}
														>
															<CardActions
																className={classes.commonCardOuter}
																disableSpacing
																sx={{ justifyContent: "center", padding: "0" }}
															>
																<IconButton className={classes.iconBackground} component="div">
																	<PendingIcon />
																</IconButton>
															</CardActions>
															<CardContent sx={{ justifyContent: "center", padding: "0" }}>
																<Typography
																	className={classes.cardContentTitle}
																	variant="body2"
																	component="p"
																	align="center"
																	color="text.primary"
																	id="InProcess_Card_Text"
																>
																	In Process
																</Typography>
																<Typography
																	className="nCountText"
																	variant="body2"
																	component="p"
																	align="center"
																	id="InProcess_Card_Count"
																>
																	{processingCount}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>
												</div>
											)}

											{
												cardCountsState.cardCounts?.error > 0 && isInRole([EpiqUser]) &&
												<div style={{ float: "left", position: "relative", marginRight: '20px' }}>
													<div style={{ position: "absolute", top: "5px", right: "-10px" }} ref={ref}>
															<Button className={classes.dashboardButtonHover} onClick={dashboardErrorHandleClick}><InfoOutlinedIcon className={classes.dashboardErrorIcon} /></Button>
														<Overlay
															show={dashboardError}
															target={target}
															placement="right-start"
															container={ref}
															containerPadding={20}
															rootClose
															onHide={() => setShowDashboardError(false)}
														>
															<Popover className={classes.dashBoardStartedWidth} id="popover-contained">
																<Popover.Header as="h3"><span style={{ color: "#000" }}>Error</span></Popover.Header>
																{
																	dashboardErrorStringContentData && (
																		<Popover.Body dangerouslySetInnerHTML={{ __html: dashboardErrorStringContentData.value }}></Popover.Body>
																	)
																}
															</Popover>
														</Overlay>
													</div>
													<Card className={[classes.errorCard, classes.card].join(" ")}>
														<CardActionArea
															id="Error_Card_Action"
															className={classes.hoverCardError}
															component={RouterLink}
															to={`/SubmissionList/8`}
														>
															<CardActions className={classes.commonCardOuter} disableSpacing sx={{ justifyContent: "center", padding: "0" }}>
																	<IconButton className={classes.errorIcon} component="div">
																	<ErrorIcon />
																</IconButton>
															</CardActions>
															<CardContent sx={{ justifyContent: "center", padding: "0" }}>
																<Typography
																		className={classes.loadedCardContentErrorTitle}
																		variant="body2"
																		component="p"
																		align="center"
																		color="text.primary"
																		id="Error_Card_Text"
																>
																	Error
																</Typography>
																	<Typography className={classes.errorCardTextColor} variant="body2" component="p" align="center" id="Error_Card_Count">
																	{cardCountsState.cardCounts?.error}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>
												</div>
											}

											{cardCountsState.cardCounts?.error > 0 && isInRole([Nominee]) && (
												<div style={{ float: "left", position: "relative", marginRight: "20px" }}>
													<div style={{ position: "absolute", top: "5px", right: "-10px" }} ref={ref}>
														<Button className={classes.dashboardButtonHover}
															
															onClick={dashboardNomineePendingHandleClick}
														>
															<InfoOutlinedIcon className={classes.dashboardStartedIcon} />
														</Button>
														<Overlay
															show={dashboardNomineePending}
															target={target}
															placement="right-start"
															container={ref}
															containerPadding={20}
															rootClose
															onHide={() => setShowDashboardNomineePending(false)}
														>
															<Popover
																className={classes.dashBoardStartedWidth}
																id="popover-contained"
															>
																<Popover.Header as="h3">
																	<span style={{ color: "#000" }}>
																		{SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE}
																	</span>
																</Popover.Header>
																{dashboardPendingStringContentData && (
																	<Popover.Body
																		dangerouslySetInnerHTML={{
																			__html: dashboardPendingStringContentData.value,
																		}}
																	></Popover.Body>
																)}
															</Popover>
														</Overlay>
													</div>
													<Card className={[classes.pendingCard, classes.card].join(" ")}>
														<CardActionArea
															id="Pending_Card_Action"
															className={classes.hoverCard}
															component={RouterLink}
															to={`/SubmissionList/8`}
														>
															<CardActions
																className={classes.commonCardOuter}
																disableSpacing
																sx={{ justifyContent: "center", padding: "0" }}
															>
																<IconButton
																	style={{ color: "#fff", borderColor: "#fff" }}
																	className={classes.pendingIcon}
																	component="div"
																>
																	<PendingActionsIcon />
																</IconButton>
															</CardActions>
															<CardContent sx={{ justifyContent: "center", padding: "0" }}>
																<Typography
																	className={classes.loadedCardContentTitle}
																	variant="body2"
																	component="p"
																	align="center"
																	color="text.primary"
																	id="Pending_Card_Name"
																	style={{ color: "#fff" }}
																>
																	{SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE}
																</Typography>
																<Typography
																	sx={{ color: "#fff" }}
																	className="nCountText"
																	variant="body2"
																	component="p"
																	align="center"
																	id="Pending_Card_Count"
																>
																	{cardCountsState.cardCounts?.error}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>
												</div>
											)}

											{
												<div style={{ float: "left", position: "relative", marginRight: "20px" }}>
													<div style={{ position: "absolute", top: "5px", right: "-10px" }} ref={ref}>
														<Button className={classes.dashboardButtonHover}
															
															onClick={dashboardPartiallyLoadedHandleClick}
														>
															<InfoOutlinedIcon className={classes.dashboardStartedIcon} />
														</Button>
														<Overlay
															show={dashboardPartiallyLoaded}
															target={target}
															placement="right-start"
															container={ref}
															containerPadding={20}
															rootClose
															onHide={() => setShowDashboardPartiallyLoaded(false)}
														>
															<Popover
																className={classes.dashBoardStartedWidth}
																id="popover-contained"
															>
																<Popover.Header as="h3">
																	<span style={{ color: "#000" }}>Partially Loaded</span>
																</Popover.Header>
																{dashboardPartiallyLoadedStringContentData && (
																	<Popover.Body
																		dangerouslySetInnerHTML={{
																			__html: dashboardPartiallyLoadedStringContentData.value,
																		}}
																	></Popover.Body>
																)}
															</Popover>
														</Overlay>
													</div>
													<Card className={[classes.partiallyCard, classes.card].join(" ")}>
														<CardActionArea
															id="PartiallyLoaded_Card_Action"
															className={classes.hoverCard}
															component={RouterLink}
															to={`/SubmissionList/11`}
															sx={{ padding: "0 20px 20px 20px" }}
														>
															<CardActions
																className={classes.commonCardOuter}
																disableSpacing
																sx={{ justifyContent: "center", padding: "0" }}
															>
																<IconButton className={classes.iconBackground} component="div">
																	<AutorenewIcon />
																</IconButton>
															</CardActions>
															<CardContent sx={{ justifyContent: "center", padding: "0" }}>
																<Typography
																	className={classes.cardContentTitle}
																	variant="body2"
																	component="p"
																	align="center"
																	color="text.primary"
																	id="PartiallyLoaded_Card_Text"
																>
																	Partially Loaded
																</Typography>
																<Typography
																	className="nCountText"
																	variant="body2"
																	component="p"
																	align="center"
																	id="PartiallyLoaded_Card_Count"
																>
																	{cardCountsState.cardCounts?.partiallyLoaded || 0}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>
												</div>
											}

											{
												<div style={{ float: "left", position: "relative", marginRight: "20px" }}>
													<div style={{ position: "absolute", top: "5px", right: "-10px" }} ref={ref}>
														<Button className={classes.dashboardButtonHover}
															
															onClick={dashboardCloseHandleClick}
														>
															<InfoOutlinedIcon className={classes.dashboardStartedIcon} />
														</Button>
														<Overlay
															show={dashboardClose}
															target={target}
															placement="right-start"
															container={ref}
															containerPadding={20}
															rootClose
															onHide={() => setShowDashboardClose(false)}
														>
															<Popover
																className={classes.dashBoardStartedWidth}
																id="popover-contained"
															>
																<Popover.Header as="h3">
																	<span style={{ color: "#000" }}>Rejected</span>
																</Popover.Header>
																{dashboardCloseStringContentData && (
																	<Popover.Body
																		dangerouslySetInnerHTML={{
																			__html: dashboardCloseStringContentData.value,
																		}}
																	></Popover.Body>
																)}
															</Popover>
														</Overlay>
													</div>
													<Card className={[classes.rejectedCard, classes.card].join(" ")}>
														<CardActionArea
															id="Rejected_Card_Action"
															className={classes.hoverCard}
															component={RouterLink}
															sx={{ padding: "0 20px 20px 20px" }}
															to={`/SubmissionList/9`}
														>
															<CardActions
																className={classes.commonCardOuter}
																disableSpacing
																sx={{ justifyContent: "center", padding: "0" }}
															>
																<IconButton className={classes.rejectedIcon} component="div">
																	<CloseRoundedIcon
																		className={classes.rejectedIconBackground}
																	/>
																</IconButton>
															</CardActions>
															<CardContent sx={{ justifyContent: "center", padding: "0" }}>
																<Typography
																	className={classes.cardContentTitle}
																	component="p"
																	variant="body2"
																	align="center"
																	color="text.primary"
																	id="Rejected_Card_Text"
																>
																	Rejected
																</Typography>
																<Typography
																	className="nCountText"
																	variant="body2"
																	component="p"
																	align="center"
																	id="Rejected_Card_Count"
																>
																	{cardCountsState.cardCounts?.rejected || 0}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>
												</div>
											}

											{
												<div style={{ float: "left", position: "relative", marginRight: '20px' }}>
													<div style={{ position: "absolute", top: "5px", right: "-10px" }} ref={ref}>
														<Button className={classes.dashboardButtonHover}  onClick={dashboardLoadedHandleClick}><InfoOutlinedIcon className={classes.dashboardLoadedIcon} /></Button>
														<Overlay
															show={dashboardLoaded}
															target={target}
															placement="right-start"
															container={ref}
															containerPadding={20}
															rootClose
															onHide={() => setShowDashboardLoaded(false)}
														>
															<Popover className={classes.dashBoardStartedWidth} id="popover-contained">
																<Popover.Header as="h3"><span>Loaded</span></Popover.Header>
																{
																	dashboardLoadedStringContentData && (
																		<Popover.Body dangerouslySetInnerHTML={{ __html: dashboardLoadedStringContentData.value }}></Popover.Body>
																	)
																}
															</Popover>
														</Overlay>
													</div>
													<Card className={[classes.loadedCard, classes.card].join(" ")}>
														<CardActionArea
															id="Loaded_Card_Action"
															className={classes.hoverCard}
															component={RouterLink}
															sx={{ padding: "0 20px 20px 20px" }}
															to={`/SubmissionList/7`}
														>
															<CardActions className={classes.commonCardOuter} disableSpacing sx={{ justifyContent: "center", padding: "0" }}>
																<IconButton className={classes.loadedIcon} component="div">
																	<DoneRoundedIcon className={classes.loadedIconBackground} />
																</IconButton>
															</CardActions>
															<CardContent sx={{ justifyContent: "center", padding: "0" }}>
																<Typography
																	className={classes.loadedCardContentLoadedTitle}
																	component="p"
																	variant="body2"
																	align="center"
																	color="text.primary"
																	id="Loaded_Card_Text"
																>
																	Loaded
																</Typography>
																<Typography
																	variant="body2"
																	component="p"
																	className={classes.loadedCardValue}
																	align="center"
																	id="Loaded_Card_Count"
																>
																	{cardCountsState.cardCounts?.loaded || 0}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>

												</div>
											}

											{cardCountsState.cardCounts?.deficient > 0 && (
												<div style={{ float: "left", position: "relative", marginRight: "0px" }}>
													<div style={{ position: "absolute", top: "5px", right: "-10px" }} ref={ref}>
														<Button className={classes.dashboardButtonHover}
															
															onClick={dashboardDeficientHandleClick}
														>
															<InfoOutlinedIcon className={classes.dashboardDeficientIcon} />
														</Button>
														<Overlay
															show={dashboardDeficient}
															target={target}
															placement="right-start"
															container={ref}
															containerPadding={20}
															rootClose
															onHide={() => setShowDashboardDeficient(false)}
														>
															<Popover
																className={classes.dashBoardDeficientWidth}
																id="popover-contained"
															>
																<Popover.Header as="h3">
																	<span style={{ color: "#000" }}>Deficient</span>
																</Popover.Header>
																{dashboardDeficientStringContentData && (
																	<Popover.Body
																		dangerouslySetInnerHTML={{
																			__html: dashboardDeficientStringContentData.value,
																		}}
																	></Popover.Body>
																)}
															</Popover>
														</Overlay>
													</div>
													<Card className={[classes.deficientCard, classes.card].join(" ")}>
														<CardActionArea
															id="Deficient_Card_Action"
															className={classes.hoverCard}
															component={RouterLink}
															to={`/SubmissionList/13`}
														>
															<CardActions
																className={classes.commonCardOuter}
																disableSpacing
																sx={{ justifyContent: "center", padding: "0" }}
															>
																<IconButton className={classes.deficientIcon} component="div">
																	<RemoveCircleIcon
																		className={classes.deficientIconBackground}
																	/>
																</IconButton>
															</CardActions>
															<CardContent sx={{ justifyContent: "center", padding: "0" }}>
																<Typography
																	className={classes.cardContentTitle}
																	component="p"
																	variant="body2"
																	align="center"
																	color="text.primary"
																	id="Deficient_Card_Text"
																>
																	Deficient
																</Typography>
																<Typography
																	variant="body2"
																	component="p"
																	className={classes.deficientCardValue}
																	align="center"
																	id="Deficient_Card_Count"
																>
																	{cardCountsState.cardCounts?.deficient || 0}
																</Typography>
															</CardContent>
														</CardActionArea>
													</Card>
												</div>
											)}
										</div>

										{/* view all submissions */}
										<div>
											<Card className={classes.viewAll}>
												{cardCountsState.error ? (
													<p style={{ color: "red", textAlign: "left" }}>{PARTIAL_INFO_MESSAGE}</p>
												) : (
													<Button
														className={classes.viewAllSubmission}
														onClick={viewAllSubmissions}
														id="ViewAllSubmission"
													>
														view all submissions
													</Button>
												)}
											</Card>
										</div>
									</Stack>
								</Paper>
							</CardContent>
						</Card>
					</Grid>

					{/*I Want To*/}
					<Grid item xs={4}>
						<Card raised={true} style={{ height: "322px" }}>
							<CardContent>
								<Paper className={classes.dashboardBackground}>
									<Stack spacing={2}>
										<div
											style={{
												fontSize: "16px",
												fontStyle: "italic",
												marginBottom: "0px",
												paddingLeft: "0",
												paddingTop: "0",
											}}
										>
											I want to:
										</div>

										<div className={classes.submissionRightCard}>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												spacing={1}
											>
												{labelDownloadSubmissionTemplate && labelDownloadSubmissionTemplate.value}
												<IconButton
													onClick={showDownloadSubmissionTemplatePopper}
													className={classes.submissionRightCardIcon}
												>
													<ChevronRightIcon sx={{ fontSize: "30px" }} />
												</IconButton>
											</Stack>
										</div>

										<div className={classes.submissionRightCard}>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												spacing={1}
											>
												{labelAddSubmission && labelAddSubmission.value}
												<IconButton
													onClick={showAddSubmissionPopper}
													className={classes.submissionRightCardIcon}
												>
													<ChevronRightIcon sx={{ fontSize: "30px" }} />
												</IconButton>
											</Stack>
										</div>

										<div className={classes.submissionRightCard}>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												spacing={1}
											>
												{labelAmendSubmission && labelAmendSubmission.value}
												<IconButton
													onClick={showAmendSubmissionPopper}
													className={classes.submissionRightCardIcon}
												>
													<ChevronRightIcon sx={{ fontSize: "30px" }} />
												</IconButton>
											</Stack>
										</div>

										<div className={classes.submissionRightCard}>
											<Stack
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												spacing={1}
											>
												{labelCheckSubmissionStatus && labelCheckSubmissionStatus.value}
												<IconButton
													onClick={showCheckSubmissionStatusPopper}
													className={classes.submissionRightCardIcon}
												>
													<ChevronRightIcon sx={{ fontSize: "30px" }} />
												</IconButton>
											</Stack>
										</div>

										<div className={classes.submissionRightCardLast}>
											<div className={classes.submissionRightCardBottomOuter}>
												{urlTutorial && (
													<YouTubeIcon
														className={classes.submissionRightCardBottomIcon}
														onClick={async () => {
															await window.open(urlTutorial.value, "_blank");
															return false;
														}}
													/>
												)}
												<span>Tutorial</span>
											</div>

											<div
												style={{ marginRight: "0px" }}
												className={classes.submissionRightCardBottomOuter}
											>
												{urlQuickStart && (
													<ImportContactsIcon
														style={{ position: "relative", left: "7px" }}
														className={classes.submissionRightCardBottomIcon}
														onClick={async () => {
															await window.open(urlQuickStart.value, "_blank");
															return false;
														}}
													/>
												)}
												<span>Quick Start</span>
											</div>
										</div>
									</Stack>
								</Paper>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>

			{claimFileModalIsOpen && claimFileModalProps && (
				<ClaimFileUploadModal modalOpen={claimFileModalIsOpen} claimFileModalProps={claimFileModalProps} />
			)}

			{supportingDocFilesModalIsOpen && supportingDocModalProps && (
				<SupportingDocFilesUploadModal
					modalOpen={supportingDocFilesModalIsOpen}
					supportingDocModalProps={supportingDocModalProps}
				/>
			)}

			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDownloading}>
				<Stack alignItems="center">
					<CircularProgress color="inherit" />
					<br />

					<Typography variant="h4" color="#fff">
						Downloading document...
					</Typography>
				</Stack>
			</Backdrop>
		</div>
	);
}
