import {createTheme, Theme } from "@mui/material";
import {SecurityColors} from '../../types/security-color-type';

const lightThemeColors: SecurityColors = {
  primarymain: "#253746",
  primarydark: "#253746",
  primarylight: "#253746",
  secondarymain: "",
  secondarydark: "",
  secondarylight: "",
  textPrimary: "#000D13",
  white: "#FFFFFF",
  black: "#000000",
  background: "#E9EBEC !important",
  paper: "#fff",
  disabled: "rgba(FF, FF, FF, 0.5)",
  header: "#253746",
  nav: "#fff",
  toolbarDefault: "#1A272D",
  linkDefault: "#006B86",
  infoIcon: "#D3D3D3",
  menu: "#22333B",
  hoverBackground: "#22333B",
  hoverText: "#ffffff",
  secondarycolor: "#ff0000",
  secondarybackground: "#ff0000",
  textSecondary:"",
  secondary:"",
  filterBoxColor: "#d5d5d5",
  subTableColor: "#eeeeee"
};

const lightTheme:Theme = createTheme({
    palette: {
        mode: 'light',
        text: {
          secondary: lightThemeColors.white
        },
        primary: {
          main: lightThemeColors.primarymain,
          dark: lightThemeColors.primarydark,
          light: lightThemeColors.primarylight,
          contrastText: '#fff',
        },
        secondary: {
          main: lightThemeColors.secondarybackground,
          dark: lightThemeColors.secondarybackground,
          light: lightThemeColors.secondarybackground,
          contrastText: '#fff',
        },
        background: {
          default: lightThemeColors.background,
          paper: lightThemeColors.paper,
        },
      },
      typography: {
        fontFamily: `'Roboto', sans-serif;`,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
    filterBox: lightThemeColors.filterBoxColor,
    subTableColor: lightThemeColors.subTableColor

});

declare module '@mui/material/styles' {
  interface Theme {
    filterBox?: string;
    subTableColor?: string;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    filterBox?: string;
    subTableColor?: string;
  }
}

export default lightTheme;
