import { Card, CardContent, Grid, Theme, Typography } from '@mui/material';
import { EpiqProjectManager, EpiqTechSupport, EpiqUser } from '../../constants/roles';
import { IInstitution, IInstitutionListState } from '../../types/types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import SecuritiesTable, { IColumnHeader, ISecuritiesTableFieldMap, ISorter } from '../../components/Shared/SecuritiesTable/SecuritiesTable';
import { UserContext, UserContextType } from '../../modules/User/UserContext'
import { createFilterDTOsFromColumnHeaders, headerContainsFilterValues } from '../../utilities/filterCriteria';
import { useDispatch, useSelector } from 'react-redux';

import { IFilterDTO } from '../../types/types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link as RouterLink } from 'react-router-dom';
import { STANDARD_KABOB_HEIGHT } from '../../constants/CommonConstants';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { getInstitutionList } from './institutionlist.actions';
import makeStyles from '@mui/styles/makeStyles';
import { formatPhoneNumber } from '../../utilities/utilities';

const useStyles = makeStyles((theme: Theme) => ({
	button: {
		background: "#006B86",
		size: 'small',
		margin: theme.spacing(1),
		color: 'white'
	},
	textLink: {
		color: theme.palette.text.primary,
		padding: '0.5em'
	},
	actionColumn: {
		width: '275px',
		"&:disabled": {
			display: 'none',
		}
	},
	disabled: {
		display: 'none'
	},
	kabobIconEnable: {
		color: "#087C9B",
		filter: "dropShadow(1px 1px 2px rgba(0, 0, 0, 0.44))",
		"&:disabled": {
			color: "#BCC0C5",
		},
	}
}));

const institutionListFilterColumns: IColumnHeader[] = [
	{ field: 'name', label: 'Institution Name', parameterName: 'name', type: 'string', value: '', allowFilter: true },
	{ field: 'email', label: 'Group Email', parameterName: 'email', type: 'string', value: '', allowFilter: true },
	{ field: 'type', label: 'Institution Type', parameterName: 'type', type: 'string', value: '', allowFilter: true },
	{ field: 'address', label: 'Address', parameterName: 'address', type: 'string', value: '', allowFilter: true },
	{ field: 'phone', label: 'Phone', parameterName: 'phone', type: 'string', value: '', allowFilter: true },
	{ field: 'actions', label: 'Actions' }
];

const defaultSort: ISorter = {
	property: 'name',
	order: 'desc'
}

let sortInfo: ISorter = { ...defaultSort };

const InstitutionListGrid = () => {
	const dispatch = useDispatch();
	const institutionListState: IInstitutionListState = useSelector((state: any) => state.institutionListState);
	const classes = useStyles();
	const { isAuthenticated, isInitialized, isInRole } = useContext(UserContext) as UserContextType;

	const initialFilter = headerContainsFilterValues(institutionListFilterColumns) ?
		createFilterDTOsFromColumnHeaders(institutionListFilterColumns) : [];

	const [kabobsMenu, setKabobsMenu] = React.useState<null | HTMLElement>(null);
	let [selectedRowIndex, setSelectedRowIndex] = useState(-1);

	const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		setKabobsMenu(event.currentTarget);
		setSelectedRowIndex(index);
	};

	const handleResetFilter = () => {
		sortInfo = { ...defaultSort };
	};

	const handleClose = () => {
		setKabobsMenu(null);
		setSelectedRowIndex(-1);
	};

	useEffect(() => {
		dispatch(getInstitutionList({ filter: initialFilter }));
	}, [dispatch, isAuthenticated, isInitialized, isInRole]);

	const fetchFilteredList = (filters: IFilterDTO[]) => {
		dispatch(getInstitutionList({ filter: filters }));
	}

	const buildListRows = (): ISecuritiesTableFieldMap[] => {
		var institutionListRows: ISecuritiesTableFieldMap[] = [];
		institutionListState && institutionListState.institutionList && institutionListState.institutionList.forEach((item: IInstitution, index) => {
			let arrAddress = [];
			arrAddress.push(item.address1);
			arrAddress.push(item.address2);
			arrAddress.push(item.address3);
			arrAddress.push(item.city);
			arrAddress.push(item.stateProvince);
			arrAddress.push(item.country);
			arrAddress.push(item.zipCode);

			let address = arrAddress.filter(val => val).join();

			let newItem: ISecuritiesTableFieldMap = {
				name: { type: "string", value: item.name || "", sortValue: item.name },
				type: { type: "string", value: item.institutionTypeName || "", sortValue: item.institutionTypeName },
				email: { type: "string", value: item.emailAddress || "", sortValue: item.emailAddress },
				address: {
					type: "string",
					value: <Tooltip title={<Typography style={{ fontSize: 14 }}>{address.toUpperCase()}</Typography>} placement="top">
						<span>{item.address1}</span>
					</Tooltip>, sortValue: item.normalizedAddress
				},
				phone: { type: "string", value: formatPhoneNumber(item.phone) || "", sortValue: item.phone },
				actions: {
					type: "link",
					value: <Fragment>
						<div className={classes.actionColumn}>
							<IconButton
								aria-label="more"
								className={classes.kabobIconEnable}
								id={"long-button-" + index}
								aria-haspopup="true"
								onClick={(event: React.MouseEvent<HTMLElement>) => {
									handleClick(event, index);
								}}
								disabled={!isInRole([EpiqProjectManager, EpiqTechSupport, EpiqUser])}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id={"long-menu-" + index}
								MenuListProps={{
									'aria-labelledby': 'long-button' + index,
								}}
								anchorEl={kabobsMenu}
								open={selectedRowIndex === index}
								onClose={handleClose}
								onClick={handleClose}
								PaperProps={{
									style: {
										maxHeight: STANDARD_KABOB_HEIGHT * 4.5,
										width: '20ch',
									},
								}}
							>

								<div>
									<MenuItem
										component={RouterLink}
										to={`InstitutionDetail/${item.id}`}
									>Edit</MenuItem>

								</div>

							</Menu>
						</div>
					</Fragment>
				}
			}

			institutionListRows.push(newItem);
		});

		return institutionListRows;
	};

	let rows = null as ISecuritiesTableFieldMap[] | null;

	if (!institutionListState.error)
		rows = buildListRows();

	return (
		<>
			{(institutionListState.isLoading === true) ?
				<Card>
					<CardContent>
						{[...Array(5)].map((elementInArray, index) => (
							<Grid container spacing={2} key={index}>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={4}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
							</Grid>
						))}
					</CardContent>
				</Card>
				: <SecuritiesTable
					columns={institutionListFilterColumns}
					rows={rows}
					sorter={sortInfo}
					paging={'client'}
					showFilter={true}
					dataFetchFunc={fetchFilteredList}
					resetFilterFunc={handleResetFilter}
				/>}

		</>
	)
};

export default InstitutionListGrid;