import React, { useState, useEffect } from 'react';
import {
	Checkbox,
	TableCell,
	TableRow,
	Theme,
	Collapse,
	Typography,
	Box
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { IColumnHeader, ISecuritiesTableField } from './SecuritiesTable';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import { format12hourDateTime } from "../../../utilities/utilities";
import SubmissionDetailSummary from '../../../modules/Submissions/SubmissionSummary';

interface SubmissionDetailCollapsibleTableRowProps {
	columns: IColumnHeader[],
	row: any,
	index: number,
	subTableTitle?: string,
	openOnLoad?: boolean
}

const renderField = (field: ISecuritiesTableField): any => {


	switch (field.type) {

		case "datetime":
			if (field.value === null) {
				return '';
			}

			return format12hourDateTime(new Date(field.value));

		case "date":
			if (field.value === null) {
				return '';
			}

			let date = new Date(field.value);
			return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`

		case "boolean":
			if (field.value === null) {
				return '';
			}

			return field.value ? <Checkbox checked={true} /> : <Checkbox checked={false} />;

		default:
			return field.value
	}
}

const createLocalStyles = makeStyles((theme: Theme) =>
	createStyles({
		tablecell: {
			whiteSpace: "pre-wrap",
			wordWrap: "break-word",
			overflowWrap: "break-word"
		},
		namecell: {
			maxWidth: '380px',
			overflowWrap: "break-word",
			whiteSpace: "pre-wrap",
			wordWrap: "break-word"
		},
		arrowcell: {
			'&:focus': {
				outline: 'none',
			}
		}
	}),
);

export default function SubmissionDetailCollapsibleTableRow({
		columns,
		row,
		index,
		subTableTitle,
		openOnLoad
	}: SubmissionDetailCollapsibleTableRowProps) {
	const classes = createLocalStyles();
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (openOnLoad) {
			setOpen(true);
        }

	}, []);

	return (
		<>
			<TableRow key={index} id={ 'tab-submissions-' + row.id.value }>
				{columns.map((column, idx) => {
					if (idx === 0) {
						return <TableCell size="small" key={idx} className={column.field === "name" ? classes.namecell : classes.tablecell} >
							{
								row.isNonClaimDeficiencyResponse.value === false
									?
									(
										<IconButton
											className={classes.arrowcell}
											aria-label="expand row"
											size="small"
											onClick={() => {
												setOpen(!open);
											}}

										>
											{open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
										</IconButton>
									)
									:
									(
										<Icon sx={{ marginLeft: '9px' }}>

										</Icon>
									)
							}
							{renderField(row[column.field])}
						</TableCell>
					}
					if (idx === 6) {

					}

					return <TableCell size="small" key={idx} className={column.field === "name" ? classes.namecell : classes.tablecell} >
						{renderField(row[column.field])}
					</TableCell>

				})}
			</TableRow>

			<TableRow>
				<TableCell key={0}
					sx={{
						backgroundColor: (theme) => theme.subTableColor,
						paddingBottom: 0,
						paddingTop: 0
					}}
					colSpan={columns.length}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box sx={{ margin: 1 }}>
							{subTableTitle && <Typography variant="h6" gutterBottom component="div">
								{subTableTitle}
							</Typography>
							}
							<SubmissionDetailSummary submissionId={ parseInt(row.id.value, 10) } />
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}