import { IResponse } from '../../types/types';
import { AlertType } from "../../types/enums";
import { showAlert } from '../Alerts/alerts.actions';

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Icon,
    Modal,
    Theme
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { deleteSubmissionAction, clearDeleteSubmissionAction } from './claimFile.actions';

const createLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
        },
        claimmodal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            justifyContent: 'center',
            width: '700px;'
        },
        modalHeader: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 500,
            color: theme.palette.common.white,
            textEmphasisColor: theme.palette.common.white,
            font: '16',
        },
        modalHeaderTitle: {
            paddingBottom: '5px;'
        },
        title: {
            flexGrow: 1,
            color: 'black'
        },
        cardAction: {
            justifyContent: 'flex-end'
        },
        primaryBtn: {
            color: '#fff!important',
            backgroundColor: 'red!important'
        },
        secondaryBtn: {
            color: 'blue',
            backgroundColor: '#fff!important',
            border: '1px solid',
            borderColor: 'blue'
        },
        paragraph: {
            marginBottom: '-5px'
        },
        icon: {
            color: 'red',
            fontSize: '30px',
            marginBottom: '-5px'
        },
    }),
);


interface ICancelSubmissionModalProps {
    modalOpen: boolean,
    handleCancelSubmissionClose: any,
    handleSupportingDocModalClose: any,
    submissionId: number,
    clearDocModal: any,
    setSubmissionIsCanceled: any,
    submissionDescription: string
}

const CancelSubmissionModal = ({ modalOpen, handleCancelSubmissionClose, handleSupportingDocModalClose, submissionId, clearDocModal, setSubmissionIsCanceled, submissionDescription }: ICancelSubmissionModalProps) => {
    const dispatch = useDispatch();
    const classes = createLocalStyles();
    const [deleteSubmissionId, setSubmissionId] = useState<number>(0);
    const deleteSubmissionResponse: IResponse = useSelector((state: any) => state.claimFileState.deleteSubmissionResponse);

    const handleModalClosed = useCallback(() => {
        dispatch(clearDeleteSubmissionAction());
        handleCancelSubmissionClose(false);

    }, [handleCancelSubmissionClose]);

    useEffect(() => {

        if (submissionId) {

            setSubmissionId(submissionId);

        }

        if (deleteSubmissionResponse && deleteSubmissionResponse.success) {

            closeCancelSubmissionModal();

        }


    }, [submissionId, deleteSubmissionResponse]);


    const cancelDeleteSubmission = (): void => {
        handleModalClosed();
    };


    const deleteSubmission = (): void => {

        if (deleteSubmissionId) {
            let deleteSubmissionPayload = {
                submissionId: deleteSubmissionId
            };

            dispatch(deleteSubmissionAction(deleteSubmissionPayload));
            setSubmissionIsCanceled(true);
        }
    };

    const closeCancelSubmissionModal = (): void => {

        dispatch(
            showAlert({
                hidden: false,
                message: `Submission ${submissionDescription} deleted`,
                severity: AlertType.success
            })
        );

        clearDocModal();
        handleSupportingDocModalClose(false);
        handleModalClosed();
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClosed}>
            <Card className={classes.claimmodal}>
                <CardHeader
                    title={<span className="material-symbols-outlined"><Icon className={classes.icon}>warning</Icon>Delete Submission</span>}
                    classes={{ title: classes.modalHeaderTitle }}
                    action={<Icon onClick={() => { handleModalClosed() }}>close</Icon>}>
                </CardHeader>
                <CardContent>
                    <Typography variant="h6" className={classes.title} noWrap>
                        Note: If you delete this submission it will no longer be available.
                    </Typography>
                    <Typography variant="h6" className={classes.title} noWrap>
                        You'll need to start over next time.
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardAction}>
                    <Button
                        classes={{ root: classes.primaryBtn, disabled: classes.primaryBtn }}
                        onClick={() => { deleteSubmission() }}
                        color="primary" >
                        Delete
                    </Button>
                    <Button
                        onClick={() => { cancelDeleteSubmission() }}
                        classes={{ root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
                        color="secondary">
                        Cancel
                    </Button>
                </CardActions>
            </Card >
        </Modal >
    );
};

export default CancelSubmissionModal;