import { PARTIAL_INFO_MESSAGE, STANDARD_KABOB_HEIGHT } from '../../constants/CommonConstants';
import { EpiqProjectManager, EpiqTechSupport, Nominee } from '../../constants/roles';
import { SubmissionTypeDisplay } from "../../types/enums";
import { IFilterDTO, ICaseState } from '../../types/types';

import {
	Card,
	CardContent,
	Grid,
	Link as MuiLink,
	Theme,
	Tooltip,
	Typography
} from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import SecuritiesTable, { IColumnHeader, ISecuritiesTableFieldMap, ISorter } from '../../components/Shared/SecuritiesTable/SecuritiesTable';
import { UserContext, UserContextType } from '../User/UserContext';
import { createFilterDTOsFromColumnHeaders, headerContainsFilterValues } from '../../utilities/filterCriteria';
import { useDispatch, useSelector } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import { getCases } from './cases.actions';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
	button: {
		background: "#006B86",
		size: 'small',
		margin: theme.spacing(1),
		color: 'white'
	},
	textLink: {
		color: theme.palette.text.primary,
		padding: '0.5em'
	},
	commonIcon: {
		background: "#006B86",
		width: "35px",
		height: "35px",
		borderRadius: "100%",
		color: "#fff",
		margin: "0 10px 0 0",
		padding: "0px",
		display: "block",
		"&:hover": {
			background: "#006B86",
		},
		textAlign: "center",
	},
	links: {
		color: "#006B86"
	},
	kabobIconEnable: {
		color: "#087C9B",
		filter: "dropShadow(1px 1px 2px rgba(0, 0, 0, 0.44))",
		"&:disabled": {
			color: "#BCC0C5",
		},
	}
}));

const caseListFilterColumns: IColumnHeader[] = [
	{ field: 'number', label: 'Case Number', parameterName: 'caseNumber', type: 'string', value: '', allowFilter: true, minWidth: '120px' },
	{ field: 'name', label: 'Case Name', parameterName: 'caseName', type: 'string', value: '', allowFilter: true, minWidth: '100%' },
	{ field: 'classPeriodFrom', label: 'Class Period Start', parameterName: 'classPeriodFrom', type: 'date', value: '', allowFilter: true },
	{ field: 'classPeriodTo', label: 'Class Period End', parameterName: 'classPeriodTo', type: 'date', value: '', allowFilter: true },
	{ field: 'claimsDeadline', label: 'Filing Deadline', parameterName: 'claimsDeadline', type: 'date', value: '', allowFilter: true },
	{ field: 'isAcceptingSubmissions', label: 'Accepting Filings', parameterName: 'isAcceptingSubmissions', type: 'boolean', value: '', allowFilter: true },
	{ field: 'actions', label: 'Actions' }
];

const defaultSort: ISorter = {
	property: 'claimsDeadline',
	order: 'desc'
};

let sortInfo: ISorter = { ...defaultSort };

const defaultFilter: IFilterDTO[] = [
	{
		'parameter': 'isAcceptingSubmissions',
		'type': 'boolean',
		'value': ''
	}
];

interface CaseListGridProps {
	selectCase: Function;
	downloadTemplate: Function;
}

const CaseListGrid = ({ selectCase, downloadTemplate }: CaseListGridProps) => {
	const { isAuthenticated, isInitialized, isInRole, user } = useContext(UserContext) as UserContextType;
	const dispatch = useDispatch();
	const classes = useStyles();
	const caseState: ICaseState = useSelector((state: any) => state.caseState);
	let hasCaseBookData = caseState.cases && caseState.cases.length > 0 ? caseState.cases.find((c) => c.claimsDeadline) : true;
	let canSubmitClaim = false;

	if (isInitialized && isAuthenticated) {

		if (isInRole([EpiqProjectManager, EpiqTechSupport, Nominee]))
			canSubmitClaim = true;

	}

	const initialFilter = headerContainsFilterValues(caseListFilterColumns) ?
		createFilterDTOsFromColumnHeaders(caseListFilterColumns) : defaultFilter;

	// ensure initialFilter always has the 'isOffshoreUser' filter

	if (user.isOffshoreUser) {
		initialFilter.push({
			'parameter': 'isOffshoreUser',
			'type': 'boolean',
			'value': 'true'
		});
	}

	useEffect(() => {
		dispatch(getCases({ filter: initialFilter }));
	}, [dispatch, user]);

	const fetchFilteredCaseList = (filters: IFilterDTO[]) => {
		if (user.isOffshoreUser) {
			filters.push({
				'parameter': 'isOffshoreUser',
				'type': 'boolean',
				'value': 'true'
			});
		}

		dispatch(getCases({ filter: filters }));
	}

	const [kabobsMenu, setKabobsMenu] = React.useState<null | HTMLElement>(null);
	let [selectedRowIndex, setSelectedRowIndex] = useState(-1);

	const handleResetFilter = () => {
		sortInfo = { ...defaultSort };
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		setKabobsMenu(event.currentTarget);
		setSelectedRowIndex(index);
	};
	const handleClose = () => {
		setKabobsMenu(null);
		setSelectedRowIndex(-1);
	};

	const buildCaseListRows = (): ISecuritiesTableFieldMap[] => {
		var caseListRows: ISecuritiesTableFieldMap[] = [];

		caseState.cases && caseState.cases.forEach((item, index) => {
			let newItem: ISecuritiesTableFieldMap = {
				number: { type: "string", value: item.number },
				name: { type: "link", value: <Link color="success" to={`CaseDetail/${item.id}`}>{item.name}</Link>, sortValue: item.name },
				claimsDeadline: { type: "date", value: item.claimsDeadline },
				classPeriodFrom: { type: "date", value: item.classPeriodFrom },
				classPeriodTo: { type: "date", value: item.classPeriodTo },
				isAcceptingSubmissions: { type: "boolean", value: item.isAcceptingSubmissions },
				actions: {
					type: "link",
					value:
						<Fragment>
							{(item.currentTemplateId) && (

								<div>

									<IconButton
										aria-label="more"
										className={classes.kabobIconEnable}
										id={"long-button-" + index}
										aria-haspopup="true"
										sx={{ padding: "0" }}
										onClick={(event: React.MouseEvent<HTMLElement>) => {
											handleClick(event, index);
										}}
									>
										<MoreVertIcon />
									</IconButton>

									<Menu
										id={"long-menu-" + index}
										MenuListProps={{
											"aria-labelledby": "long-button" + index,
										}}
										anchorEl={kabobsMenu}
										open={selectedRowIndex === index}
										onClose={handleClose}
										onClick={handleClose}
										PaperProps={{
											style: {
												maxHeight: STANDARD_KABOB_HEIGHT * 4.5,
												width: "20ch",
											},
										}}
									>
										<div>
											<MenuItem
												onClick={() => { downloadTemplate(item.id) }}
											>
												<Tooltip placement="left-end"
													title="Download a template for use in filing claims.">
													<Typography>Download Template</Typography>
												</Tooltip>
											</MenuItem>

											{
												(item.isAcceptingSubmissions && canSubmitClaim) &&
												<MenuItem
													onClick={() => { selectCase(item.id, SubmissionTypeDisplay.Original, item.name, item.number) }}
												>
													<Tooltip placement="left-end"
														title="Add Submission">
														<Typography>Add Submission</Typography>
													</Tooltip>
												</MenuItem>
											}
										</div>

									</Menu>

								</div>
							)}
						</Fragment >
				}
			}

			caseListRows.push(newItem);
		});

		return caseListRows;
	};

	const rows = buildCaseListRows();

	return (
		<>
			{(caseState.isLoading === true) ?
				<Card>
					<CardContent>
						{[...Array(5)].map((elementInArray, index) => (
							<Grid container spacing={2} key={index}>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={4}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
							</Grid>
						))}
					</CardContent>
				</Card>
				: <>
					<SecuritiesTable
						columns={caseListFilterColumns}
						rows={rows}
						sorter={sortInfo}
						paging={'client'}
						showFilter={true}
						dataFetchFunc={fetchFilteredCaseList}
						defaultFilter={defaultFilter}
						resetFilterFunc={handleResetFilter}
					/>
					{!hasCaseBookData && <p style={{ color: 'red' }}>{PARTIAL_INFO_MESSAGE}</p>}
				</>
			}
		</>
	)
};

export default CaseListGrid;