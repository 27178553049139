import * as React from "react";
import { IRegisterUser, IDeleteUser } from "../../types/types";
import { Theme, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { clearRemoveUserAccessResponseAction, removeUserAccessAction } from "./userlist.actions";
import makeStyles from '@mui/styles/makeStyles';
import { useContext } from "react";
import { UserContext, UserContextType } from "../User/UserContext";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        minWidth: "500px",
        maxwidth: "700px"
    }
}));

interface IModalDialogueProps {
    dialogOpen: boolean;
    handleDialogClose: any;
    userToRemove?: IRegisterUser;
}

const RemoveUserAccessDialog = ({
    userToRemove,
    dialogOpen,
    handleDialogClose,
}: IModalDialogueProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const descriptionElementRef = React.useRef<HTMLElement>(null);
    const { user } = useContext(UserContext) as UserContextType;

    const handleSubmit = () => {
        if (!userToRemove)
            return;

        var deleteUserInfo: IDeleteUser = { userId: userToRemove.id, deletedByUserId: user.id };
        dispatch(removeUserAccessAction(deleteUserInfo));
        dispatch(clearRemoveUserAccessResponseAction());
        handleDialogClose(false);
    };

    React.useEffect(() => {
        if (!dialogOpen)
            return;

        const { current: descriptionElement } = descriptionElementRef;

        if (descriptionElement)
            descriptionElement.focus();
    }, [dialogOpen]);

    return (
        <div>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                fullWidth
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{ paper: classes.paper }}
            >
                <DialogTitle className="cmn-dialogtitle" id="scroll-dialog-title">
                    Deactivate User Account : {userToRemove?.email}
                </DialogTitle>

                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                        className="cmn-dialogtext"
                    >
                        <Typography
                            align="center"
                            style={{ wordWrap: "break-word" }}
                            color="black"
                            component="span"
                            className="cmn-dialogtext"
                        >
                            This will remove the user's access to this site.
                            <br />
                            <br />
                            They will not be able to login to the system.
                        </Typography>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button color="inherit"
                        className="cmn-secbtn"
                        size="small"
                        onClick={handleDialogClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        color="primary"
                        size="small"
                        variant="contained"
                        className="btn-primary"
                    >
                        Deactivate User
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default RemoveUserAccessDialog;