import React, { useState, useEffect } from 'react';
import {
	Dialog, DialogTitle, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Table, TableBody, TableCell, TableHead, TableRow, CircularProgress, Backdrop, Typography,
	TextField
} from '@mui/material';
import axios from 'axios';
import authService from '../../components/api-authorization/AuthorizeService';
import { IInstitution } from '../../types/types';
import makeStyles from '@mui/styles/makeStyles';

interface User {
	id: number;
	email: string;
	institution: IInstitution;
}

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		verticalAlign: 'top'
	},
	search: {
		border: '1px solid #707070',
		borderRadius: '4px',
	},
	headerButton: {
		verticalAlign: 'bottom',
	},
	button: {
		height: '2.6rem',
		color: '#707070',
		backgroundColor: '#fff!important',
	},
	radio: {
		'& span': { color: 'black' }
	},
	disabledBtn: {
		backgroundColor: '#cfcfcf !important',
	},
	primaryBtn: {
		color: '#fff!important',
		backgroundColor: '#007bff!important'
	},
	secondaryBtn: {
		color: 'blue',
		backgroundColor: '#fff!important',
		border: '1px solid blue',
	},
}));
const DialogGetApprovedNominees: React.FC<{ open: boolean; onSubmit: (selectedId: number, selectedName: string) => void, onClose: () => void }> = ({ open, onSubmit, onClose }) => {
	const [users, setUsers] = useState<User[]>([]);
	const [selectedUser, setSelectedUser] = useState<number | null>(null);
	const [error, setError] = useState<TypeError | undefined>(undefined as TypeError | undefined);
	const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' }>({ key: 'email', direction: 'asc' });

	const classes = useStyles();

	useEffect(() => {
		const fetchUsers = async () => {
			setError(undefined);

			try {
				const token = await authService.getAccessToken();

				const response = await axios.get('api/user/approveduserlist', {
					headers: { Authorization: `Bearer ${token}` }
				});

				setUsers(response.data);
			} catch (e:any) {
				setError(e);
				console.error('Error fetching users:', e);
			}
		};

		fetchUsers();
	}, []);

	const clearSearch = () => {
		setSearchEmail('');
		setSearchInstitution('');
	}

	const handleSort = (key: string) => {
		let direction: 'asc' | 'desc' = 'asc';

		if (sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc';
		}

		setSortConfig({ key, direction });

		setUsers((prevUsers) =>
			[...prevUsers].sort((a, b) => {
				if (a[key as keyof User] < b[key as keyof User]) return direction === 'asc' ? -1 : 1;
				if (a[key as keyof User] > b[key as keyof User]) return direction === 'asc' ? 1 : -1;
				return 0;
			})
		);
	};

	const handleSubmit = async () => {
		if (selectedUser) {
			let u: User = users.filter((user: User) => user.id === selectedUser)[0];
			onSubmit(selectedUser, u.email);
		}
	};
	const [searchEmail, setSearchEmail] = useState<string>('');

	const handleSearchEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchEmail(event.target.value);
	};

	const [searchInstitution, setSearchInstitution] = useState<string>('');

	const handleSearchInstitution = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInstitution(event.target.value);
	};

	const filteredUsers = users.filter((user) => {
		if (searchEmail !== '') {
			return user.email.toLowerCase().includes(searchEmail.toLowerCase())
		}

		if (searchInstitution !== '') {
			return user.institution.name.toLowerCase().includes(searchInstitution.toLowerCase())
		}

		return true;
	});

	return (
		<Dialog maxWidth='md' fullWidth open={open} onClose={onClose}>
			<DialogTitle>Nominee User Selection</DialogTitle>

			<DialogContent>
				<FormControl component="fieldset">
					<FormLabel component="legend">On behalf of…</FormLabel>

					<RadioGroup
							value={selectedUser}
							onChange={(e) => setSelectedUser(Number(e.target.value))}>
									
						<Table size="small" style={{ marginBottom: '10px', maxHeight: '128px', overflowY: 'auto' }}>
							<TableHead>
								<TableRow>
										<TableCell className={ classes.tableHeader }>
										<p>Select</p>
									</TableCell>

										<TableCell className={classes.tableHeader}
											onClick={() => handleSort('email')}>
										<p>Username {sortConfig.key === 'email' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</p>
										<TextField
											className={ classes.search}
											variant='outlined'
											value={searchEmail}
											onChange={handleSearchEmail}
											size="small"
										/>

									</TableCell>

										<TableCell className={classes.tableHeader}
											onClick={() => handleSort('institution')}>
											<p>Institution {sortConfig.key === 'institution' && (sortConfig.direction === 'asc' ? '↑' : '↓')}</p>
											<TextField
												className={classes.search}
												variant='outlined'
												value={searchInstitution}
												onChange={handleSearchInstitution}
												size="small"
											/>
									</TableCell>
									<TableCell className={ classes.headerButton}>
										<Button
											className={ classes.button}
											variant='text'
											onClick={clearSearch}>Reset
										</Button>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{filteredUsers.map((user) => (
									<TableRow key={user.id}>
										<TableCell>
											<FormControlLabel value={user.id} control={<Radio className={ classes.radio } />} label="" />
										</TableCell>

										<TableCell>{user.email}</TableCell>
										<TableCell>{user.institution.name}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>

					</RadioGroup>
				</FormControl>

				{error && <Typography color="error">{error}</Typography>}
					
			</DialogContent>

			<DialogActions>
				<Button
					classes={!selectedUser ? { root: classes.disabledBtn, disabled: classes.disabledBtn } :
						{ root: classes.primaryBtn, disabled: classes.primaryBtn }}
					onClick={handleSubmit}
					disabled={!selectedUser}>OK</Button>
				<Button
					classes={{ root: classes.secondaryBtn, disabled: classes.disabledBtn }}
					onClick={onClose}>{'Cancel'}</Button>
			</DialogActions>

		</Dialog>
	);
};

export default DialogGetApprovedNominees;
