import { takeEvery, all, call, put } from 'redux-saga/effects';
import { getSettingsSuccess, getSettingsFailed, GET_SETTINGS } from './settings.actions';
import { getSettings } from './settings.api';

function* getSettingsSaga(): any {
	try {
		const settings = process.env.NODE_ENV.localeCompare('development', undefined, { sensitivity: 'base' }) === 0
			? {
				'casebookApiBaseURL': process.env.REACT_APP_CASEBOOKAPIBASEURL,
				'helpBaseUrl': process.env.REACT_APP_HELPBASEURL,
				'sessionTimeOutMilliSeconds': process.env.REACT_APP_SESSION_TIMEOUT_MILLISECONDS
			}
			: yield call(getSettings);

		localStorage.setItem('casebookApiBaseURL', settings.casebookApiBaseURL);

		yield put(getSettingsSuccess(settings));
	} catch (error: any) {
		yield put(getSettingsFailed(error.message));
	}
}

function* getSettingsWatcher() {
	yield takeEvery(GET_SETTINGS, getSettingsSaga);
}

export default function* settingsSaga() {
	yield all([getSettingsWatcher()])
}