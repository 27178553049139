import { IDeleteUser, IInstitution, IRegisterUser } from '../../types/types';
import { IApproveUserCriteria, IDenyUserCriteria, IReviewUserCriteria } from "../../types/types";

export const GET_USERLIST = "GET_USERLIST"
export const GET_USERLIST_SUCCESS = "GET_USERLIST_SUCCESS";
export const GET_USERLIST_FAILURE = "GET_USERLIST_FAILURE";

export const GET_ROLES_LIST = "GET_ROLES_LIST"
export const GET_ROLES_LIST_SUCCESS = "GET_ROLES_LIST_SUCCESS";
export const GET_ROLES_LIST_FAILURE = "GET_ROLES_LIST_FAILURE";

export const GET_APPROVE_USER_CONTENT = "GET_APPROVE_USER_CONTENT"
export const GET_APPROVE_USER_CONTENT_SUCCESS = "GET_APPROVE_USER_CONTENT_SUCCESS";
export const GET_APPROVE_USER_CONTENT_FAILURE = "GET_APPROVE_USER_CONTENT_FAILURE";

export const APPROVE_USER = "APPROVE_USER"
export const APPROVE_USER_SUCCESS = "APPROVE_USER_SUCCESS";
export const APPROVE_USER_FAILURE = "APPROVE_USER_FAILURE";

export const DENY_USER = "DENY_USER"
export const DENY_USER_SUCCESS = "DENY_USER_SUCCESS";
export const DENY_USER_FAILURE = "DENY_USER_FAILURE";
export const CLEAR_DENY_USER_RESPONSE = "CLEAR_DENY_USER_RESPONSE";

export const REMOVE_USER_ACCESS = "REMOVE_USER_ACCESS"
export const REMOVE_USER_ACCESS_SUCCESS = "REMOVE_USER_ACCESS_SUCCESS";
export const REMOVE_USER_ACCESS_FAILURE = "REMOVE_USER_ACCESS_FAILURE";
export const CLEAR_REMOVE_USER_ACCESS_RESPONSE = "CLEAR_REMOVE_USER_ACCESS_RESPONSE";

export const REVIEW_USER = "REVIEW_USER"
export const REVIEW_USER_SUCCESS = "REVIEW_USER_SUCCESS";
export const REVIEW_USER_FAILURE = "REVIEW_USER_FAILURE";

export const REGISTER_USER_INSTITUTIONS = 'REGISTER_USER_INSTITUTIONS';
export const REGISTER_USER_INSTITUTIONS_SUCCESS = 'REGISTER_USER_INSTITUTIONS_SUCCESS';
export const REGISTER_USER_INSTITUTIONS_FAILED = 'REGISTER_USER_INSTITUTIONS_FAILED';

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const getUserList = (payload: any) => ({
    type: GET_USERLIST,
    payload
});

export const getUserListSuccess = (payload: any) => {
    return {
        type: GET_USERLIST_SUCCESS,
        payload
    };
};

export const getUserListFailure = (payload: any) => {
    return {
        type: GET_USERLIST_FAILURE,
        payload
    };
};

export const getApproveUserContent = (payload: any) => ({
    type: GET_APPROVE_USER_CONTENT,
    payload
});

export const getApproveUserContentSuccess = (payload: any) => {
    return {
        type: GET_APPROVE_USER_CONTENT_SUCCESS,
        payload
    };
};

export const getApproveUserContentFailure = (payload: any) => {
    return {
        type: GET_APPROVE_USER_CONTENT_FAILURE,
        payload
    };
};

export const approveUser = (payload: IApproveUserCriteria) => ({
    type: APPROVE_USER,
    payload
});

export const approveUserSuccess = (payload: any) => {
    return {
        type: APPROVE_USER_SUCCESS,
        payload
    };
};

export const approveUserFailure = (payload: any) => {
    return {
        type: APPROVE_USER_FAILURE,
        payload
    };
};

export const denyUserAction = (payload: IDenyUserCriteria) => {
    return {
        type: DENY_USER,
        payload
    };
};

export const denyUserSuccessAction = (payload: any) => {
    return {
        type: DENY_USER_SUCCESS,
        payload
    };
};

export const denyUserFailureAction = (payload: any) => {
    return {
        type: DENY_USER_FAILURE,
        payload
    };
};

export const removeUserAccessAction = (payload: IDeleteUser) => {
    return {
        type: REMOVE_USER_ACCESS,
        payload
    };
};

export const removeUserAccessSuccessAction = (payload: any) => {
    return {
        type: REMOVE_USER_ACCESS_SUCCESS,
        payload
    };
};

export const removeUserAccessFailureAction = (payload: any) => {
    return {
        type: REMOVE_USER_ACCESS_FAILURE,
        payload
    };
};

export const reviewUserAction = (payload: IReviewUserCriteria) => {
    return {
        type: REVIEW_USER,
        payload
    };
};

export const reviewUserSuccessAction = (payload: any) => {
    return {
        type: REVIEW_USER_SUCCESS,
        payload
    };
};

export const reviewUserFailureAction = (payload: any) => {
    return {
        type: REVIEW_USER_FAILURE,
        payload
    };
};

export const registerUserInstitutions = (payload: string) => ({
    type: REGISTER_USER_INSTITUTIONS,
    payload
});

export const registerUserInstitutionsSuccess = (payload: IInstitution[]) => ({
    type: REGISTER_USER_INSTITUTIONS_SUCCESS,
    payload
});

export const registerUserInstitutionsFailed = (payload: any) => ({
    type: REGISTER_USER_INSTITUTIONS_FAILED,
    payload
});

export const clearApproveDenyUserResponseAction = () => ({
    type: CLEAR_DENY_USER_RESPONSE
});

export const clearRemoveUserAccessResponseAction = () => ({
    type: CLEAR_REMOVE_USER_ACCESS_RESPONSE
});

export const updateUser = (payload: IRegisterUser) => ({
    type: UPDATE_USER,
    payload
});

export const updateUserSuccess = (payload: any) => {
    return {
        type: UPDATE_USER_SUCCESS,
        payload
    };
};

export const updateUserFailure = (payload: any) => {
    return {
        type: UPDATE_USER_FAILURE,
        payload
    };
};

export const getRolesList = (payload: any) => ({
    type: GET_ROLES_LIST,
    payload
});

export const getRolesListSuccess = (payload: any) => {
    return {
        type: GET_ROLES_LIST_SUCCESS,
        payload
    };
};

export const getRolesListFailure = (payload: any) => {
    return {
        type: GET_ROLES_LIST_FAILURE,
        payload
    };
};