import { IInstitution } from '../../types/types';

export const GET_INSTITUTIONLIST ="GET_INSTITUTIONLIST"
export const GET_INSTITUTIONLIST_SUCCESS = "GET_INSTITUTIONLIST_SUCCESS";
export const GET_INSTITUTIONLIST_FAILURE = "GET_INSTITUTIONLIST_FAILURE";
export const GET_INSTITUTION_BY_ID = "GET_INSTITUTION_BY_ID";
export const GET_INSTITUTION_BY_ID_SUCCESS = "GET_INSTITUTION_BY_ID_SUCCESS";
export const GET_INSTITUTION_BY_ID_FAILED = "GET_INSTITUTION_BY_ID_FAILED";
export const UPDATE_INSTITUTION = 'UPDATE_INSTITUTION';
export const UPDATE_INSTITUTION_SUCCESS = 'UPDATE_INSTITUTION_SUCCESS';
export const UPDATE_INSTITUTION_FAILED = 'UPDATE_INSTITUTION_FAILED';
export const GET_INSTITUTION_TYPE = "GET_INSTITUTION_TYPE";
export const GET_INSTITUTION_TYPE_SUCCESS = "GET_INSTITUTION_TYPE_SUCCESS";
export const GET_INSTITUTION_TYPE_FAILURE = "GET_INSTITUTION_TYPE_FAILURE";
export const GET_INSTITUTION_COUNTRIESSTATES = "GET_INSTITUTION_COUNTRIESSTATES";
export const GET_INSTITUTION_COUNTRIESSTATES_SUCCESS = "GET_INSTITUTION_COUNTRIESSTATES_SUCCESS";
export const GET_INSTITUTION_COUNTRIESSTATES_FAILURE = "GET_INSTITUTION_COUNTRIESSTATES_FAILURE";

export const getInstitutionList = (payload:any) => ({
    type: GET_INSTITUTIONLIST,
    payload
});

export const getInstitutionListSuccess = (payload: any) => {
    return {
        type: GET_INSTITUTIONLIST_SUCCESS,
        payload
    };
};

export const getInstitutionListFailure = (payload: any) => {
    return {
        type: GET_INSTITUTIONLIST_FAILURE,
        payload
    };
};

export const getInstitutionByIdAction = (payload: any ) => ({
    type: GET_INSTITUTION_BY_ID,
    payload
});

export const getInstitutionByIdSuccess = (payload: any) => ({
    type: GET_INSTITUTION_BY_ID_SUCCESS,
    payload
});

export const getInstitutionByIdFailed = (payload: any) => ({
    type: GET_INSTITUTION_BY_ID_FAILED,
    payload
});

export const updateInstitutionAction = (payload: IInstitution)  => ({
    type: UPDATE_INSTITUTION,
    payload
});

export const updateInstitutionSuccess = (payload: any) => ({
    type: UPDATE_INSTITUTION_SUCCESS,
    payload
});

export const updateInstitutionFailed = (payload: any) => ({
    type: UPDATE_INSTITUTION_FAILED,
    payload
});

export const getInstitutionTypeAction = () => ({
    type: GET_INSTITUTION_TYPE
});

export const getInstitutionTypeSuccess = (payload: IInstitution) => {
    return {
        type: GET_INSTITUTION_TYPE_SUCCESS,
        payload
    };
};

export const getInstitutionTypeFailure = (payload: any) => {
    return {
        type: GET_INSTITUTION_TYPE_FAILURE,
        payload
    };
};

export const getCountriesStatesAction = () => ({
    type: GET_INSTITUTION_COUNTRIESSTATES
});

export const getCountriesStatesSuccess = (payload: any) => {
    return {
        type: GET_INSTITUTION_COUNTRIESSTATES_SUCCESS,
        payload
    };
};

export const getCountriesStatesFailure = (payload: any) => {
    return {
        type: GET_INSTITUTION_COUNTRIESSTATES_FAILURE,
        payload
    };
};

