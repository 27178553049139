import { AlertType, DocumentType, DocumentTypeDisplay, Strings, SubmissionState, SubmissionType, SubmissionTypeDisplay } from "../../types/enums";
import { Button, Card, CardActions, CardHeader, Grid, Icon, InputLabel, Modal, Theme } from '@mui/material';
import {
    ICaseDocumentType, IClaimFileState, IDocument, IResponse, IStringValue,
    ISupportingDocModalProps, ISupportingDocTypeProps
} from '../../types/types';
import React, { useEffect, useState } from 'react';
import {
    clearCaseDocumentTypeAction,
    clearDeleteDocumentAction,
    clearPublishSubmissionMessageAction,
    clearSupportEmailAddressAction,
    clearSupportingDocFileAction,
    deleteDocumentsAction,
    getCaseDocumentTypesAction,
    publishSubmissionMessageAction,
    setSupportingDocTypeProps,
    uploadSupportingDocFileAction
} from './claimFile.actions';
import { clearSubmissionDocumentsListAction, getSubmissionDocumentsListAction }
    from '../Submissions/submissions.actions';
import { useDispatch, useSelector } from 'react-redux';
import CancelSubmissionModal from './CancelSubmissionModal';
import SaveForLaterModal from './SaveForLaterModal';
import { Typography } from '@mui/material';
import authService from '../../components/api-authorization/AuthorizeService';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { showAlert } from '../Alerts/alerts.actions';
import { useCallback } from "react";
import OptionalFileUploader from "./OptionalFileUpload";
import { CardBody, CardFooter } from "reactstrap";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const createLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
        },
        claimmodal: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            justifyContent: 'center',
            width: '700px;'
        },
        modalHeader: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 500,
            color: theme.palette.common.white,
            textEmphasisColor: theme.palette.common.white,
            font: '16',
        },
        modalHeaderTitle: {
            paddingBottom: '5px;'
        },
        title: {
            flexGrow: 1,
            color: 'black'
        },
        titleLeft: {
            color: 'black',
            float: 'left',
            textDecoration: 'underline'
        },
        titleRight: {
            color: 'black',
            float: 'right'
        },
        label: {
            color: 'black',
            float: 'left',
            fontSize: '20px',
            marginRight: '5px',
            marginTop: '1px'
        },
        cardAction: {
            justifyContent: 'flex-end'
        },
        disabledButton: {
            backgroundColor: '#cfcfcf !important',
        },
        primaryBtn: {
            color: '#fff!important',
            backgroundColor: '#007bff!important'
        },
        secondaryBtn: {
            color: 'blue',
            backgroundColor: '#fff!important',
            border: '1px solid',
            borderColor: 'blue'
        },
        paragraph: {
            marginBottom: '-5px'
        },
        uploadedBtn: {
            color: 'white!important',
            backgroundColor: '#28a745!important'
        },
        cardHeaderTitleDiv: {
            display: 'flex',
            justifyContent: 'space-between'
        },
        error: {
            color: 'red',
            float: 'left',
            fontSize: '20px'
        },
    }),
);

const getDocTypeIdFromDescription = (description: string): number => {
    let id;

    switch (description) {
        case DocumentTypeDisplay.DataVerification:
            id = DocumentType.DataVerification;
            break;
        case DocumentTypeDisplay.AttestationDocument:
            id = DocumentType.AttestationDocument;
            break;
        case DocumentTypeDisplay.SignatureAuthorization:
            id = DocumentType.SignatureAuthorization;
            break;
        case DocumentTypeDisplay.ProofofClaim:
            id = DocumentType.ProofofClaim;
            break;
        case DocumentTypeDisplay.Other:
            id = DocumentType.Other;
            break;
        default:
            id = 0;
            break;
    }

    return id;
}

interface ISupportingDocModal {
    modalOpen: boolean,
    supportingDocModalProps: ISupportingDocModalProps
}

const DeficiencyOptionalFilesUploadModal = ({ modalOpen, supportingDocModalProps }: ISupportingDocModal) => {
    const dispatch = useDispatch();
    const classes = createLocalStyles();
    const handleClose: Function = supportingDocModalProps.handleClose;
    const submissionTypeDisplay: SubmissionTypeDisplay = supportingDocModalProps.submissionTypeDisplay;
    const submissionType: SubmissionType = supportingDocModalProps.submissionType;
    const submissionState: SubmissionState = supportingDocModalProps.submissionState;
    const caseId: number = supportingDocModalProps.caseId;
    const caseName: string = supportingDocModalProps.caseName;
    const caseNumber: string = supportingDocModalProps.caseNumber;
    const submissionDescription = supportingDocModalProps.submissionDescription;
    const [outSubmissionId, setSubmissionId] = useState<number>(supportingDocModalProps.submissionId);
    const [filesAreSubmitted, setFilesAreSubmitted] = useState<boolean>(false);
    const [publishMessage, setPublishMessage] = useState<boolean>(false);
    const [submissionSaveForLaterMessageIsShown, setSubmissionSaveForLaterMessageIsShown] = useState<boolean>(false);
    const [cancelSubmissionModalIsOpen, setCancelSubmissionModalIsOpen] = useState(false);
    const documentTypes: ICaseDocumentType[] = useSelector((state: any) => state.claimFileState.documentTypes);
    const [docTypeRows, setDocTypeRows] = useState<ISupportingDocTypeProps[]>();
    const [submissionIsCanceled, setSubmissionIsCanceled] = useState<boolean>(false);
    const submissionDocuments: IDocument[] = useSelector((state: any) => state.submissionListState.submissionDocuments);
    const submissionDocumentsIsLoaded: boolean = useSelector((state: any) => state.submissionListState.documentsLoaded);
    const claimFileState: IClaimFileState = useSelector((state: any) => state.claimFileState);
    const [SaveForLaterModalModalDialogOpen, setSaveForLaterModalModalDialogOpen] = React.useState(false);
    const [saveLaterText, setSaveLaterText] = useState<string>("");
    const [stringContentData, setStringContentData] = useState<IStringValue[]>([]);
    const [additionalDocuments, setAdditionalDocuments] = useState<ISupportingDocTypeProps[]>([]);
    const [allFilesSelected, setAllFilesSelected] = useState(false);
    const [otherDocumentIdsForDeletion, setOtherDocumentIdsForDeletion] = useState<number[]>([]);

    const handleModalClose = () => {

        if (!filesAreSubmitted && !submissionIsCanceled) {
            cancelSubmission();
            return;
        }

        clearModal();
        setFilesAreSubmitted(false);
        setSubmissionId(0);
        dispatch(clearSupportingDocFileAction());
        dispatch(clearPublishSubmissionMessageAction());
        dispatch(clearCaseDocumentTypeAction());
        dispatch(clearDeleteDocumentAction());
        dispatch(clearSupportEmailAddressAction());
        handleClose();
    };

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: path, title: "Deficiency Optional Files Upload Modal" });
    }, [location]);

    useEffect(() => {

        if (modalOpen && !claimFileState.loadingDocumentTypes && !claimFileState.loadDocumentTypesSuccess)
            getRequiredDocumentTypes();

        if (modalOpen && !docTypeRows && claimFileState.loadDocumentTypesSuccess && (hasSubmissionDocuments() || hasNoSubmissionDocuments()))
            buildDocTypeProps();

        if (sendSubmissionMessage())
            publishSubmissionMessage();

        if (saveForLaterMessage())
            showSubmissionSaveForLaterMessage();

        if (closeSupportingDocModal())
            handleModalClose();

        if (!stringContentData || stringContentData.length === 0 )
            GetStringValue();

    }, [modalOpen, submissionDocumentsIsLoaded, filesAreSubmitted, submissionSaveForLaterMessageIsShown, claimFileState]);

    const buildDocTypeProps = (): void => {
        setDocTypeRows(buildDocTypeRows());
    };

    const getRequiredDocumentTypes = (): void => {

        if (submissionState === SubmissionState.Started)
            getExistingSubmissionDocuments();

        let payload = {
            caseId: caseId,
            submissionType: submissionType,
            includeConsolidatedDocumentsType: false
        }

        dispatch(getCaseDocumentTypesAction(payload));
    };

    const getExistingSubmissionDocuments = (): void => {
        let payload = {
            submissionId: outSubmissionId,
            filter: []
        };

        dispatch(getSubmissionDocumentsListAction(payload));
    };

    let tempDocId: number = 1;

    function handleDocumentType(item: ICaseDocumentType, docTypeRows: ISupportingDocTypeProps[]) {
        let docType: DocumentType = item.documentTypeId;

        if (hasSubmissionDocuments())
            var submissionDocument = submissionDocuments.find(r => r.documentType == docType);

        let response: IResponse = { success: false, message: "" };

        let newItem: ISupportingDocTypeProps = {
            id: tempDocId++,
            docTypeDescription: item.label,
            existingFileName: submissionDocument ? submissionDocument.name : "",
            existingDocumentId: submissionDocument ? submissionDocument.id : 0,
            isUploading: false,
            isUploaded: false,
            isUploadFailed: false,
            isNotProvided: false,
            progress: 0,
            file: undefined,
            fileIsCorrectType: fileIsCorrectType,
            response: response,
            isConsolidatedDocumentsType:false
        };

        docTypeRows.push(newItem);
    }

    const buildDocTypeRows = (): ISupportingDocTypeProps[] => {
        var docTypeRows: ISupportingDocTypeProps[] = [];

        handleOtherDocumentType(additionalDocuments);
        handleAddNewRow();

        let payload = {
            supportingDocTypeProps: docTypeRows
        };

        dispatch(setSupportingDocTypeProps(payload));

        return docTypeRows;
    };

    const hasSubmissionDocuments = (): boolean => {
        return (submissionState == SubmissionState.Started && submissionDocumentsIsLoaded && submissionDocuments.length > 0)
            ? true
            : false;
    }

    const hasNoSubmissionDocuments = (): boolean => {
        return ((submissionState != SubmissionState.Started) || submissionDocumentsIsLoaded && submissionDocuments.length == 0)
            ? true
            : false;
    }

    const filesAreUploading = (): boolean => {
        return (filesAreSubmitted && docTypeRows && (docTypeRows.some(item => item.isUploading) || claimFileState.publishMessage))
            ? true
            : false;
    }

    const allFilesUploaded = (): boolean => {
        return (filesAreSubmitted && docTypeRows && docTypeRows.every(item => item.isUploading == false) && docTypeRows.every(item => item.isUploadFailed == false))
            ? true
            : false;
    }

    const hasFilesWithIncorrectFileType = (): boolean => {
        var badFiles = [];

        if (docTypeRows) {
            docTypeRows.forEach((item) => {
                let input = document.getElementById(`fileInput${item.id}`) as HTMLInputElement;

                if (input && input.files && input.files.length > 0 && !fileIsCorrectType(input.files[0])) 
                    badFiles.push(true);
                
            });

            return (badFiles.length > 0) ? true : false
        }

        return false;
    };

    const fileIsCorrectType = (file: File): boolean => {

        if (file && file !== undefined) {
            let extension = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();

            return extension === 'xlsx' || extension === 'docx' || extension === 'pdf' || extension === 'txt';
        }

        return false;
    };

    const clearModal = (): void => {

        if (docTypeRows)
            docTypeRows.forEach((item) => {
                let input = document.getElementById(`fileInput${item.id}`) as HTMLInputElement;

                if (input)
                    input.value = "";
            });
    };

    const cancelSubmission = (): void => {
        setCancelSubmissionModalIsOpen(true);
    };

    const deleteExistingDocuments = (documentIds: number[]): void => {
        let payload = {
            documentIds: documentIds
        };

        dispatch(deleteDocumentsAction(payload));
    };

    const uploadFiles = (filesToUpload: ISupportingDocTypeProps[]): void => {
        let documentIds: number[] = [];
        filesToUpload.forEach((item) => {
            let data = new FormData();
            data.append('file', item.file!);

            let payload = {
                file: data,
                submissionId: outSubmissionId,
                documentTypeId: getDocTypeIdFromDescription(item.docTypeDescription),
                supportingDocTypeProps: item
            };

            dispatch(uploadSupportingDocFileAction(payload));

            if (item.existingDocumentId > 0)
                documentIds.push(item.existingDocumentId);

        })

        if (documentIds.length > 0)
            deleteExistingDocuments(documentIds);

    };

    const submitFiles = (publishSubmission: boolean): void => {
        setFilesAreSubmitted(true);
        let filesToUpload: ISupportingDocTypeProps[] = [];

        if (docTypeRows) {
            docTypeRows.forEach((item) => {

                if (item.file) {
                    var file: File = item.file;
                    let isUploaded = item.isUploaded;

                    if (file && !isUploaded) {
                        item.isUploading = true;
                        filesToUpload.push(item)
                    }
                }
                else
                    item.isNotProvided = true;
            });

            var index = filesToUpload.length;

            additionalDocuments.forEach((item) => {

                if (item.file) {
                    item.id = index + 1;
                    var file: File = item.file;
                    let isUploaded = item.isUploaded;

                    if (file && !isUploaded) {
                        item.isUploading = true;
                        filesToUpload.push(item)
                    }
                }
                else
                    item.isNotProvided = true;
            });

            let payload = {
                supportingDocTypeProps: docTypeRows
            };

            dispatch(setSupportingDocTypeProps(payload));
        }

        if (otherDocumentIdsForDeletion.length > 0)
            deleteExistingDocuments(otherDocumentIdsForDeletion);

        if (filesToUpload.length > 0)
            uploadFiles(filesToUpload);

        if (publishSubmission)
            setPublishMessage(true);
    };

    function handleOtherDocumentType(additionalDocuments: ISupportingDocTypeProps[]) {
        let response: IResponse = { success: false, message: "" };

        let newItem: ISupportingDocTypeProps = {
            id: 0,
            docTypeDescription: DocumentTypeDisplay.Other,
            existingFileName: "",
            existingDocumentId: 0,
            isUploading: false,
            isUploaded: false,
            isUploadFailed: false,
            isNotProvided: true,
            progress: 0,
            file: undefined,
            fileIsCorrectType: fileIsCorrectType,
            response: response,
            isConsolidatedDocumentsType: false
        };

        if (hasSubmissionDocuments()) {
            submissionDocuments.filter(d => d.documentType === DocumentType.Other).forEach((d) => {
                newItem = {
                    ...newItem,
                    id: tempDocId++,
                    existingFileName: d.name,
                    existingDocumentId: d.id,
                };

                additionalDocuments.push(newItem);
            });
        }
    }

    const handleOptionalDocFileUpload = useCallback((index, event) => {
        index = index + 1;
        var newFile = event.target.files[0];
        let response: IResponse = { success: false, message: "" };

        let newItem: ISupportingDocTypeProps = {
            id: 0,
            docTypeDescription: DocumentTypeDisplay.Other,
            existingFileName: "",
            existingDocumentId: 0,
            isUploading: false,
            isUploaded: false,
            isUploadFailed: false,
            isNotProvided: true,
            progress: 0,
            file: undefined,
            fileIsCorrectType: fileIsCorrectType,
            response: response,
            isConsolidatedDocumentsType: false
        };

        newItem = {
            ...newItem,
            id: index,
            existingFileName: "",
            existingDocumentId: 0,
            file: newFile
        };

        setAdditionalDocuments([newItem, ...additionalDocuments]);

    }, [additionalDocuments]);

    const handleAddNewRow = () => {
        let response: IResponse = { success: false, message: "" };

        let newItem: ISupportingDocTypeProps = {
            id: 0,
            docTypeDescription: DocumentTypeDisplay.Other,
            existingFileName: "",
            existingDocumentId: 0,
            isUploading: false,
            isUploaded: false,
            isUploadFailed: false,
            isNotProvided: true,
            progress: 0,
            file: undefined,
            fileIsCorrectType: fileIsCorrectType,
            response: response,
            isConsolidatedDocumentsType: false
        };

        setAdditionalDocuments([...additionalDocuments, newItem]);
    };

    const handleRemoveClick = useCallback((index: any) => {
        var list = [...additionalDocuments];
        var recordToDelete = list[index];
        setOtherDocumentIdsForDeletion([recordToDelete.existingDocumentId, ...otherDocumentIdsForDeletion]);
        list.splice(index, 1);
        setAdditionalDocuments(list);
    }, [additionalDocuments]);

    const handleCancelSubmissionModalClosed = () => {
        setCancelSubmissionModalIsOpen(false);
    };

    const handleSaveForLaterModalModalDialogClose = () => {
        setSaveForLaterModalModalDialogOpen(false);
    }

    const saveForLaterMessage = (): boolean => {
        return (allFilesUploaded() && !publishMessage)
            ? true
            : false;
    }

    const sendSubmissionMessage = (): boolean => {
        return (allFilesUploaded() && publishMessage && !claimFileState.publishMessageDispatched)
            ? true
            : false;
    }

    const publishSubmissionMessage = (): void => {

        let submissionMessagePayload = {
            submissionId: outSubmissionId
        };

        dispatch(publishSubmissionMessageAction(submissionMessagePayload));
    };

    const showSubmissionSaveForLaterMessage = (): void => {
        dispatch(
            showAlert({
                hidden: false,
                message: `Submission ${submissionDescription} saved for later`,
                severity: AlertType.success
            })
        );

        setSubmissionSaveForLaterMessageIsShown(true);
    };

    const saveForLater = async (): Promise<void> => {

        if (stringContentData !== null)
            stringContentData?.map((content) => {

                if (content.key === Strings.SubmissionDocsNotSubmitted) {
                    setSaveLaterText(content.value);
                    setSaveForLaterModalModalDialogOpen(true);
                }

            });
        
    };

    //TODO: Ajay needs to change this code for showing the tooltips from DB.
    const GetStringValue = async (): Promise<void> => {
        const token = await authService.getAccessToken();
        const criteria = [Strings.SubmissionDocsNotSubmitted, Strings.TermsOfService, Strings.DataVerification, Strings.AttestationDocument, Strings.SignatureAuthorization, Strings.ProofOfClaim, Strings.OptionalSupportingDocumentText];
        await fetch(`api/Common/GetStringsByKeys`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(criteria)
        })
            .then((response) => {
                const contentType = response.headers.get('content-type');

                if (!contentType || !contentType.includes('application/json')) {
                    throw new TypeError("Oops, we haven't got JSON!");
                }

                return response.json();
            })
            .then((data) => {
                setStringContentData(data);
            })
            .catch((error) => console.error(error));
    }

    const closeSupportingDocModal = (): boolean => {
        return (allFilesUploaded() && (claimFileState.publishMessageSuccess || submissionSaveForLaterMessageIsShown))
            ? true : false
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleModalClose}
        >

            <Card className={classes.claimmodal}>

                <CancelSubmissionModal
                    modalOpen={cancelSubmissionModalIsOpen}
                    handleCancelSubmissionClose={handleCancelSubmissionModalClosed}
                    handleSupportingDocModalClose={handleClose}
                    submissionId={outSubmissionId}
                    clearDocModal={handleModalClose}
                    setSubmissionIsCanceled={setSubmissionIsCanceled}
                    submissionDescription={submissionDescription}
                />

                <CardHeader
                    title={
                        <div className={classes.cardHeaderTitleDiv}>
                            <Typography variant="h5" className={classes.titleLeft} noWrap>
                                {submissionTypeDisplay}
                            </Typography>
                            <Typography variant="h5" className={classes.titleRight} noWrap>
                                {submissionDescription}
                            </Typography>
                        </div>
                    }
                    subheader={
                        <div>
                            <InputLabel className={classes.label}>Case:</InputLabel>
                            <Typography variant="h6" className={classes.title} noWrap>
                                {caseName}
                            </Typography>
                            <Typography variant="h6" className={classes.title} noWrap>
                                {caseNumber}
                            </Typography>
                        </div>
                    }
                    classes={{ title: classes.modalHeaderTitle }}
                    action={<Icon onClick={() => { handleModalClose() }}>close</Icon>}>
                </CardHeader>

                <CardBody>
                    <Grid item xs={12}>
                        <OptionalFileUploader handleOptionalDocFileUpload={handleOptionalDocFileUpload} additionalDocuments={additionalDocuments} handleRemoveOptionalDocFileUpload={handleRemoveClick} stringContentData={stringContentData} />
                    </Grid>
                </CardBody>

                <CardFooter>
                    <CardActions className={classes.cardAction}>

                        <Button
                            disabled={filesAreSubmitted}
                            classes={filesAreSubmitted ? { root: classes.disabledButton, disabled: classes.disabledButton } :
                                { root: classes.primaryBtn, disabled: classes.primaryBtn }}
                            onClick={() => { submitFiles(true) }}
                            color="primary" >
                            Submit
                        </Button>
                        <Button
                            disabled={filesAreSubmitted || hasFilesWithIncorrectFileType()}
                            classes={filesAreSubmitted || hasFilesWithIncorrectFileType() ? { root: classes.disabledButton, disabled: classes.disabledButton } :
                                { root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
                            onClick={() => { saveForLater() }}
                            variant="outlined"
                            color="secondary">
                            Save for later
                        </Button>
                        <Button
                            onClick={() => { !filesAreSubmitted ? cancelSubmission() : handleModalClose() }}
                            classes={{ root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
                            color="secondary">
                            {!filesAreSubmitted ? "Cancel" : "Close"}
                        </Button>
                    </CardActions>
                    {
                        saveLaterText &&
                        (
                            <SaveForLaterModal
                                message={saveLaterText}
                                modalOpen={SaveForLaterModalModalDialogOpen}
                                handleClose={handleSaveForLaterModalModalDialogClose}
                                saveforlatersubmit={submitFiles}
                            />

                        )
                    }
                </CardFooter>

            </Card >
        </Modal >
    );
};

export default DeficiencyOptionalFilesUploadModal;