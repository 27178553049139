import { ITemplate } from '../../types/types';

export const SAVE_CASE = 'SAVE_CASE';
export const SAVE_CASE_SUCCESS = 'SAVE_CASE_SUCCESS';
export const SAVE_CASE_FAILED = 'SAVE_CASE_FAILED';

export const GET_CASES = 'GET_CASES';
export const GET_CASES_SUCCESS = 'GET_CASES_SUCCESS';
export const GET_CASES_FAILED = 'GET_CASES_FAILED';

export const GET_CASE_BY_ID = 'GET_CASE_BY_ID';
export const GET_CASE_BY_ID_SUCCESS = 'GET_CASE_BY_ID_SUCCCESS';
export const GET_CASE_BY_ID_FAILED = 'GET_CASE_BY_ID_FAILED';
export const CLEAR_GET_CASE_BY_ID = 'CLEAR_GET_CASE_BY_ID';

export const GET_CASE_TEMPLATE = 'GET_CASE_TEMPLATE';
export const GET_CASE_TEMPLATE_SUCCESS = 'GET_CASE_TEMPLATE_SUCCESS';
export const GET_CASE_TEMPLATE_FAILED = 'GET_CASE_TEMPLATE_FAILED';

export const DOWNLOAD_CASE_TEMPLATE = 'DOWNLOAD_CASE_TEMPLATE';
export const DOWNLOAD_CASE_TEMPLATE_SUCCEEDED = 'DOWNLOAD_CASE_TEMPLATE_SUCCEEDED';
export const DOWNLOAD_CASE_TEMPLATE_FAILED = 'DOWNLOAD_CASE_TEMPLATE_FAILED';

export const IMPORT_CASE = 'IMPORT_CASE';
export const IMPORT_CASE_SUCCESS = 'IMPORT_CASE_SUCCESS';
export const IMPORT_CASE_FAILED = 'IMPORT_CASE_FAILED';

export const SELECT_CASE_TEMPLATE = 'SELECT_CASE_TEMPLATE';
export const SELECT_CASE_TEMPLATE_SUCCEEDED = 'SELECT_CASE_TEMPLATE_SUCCEEDED';
export const SELECT_CASE_TEMPLATE_FAILED = 'SELECT_CASE_TEMPLATE_FAILED';


export const saveCase = (payload: any) => ({
    type: SAVE_CASE,
    payload
});

export const saveCaseSuccess = (payload: any) => ({
    type: SAVE_CASE_SUCCESS,
    payload
});

export const saveCaseFailed = (payload: any) => ({
    type: SAVE_CASE_FAILED,
    payload
});

export const getCases = (payload: any) => ({
    type: GET_CASES,
    payload
});

export const getCasesSuccess = (payload: any) => ({
    type: GET_CASES_SUCCESS,
    payload
});

export const getCasesFailed = (payload: any) => ({
    type: GET_CASES_FAILED,
    payload
});

export const getCaseById = (payload: { caseId: number, history: any }) => ({
    type: GET_CASE_BY_ID,
    payload
});

export const getCaseByIdSuccess = (payload: any) => ({
    type: GET_CASE_BY_ID_SUCCESS,
    payload
});

export const getCaseByIdFailed = (payload: any) => ({
    type: GET_CASE_BY_ID_FAILED,
    payload
});

export const clearGetCaseById = () => ({
    type: CLEAR_GET_CASE_BY_ID
});

export const getCaseTemplate = (payload: string) => ({
    type: GET_CASE_TEMPLATE,
    payload
});

export const getCaseTemplateSuccess = (payload: ITemplate) => ({
    type: GET_CASE_TEMPLATE_SUCCESS,
    payload
});

export const getCaseTemplateFailed = (payload: any) => ({
    type: GET_CASE_TEMPLATE_FAILED,
    payload
});

export const importCase = (payload: { caseDetailId: number, userId: number, history: any }) => ({
    type: IMPORT_CASE,
    payload
});

export const importCaseSuccess = () => ({
    type: IMPORT_CASE_SUCCESS
});

export const importCaseFailed = (error: any) => ({
    type: IMPORT_CASE_FAILED,
    payload: error
});

export const downloadCaseTemplateAction = (caseId: number) => ({
    type: DOWNLOAD_CASE_TEMPLATE,
    caseId
});

export const downloadCaseTemplateSucceeded = () => ({
    type: DOWNLOAD_CASE_TEMPLATE_SUCCEEDED
});

export const downloadCaseTemplateFailed = (payload: any) => ({
    type: DOWNLOAD_CASE_TEMPLATE_FAILED,
    payload
});


export const selectCaseTemplateAction = (caseId: number) => ({
    type: SELECT_CASE_TEMPLATE,
    caseId
});

export const selectCaseTemplateSucceeded = () => ({
    type: SELECT_CASE_TEMPLATE_SUCCEEDED
});

export const selectCaseTemplateFailed = (payload: any) => ({
    type: SELECT_CASE_TEMPLATE_FAILED,
    payload
});