import React, { Fragment, useEffect, useState, useContext } from "react";
import { DEFAULT_ROWS_PER_PAGE, STANDARD_KABOB_HEIGHT } from '../../constants/CommonConstants';
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid, Skeleton, Theme, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SecuritiesTable, {
	IColumnHeader,
	ISorter,
	ISecuritiesTableFieldMap,
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import { getCaseSubmissionsAction, getSubmissionDocumentAction } from "../Submissions/submissions.actions";
import {
	ISubmissionListResponse,
	ISubmissionListState,
	ISubmissionListItem,
	IFilterDTO,
	ICaseState,
} from "../../types/types";
import {
	SubmissionState,
	SubmissionType,
	SubmissionTypeDisplay
} from "../../types/enums";
import {
	EpiqProjectManager,
	EpiqTechSupport,
	Nominee
} from '../../constants/roles';
import { createFilterDTOsFromColumnHeaders } from "../../utilities/filterCriteria";
import { Link } from "react-router-dom";
import { getSubmissionStatusList, getSubmissionStatusText, transformNomineeStatusList } from '../../utilities/submissionStatus';
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import { SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE } from "../../constants/CommonConstants";
import { filterSubmissions } from "../Submissions/common";

const useStyles = makeStyles((theme: Theme) => ({
	greenColor: {
		color: "rgb(16, 189, 143)",
	},
	redColor: {
		color: "red",
	},
	grayColor: {
		color: "gray",
	},
	viewButton: {
		background: "#006B86",
		color: "white",
	},
	disabled: {
		display: 'none'
	},
	links: {
		color: "#006B86"
	},
	kabobIconEnable: {
		color: "#087C9B",
		filter: "dropShadow(1px 1px 2px rgba(0, 0, 0, 0.44))",
		"&:disabled": {
			color: "#BCC0C5",
		},
	}
}));

const submissionColumns: IColumnHeader[] = [
	{
		field: "dateOfSubmission",
		label: "Date of Submission",
		parameterName: "dateOfSubmissionEnd",
		type: "date",
		value: "",
		allowFilter: true,
	},
	{ field: "number", label: "Submission #", parameterName: "number", type: "string", value: "", allowFilter: true },
	{ field: "fileName", label: "File Name", parameterName: "fileName", type: "string", value: "", allowFilter: true },
	{
		field: "institutionName",
		label: "Institution",
		parameterName: "institutionName",
		type: "string",
		value: "",
		allowFilter: true,
	},
	{
		field: "status",
		label: "Status",
		parameterName: "statusList",
		type: "selectMany",
		enumValues: [],
		value: "",
		allowFilter: true,
		hideSortIcon: true
	},
	{ field: 'actions', label: 'Actions' }
];

const relatedSubmissionColumns: IColumnHeader[] = [
	{ field: "dateOfSubmission", label: "Date of Submission" },
	{ field: "number", label: "Submission #" },
	{ field: "fileName", label: "File Name" },
	{ field: "institutionName", label: "Institution Name" },
	{ field: "status", label: "Status", hideSortIcon: true }
];

const defaultSort: ISorter = {
	property: "dateOfSubmission",
	order: "desc",
};

let sortInfo: ISorter = { ...defaultSort };

interface ICaseSubmissionGridProps {
	caseId?: number;
	selectSubmission: Function;
	resumeSubmission: Function;
	slideoutDrawer: Function;
	isDirty: boolean;
	selectDeficiencySubmission: Function;
}

export default function CaseSubmissionGrid({ caseId, selectSubmission, resumeSubmission, slideoutDrawer, isDirty, selectDeficiencySubmission}: ICaseSubmissionGridProps) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { isAuthenticated, isInitialized, isInRole, user } = useContext(UserContext) as UserContextType;
	let caseState: ICaseState = useSelector((state: any) => state.caseState);
	let rowsPerPage: number = 5;

	let submissionResponse: ISubmissionListResponse = useSelector((state: any) => {
		return {
			submissions: state.submissionListState.submissions,
			totalRowCount: state.submissionListState.totalRowCount
		};
	});

    const submissionState: ISubmissionListState = useSelector((state: any) => state.submissionListState);
    let initialFilter: IFilterDTO[];

	useEffect(() => {

        if (caseId) {
            populateStatusListFilter();
            initialFilter = createFilterDTOsFromColumnHeaders(submissionColumns);
            dispatch(getCaseSubmissionsAction({
                filter: initialFilter,
                caseId: caseId,
                institutionId: user.institutionId,
                sorter: sortInfo,
                pageNumber: submissionState.pageNumber || 1,
                rowsPerPage: rowsPerPage || 5
            }));
        }

	}, [dispatch, caseId, isDirty]);

    const populateStatusListFilter = () => {

        if (submissionColumns[4].enumValues?.length === 0) {
            submissionColumns[4].enumValues = getSubmissionStatusList(isInRole([Nominee]));
        }

    }

    const transformStatusListFilter = (filters: IFilterDTO[]) => {
        let statusListObj = filters.find(item => item.parameter === 'statusList');

        if (statusListObj)
            statusListObj.value = transformNomineeStatusList(statusListObj.value);

    }

    const fetchFilteredCaseSubmissionsList = (filters: IFilterDTO[], pageNumber: number, rowsPerPage: number) => {

        populateStatusListFilter();
        transformStatusListFilter(filters);

        dispatch(getCaseSubmissionsAction({
            filter: filters,
            caseId: caseId,
            institutionId: user.institutionId,
            sorter: sortInfo,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        }));
    };

	const getSubmissionDocument = (submissionId: number) => {
		dispatch(getSubmissionDocumentAction(submissionId));
	};

	const [kabobsMenu, setKabobsMenu] = React.useState<null | HTMLElement>(null);

	let [selectedRowId, setselectedRowId] = useState('-1');

	const handleResetFilter = () => {
		sortInfo = { ...defaultSort };
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>, elementId: string) => {
		setKabobsMenu(event.currentTarget);
		setselectedRowId(elementId);
	};

	const handleClose = () => {
		setKabobsMenu(null);
		setselectedRowId('-1');
	};

	const userCanPerformSubmissionActions: boolean = (isInitialized && isAuthenticated && isInRole([EpiqProjectManager, EpiqTechSupport, Nominee]));

	const getSubmissionActions = (caseId: number,
		submissionState: SubmissionState,
		submissionId: number,
		parentSubmissionId: number,
		caseName: string,
		caseNumber: string,
		submissionType: SubmissionType,
		submissionDescription: string,
		relatedSubmissions: ISubmissionListItem[],
		canAcceptDeficiencyResponse: boolean
	): any => {
		if (submissionState === SubmissionState.Started) {
			return (
				(caseState.selectedCaseDetail?.isAcceptingSubmissions &&
					<div>
						<MenuItem
							onClick={() => {

								let idToResume = 0;
								let submissionTypeToResume: SubmissionType = 0

								if (relatedSubmissions.length > 0) {
									idToResume = relatedSubmissions[0].id;
									submissionTypeToResume = relatedSubmissions[0].submissionTypeId;
								}
								else {
									idToResume = submissionId;
									submissionTypeToResume = submissionType;
								}

								resumeSubmission(
									caseId,
									submissionTypeToResume,
									idToResume,
									caseName,
									caseNumber,
									submissionState,
									submissionDescription
								);
							}
							}
						>
							<Tooltip title={
								<Typography style={{ fontSize: 14 }}>Resume Submission</Typography>
							}
								placement="left-end"
							>
								<Typography>Resume</Typography>
							</Tooltip>
						</MenuItem>
					</div>
				)
			);
		}

		if (submissionState === SubmissionState.Rejected) {
			return (
				(caseState.selectedCaseDetail?.isAcceptingSubmissions &&
					<div>
						<MenuItem
							onClick={() => {
								selectSubmission(
									caseId,
									SubmissionTypeDisplay.Replacement,
									(parentSubmissionId) ? parentSubmissionId : submissionId,
									caseName,
									caseNumber
								)
							}
							}
						>
							<Tooltip title={
								<Typography style={{ fontSize: 14 }}>Replace Submission</Typography>
							}
								placement="left-end"
							>
								<Typography>Replace</Typography>
							</Tooltip>
						</MenuItem>
					</div>
				)
			);
		}

		else {
			return (
				(
					<div>
						{(caseState.selectedCaseDetail?.isAcceptingSubmissions &&
							<>
								<MenuItem
									onClick={() => {
										selectSubmission(
											caseId,
											SubmissionTypeDisplay.Replacement,
											(parentSubmissionId) ? parentSubmissionId : submissionId,
											caseName,
											caseNumber
										)
									}
									}
								>
									<Tooltip title={
										<Typography style={{ fontSize: 14 }}>Replace Submission</Typography>
									}
										placement="left-end"
									>
										<Typography>Replace</Typography>
									</Tooltip>
								</MenuItem>

								<MenuItem
									onClick={() => {
										selectSubmission(
											caseId,
											SubmissionTypeDisplay.Update,
											(parentSubmissionId) ? parentSubmissionId : submissionId,
											caseName,
											caseNumber
										)
									}
									}
								>
									<Tooltip title={
										<Typography style={{ fontSize: 14 }}>Update Submission</Typography>
									}
										placement="left-end"
									>
										<Typography>Update</Typography>
									</Tooltip>
									</MenuItem>
							</>
						)}
						{(canAcceptDeficiencyResponse &&
							<MenuItem
								onClick={() => {
									selectDeficiencySubmission(caseId,
										SubmissionTypeDisplay.Deficiency,
										(parentSubmissionId) ? parentSubmissionId : submissionId,
										caseName,
										caseNumber
									)
								}}
							>
								<Tooltip title={
									<Typography style={{ fontSize: 14 }}>Deficiency Respons</Typography>
								}
									placement="left-end"
								>
									<Typography>Defect Response</Typography>
								</Tooltip>
							</MenuItem>
						)}

					</div>
				)
			);
		}
	};

	const buildRelatedSubmissionRows = (parentIndex: number, row: ISubmissionListItem): ISecuritiesTableFieldMap[] => {
		var relatedSubmissionRows: ISecuritiesTableFieldMap[] = [];

		row.relatedSubmissions &&
			row.relatedSubmissions.forEach((item, index) => {
				let deepIndex = parentIndex + '.' + index;

				if (isInRole([Nominee]) && item.status.toLowerCase() === "error")
					item.status = SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE;

				let newItem: ISecuritiesTableFieldMap = {
					dateOfSubmission: { type: "date", value: item.dateOfSubmission },
					number: {
						type: "link",
						value: (
							<Link color="success" to={`/SubmissionDetail/${item.id}`}>
								{item.submissionDescription}
							</Link>
						)
					},
					fileName: {
						type: "link",
						value: (
							<span style={{ cursor: "pointer", color: "#006B86" }}						
								onClick={() => {
									getSubmissionDocument(item.id);
								}}
							>
								{item.fileName}
							</span>

						)
					},
					institutionName: {
						type: "link",
						value: (
							<Link color="success" to="#" onClick={() => { slideoutDrawer(item.institutionId, true) }}>{item.institutionName}</Link>
						),
						sortValue: item.institutionName,
					},
					status: {
						type: "string",
						value: <span>{getSubmissionStatusText(isInRole([Nominee]), item.status) || ''}</span>
					}
				};

				relatedSubmissionRows.push(newItem);
			});

		return relatedSubmissionRows;
	};

	const buildSubmissionRows = (): ISecuritiesTableFieldMap[] => {
		var submissionRows: ISecuritiesTableFieldMap[] = [];

		let submissionsToDisplay: ISubmissionListItem[] = [];

		submissionsToDisplay = filterSubmissions(submissionResponse.submissions, isInRole([Nominee]))

		submissionsToDisplay &&
			submissionsToDisplay.forEach((item, index) => {
				if (isInRole([Nominee]) && item.status.toLowerCase() === "error")
					item.status = SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE;

				let newItem: ISecuritiesTableFieldMap = {
					collapsible: { type: 'boolean', value: item.hasRelatedSubmissions ? true : false },
					subTableRows: { type: 'array', value: buildRelatedSubmissionRows(index, item) },
					dateOfSubmission: { type: "date", value: item.dateOfSubmission },
					number: {
						type: "link",
						value: (
							<Link color="success" to={`/SubmissionDetail/${item.id}`}>
								{item.submissionDescription}
							</Link>
						),
						sortValue: item.submissionDescription,
					},
					fileName: {
						type: "link",
						value: (
							<span style={{ cursor: "pointer", color: "#006B86" }}								
								onClick={() => {
									getSubmissionDocument(item.latestSubmissionId);
								}}
							>
								{item.fileName}
							</span>

						),
						sortValue: item.fileName,
					},
					institutionName: {
						type: "link",
						value: (
							<Link color="success" to="#" onClick={() => { slideoutDrawer(item.institutionId, true) }}>{item.institutionName}</Link>
						),
						sortValue: item.institutionName,
					},
					status: {
						type: "string",
						value: <span>{getSubmissionStatusText(isInRole([Nominee]), item.status) || ''}</span>,
						sortValue: item.status,
					},
					actions: {
						type: "link",
						value:
							<Fragment>

								<div>
									<IconButton
										aria-label="more"
										className={classes.kabobIconEnable}
										id={"long-button-" + index}
										aria-haspopup="true"
										sx={{ padding: "0" }}
										onClick={(event: React.MouseEvent<HTMLElement>) => {
											handleClick(event, index.toString());
										}}
										disabled={[
											SubmissionState.Submitted,
											SubmissionState.Extracting,
											SubmissionState.Extracted,
											SubmissionState.Verifying,
											SubmissionState.Verified,
											SubmissionState.Loading,
											SubmissionState.Error,
											SubmissionState.PartiallyExtracted,
											SubmissionState.Received].includes(item.submissionStateId)
											|| !userCanPerformSubmissionActions
											|| (!caseState.selectedCaseDetail?.isAcceptingSubmissions && !item.canAcceptDeficiencyResponse)}
									>
										<MoreVertIcon />
									</IconButton>

									<Menu
										id={"long-menu-" + index}
										MenuListProps={{
											"aria-labelledby": "long-button" + index,
										}}
										anchorEl={kabobsMenu}
										open={selectedRowId === index.toString()}
										onClose={handleClose}
										onClick={handleClose}
										PaperProps={{
											style: {
												maxHeight: STANDARD_KABOB_HEIGHT * 4.5,
												width: "20ch",
											},
										}}
									>
										{userCanPerformSubmissionActions
											&& (caseState.selectedCaseDetail?.isAcceptingSubmissions || item.canAcceptDeficiencyResponse)
											&& getSubmissionActions(item.caseId,
												item.submissionStateId,
												item.id,
												item.parentSubmissionId,
												item.caseName,
												item.caseNumber,
												item.submissionTypeId,
												item.submissionDescription,
												item.relatedSubmissions || [],
												item.canAcceptDeficiencyResponse
											)
										}
									</Menu>
								</div>
							</Fragment>
					}
				};

				submissionRows.push(newItem);
			});

		return submissionRows;
	};

	const rows = buildSubmissionRows();

	return (
		<>
			{
				(submissionState.isLoaded) ?
					(<SecuritiesTable
						columns={submissionColumns}
						subTableColumns={relatedSubmissionColumns}
						subTableTitle={'Related Files'}
						rows={rows}
						totalRowCount={submissionResponse.totalRowCount}
						sorter={sortInfo}
						paging={'server'}
						pageNumber={submissionState.pageNumber - 1}  // page numbers need to be 0-based to keep mui happy
						rowsPerServerPage={submissionState.rowsPerPage}
						showFilter={true}
						dataFetchFunc={fetchFilteredCaseSubmissionsList}
						collapsible={true}
						resetFilterFunc={handleResetFilter}
					/>
					)
					:
					(<Card>
						<CardContent>
							{[...Array(5)].map((elementInArray, index) => (
								<Grid container spacing={2} key={index}>
									<Grid item xs={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={4}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={2}>
										<Skeleton animation="wave" />
									</Grid>
								</Grid>
							))}
						</CardContent>
					</Card>)
			}
		</>
	);
}