import alertsSaga from '../modules/Alerts/alerts.saga';
import { all } from 'redux-saga/effects';
import casebookSaga from '../modules/Casebook/casebook.saga';
import casesSaga from '../modules/Cases/cases.saga';
import settingsSaga from '../modules/Settings/settings.saga';
import userSaga from '../modules/User/user.saga';
import userlistSaga from '../modules/Users/userlist.saga';
import institutionlistSaga from '../modules/Institutions/institutionlist.saga';
import submissionsSaga from '../modules/Submissions/submissions.saga';
import cardCountsSaga from '../modules/Dashboard/dashboard.saga';
import adminCardCountsSaga from '../modules/Admin/admin.saga';
import templateSaga from '../modules/Templates/template.saga';
import claimFileSaga from '../modules/ClaimFileUpload/claimFile.saga';
import claimantsSaga from '../modules/Claimants/claimants.saga';
import cmxSaga from '../modules/Cmx/cmx.saga';

export default function* () {
    yield all([
        casesSaga(),
        casebookSaga(),
        settingsSaga(),
        alertsSaga(),
        userSaga(),
        userlistSaga(),
        institutionlistSaga(),
        submissionsSaga(),
        cardCountsSaga(),
        adminCardCountsSaga(),
        templateSaga(),
        claimFileSaga(),
        claimantsSaga(),
        cmxSaga()
    ])
}