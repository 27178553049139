import { Button, Grid, TextField, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ISupportingDocTypeProps, IStringValue } from "../../types/types";
import React, { useEffect, useState, useRef } from "react";
import authService from '../../components/api-authorization/AuthorizeService';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import InfoIcon from '@mui/icons-material/Info';

const createLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        addSupportOuter: {
            marginTop: '30px',
            maxHeight: '200px',
            overflow: 'visible'
        },
        title: {
            flexGrow: 1,
            color: 'black',
            display: "inline-flex"
        },
        disabledButton: {
            backgroundColor: '#cfcfcf !important',
        },
        primaryBtn: {
            color: '#fff!important',
            backgroundColor: '#007bff!important'
        },
        secondaryBtn: {
            color: 'blue',
            backgroundColor: '#fff!important',
            border: '1px solid',
            borderColor: 'blue'
        },
        paragraph: {
            marginTop: '10px',
            marginBottom: '0px'
        },
        inputDiv: {
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            marginTop: '5px',
        },
        inputTextField: {
            width: "75%",
            marginRight: "5px"
        },
        uploadedBtn: {
            color: 'white !important',
            backgroundColor: 'darkred !important'
        },
        supportingDocumentIcon: {
            fontSize: "20px;",
            color: "#d7d5d5",
        },
        dashBoardStartedWidth: {
            width: "500px"
        },
    }),
);

interface IhandleOptionalDocFileUploadProps {
    handleOptionalDocFileUpload: Function,
    handleRemoveOptionalDocFileUpload: Function,
    additionalDocuments: ISupportingDocTypeProps[],
    stringContentData: IStringValue[]
}


const OptionalFileUploader = ({ handleOptionalDocFileUpload, handleRemoveOptionalDocFileUpload, additionalDocuments, stringContentData }: IhandleOptionalDocFileUploadProps) => {
    const classes = createLocalStyles();
    let docFileProps: ISupportingDocTypeProps = additionalDocuments.find(r => r.id == additionalDocuments[0].id)!;
    const fileIsCorrectType: Function = docFileProps?.fileIsCorrectType;
    const [errors, setErrors] = useState<string[]>([]);

    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    let [optionalSupportingDocuments, setShowOptionalSupportingDocuments] = useState(false);
    const [optionalSupportingDocumentText, setOptionalSupportingDocumentText] = useState<string>("");

    const optionalSupportingDocumentsHandleClick = (event: any) => {

        if (stringContentData !== null) {
            stringContentData?.map((content) => {
                if (content.key.toLowerCase().replaceAll(" ", "") === "optionalSupportingDocumentText".toLowerCase().replaceAll(" ", "")) {
                    setOptionalSupportingDocumentText(content.value);
                    setShowOptionalSupportingDocuments(!optionalSupportingDocuments);
                    setTarget(event.target);
                }
            });

        }
    };


    const validateAndHandleOptionalDocFileUpload = (i: any, event: any) => {
        var file = event.target.files[0];
        let id: string = event.target.id;
        let newErrorList = [...errors];
        let idx = newErrorList.indexOf(id);

        if (file && file !== undefined) {
            let extension = file.name.substring(file.name.lastIndexOf(".") + 1).toLowerCase();

            if (extension === 'xlsx' || extension === 'docx' || extension === 'pdf' || extension === 'txt') {
                handleOptionalDocFileUpload(i, event);
                
                if (idx > -1) {
                    newErrorList.splice(idx, 1);
                }
            }

            else {
               
                if (idx === -1) {
                    newErrorList.push(id);
                }
            }
        }

        else {
            
            if (idx === -1) {
                newErrorList.push(id);
            }
        }

        setErrors(newErrorList);
    }

    return (
        <>
            <div className={classes.addSupportOuter}>
                <Typography variant="h6" className={classes.title} noWrap>
                    Add Optional Supporting Documents

                </Typography>
                <div style={{ position: "relative", display: "inline-flex" }} ref={ref}>
                    <Button style={{ background: "none", outline: "none", position: "absolute", left: "5px", padding: "0", top: "-18px", justifyContent: "left" }}
                        onClick={optionalSupportingDocumentsHandleClick}>
                        <InfoIcon className={classes.supportingDocumentIcon} /></Button>
                    <Overlay
                        show={optionalSupportingDocuments}
                        target={target}
                        placement="left"
                        container={ref}
                        containerPadding={20}
                        rootClose
                        onHide={() => setShowOptionalSupportingDocuments(false)}
                    >
                        <Popover className={classes.dashBoardStartedWidth} id="popover-contained">
                            <Popover.Header as="h3">Add Optional Supporting Documents</Popover.Header>
                            {optionalSupportingDocumentText && (
                                <Popover.Body dangerouslySetInnerHTML={{ __html: optionalSupportingDocumentText }}></Popover.Body>
                            )}
                        </Popover>
                    </Overlay>
                </div>
                {additionalDocuments?.map((x, i) => {
                    return (
                        <Grid item xs={12} key={ i }>
                            <div className={classes.inputDiv}>
                                <TextField
                                    error={errors.includes(`fileInput-${i}`)}
                                    className={classes.inputTextField}
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    helperText={errors.includes(`fileInput-${i}`) && "Unsupported file type"}
                                    label={errors.includes(`fileInput-${i}`) && "Error"}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    value={(() => {
                                        if (x.file) {
                                            return x.file.name;
                                        }

                                        if (x.existingFileName) {
                                            return x.existingFileName;
                                        }

                                        let element: HTMLInputElement = document.getElementById(`fileInput-${i}`) as HTMLInputElement;

                                        if (element && element.files && element.files[0]) {
                                            return element.files[0].name
                                        }

                                        return 'No file selected...';
                                    })()}
                                    id={`textBox-${i}`}
                                />
                                {x.file || x.existingFileName ? (
                                    <span className="btn-box">
                                        {additionalDocuments.length !== 1 && (
                                            <Button
                                                id={`remove-${i}`}
                                                variant="contained"
                                                className={classes.uploadedBtn}
                                                onClick={() => handleRemoveOptionalDocFileUpload(i)}
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </span>
                                ) : (
                                    <Button
                                        variant="contained"
                                        className={classes.primaryBtn}
                                        component="label"
                                    >
                                        Select a File
                                        <input
                                            id={`fileInput-${i}`}
                                            type="file"
                                            name="fileInput"
                                            accept=".xlsx, .docx, .pdf, .txt"
                                            hidden
                                            onChange={(event) => validateAndHandleOptionalDocFileUpload(i, event)}
                                        />
                                    </Button>

                                )}

                            </div>
                        </Grid>
                    );
                })}
            </div>
        </>
    );
}

export default OptionalFileUploader;