import { IRoles, IRegisterUser } from "../../types/types";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Icon,
    Modal,
    Theme,
    Box,
    Checkbox,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import React, { useCallback } from "react";
import { updateUser } from "./userlist.actions";
import { useDispatch } from "react-redux";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Typography } from "@mui/material";

const createLocalStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: "absolute",
        },
        modal: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            justifyContent: "center",
        },
        modalHeader: {
            backgroundColor: theme.palette.primary.main,
            minWidth: 500,
            color: theme.palette.common.white,
            textEmphasisColor: theme.palette.common.white,
            font: "14",
        },
        modalHeaderTitle: {
            textDecoration: "underline",
            padding: "5px;",
        },
        disabledButton: {
            backgroundColor: "#cfcfcf !important",
        },
        title: {
            flexGrow: 1,
            color: "black",
        },
        label: {
            color: "black",
            float: "left",
            fontSize: "20px",
            marginRight: "5px",
            marginTop: "1px",
        },
        cardAction: {
            justifyContent: "flex-end",
        },
        primaryBtn: {
            color: "#fff!important",
            backgroundColor: "#007bff!important",
        },
        secondaryBtn: {
            color: "blue",
            backgroundColor: "#fff!important",
            border: "1px solid blue",
        },
        paragraph: {
            marginBottom: "0px",
        },
        modalBackground: {
            backgroundColor: "inherit",
        },
    })
);

interface IManageUserRolesModalProps {
    modalOpen: boolean;
    handleClose: any;
    selectedUser?: IRegisterUser;
    roles: IRoles[];
}

const ManageUserRolesModal = ({
    modalOpen,
    handleClose,
    selectedUser,
    roles }: IManageUserRolesModalProps) => {
    const dispatch = useDispatch();
    const classes = createLocalStyles();
    const [selectedRoles, setSelectedRoles] = React.useState(roles);

    const handleModalClosed = useCallback(() => {

        if (!modalOpen)
            return;

        handleClose(false);

    }, [handleClose]);

    const updateUserRoles = (): void => {

        if (!selectedUser)
            return;

        let selectedroles: string[] = [];

        selectedRoles.forEach(function (role) {

            if (!role?.isChecked)
                return;

            var test = role.id;
            selectedroles.push(test);
        });

        if (selectedroles.length > 0)
            selectedUser.roles = selectedroles;

        dispatch(updateUser(selectedUser));
        setSelectedRoles(roles);
        handleClose(false);

    };


    const handleSelectedRoles = (e: React.ChangeEvent<HTMLInputElement>) => {
        let clonedRoles = [...selectedRoles];

        clonedRoles.forEach(function (clonedrole) {

            if (clonedrole.id === e.target.value)
                clonedrole.isChecked = e.target.checked;

        });

        setSelectedRoles(clonedRoles);
    };

    return (
        <Modal open={modalOpen} onClose={handleModalClosed}>
            <Card className={classes.modal}>
                <CardHeader
                    title="Manage User Roles"
                    classes={{ title: classes.modalHeaderTitle }}
                    action={
                        <Icon
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                handleModalClosed();
                            }}
                        >
                            close
                        </Icon>
                    }
                ></CardHeader>

                <CardContent className={classes.modalBackground}>
                    <Typography variant="h6" className={classes.title} noWrap>
                        User: {selectedUser?.email}
                    </Typography>

                    <Box>
                        <div>
                            <List>
                                {roles.map((role: IRoles) => (
                                    <ListItem button key={role.id}>
                                        <Checkbox sx={{ color: 'black' }}
                                            color="primary"
                                            checked={role.isChecked ? true : false}
                                            onChange={handleSelectedRoles}
                                            value={role.id}
                                            inputProps={{ "aria-label": "checkbox" }}
                                            disabled={role.disabled}
                                            name={role.id}
                                        />
                                        <ListItemText primary={role.name} />
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    </Box>
                </CardContent>
                <CardActions className={classes.cardAction}>
                    <Button
                        classes={{ root: classes.primaryBtn }}
                        onClick={() => {
                            updateUserRoles();
                        }}
                        color="primary"
                    >
                        Continue
                    </Button>
                    <Button
                        onClick={() => {
                            handleModalClosed();
                        }}
                        classes={{ root: classes.secondaryBtn, disabled: classes.secondaryBtn }}
                        color="secondary"
                    >
                        Cancel
                    </Button>
                </CardActions>
            </Card>
        </Modal>
    );
};

export default ManageUserRolesModal;