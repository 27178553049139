import { INTEGER_FORMAT_REGEX } from '../../constants/CommonConstants';

import { Card, CardContent, Grid, InputLabel, Skeleton, Theme, Typography } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from "react";
import SecuritiesCard from "../../components/Shared/SecuritiesCard";
import { IClaimCountsDTO, ISubmissionDetail } from "../../types/types";
import { getClaimCountsForSubmission, getSummedClaimCountsForSubmission, getSubmissionDetailById } from './submissions.api';


const useStyles = makeStyles((theme: Theme) => ({
    title: {
        flexGrow: 1,
        fontSize: '15px',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: '500',
        lineHeight: '1.5',
        flexWrap: 'wrap',
        margin: '0px 39px 0.35em 14px',
        color: '#000000',
        display: 'flex'
    },
    newLineBox: {
        flexGrow: 1,
        flexWrap: 'wrap',
        margin: '0px 39px 0px 14px',
        display: 'inline-flex',
        overflow: 'visible',

        color: '#000000',
        fontSize: '15px',
        fontFamily: 'Roboto, sans-serif',
        fontWeight: '500',
        lineHeight: '1.5',
    },
    label: {
        flexGrow: 1,
        flexWrap: 'wrap',
        margin: '0px 39px 0px 14px',
        display: 'inline-flex',
        overflow: 'visible',

        color: '#0F0F0F',
        fontWeight: '600',
        fontSize: '14px',
        fontFamily: 'Montserrat, sans-serif',
        lineHeight: '1.5',
    },
    subTitle: {
        color: 'grey',
        fontStyle: 'italic',
        opacity: '.5',
        marginLeft: '5px',
        marginRight: '50px',
    },
    countTitle:{
        marginLeft: '5px',
        lineHeight: '1.5',
        display: 'flex'
    },
    tcountText:{
        marginBottom: '0.5rem',
        fontFamily: 'Montserrat, sans-serif',
        color: '#231F20',
    },
    contactDetails: {
        background: '#FBFBFB',
        margin: '23px 0px 0 0px',
        borderBottom: '1px solid #838A95',
        paddingTop:'0px !important'
    },
    reporedDetails: {
        margin: '0',
        borderBottom: '1px solid #838A95',
        paddingTop: '0px!important'
    },
    transactionsDetails: {
        margin: '0',
        borderBottom: '1px solid #838A95',
        paddingTop: '0px!important'
    },
    commonRightBorder: {
        borderRight: 'none',
        paddingRight: '0px',
        marginRight: '20px',
        paddingTop: '16px'
    },
    commonRightBorderRight: {
        borderRight: '1px solid #e3e5e7',
        paddingRight: '40px'
    },
    claimAccepted: {
        background: '#f2f0ee',
        paddingTop: '0px !important'
    },
    summaryCountMatchColor: {
        color: '#ff0000',
        marginBottom: '0.5rem',
        fontFamily: 'Montserrat, sans-serif',
    }
}));

interface ISubmissionDetailSummaryFormProps {
    submissionDetail?: ISubmissionDetail;
    submissionId?: number;
    shouldSumCounts?: boolean;
}



const companyAddress = (submissionDetail: ISubmissionDetail) => {
    
    var address = "";

    if (submissionDetail) {
        if (submissionDetail.summaryCompanyCity)
            address += submissionDetail.summaryCompanyCity + ", ";

        if (submissionDetail.summaryCompanyState)
            address += submissionDetail.summaryCompanyState + " ";

        if (submissionDetail.summaryCompanyZipCode)
            address += submissionDetail.summaryCompanyZipCode;
    }
    
    



    return (
        <address>
            {submissionDetail.summaryCompanyName && <>{`${submissionDetail.summaryCompanyName}`} <br /></>}
            {submissionDetail.summaryCompanyAddress1 && <>{`${submissionDetail.summaryCompanyAddress1}`} <br /></>}
            {submissionDetail.summaryCompanyAddress2 && <>{`${submissionDetail.summaryCompanyAddress2}`} <br /></>}
            {address && <>{`${address}`} <br /></>}
            {submissionDetail.summaryCompanyCountry && <>{`${submissionDetail.summaryCompanyCountry}`} <br /></>}
        </address>
    );
};

const contactInfo = (submissionDetail: ISubmissionDetail) => {
    return (
        <div>
            {submissionDetail.summaryContactName && <>{`${submissionDetail.summaryContactName}`} <br /></>}
            {submissionDetail.summaryContactPhone && <>{`${submissionDetail.summaryContactPhone}`} <br /></>}
            {submissionDetail.summaryContactEmail && <>{`${submissionDetail.summaryContactEmail}`} <br /></>}
        </div>
    );
};

export default function SubmissionDetailSummary({
    submissionDetail,
    submissionId,
    shouldSumCounts=false
}: ISubmissionDetailSummaryFormProps) {
    const classes = useStyles();
    const [localSubmissionDetail, setLocalSubmissionDetail] = useState<ISubmissionDetail | null>(submissionDetail || null);

    const notImportedClaims = localSubmissionDetail ? 
        localSubmissionDetail.beneficialOwnerCount - localSubmissionDetail.loadedClaimantCount : 0;

    const notImportedTransactions = localSubmissionDetail ? 
        localSubmissionDetail.transactionCount - localSubmissionDetail.loadedTransactionCount : 0;

    const [claimCounts, setClaimCounts] = useState<IClaimCountsDTO | null>(null);
    const [isStarted, setIsStarted] = useState(false);

    useEffect(() => {
        if (submissionDetail) {
            // set the local submission detail copy
            setLocalSubmissionDetail({ ...submissionDetail });
            setIsStarted(submissionDetail.submissionStatus.toLowerCase() === 'started');

            if (shouldSumCounts) {
                getSummedClaimCountsForSubmission(submissionDetail.id)
                    .then((data) => {
                        if (data) {
                            setClaimCounts(data);
                        }
                    });
            } else {
                getClaimCountsForSubmission(submissionDetail.id)
                    .then((data) => {
                        if (data) {
                            setClaimCounts(data);
                        }
                    });
            }

        }

        if (submissionId) {
            //get the submission detail info
            getSubmissionDetailById(submissionId.toString(), false)
                .then((data) => {
                    setLocalSubmissionDetail(data);
                    setIsStarted(data.submissionStatus.toLowerCase() === 'started');
                })
                .catch((e: any) => {
                    console.log(e);
                });
                

            //also... get the claim counts
            getClaimCountsForSubmission(submissionId)
                .then((data) => {
                    if (data) {
                        setClaimCounts(data);
                    }
                });
        }
    }, []);

    const compareClaimantCount = (): boolean => {
        if (localSubmissionDetail && localSubmissionDetail.statedClaimantCount === localSubmissionDetail.beneficialOwnerCount) {
            return true;
        }

        return false;
    }

    const compareTransactionCount = (): boolean => {

        if (localSubmissionDetail && localSubmissionDetail.statedTransactionCount === localSubmissionDetail.transactionCount) {
            return true;
        }

        return false;
    }

    const formatCount = (count: number, isStarted: boolean) => {
        if (isStarted && count === 0) {
            return ''
        }

        return count.toString().replace(INTEGER_FORMAT_REGEX, '$1,');
    }

    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <SecuritiesCard>
                    {localSubmissionDetail ? (
                        <CardContent className={classes.title}>
                            <Grid container spacing={2}>

                                {
                                    isStarted ?
                                        <Grid className={classes.contactDetails} item xs={12} sm={12} lg={12}>
                                            <Typography >No data extracted from submission workbook</Typography>
                                        </Grid>
                                        :
                                        <Grid className={classes.contactDetails} item xs={12} sm={12} lg={12}>
                                            <InputLabel className={classes.label}>Contact Info</InputLabel>
                                            <Typography className={classes.newLineBox} noWrap component="div">
                                                <div className={classes.countTitle}>
                                                    <div className={classes.commonRightBorder}>
                                                        <div style={{ borderRight: '0px' }} className={classes.commonRightBorderRight}>
                                                            <p style={{ marginBottom: '5px' }}>Name</p>
                                                            <p className={classes.tcountText}> {localSubmissionDetail.summaryContactName && <>{`${localSubmissionDetail.summaryContactName}`} </>}</p>
                                                        </div>
                                                    </div>
                                                    <div className={classes.commonRightBorder}>
                                                        <div style={{ borderRight: '0px' }} className={classes.commonRightBorderRight}>
                                                            <p style={{ marginBottom: '5px' }}>Email</p>
                                                            <p className={classes.tcountText}>  {localSubmissionDetail.summaryContactEmail && <>{`${localSubmissionDetail.summaryContactEmail}`} </>}</p>
                                                        </div>
                                                    </div>
                                                    <div className={classes.commonRightBorder}>
                                                        <div style={{ borderRight: '0px' }} className={classes.commonRightBorderRight}>
                                                            <p style={{ marginBottom: '5px' }}>Phone Number</p>
                                                            <p className={classes.tcountText}> {localSubmissionDetail.summaryContactPhone && <>{`${localSubmissionDetail.summaryContactPhone}`} </>} </p>
                                                        </div>
                                                    </div>
                                                    <div className={classes.commonRightBorder}>
                                                        <div style={{ borderRight: '0px' }} className={classes.commonRightBorderRight}>
                                                            <p style={{ marginBottom: '5px' }}>Company Address</p>
                                                            <p className={classes.tcountText}>
                                                                {localSubmissionDetail.summaryCompanyName
                                                                    && <>{`${localSubmissionDetail.summaryCompanyName}`}</>}
                                                                {localSubmissionDetail.summaryCompanyAddress1
                                                                    && <>{`, ${localSubmissionDetail.summaryCompanyAddress1}`} </>} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Typography>
                                        </Grid>
                                }
                                

                                <Grid className={classes.reporedDetails} item xs={12} sm={6} lg={6}>
                                    <InputLabel style={{ lineHeight: '55px' }} className={classes.label}>Claims</InputLabel>
                                    <Typography className={classes.newLineBox} noWrap component="div">
                                        <div className={classes.countTitle}>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Reported</p>
                                                    <p className={isStarted || compareClaimantCount() ? classes.tcountText : classes.summaryCountMatchColor}> {formatCount(localSubmissionDetail.statedClaimantCount, isStarted)} </p>
                                                </div>
                                            </div>
                                            <div style={{ marginRight: '0', }} className={classes.commonRightBorder}>
                                                <div style={{ borderRight: '0px' }} className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>In Files</p>
                                                    <p className={isStarted || compareClaimantCount() ? classes.tcountText : classes.summaryCountMatchColor}> {formatCount(localSubmissionDetail.beneficialOwnerCount, isStarted)} </p>
                                                </div>
                                            </div>
                                            <div style={{ background: '#f2f0ee', paddingLeft: '20px', border: '1px solid #c7cace', borderBottomColor: '#f2f0ee', borderTop: 'none', position:'relative', top: '1px' }} className={classes.commonRightBorder}>
                                                <div style={{ borderRight: '0px' }} className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Imported</p>
                                                    <p className={classes.tcountText}> {formatCount(localSubmissionDetail.loadedClaimantCount, isStarted)} </p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Not Imported</p>
                                                    <p className={classes.tcountText}> {formatCount(notImportedClaims, isStarted)} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid className={classes.transactionsDetails} item xs={12} sm={6} lg={6}>
                                    <InputLabel style={{ lineHeight: '55px' }} className={classes.label}>Transactions</InputLabel>
                                    <Typography className={classes.newLineBox} noWrap component="div">
                                        <div className={classes.countTitle}>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Reported</p>
                                                    <p className={isStarted || compareTransactionCount() ? classes.tcountText : classes.summaryCountMatchColor}> {formatCount(localSubmissionDetail.statedTransactionCount, isStarted)} </p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>In Files</p>
                                                    <p className={isStarted || compareTransactionCount() ? classes.tcountText : classes.summaryCountMatchColor}> {formatCount(localSubmissionDetail.transactionCount, isStarted)} </p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Imported</p>
                                                    <p className={classes.tcountText}> {formatCount(localSubmissionDetail.loadedTransactionCount, isStarted)} </p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Not Imported</p>
                                                    <p className={classes.tcountText}> {formatCount(localSubmissionDetail.transactionCount - localSubmissionDetail.loadedTransactionCount, isStarted)} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Typography>
                                </Grid>


                                <Grid className={classes.claimAccepted} item xs={12} sm={12} lg={12}>
                                    <InputLabel className={classes.label}>Claims Status</InputLabel>
                                    <Typography className={classes.newLineBox} noWrap component="div">
                                        <div className={classes.countTitle}>
                                            <div className={classes.commonRightBorder}>
                                                <div style={{ borderRight: '0px' }} className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Accepted</p>
                                                    <p className={classes.tcountText}> {claimCounts ? formatCount(claimCounts.accepted, isStarted) : ''}</p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Under Review</p>
                                                    <p className={classes.tcountText}> {claimCounts ? formatCount(claimCounts?.underReview, isStarted) : ''} </p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div style={{ borderRight: '0px' }} className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Deficient</p>
                                                    <p className={classes.tcountText}> {claimCounts ? formatCount(claimCounts.deficient, isStarted) : ' '} </p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Withdrawn</p>
                                                    <p className={classes.tcountText}> {claimCounts ? formatCount(claimCounts.withdrawn, isStarted) : ''} </p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Duplicate</p>
                                                    <p className={classes.tcountText}> {claimCounts ? formatCount(claimCounts.duplicate, isStarted) : ''} </p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Denied</p>
                                                    <p className={classes.tcountText}> {claimCounts ? formatCount(claimCounts.denied, isStarted) : ''} </p>
                                                </div>
                                            </div>
                                            <div className={classes.commonRightBorder}>
                                                <div className={classes.commonRightBorderRight}>
                                                    <p style={{ marginBottom: '5px' }}>Paid</p>
                                                    <p className={classes.tcountText}> {claimCounts ? formatCount(claimCounts.paid, isStarted) : ''} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Typography>
                                </Grid>

                            </Grid>
                        </CardContent>
                    ) : (
                        <Card>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} lg={2}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} lg={2}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} lg={2}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} lg={2}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={12} sm={8} lg={4}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={12} sm={4} lg={2}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={12} sm={8} lg={4}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={12} sm={8} lg={4}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </SecuritiesCard>
            </Grid>
        </Grid>
    );
    
    
}

function setClaimCounts(data: IClaimCountsDTO) {
    throw new Error('Function not implemented.');
}
