import { SubmissionTypeDisplay } from "../../types/enums";
import { ICaseState } from "../../types/types";
import {
    Grid,
    MenuItem,
    Theme,
    Tooltip,
    Typography
} from "@mui/material";
import React, { Fragment, useContext, useEffect } from "react";
import SecuritiesTable, {
    IColumnHeader,
    ISecuritiesTableFieldMap,
    ISorter,
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import SecuritiesOmniSearch from "../../components/Shared/SecuritiesOmniSearch";
import { useDispatch, useSelector } from "react-redux";
import { IFilterDTO } from "../../types/types";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { getCases } from "../Cases/cases.actions";
import makeStyles from '@mui/styles/makeStyles';
import { UserContext, UserContextType } from '../User/UserContext';
import { EpiqProjectManager, EpiqTechSupport, Nominee } from '../../constants/roles';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        background: "#006B86",
        size: 'small',
        margin: theme.spacing(1),
        color: 'white'
    },
    textLink: {
        color: theme.palette.text.primary,
        padding: '0.5em'
    },
    dashboardBackground: {
        paddingLeft: '5px',
        paddingRight: '5px',
        display: 'inline-block',
        "&:hover": {
            background: 'none',
        },
    },
    actionIcons: {
        fontSize: "25px",
        color: "#006B86",
    },
    actionIconsTransparent: {
        fontSize: "25px",
        color: "rgba(0, 0, 0, 0)",
    }

}));

const caseListFilterColumns: IColumnHeader[] = [
    {
        field: "actions",
        label: "Actions",
        hideSortIcon: true,
    },
    {
        field: "number",
        label: "Case Number",
        parameterName: "caseNumber",
        type: "string",
        value: "",
        allowFilter: false,
        hideSortIcon: true
    },
    {
        field: "isAcceptingSubmissions",
        label: "Accepting Filings",
        parameterName: "isAcceptingSubmissions",
        type: "boolean",
        value: "",
        allowFilter: false,
        hideSortIcon: true
    },
    {
        field: "name",
        label: "Case Name",
        parameterName: "caseName",
        type: "string",
        value: "",
        allowFilter: false,
        hideSortIcon: true
    },
    {
        field: "claimsDeadline",
        label: "Filing Deadline",
        parameterName: "claimsDeadline",
        type: "date",
        value: "",
        allowFilter: false,
        hideSortIcon: true
    },

];

interface CaseListGridProps {
    selectCase: Function;
    downloadTemplate: Function;
}

const DashboardCaseListGrid = ({ selectCase, downloadTemplate }: CaseListGridProps) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const caseState: ICaseState = useSelector((state: any) => state.caseState);
    let canSubmitClaim = false;
    const { isAuthenticated, isInitialized, isInRole, user } = useContext(UserContext) as UserContextType;

    if ((isInitialized && isAuthenticated) && (isInRole([EpiqProjectManager, EpiqTechSupport, Nominee])))
        canSubmitClaim = true;

    const initialFilter: IFilterDTO[] = [];//  = [

    if (user.isOffshoreUser)
        initialFilter.push({
            'parameter': 'isOffshoreUser',
            'type': 'boolean',
            'value': 'true'
        });

    useEffect(() => {
        if (user.id)	// avoid 2 database calls
            dispatch(getCases({ filter: initialFilter, showPastFilingDeadline: true }));
    }, [dispatch, user]);


    const buildCaseListRows = (): ISecuritiesTableFieldMap[] => {
        var caseListRows: ISecuritiesTableFieldMap[] = [];

        caseState.cases &&
            caseState.cases.forEach((item, index) => {
                let newItem: ISecuritiesTableFieldMap = {
                    actions: {
                        type: "link",
                        value: (
                            <Fragment>
                                {(item.currentTemplateId) && (
                                    <div>
                                        <div>
                                            {(item.isAcceptingSubmissions && canSubmitClaim) && (
                                                <MenuItem
                                                    onClick={() => {
                                                        selectCase(item.id, SubmissionTypeDisplay.Original, item.name, item.number);
                                                    }}
                                                    id={"menuItemAddSubmission" + item.id} className={classes.dashboardBackground}
                                                >
                                                    <Tooltip
                                                        title={<Typography style={{ fontSize: 14 }}>Upload Submission</Typography>} placement="top">
                                                        <UploadFileIcon className={classes.actionIcons} />
                                                    </Tooltip>

                                                </MenuItem>
                                            )}

                                            {(!item.isAcceptingSubmissions || !canSubmitClaim) && (
                                                <MenuItem className={classes.dashboardBackground} disabled={true}>
                                                    <Tooltip
                                                        title={<Typography style={{ fontSize: 14 }}></Typography>} placement="top">
                                                        <UploadFileIcon className={classes.actionIconsTransparent} />
                                                    </Tooltip>
                                                </MenuItem>
                                            )}

                                            <MenuItem
                                                onClick={() => {
                                                    downloadTemplate(item.id);
                                                }}
                                                id={"menuItemDownloadTemplate" + item.id} className={classes.dashboardBackground}>
                                                <Tooltip
                                                    title={<Typography style={{ fontSize: 14 }}>Download Template</Typography>} placement="top">
                                                    <FileDownloadOutlinedIcon className={classes.actionIcons} />
                                                </Tooltip>
                                            </MenuItem>
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        ),
                    },
                    number: { type: "string", value: item.number },
                    isAcceptingSubmissions: { type: "boolean", value: item.isAcceptingSubmissions },
                    name: {
                        type: "link",
                        value: (
                            <Link color="success" to={`CaseDetail/${item.id}`}>
                                {item.name}
                            </Link>
                        ),
                        sortValue: item.name,
                    },
                    claimsDeadline: { type: "date", value: item.claimsDeadline },

                };

                caseListRows.push(newItem);
            });

        return caseListRows;
    };

    const rows = buildCaseListRows();

    const handleSearchClick = (searchString: string) => {

        var filter: IFilterDTO[] = initialFilter;

        filter.push(
            {
                parameter: "OmniSearchString",
                type: "string",
                value: searchString
            });

        dispatch(getCases({ filter: filter, showPastFilingDeadline: true }));

    };

    const handleResetSearchClick = () => {

        dispatch(getCases({ filter: initialFilter, showPastFilingDeadline: true }));
    };

    return (
        <div>
            <SecuritiesOmniSearch
                id="dashboardCaseListSearch"
                headerLabel="Cases"
                tooltip="Cases"
                placeHolderText="Search for a case..."
                handleParentSearchClick={handleSearchClick}
                handleParentResetSearchClick={handleResetSearchClick}
            />
            <Grid container>
                <Grid item xs={12}>
                    {caseState.isLoading === true ? (
                        <>
                            {[...Array(5)].map((_elementInArray, index) => (
                                <Grid container spacing={2} key={index}>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    ) : (
                        <SecuritiesTable
                            cssSelectorId="dashboardCaseListTable"
                            columns={caseListFilterColumns}
                            rows={rows}
                            maxTableHeight={390}
                            sorter={{
                                property: "claimsDeadline",
                                order: 'desc'
                            } as ISorter}
                            disableSorting={true}

                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

export default DashboardCaseListGrid;