import React from 'react';
import { Button, Stack, TextField, Theme, Tooltip } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import LaunchIcon from '@mui/icons-material/Launch';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		locked: {
			'& .MuiInputBase-input': {
				color: theme.palette.text.primary
			},
			marginTop: 8
		},
		lockedUrl: {
			'& .MuiInputBase-input': {
				color: '#006B86'
			},
			marginTop: 8
		}
	})
);

const ConditionalWrapper = ({ condition, wrapper, children }: any) =>
	condition
		? wrapper(children)
		: children;

interface SecuritiesTextFieldProps {
	id: string,
	name?: string,
	value?: string | number,
	label?: string,
	fullWidth?: boolean,
	multiline?: boolean,
	type: "standard" | "disabled" | "locked" | "lockedUrl",
	tooltip?: string,
	zoom?: any
}

const SecuritiesTextField = ({ id, value, label, type, fullWidth, multiline, tooltip, zoom }: SecuritiesTextFieldProps) => {
	const classes = useStyles();
	const variantProps =
		type === "locked" || type === "lockedUrl"
			? { variant: "standard" as "standard" }
			: { variant: "filled" as "filled" };

	const [open, setOpen] = React.useState(false);

	function ScrollDialog(props: {
		open: boolean;
		handleClose: ((event: {}) => void) | undefined;
		title: string | undefined;
		text: string | number | undefined;
	}) {
		const [scroll] = React.useState<DialogProps['scroll']>('paper');
		const descriptionElementRef = React.useRef<HTMLElement>(null);

		React.useEffect(() => {
			if (props.open) {
				const { current: descriptionElement } = descriptionElementRef;

				if (descriptionElement !== null) {
					descriptionElement.focus();
				}
			}
		}, [props.open]);

		return (
			<div>
				<Dialog
					open={props.open}
					onClose={props.handleClose}
					scroll={scroll}
					aria-labelledby="scroll-dialog-title"
					aria-describedby="scroll-dialog-description"
				>
					<DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>
					<DialogContent dividers={scroll === 'paper'}>
						<DialogContentText
							id="scroll-dialog-description"
							ref={descriptionElementRef}
							tabIndex={-1}
							color="text.primary"
						>
							{props.text}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={props.handleClose}>Cancel</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}

	function checkOverflow(el: HTMLElement | null) {
		if (!el) return false;

		var curOverflow = el.style.overflow;

		if (!curOverflow || curOverflow === "visible")
			el.style.overflow = "hidden";

		var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
		el.style.overflow = curOverflow;
		return isOverflowing;
	}

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<section>
			<ConditionalWrapper
				condition={tooltip}
				wrapper={(children: any) => <Tooltip title={tooltip || ''} placement="top">{children}</Tooltip>}>
				<Stack direction="row" spacing={1}>
					<TextField
						id={id}
						value={value || ""}
						label={label}
						disabled={type === "disabled" || type === "locked"}
						fullWidth={fullWidth}
						multiline={multiline}
						className={type === 'locked' ? classes.locked : 'lockedUrl' ? classes.lockedUrl : ''}
						{...variantProps} />
					{zoom && value && checkOverflow(document.getElementById(id)) &&
						<Button style={{ background: "none", outline: "none", padding: "0", position: "relative", justifyContent: "left", minWidth: "20px", width: "15px", height: "15px" }} onClick={handleClickOpen}>
							<LaunchIcon style={{ display: 'inline' }} />
						</Button>
					}

					<ScrollDialog open={open} handleClose={handleClose} title={label} text={value} />

					
				</Stack>
			</ConditionalWrapper>
		</section>
	)
};

export default SecuritiesTextField;

