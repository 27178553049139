import { AlertType } from "../../types/enums";
import {ISubmissionDetail, ISubmissionListState, ISubmissionRejectionSlideoutProps } from "../../types/types";
import React, { useEffect } from 'react';
import { useSignal } from '@preact/signals-react';
import { getInstitutionDetailsByIdAction, getUserDetailsByIdAction, getSubmissionDetailByIdAction} from "./submissions.actions";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import InstitutionSlideoutDrawer from "./InstitutionSlideoutDrawer";
import RejectionsSlideoutDrawer from "./RejectionsSlideoutDrawer";
import SubmissionDetailForm from "./SubmissionDetailForm";
import { Theme } from '@mui/material';
import UserSlideoutDrawer from "./UserSlideoutDrawer";
import makeStyles from '@mui/styles/makeStyles';
import { showAlert } from "../Alerts/alerts.actions";
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme: Theme) => ({
    commonBackgroundCard: {
        boxShadow: 'none',
        padding: '0',
        margin: '14px 17px 0 5px',
        background: 'none',
        borderRadius: '0px',
    },
    title: {
        flexGrow: 1,
        marginBottom: '15px',
    },
}));

export default function SubmissionDetailPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    let selectedSubmissionDetail: ISubmissionDetail = useSelector((state: any) => state.submissionListState.submissionDetail);
    let { id } = useParams<{ id: string }>();
    let submissionListState: ISubmissionListState = useSelector((state: any) => state.submissionListState);
    let selectedUserSlideoutDetails: any = useSelector((state: any) => state.submissionListState.userSlideoutDetail);
    let [showInstitution, setShowInstitution] = React.useState(false);
    let [showUser, setShowUser] = React.useState(false);
    const [slideoutDrawerInstitutionOpen, setslideoutDrawerInstitutionOpen] = React.useState(false);
    const [slideoutDrawerUserOpen, setslideoutDrawerUserOpen] = React.useState(false);
    const slideoutRejectionsDrawerOpen = useSignal(false);
    let [submissionRejectionSlideoutProps, setSubmissionRejectionSlideoutProps] = React.useState<ISubmissionRejectionSlideoutProps>()

    const slideoutDrawerInstitution = (id: number) => {
        setShowInstitution(true);
        setShowUser(false);
        dispatch(getInstitutionDetailsByIdAction(id));
        setslideoutDrawerInstitutionOpen(true);
    };

    const slideoutDrawerUser = (id: number) => {
        setShowInstitution(false);
        setShowUser(true);
        dispatch(getUserDetailsByIdAction(id));
        setslideoutDrawerUserOpen(true);
    };


    const handleRejectionsDrawerClose = () => {
        slideoutRejectionsDrawerOpen.value = false;
     };

    const slideoutDrawerRejections = (id: number) => {

        var submission = submissionListState.submissions
            .find(r => r.id === id)

        slideoutRejectionsDrawerOpen.value = true;
        setSubmissionRejectionSlideoutProps(
            {
                submissionId: id,
                submissionFileName: (submission) ? submission.fileName : "",
                handleClose: handleRejectionsDrawerClose
            }
        );
    };


    const handleDrawerInstitutionClose = () => {
        setslideoutDrawerInstitutionOpen(false);
        setShowInstitution(false);
    };

    const handleDrawerUserClose = () => {
        setslideoutDrawerUserOpen(false);
        setShowUser(false);
    };

    const location = useLocation();

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: "pageview", page: path, title: "Submission Detail Page" });
    }, [location]);

    useEffect(() => {
        const submissionId = Number(id);

        if (isNaN(submissionId)) {
            dispatch(showAlert({
                hidden: false,
                message: `The requested submission id is invalid.`,
                severity: AlertType.error
            }))

            history.push('/SubmissionList')
            return;
        }

        dispatch(getSubmissionDetailByIdAction({ id: submissionId, history: history }));
    }, [dispatch, id, history]);

    
    return (
        <div className={classes.commonBackgroundCard}>

            {
                id && selectedSubmissionDetail &&
                <SubmissionDetailForm
                    submissionDetail={selectedSubmissionDetail}
                    slideoutDrawerInstitution={slideoutDrawerInstitution}
                    slideoutDrawerUser={slideoutDrawerUser}
                    submissionId={Number(id)}
                    slideoutDrawerRejections={slideoutDrawerRejections}
                />
            }

            {
                (showInstitution) && (submissionListState?.isLoading === false && submissionListState?.institutionDetail) &&
                (
                    <>
                        {
                            <InstitutionSlideoutDrawer
                                slideoutDrawerInstitution={submissionListState}
                                isOpen={slideoutDrawerInstitutionOpen}
                                handleClose={handleDrawerInstitutionClose}
                            />
                        }
                    </>
                )
            }

            {
                (showUser) && (submissionListState?.isLoading === false && selectedUserSlideoutDetails) &&
                (
                    <>
                        {
                            <UserSlideoutDrawer
                                selectedDrawerUser={selectedUserSlideoutDetails}
                                isOpen={slideoutDrawerUserOpen}
                                handleClose={handleDrawerUserClose}
                            />
                        }
                    </>
                )
            }

            {/* RejectionsSlideoutDrawer */}
            {
                slideoutRejectionsDrawerOpen && submissionRejectionSlideoutProps && 
                (
                    <>
                        {
                            <RejectionsSlideoutDrawer
                                isOpen={slideoutRejectionsDrawerOpen.value}
                                submissionRejectionSlideoutProps={submissionRejectionSlideoutProps}
                            />
                        }
                    </>
                )
            }

            {submissionListState.error && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
        </div>

    )
};