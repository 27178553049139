import React, { Component, useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { UserContext, UserContextType } from "../../modules/User/UserContext";

import { ApplicationPaths } from './ApiAuthorizationConstants';

const AuthorizeAndAllowRoute = ({component:Component, allowedRoles, ...rest}:any) => {
    const { isInitialized, isAuthenticated, isInRole } = useContext(UserContext) as UserContextType;
	    
    return <Route {...rest}
            render={(props:any) => {
                if (!isInitialized) {
                    //not initialized
                    return <div></div>;
                }

                if (isAuthenticated) {
                    //is authenticated and in role
                    if (allowedRoles && isInRole(allowedRoles)) {
                        //in role
                        return <Component {...props} />
                    }

                    //authenticated and not in role
                    return <Redirect to={'/errorPage'} />
                } 
                else {
                    //not authenticated
                    return <Redirect to={ApplicationPaths.Login} />
                }
            }}
        />
}

export default AuthorizeAndAllowRoute;