import { IAction, IUserListState } from '../../types/types';
import {
    APPROVE_USER,
    APPROVE_USER_FAILURE,
    APPROVE_USER_SUCCESS,
    DENY_USER,
    DENY_USER_FAILURE,
    DENY_USER_SUCCESS,
    REMOVE_USER_ACCESS,
    REMOVE_USER_ACCESS_FAILURE,
    REMOVE_USER_ACCESS_SUCCESS,
    GET_APPROVE_USER_CONTENT,
    GET_APPROVE_USER_CONTENT_FAILURE,
    GET_APPROVE_USER_CONTENT_SUCCESS,
    GET_USERLIST,
    GET_USERLIST_FAILURE,
    GET_USERLIST_SUCCESS,
    REVIEW_USER,
    REVIEW_USER_FAILURE,
    REVIEW_USER_SUCCESS,
    CLEAR_DENY_USER_RESPONSE,
    UPDATE_USER,
    UPDATE_USER_FAILURE,
    UPDATE_USER_SUCCESS,
    GET_ROLES_LIST,
    GET_ROLES_LIST_FAILURE,
    GET_ROLES_LIST_SUCCESS,
    CLEAR_REMOVE_USER_ACCESS_RESPONSE,
} from './userlist.actions';

const initialState: IUserListState = {
    isLoading: false,
    userList: [],
    institution: undefined,
    error: null,
    needsRefresh: false,
    registrationDetail: undefined,
    approveUserResponse: undefined,
    denyUserResponse: undefined,
    rolesList: []
}

const userListReducer = (state = initialState, { type, payload }: IAction) => {
    switch (type) {
        case GET_USERLIST:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case GET_USERLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                userList: payload,
                needsRefresh: false
            }
        case GET_USERLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case GET_APPROVE_USER_CONTENT:
            return {
                ...state,
                isLoading: true,
                approveUserResponse: null,
                needsRefresh: false
            }
        case GET_APPROVE_USER_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                institution: payload,
            }
        case GET_APPROVE_USER_CONTENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case APPROVE_USER:
            return {
                ...state,
                isLoading: true
            }
        case APPROVE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                approveUserResponse: payload,
                needsRefresh: true
            }
        case APPROVE_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case DENY_USER:
            return {
                ...state,
                isLoading: true
            }
        case DENY_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                denyUserResponse: payload,
                needsRefresh: true
            }
        case DENY_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case REVIEW_USER:
            return {
                ...state,
                isLoading: true
            }
        case REVIEW_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                registrationDetail: payload
            }
        case REVIEW_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case CLEAR_DENY_USER_RESPONSE:
            return {
                ...state,
                denyUserResponse: undefined,
                approveUserResponse: undefined
            }
        case REMOVE_USER_ACCESS:
            return {
                ...state,
                isLoading: true
            }
        case REMOVE_USER_ACCESS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                removeUserAccess: payload,
                needsRefresh: true
            }
        case REMOVE_USER_ACCESS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case CLEAR_REMOVE_USER_ACCESS_RESPONSE:
            return {
                ...state,
                removeUserAccess: undefined,
            }
        case UPDATE_USER:
            return {
                ...state,
                isLoading: true
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                updateUserResponse: payload
            }
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case GET_ROLES_LIST:
            return {
                ...state,
                isLoading: true,
            }
        case GET_ROLES_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                rolesList: payload,
            }
        case GET_ROLES_LIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        default:
            return state;
    }
}

export default userListReducer;