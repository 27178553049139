import { IAction, IInstitutionListState } from '../../types/types';

import { GET_INSTITUTIONLIST, GET_INSTITUTIONLIST_FAILURE, GET_INSTITUTIONLIST_SUCCESS, GET_INSTITUTION_BY_ID, GET_INSTITUTION_BY_ID_FAILED, GET_INSTITUTION_BY_ID_SUCCESS, GET_INSTITUTION_COUNTRIESSTATES, GET_INSTITUTION_COUNTRIESSTATES_FAILURE, GET_INSTITUTION_COUNTRIESSTATES_SUCCESS, GET_INSTITUTION_TYPE, GET_INSTITUTION_TYPE_FAILURE, GET_INSTITUTION_TYPE_SUCCESS, UPDATE_INSTITUTION, UPDATE_INSTITUTION_FAILED, UPDATE_INSTITUTION_SUCCESS } from './institutionlist.actions';

const initialState: IInstitutionListState = {
    isLoading: false,
    institutionList: [],
    error: null,
    errorGetInstitutionType: null,
    errorGetCountriesStates: null,
    errorGetInstitutionById: null,
    errorUpdateInstitution: null
}

export default (state = initialState, { type, payload }: IAction) => {
    switch (type) {
        case GET_INSTITUTIONLIST:
            return {
                ...state,
                isLoading: true,
                error: null    
            }
        case GET_INSTITUTIONLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                institutionList: payload
            }
        case GET_INSTITUTIONLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        case GET_INSTITUTION_BY_ID:
             return {
                ...state,
                 isLoading: true,
                 institutiondUpdatedResponse: null, 
                 needsRefresh: false,
                 errorGetInstitutionById: null,
                 errorUpdateInstitution: null
             }
        case GET_INSTITUTION_BY_ID_SUCCESS:
            return {
                 ...state,
                 isLoading: false,
                 selectedInstitutionDetails: payload
            }
        case GET_INSTITUTION_BY_ID_FAILED:
            return {
                 ...state,
                 isLoading: false,
                 errorGetInstitutionById: payload
                }
        case UPDATE_INSTITUTION:
             return {
                  ...state,
                  isLoading: true,
                  institutionDetail: null,
                  updateInstitutionResponse: null,
                  updateSuccess: false,
                  errorUpdateInstitution: null
             }
        case UPDATE_INSTITUTION_SUCCESS:
             return {
                  ...state,
                  isLoading: false,
                  institutionDetail: payload,
                  updateInstitutionResponse: payload,
                  updateSuccess: true
             }
        case UPDATE_INSTITUTION_FAILED:
             return {
                  ...state,
                  isLoading: false,
                  errorUpdateInstitution: payload,
                  updateSuccess: false
             }
        case GET_INSTITUTION_TYPE:
              return {
                   ...state,
                  isLoading: true,
                  errorGetInstitutionType: null
              }
        case GET_INSTITUTION_TYPE_SUCCESS:
             return {
                  ...state,
                  isLoading: false,
                  institutionTypeList: payload
             }
         case GET_INSTITUTION_TYPE_FAILURE:
              return {
                   ...state,
                   isLoading: false,
                  errorGetInstitutionType: payload
              }
         case GET_INSTITUTION_COUNTRIESSTATES:
               return {
                     ...state,
                   isLoading: true,
                   errorGetCountriesStates:  null
               }
         case GET_INSTITUTION_COUNTRIESSTATES_SUCCESS:
              return {
                    ...state,
                    isLoading: false,
                    countriesstatesInstitution: payload
              }
         case GET_INSTITUTION_COUNTRIESSTATES_FAILURE:
               return {
                    ...state,
                    isLoading: false,
                   errorGetCountriesStates: payload
               }
    
        default:
            return state;
    }
}