import {
	GET_CLAIMANT_STATUS_BY_ID_LIST, GET_CLAIMANT_STATUS_BY_ID_LIST_FAILED, GET_CLAIMANT_STATUS_BY_ID_LIST_SUCCESS, CLEAR_CLAIMANT_STATUS_BY_ID_LIST
} from './cmx.actions';

import { ICmxState, IAction } from '../../types/types';

const initialState: ICmxState = {
	statusLoading: false,
	statusLoaded: false,
	cmxClaimantStatusList: [],
	error : undefined
};

export default (state = initialState, { type, payload }: IAction) => {
	switch (type) {
		case GET_CLAIMANT_STATUS_BY_ID_LIST:
			return {
				...state,
				statusLoading: true,
				statusLoaded: false,
				cmxClaimantStatusList: []
			}
		case GET_CLAIMANT_STATUS_BY_ID_LIST_SUCCESS:
			return {
				...state,
				statusLoading: false,
				statusLoaded: true,
				cmxClaimantStatusList: payload
			}
		case GET_CLAIMANT_STATUS_BY_ID_LIST_FAILED:
			return {
				...state,
				statusLoading: false,
				statusLoaded: false,
				cmxClaimantStatusList: [],
				error: payload
			}
		case CLEAR_CLAIMANT_STATUS_BY_ID_LIST:
			return {
				...state,
				statusLoading: false,
				statusLoaded: false,
				cmxClaimantStatusList: [],
				error: undefined
			}
		default:
			return state;
	}
};