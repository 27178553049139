import { IColumnHeader } from '../components/Shared/SecuritiesTable/SecuritiesTable';
import { IFilterDTO } from '../types/types';

export const createFilterCriteria = (filterList: IFilterDTO[]): any => {
	let c: any = {};

	for (var i = 0; i < filterList.length; i++) {
		let f: IFilterDTO = filterList[i];

		switch (f.type) {
			case 'number':
				if (f.value !== '') {
					c[f.parameter] = parseFloat(f.value);
				}
				break;
			case 'date':
				if (f.value !== '') {
					let d = new Date(f.value);

					c[f.parameter] = d.toISOString();
				}
				break;
			case 'selectOne':
				if (f.value !== '' && f.value !== '0') {
					let arr: number[] = [];
					// parseInt should not need additional checking since we control
					// the values passed.
					arr.push(parseInt(f.value, 10));
					c[f.parameter] = arr;
				}
				break;
			case 'selectMany':
				if (f.value !== '' && f.value !== '0') {
					// split the string if necessary, convert values to int, pass the int array
					let arr: string[] = f.value.split(',');
					let numArr: number[] = [];

					for (var j = 0; j < arr.length; j++) {
						let item = arr[j];
						numArr.push(parseInt(item, 10));
					}

					c[f.parameter] = numArr;
				}
				break;
			case 'selectManyString':
				if (f.value !== '' && f.value !== '0') {
					// split the string if necessary, convert values to int, pass the int array
					let arr: string[] = f.value.split(',');
					let stringArr: string[] = [];

					for (var j = 0; j < arr.length; j++) {
						let item = arr[j];
						stringArr.push(item);
					}

					c[f.parameter] = stringArr;
				}
				break;

			case 'boolean':
				if (f.value !== '' && f.value !== '0') {
					c[f.parameter] = f.value === 'true' ? true : false;
				}
				break;
			default:
				if (f.value !== '') {
					c[f.parameter] = f.value.trim();
				}
		}
	}

	return c;
}


export const createFilterDTOsFromColumnHeaders = (filterColumns: IColumnHeader[]) => {
	let arr: IFilterDTO[] = [];

	for (var i = 0; i < filterColumns.length; i++) {

		if (filterColumns[i].allowFilter) {
			let item: IFilterDTO = {
				parameter: filterColumns[i].parameterName || '',
				type: filterColumns[i].type || '',
				value: filterColumns[i].value || ''
			};

			arr[i] = item;
		}
	}

	return arr;
}

export const headerContainsFilterValues = (filterColumns: IColumnHeader[]): boolean => {
	let filtersFound = false;

	for (var i = 0; i < filterColumns.length; i++) {

		if (filterColumns[i].allowFilter && filterColumns[i].value && filterColumns[i].value !== '') {
			filtersFound = true;
			break;
		}
	}

	return filtersFound;
}