import { GET_LOGGED_IN_USER, GET_LOGGED_IN_USER_SUCCESS, GET_LOGGED_IN_USER_FAILED } from './user.actions';
import { IAction, IUserState } from '../../types/types';

const initialState: IUserState = {
    isLoading: false,
    user: {
        name: ''
    },
    error: null
}

export default (state = initialState, { type, payload }: IAction) => {
    switch (type) {
        case GET_LOGGED_IN_USER:
            return {
                ...state,
                isLoading: true
            }
        case GET_LOGGED_IN_USER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: {name: payload}
            }
        case GET_LOGGED_IN_USER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: payload
            }
        default:
            return state;
    }
}