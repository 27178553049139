import { ISubmissionListState } from "../../types/types"; 
import React from "react";
import { Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";
import { convertUTCToLocalTime, formatPhoneNumber, formatUSZipCode } from "../../utilities/utilities";

const useStyles = makeStyles((theme: Theme) => ({
    institutionalDetails: {
        margin: "0px",
        padding: "80px 20px",
        alignItems: "center",
        justifyContent: "space-evenly",
        verticalAlign: "top",
    },
    institutionalDetailHead: {
        fontSize: "22px",
        fontWeight: "600",
        marginBottom: "40px",
    },
    institutionalDetailsContactOuter: {
        marginTop: "20px",
    },
    institutionalDetailTitle: {
        fontSize: "15px",
        fontWeight: "500",
    },
    institutionalDetailTitleEmail: {
        fontSize: "15px",
        fontWeight: "500",
        textTransform: "none",
    },
    institutionalDetailContact: {
        fontSize: "16px",
        fontWeight: "700",
        marginBottom: "20px",
    },
    institutionalDetailspan: {
        width: "150px",
        display: "inline-block",
    },
}));

interface ISlideoutDrawerProps {
    slideoutDrawerInstitution?: ISubmissionListState;
    isOpen: boolean;
    handleClose: any;
}

const InstitutionSlideoutDrawer = ({ slideoutDrawerInstitution, isOpen, handleClose }: ISlideoutDrawerProps) => {
    const classes = useStyles();

    return (
        <div>
            <Drawer anchor={"right"} open={isOpen} onClose={handleClose} sx={{ zIndex: "9999" }}>
                <Box sx={{ width: 380 }} role="presentation" onClick={handleClose} onKeyDown={handleClose}>
                    <div className={classes.institutionalDetails}>
                        <h1 className={classes.institutionalDetailHead}>Institution Details</h1>

                        <h2 className={classes.institutionalDetailContact}>
                            {slideoutDrawerInstitution?.institutionDetail.name}
                        </h2>

                        <h2 className={classes.institutionalDetailTitle}>
                            <div>{slideoutDrawerInstitution?.institutionDetail.address1}</div>
                            <div>{slideoutDrawerInstitution?.institutionDetail.address2}</div>
                            <div>{slideoutDrawerInstitution?.institutionDetail.address3}</div>
                            <div>
                                {slideoutDrawerInstitution?.institutionDetail?.city}
                                {slideoutDrawerInstitution?.institutionDetail?.city ? ", " : ""}
                                {slideoutDrawerInstitution?.institutionDetail?.stateProvince}
                                <span> </span>
                                {slideoutDrawerInstitution?.institutionDetail.country.toUpperCase() === "UNITED STATES" ?
                                    formatUSZipCode(slideoutDrawerInstitution?.institutionDetail.zipCode) :
                                    slideoutDrawerInstitution?.institutionDetail.zipCode}
                            </div>

                            {
                                (slideoutDrawerInstitution?.institutionDetail?.country.toUpperCase() !== "UNITED STATES") && (
                                    <div>{slideoutDrawerInstitution?.institutionDetail.country}</div>
                                )
                            }
                           
                            <div>{formatPhoneNumber(slideoutDrawerInstitution?.institutionDetail.phone)}</div>
                            <div>{slideoutDrawerInstitution?.institutionDetail.emailAddress}</div>
                        </h2>

                        <div className={classes.institutionalDetailsContactOuter}>
                            <h2 className={classes.institutionalDetailTitle}>
                                <div>
                                    <span>
                                        <strong>Created On:</strong>
                                    </span>{" "}
                                    {
                                        convertUTCToLocalTime(
                                        slideoutDrawerInstitution?.institutionDetail?.insertedOn === undefined
                                            ? ""
                                            : slideoutDrawerInstitution?.institutionDetail?.insertedOn
                                        )
                                    }
                                </div>

                                <div>
                                    <span>
                                        <strong>Created By:</strong>
                                    </span>{" "}
                                    {slideoutDrawerInstitution?.institutionDetail.insertedByUserName}
                                </div>

                                <div>
                                    <span>
                                        <strong>Last Updated:</strong>
                                    </span>{" "}
                                    {
                                        convertUTCToLocalTime(
                                        slideoutDrawerInstitution?.institutionDetail?.updatedOn === undefined
                                            ? ""
                                            : slideoutDrawerInstitution?.institutionDetail?.updatedOn
                                        )
                                    }
                                </div>
                            </h2>
                        </div>
                    </div>
                </Box>
            </Drawer>
        </div>
    );
};

export default InstitutionSlideoutDrawer;