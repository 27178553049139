import { INomineeCardCountsState, IAction } from '../../types/types';
import { GET_NOMINEE_CARD_COUNT_SUCCESS, GET_NOMINEE_CARD_COUNT_FAILURE, GET_NOMINEE_CARD_COUNT } from './dashboard.actions';

const initialState: INomineeCardCountsState = {
    isLoading: false,
    cardCounts: { submitted: 0, extracting: 0, extracted: 0, verifying: 0, verified: 0, loading: 0, loaded: 0, error: 0, rejected: 0, partiallyExtracted: 0, partiallyLoaded: 0, started: 0, deficient: 0},
    error: null
}

const dashboardReducer = (state = initialState, { type, payload }: IAction) => {

    switch (type) {
        case GET_NOMINEE_CARD_COUNT:
            return {
                ...state,
                isLoading: true
            }
        case GET_NOMINEE_CARD_COUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                cardCounts: payload
            }
        case GET_NOMINEE_CARD_COUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: payload
            }

        default:
            return state;
    }

}

export default dashboardReducer;