import { PARTIAL_INFO_MESSAGE, DEFAULT_ROWS_PER_PAGE } from '../../constants/CommonConstants';
import { IFilterDTO, IClaimantListState, IClaimantListResponse} from "../../types/types";
import React, { useContext, useEffect, useState } from "react";
import SecuritiesTable, {
	IColumnHeader,
	ISecuritiesTableFieldMap,
	ISorter
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import { UserContext, UserContextType } from "../../modules/User/UserContext";
import { createFilterDTOsFromColumnHeaders } from "../../utilities/filterCriteria";
import { getClaimantsForSubmissionAction, exportSubmissionClaimantsAction } from "./claimants.actions";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Grid, Skeleton, Theme } from "@mui/material";
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

export const accountTypes = [
	{ "label": "Individual", "value": '1' },
	{ "label": "Joint", "value": '2' },
	{ "label": "Partnership", "value": '3' },
	{ "label": "Corporation", "value": '4' },
	{ "label": "Trust", "value": '5' },
	{ "label": "Estate", "value": '6' },
	{ "label": "IRA", "value": '7' },
	{ "label": "Other", "value": '8' }
];

export const claimStatuses = [
	{ "label": "Accepted", "value": 'Accepted' },
	{ "label": "Deficient", "value": 'Deficient' },
	{ "label": "Denied", "value": 'Denied' },
	{ "label": "Duplicate", "value": 'Duplicate' },
	{ "label": "Paid", "value": 'Paid' },
	{ "label": "Under Review", "value": 'Under Review' },
	{ "label": "Withdrawn", "value": 'Withdrawn' },
];

interface IClaimantListGridProps {
	submissionId: number;
	cmxCACaseNumber: number;
}

const claimantColumns: IColumnHeader[] = [
	{ field: "cmxClaimNumber", label: "Epiq Claim Number", parameterName: "cmxClaimNumber", type: "number", value: "", allowFilter: true },
	{ field: "accountNumber", label: "Account Number", parameterName: "accountNumber", type: "string", value: "", allowFilter: true },
	{ field: "accountType", label: "Account Type", parameterName: "accountTypeList", type: "selectMany", enumValues: [], value: "", allowFilter: true },
	{ field: "keyName", label: "Beneficial Owner", parameterName: "keyName", type: "string", value: "", allowFilter: true },
	{ field: "status", label: "Claim Status", parameterName: "claimStatusList", type: "selectManyString", enumValues: [], value: "", allowFilter: true },
	{ field: 'actions', label: 'Actions', hideHeader: true }
];

const defaultSort: ISorter = {
	property: "cmxClaimNumber",
	order: "asc",
};

let sortInfo: ISorter = { ...defaultSort };

let filterBackup: IFilterDTO[];

const ClaimantListGrid = ({ submissionId, cmxCACaseNumber }: IClaimantListGridProps) => {
	const { isAuthenticated, isInitialized } = useContext(UserContext) as UserContextType;
	const dispatch = useDispatch();
	const cmxCaseNumber: number = cmxCACaseNumber;
	let initialFilter: IFilterDTO[];
	let claimantListState: IClaimantListState = useSelector((state: any) => state.claimantListState);
	let claimantResponse: IClaimantListResponse = useSelector((state: any) => {
		return {
			claimants: state.claimantListState.claimants,
			totalRowCount: state.claimantListState.totalRowCount
		};
	});

	claimantColumns[2].enumValues = accountTypes;
	claimantColumns[4].enumValues = claimStatuses;
	const location = useLocation();

	useEffect(() => {
		const path = location.pathname + location.search;
		ReactGA.send({ hitType: "pageview", page: path, title: "Claimant List Page" });
	}, [location]);
	
	useEffect(() => {
		if (isInitialized && isAuthenticated) {
			initialFilter = createFilterDTOsFromColumnHeaders(claimantColumns);

			filterBackup = [...initialFilter];

			dispatch(getClaimantsForSubmissionAction({
				submissionId: submissionId,
				cmxCaseNumber: cmxCaseNumber,
				filter: initialFilter,
				sorter: sortInfo,
				pageNumber: claimantListState.pageNumber || 1,
				rowsPerPage: claimantListState.rowsPerPage || DEFAULT_ROWS_PER_PAGE
			}));
		}
	}, [isAuthenticated, isInitialized, dispatch]);

	const handleResetFilter = () => {
		sortInfo = { ...defaultSort };
	};

	const fetchFilteredClaimantList = (filters: IFilterDTO[], pageNumber: number, rowsPerPage: number) => {

		filterBackup = [...filters];

		dispatch(getClaimantsForSubmissionAction({
			submissionId: submissionId,
			cmxCaseNumber: cmxCaseNumber,
			filter: filters,
			sorter: sortInfo,
			pageNumber: pageNumber,
			rowsPerPage: rowsPerPage
		}));
	};

	const exportFilteredClaimantList = () => {
		dispatch(exportSubmissionClaimantsAction({
			submissionId: submissionId,
			filter: filterBackup,
			cmxCaseNumber: cmxCACaseNumber
		}));
	};

	const hasAllData = (): boolean => {
		return (claimantResponse.claimants && claimantResponse.claimants.length > 0 ) ? true : false
	}

	const buildClaimantRows = (): ISecuritiesTableFieldMap[] => {
		var claimantRows: ISecuritiesTableFieldMap[] = [];

		claimantResponse.claimants &&
			claimantResponse.claimants.forEach((item, index) => {
								

				let newItem: ISecuritiesTableFieldMap = {
					cmxClaimNumber: { type: "number", value: item.cmxClaimNumber },
					accountNumber: { type: "string", value: item.accountNumber },
					accountType: { type: "string", value: accountTypes[item.accountTypeId - 1].label },
					keyName: { type: "string", value: item.keyName },
					status: { type: "string", value: item.claimStatus},
					actions: { type: "string", value: '' }
				};

				claimantRows.push(newItem);
			});


		return claimantRows;
	};

	const claimantRows = buildClaimantRows();

	return (
		<>
			{(claimantListState.isLoaded && claimantRows) ?
				(<>
					<SecuritiesTable
						columns={claimantColumns}
						rows={claimantRows}
						totalRowCount={claimantResponse.totalRowCount}
						sorter={sortInfo}
						paging={'server'}
						pageNumber={claimantListState.pageNumber - 1}  // page numbers need to be 0-based to keep mui happy
						rowsPerServerPage={claimantListState.rowsPerPage}
						showFilter={true}
						dataFetchFunc={fetchFilteredClaimantList}
						maxTableHeight={350}
						dataExportFunc={exportFilteredClaimantList}
						enableExport={true}
						resetFilterFunc={handleResetFilter}
					/>
					{
						(!hasAllData() && !claimantListState.error) &&
						<p style={{ color: 'red' }}> {PARTIAL_INFO_MESSAGE}</p>
					}
					{
						(claimantListState.error) &&
						<p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>
					}
				</>)
				:
				(<Card>
					<CardContent>
						{[...Array(DEFAULT_ROWS_PER_PAGE)].map((elementInArray, index) => (
							<Grid container spacing={2} key={index}>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={4}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
							</Grid>
						))}
					</CardContent>
				</Card>)
			}
		</>
	);
};

export default ClaimantListGrid;