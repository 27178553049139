import { TableCell, TableHead, TableRow, TableSortLabel, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { IColumnHeader, ISecuritiesTableFieldMap, Order } from './SecuritiesTable'
import React from 'react';

interface ISecuritiesTableHeaderProps {
	columns: IColumnHeader[];
	orderBy: keyof ISecuritiesTableFieldMap | undefined;
	order: Order;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ISecuritiesTableFieldMap) => void;
}

const createLocalStyles = makeStyles((theme: Theme) =>
	createStyles({
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		tableHead: {
			backgroundColor: 'white',
			borderRadius: '3px',
			position: 'sticky',
			top: 0,
			zIndex: 2
		},
		defaultHeader: {
			pointerEvents: 'none',
			cursor: 'not-allowed',
			color: 'black',
		},
	}),
);

export default function SecuritiesTableHeader<T>({ columns, orderBy, order, onRequestSort }: ISecuritiesTableHeaderProps) {
	const classes = createLocalStyles();
	const createSortHandler = (property: keyof ISecuritiesTableFieldMap) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={classes.tableHead}>
			<TableRow>
				{columns.map((column, index) => (
					<TableCell key={index} >
						<TableSortLabel className={classes.defaultHeader}
							active={orderBy === column.field && !column.hideSortIcon}
							direction={orderBy === column.field ? order : 'asc'}
							onClick={createSortHandler(column.field)}
							hideSortIcon={column.hideSortIcon || undefined}
						>
							{column.label}
							{orderBy === column.field ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}