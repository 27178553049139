import React, { useContext, useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Backdrop, IconButton, MenuItem, Card, CardContent, CircularProgress, Grid, Stack, Typography, Theme } from "@mui/material";
import SecuritiesCard from "../../components/Shared/SecuritiesCard";
import { ISubmissionDetail, IFilterDTO, IDocumentation, ISubmissionListState, IStringValue } from "../../types/types";
import { Skeleton } from "@mui/material";
import SecuritiesTable, {
	IColumnHeader,
	ISorter,
	ISecuritiesTableFieldMap,
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import { getDocumentAction, getSubmissionDocumentsListAction, clearSubmissionDocumentsListAction } from "../Submissions/submissions.actions";
import { submissionDocumentType } from "../Submissions/common";
import { convertUTCToLocalTime, getInfoJsonWithArray } from "../../utilities/utilities";
import { STANDARD_KABOB_HEIGHT, TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import { DocumentOrigin, Strings, DocumentType } from "../../types/enums";
import {
	EpiqProjectManager,
	EpiqTechSupport,
	Nominee
} from '../../constants/roles';
import { UserContext, UserContextType } from "../User/UserContext";
import { Menu } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SubmissionEpiqDocumentApproveModal from "./SubmissionEpiqDocumentApproveModal";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme: Theme) => ({
	kabobIconEnable: {
		color: "#087C9B",
		filter: "dropShadow(1px 1px 2px rgba(0, 0, 0, 0.44))",
		"&:disabled": {
			color: "#BCC0C5",
		},
	}

}));

const submissionColumns: IColumnHeader[] = [
	{ field: "submissionNumber", label: "Submission Number", parameterName: "submissionNumber", type: "string", value: "", allowFilter: true },
	{ field: "submittedOn", label: "Date of Submission", parameterName: "submittedOn", type: "date", value: "", allowFilter: true },
	{ field: "documentType", label: "Document Type", parameterName: "documentTypeList", type: "selectMany", enumValues: [], value: "", allowFilter: true, hideSortIcon: true },
	{ field: "fileName", label: "File Name", parameterName: "fileName", type: "string", value: "", allowFilter: true },
	{ field: "actions", label: "Actions" },
];

const sortInfo: ISorter = {
	property: "submittedOn",
	order: "desc",
};
interface ISubmissionDetailDocumentsByEpiqProps {
	submissionDetail: ISubmissionDetail;
}
export default function SubmissionDetailDocumentsByEpiq({ submissionDetail }: ISubmissionDetailDocumentsByEpiqProps) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { isInRole } = useContext(UserContext) as UserContextType;
	let documentListState: any = useSelector((state: any) => {
		return {
			submissionDocuments: state.submissionListState.submissionDocuments,
			documentsLoading: state.submissionListState.documentsLoading,
			documentsLoaded: state.submissionListState.documentsLoaded
		};
	});
	let submissionListState: ISubmissionListState = useSelector((state: any) => state.submissionListState);
	let isGenerating: boolean = useSelector((state: any) => state.submissionListState.deficientTemplateGenerating);
	const [selectedDocId, setSelectedDocId] = React.useState(0);
	const [selectedMailingBatchId, setSelectedMailingBatchId] = React.useState(0);
	const [showApproveDialog, setShowApproveDialog] = React.useState(false);
	const [approveDialogOpen, setApproveDialogOpen] = React.useState(false);
	const [submissionDocumentsModalOptions, setSubmissionDocumentsModalOptions] = useState<IStringValue | null>();
	let batchApprovalOptionsCompleted: boolean = useSelector((state: any) => state.submissionListState.batchApprovalOptionsResponse);
	var title = "Release defect notice and workbook batch.";
	document.title = "";

	useEffect(() => {


		if (batchApprovalOptionsCompleted)
			handleApproveDialogClose();

	}, [dispatch, isInRole, batchApprovalOptionsCompleted]);

	useEffect(() => {

		const fetchSubmissionDocumentsModalOptions = async () => {
			const submissionDocumentsModalOptionsData: any = await getInfoJsonWithArray([Strings.DeficiencyMailingBatchSendOne,
			Strings.DeficiencyMailingBatchAutoSendAll,
			Strings.DeficiencyMailingBatchReject]);
			setSubmissionDocumentsModalOptions(submissionDocumentsModalOptionsData);
		}

		if (!submissionDocumentsModalOptions)
			fetchSubmissionDocumentsModalOptions().catch(console.error);

	}, [dispatch, isInRole, submissionDocumentsModalOptions]);

	useEffect(() => {
		dispatch(clearSubmissionDocumentsListAction());

		dispatch(getSubmissionDocumentsListAction({
			submissionId: submissionDetail.id,
			filter: [{ parameter: 'includeRelatedSubmissions', type: 'boolean', value: 'true' }],
		}));
	}, [dispatch, isGenerating, submissionDetail.id, batchApprovalOptionsCompleted]);

	const getSubmissionDocumentTypeFilter = () => {

		return submissionDocumentType.filter(item => item.isInternal === true).sort((a, b) => a.label.localeCompare(b.label)).map(({ label, value }) => ({ label, value: value.toString() }));
	};

	submissionColumns[2].enumValues = getSubmissionDocumentTypeFilter();

	const getSubmissionDocument = (documentId: number) => {
		dispatch(getDocumentAction(documentId));
	};

	const getDocumentTypeForDisplay = (docType: number) => {
		
		for (let i = 0; i < submissionDocumentType.length; i++) {
			if (submissionDocumentType[i].value === docType) {
				return submissionDocumentType[i].label;
			}
		};

		return 'Document type not found';
	};

	const defaultFilter: IFilterDTO =
	{
		'parameter': 'includeRelatedSubmissions',
		'type': 'boolean',
		'value': 'true'
	};

	const fetchFilteredDocumentsList = (filters: IFilterDTO[]) => {

		if (!filters.find(s => s.parameter === defaultFilter.parameter)?.parameter) {
			filters.push(defaultFilter);
		}

		dispatch(getSubmissionDocumentsListAction({
			submissionId: submissionDetail.id,
			filter: filters
		}));
	};

	let isEpiqUser = true;

	if (isInRole([Nominee]))
		isEpiqUser = false;

	const [kabobsMenu, setKabobsMenu] = React.useState<null | HTMLElement>(null);
	let [selectedRowIndex, setSelectedRowIndex] = useState(-1);

	const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		setKabobsMenu(event.currentTarget);
		setSelectedRowIndex(index);
	};



	const handleClose = () => {
		setKabobsMenu(null);
		setSelectedRowIndex(-1);
	};

	const selectSubmissionDocument = (docid: number, mailingBatchId: number) => {

		if (docid) {
			setSelectedDocId(docid);
			setSelectedMailingBatchId(mailingBatchId);
			setShowApproveDialog(true);
			setApproveDialogOpen(true);
		}
	};

	const handleApproveDialogClose = () => {
		setApproveDialogOpen(false);
		setShowApproveDialog(false);
	};

	const buildDocumentRows = (): ISecuritiesTableFieldMap[] => {
		var documentRows: ISecuritiesTableFieldMap[] = [];
		let uploadedDocuments: IDocumentation[];

		if (!documentListState.documentsLoading &&
			!documentListState.documentsLoaded) {
			uploadedDocuments = submissionDetail.documentSubmissions;
		}
		else {
			uploadedDocuments = documentListState.submissionDocuments;
		}

		uploadedDocuments && uploadedDocuments.filter(a => a.documentOrigin === DocumentOrigin.Internal).forEach((item, index) => {

			let newItem: ISecuritiesTableFieldMap = {
				submissionNumber: {
					type: "string", value: item.submissionNumber
				},
				actions: {
					type: "link",
					value: (
						<Fragment>
							{(item.id) && (!item.isApproved) && (item.documentType === DocumentType.DeficiencyWorkbook) && (isInRole([EpiqProjectManager, EpiqTechSupport])) && (
								<div>
									<IconButton
										aria-label="more"
										className={classes.kabobIconEnable}
										id={"long-button-" + index}
										aria-haspopup="true"
										sx={{ padding: "0" }}
										onClick={(event: React.MouseEvent<HTMLElement>) => {
											handleClick(event, index);
										}}
									>
										<MoreVertIcon />
									</IconButton>

									<Menu
										id={"long-menu-" + index}
										MenuListProps={{
											"aria-labelledby": "long-button" + index,
										}}
										anchorEl={kabobsMenu}
										open={selectedRowIndex === index}
										onClose={handleClose}
										onClick={handleClose}
										PaperProps={{
											style: {
												maxHeight: STANDARD_KABOB_HEIGHT * 4.5,
												width: "25ch",
											},
										}}
									>

										<div>

											{
												isInRole([EpiqProjectManager, EpiqTechSupport]) && (
													<>
														<MenuItem
															onClick={() => {
																selectSubmissionDocument(item.id, item.mailingBatchId);
															}}
														>
															Batch Approval Options
														</MenuItem>
													</>
												)
											}

										</div>
									</Menu>
								</div>
							)}
						</Fragment>
					),
				},
				documentType: { type: "string", value: <div> {getDocumentTypeForDisplay(item.documentType)} </div> },
				fileName: {
					type: "link",
					value: (
						<span style={{ cursor: "pointer", color: "#006B86" }}
							onClick={() => {
								getSubmissionDocument(item.id);
							}}
						>
							{item.name}
						</span>
					),
					sortValue: item.name,
				},
				submittedOn: {
					type: "datetime", value: ((item.submittedOn == null) ? null : convertUTCToLocalTime(item.submittedOn))
				},
			};

			if (isEpiqUser) {
				documentRows.push(newItem);
			} else {
				if (item.documentType === DocumentType.DeficiencyWorkbook) {
					if (item.isMailingBatchSent) {
						documentRows.push(newItem);
					}
				} else {
					documentRows.push(newItem);
				}
			}
		});

		return documentRows;
	}

	const rows = buildDocumentRows();

	return (
		<Grid container spacing={2}>

			{/* generating document spinner */}
			<Grid container direction="row" justifyContent="flex-end">
				<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isGenerating}>
					<Stack alignItems="center">
						<CircularProgress color="inherit" />
						<br />

						<Typography variant="h4" color="#fff">
							Generating document...
						</Typography>
					</Stack>
				</Backdrop>
			</Grid>

			<Grid item xs={12}>
				<SecuritiesCard>
					{submissionDetail ? (

						//document list
						<CardContent>
							<div>
								<SecuritiesTable
									columns={submissionColumns}
									rows={rows}
									sorter={sortInfo}
									paging={'client'}
									showFilter={true}
									dataFetchFunc={fetchFilteredDocumentsList}
								/>
							</div>
						</CardContent>
					) : (

						//skeletons
						<Card>
							<CardContent>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={8} lg={4}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={4} lg={2}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={8} lg={4}>
										<Skeleton animation="wave" />
									</Grid>
									<Grid item xs={12} sm={8} lg={4}>
										<Skeleton animation="wave" />
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					)}

					{
						showApproveDialog && (
							<>
								<SubmissionEpiqDocumentApproveModal
									submissionName={submissionDetail.submissionDescription}
									caseName={submissionDetail.caseName}
									institutionName={submissionDetail.institutionName}
									submissionId={submissionDetail.id}
									title={title}
									body={submissionDocumentsModalOptions}
									documentId={selectedDocId}
									modalOpen={approveDialogOpen}
									handleClose={handleApproveDialogClose}
									caseId={submissionDetail.caseId}
									mailingBatchId={selectedMailingBatchId}
								/>

							</>
						)
					}

					{(submissionListState.error || submissionListState.errorGetDocuments) && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
				</SecuritiesCard>
			</Grid>
		</Grid>
	);
}