import { SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE } from '../../constants/CommonConstants';
import { ISubmissionListState } from "../../types/types";
import { Nominee } from '../../constants/roles';
import { Card, CardContent, Grid, Skeleton } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import SubmissionDetailTable from "../../components/Shared/SecuritiesTable/SubmissionDetailTable";
import {
	IColumnHeader,
	ISecuritiesTableFieldMap,
	ISorter,
} from "../../components/Shared/SecuritiesTable/SecuritiesTable";
import { getRelatedSubmissionsAction, getSubmissionDocumentAction } from "../Submissions/submissions.actions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UserContext, UserContextType } from '../../modules/User/UserContext';
import { TECHNICAL_ERROR_MESSAGE } from "../../constants/CommonConstants";
import { getSubmissionStatusText } from '../../utilities/submissionStatus';
import React from 'react';

const relatedSubmissionColumns: IColumnHeader[] = [
	{ field: "dateOfSubmission", label: "Date of Submission", hideSortIcon: true },
	{ field: "number", label: "Submission #", hideSortIcon: true },
	{ field: "fileName", label: "File Name", hideSortIcon: true },
	{ field: "submittedByUserName", label: "Submitted By User", hideSortIcon: true },
	{ field: "status", label: "Status", hideSortIcon: true }
];

const sortInfo: ISorter = {
} as ISorter;

interface IRelatedSubmissionGridProps {
	submissionId: number;
	idToExpandOnLoad?: number;
}

let scrollIndex = -1;

export default function RelatedSubmissionGrid({ submissionId, idToExpandOnLoad }: IRelatedSubmissionGridProps) {
	const dispatch = useDispatch();
	const { isInRole } = useContext(UserContext) as UserContextType;
	const submissionState: ISubmissionListState = useSelector((state: any) => state.submissionListState);

	const ref = useRef(null);

	useEffect(() => {
		dispatch(getRelatedSubmissionsAction(submissionId));
	}, [dispatch, submissionId]);

	useEffect(() => {
		setTimeout(() => {
			var el = ref && ref.current ? ref.current as unknown as HTMLElement : null;

			if (el && scrollIndex > 0) {
				el.scrollTo({ top: scrollIndex * 50, behavior: 'smooth' });
				scrollIndex = -1;
			}
		}, 1600);
	}, [ref]);

	const getSubmissionDocument = (submissionId: number) => {
		dispatch(getSubmissionDocumentAction(submissionId));
	}

	const buildRelatedSubmissionRows = (): ISecuritiesTableFieldMap[] => {
		var relatedSubmissionRows: ISecuritiesTableFieldMap[] = [];

		if (!submissionState.relatedSubmissions || submissionState.relatedSubmissions.length < 1) return relatedSubmissionRows;

		submissionState.relatedSubmissions.forEach((item, index) => {
			if (isInRole([Nominee]) && item.status.toLowerCase() === "error")
				item.status = SUBMISSION_ERROR_STATUS_TEXT_FOR_NOMINEE;

			let newItem: ISecuritiesTableFieldMap = {
				id: { type: 'number', value: item.id },
				dateOfSubmission: { type: "date", value: item.dateOfSubmission },
				number: {
					type: "string",
					value: item.submissionDescription,
					sortValue: item.submissionDescription
				},
				fileName: {
					type: "link",
					value: (
						<Link
							color="success"
							to="#"
							onClick={() => { getSubmissionDocument(item.id) }}
						>
							{item.fileName}
						</Link>

					),
					sortValue: item.fileName,
				},
				submittedByUserName: {
					type: "string",
					value: item.submittedByUserName
				},
				status: {
					type: "string",
					value: (
						<span>{getSubmissionStatusText(isInRole([Nominee]), item.status) || ''}</span>
					),
					sortValue: item.status,
				},
				isNonClaimDeficiencyResponse: { type: 'boolean', value: item.isNonClaimDeficiencyResponse }
			};

			relatedSubmissionRows.push(newItem);
		});

		// return relatedSubmissionRows sorted by id descending

		return relatedSubmissionRows.sort((a, b) => {
			if (a.id.value > b.id.value) return -1;
			if (a.id.value < b.id.value) return 1;
			return 0;
		});
	};

	const doTheScroll = (index: number) => {
		scrollIndex = index;
	};

	const rows = buildRelatedSubmissionRows();

	return (
		<>
			{(submissionState.isLoading === true) ?
				(<Card>
					<CardContent>
						{[...Array(5)].map((elementInArray, index) => (
							<Grid container spacing={2} key={index}>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={4}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
								<Grid item xs={2}>
									<Skeleton animation="wave" />
								</Grid>
							</Grid>
						))}
					</CardContent>
				</Card>)
				:
				(<>
					<SubmissionDetailTable
						ref={ref}
						columns={relatedSubmissionColumns}
						rows={rows}
						sorter={sortInfo}
						idToExpandOnLoad={idToExpandOnLoad}
						maxTableHeight={525}
						scrollFunc={doTheScroll}
					/>
				</>)}
			{(submissionState.errorGetRelatedSubmissions || submissionState.errorGetSubmissionDocument) && <p style={{ color: 'red' }}>{TECHNICAL_ERROR_MESSAGE}</p>}
		</>
	);
}